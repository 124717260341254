import { usePortfolioItemAssets } from 'hooks/useAssets';
import { useViewPortfolioItemContext } from '../ViewPortfolioItem';
import ViewPortfolioItemAboutSection from '../ViewPortfolioItemAboutSection';
import VimeoPlayer from '../../../common/VimeoPlayer/VimeoPlayer';
import ViewPortfolioItemSection from '../ViewPortfolioItemSection';
import RevealWhenInView from '../../../common/RevealInView/RevealWhenInView';
import { useTranslation } from 'react-i18next';
import ViewPortfolioRutubeSection from './ViewPortfolioRutubeSection';

export interface ViewPortfolioRutubeProps {}

const ViewPortfolioRutube = (props: ViewPortfolioRutubeProps) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);
  const { t } = useTranslation();

  return (
    <>
      <ViewPortfolioItemAboutSection
        dynamicBackgroundColor={false}
        style={{ backgroundColor: '#FF54A6', color: '#fff' }}
        name={data.name}
        image={{
          isLogo: true,
          src: buildPageImageSrc('about', 'svg'),
        }}
        description={data.description}
      />
      {/*'экраны 3-5*/}
      <ViewPortfolioRutubeSection quantity={3} startingSection={3} />
      {/*'экран 6*/}
      <ViewPortfolioItemSection
        style={{ backgroundColor: '#FDFDFD' }}
        className="portfolio-item-section--type-rutube-6">
        <RevealWhenInView>
          <img
            src={buildPageImageSrc(t(`portfolioItem:rutube.section-6.image`))}
            alt={'rutube'}
          />
        </RevealWhenInView>
        <div className="rutube-section-video">
          <VimeoPlayer
            size="fill"
            url={t(`portfolioItem:rutube.section-6.video`)}
            play
            muted
            controls={false}
            loop={true}
          />
        </div>
      </ViewPortfolioItemSection>
      {/*'экран 7*/}
      <ViewPortfolioRutubeSection quantity={1} startingSection={7} />
      {/*'экран 8*/}
      <ViewPortfolioItemSection
        style={{ backgroundColor: '#FDFDFD' }}
        className="portfolio-item-section--type-rutube-8">
        <RevealWhenInView>
          <img
            src={buildPageImageSrc(t(`portfolioItem:rutube.section-8.image`))}
            alt={'rutube'}
          />
        </RevealWhenInView>
        <div className="rutube-section-video">
          <VimeoPlayer
            size="fill"
            url={t(`portfolioItem:rutube.section-8.video`)}
            play
            muted
            controls={false}
            loop={true}
          />
        </div>
      </ViewPortfolioItemSection>
      {/*'экран 9*/}
      <ViewPortfolioRutubeSection quantity={1} startingSection={9} />
      {/*'экран 10*/}
      <ViewPortfolioItemSection
        style={{ backgroundColor: '#FDFDFD' }}
        className="portfolio-item-section--type-rutube-10">
        <div className="rutube-section-video">
          <VimeoPlayer
            size="fill"
            url={t(`portfolioItem:rutube.section-10.video`)}
            play
            muted
            controls={false}
            loop={true}
          />
        </div>
      </ViewPortfolioItemSection>
      {/*'экран 11-12*/}
      <ViewPortfolioRutubeSection quantity={2} startingSection={11} />
      {/*'экран 13*/}
      <ViewPortfolioItemSection className="portfolio-item-section--type-rutube-13">
        <div className="rutube-section-video">
          <VimeoPlayer
            size="fill"
            url={t(`portfolioItem:rutube.section-13.video`)}
            play
            muted
            controls={false}
            loop={true}
          />
        </div>
      </ViewPortfolioItemSection>
    </>
  );
};

export default ViewPortfolioRutube;
