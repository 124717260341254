import ViewPortfolioWomanSport from 'components/views/ViewPortfolioItem/ViewPortfolioWomanSportBranding';
import { PortfolioItemDataWithComponent } from 'data/portfolio/types';
import { buildPublicImageSrc } from 'helpers/path';

const porfolioItem: PortfolioItemDataWithComponent = {
  id: 'woman-sport-branding',
  component: ViewPortfolioWomanSport,
  title: 'woman-sport',
  name: 'Woman sport',
  altName: 'Женский\nфитнес-клуб',
  year: 2019,
  text: 'wsportclub.ru',

  socials: [
    {
      label: 'behance',
      href: 'https://www.behance.net/gallery/88442093/Woman-Sport-Branding',
    },
  ],

  preview: {
    label: 'логотип и фирменный стиль',
    media: {
      type: 'image',
      fromSrc: buildPublicImageSrc({
        path: 'portfolios/woman-sport-branding',
        file: 'preview-from.jpg',
      }),
      toSrc: buildPublicImageSrc({
        path: 'portfolios/woman-sport-branding',
        file: 'preview-to.jpg',
      }),
    },
  },

  poolOfWorks: ['Logotype', 'Brand style'],

  type: 'branding',
  subtype: 'branding',

  description: [
    'женский фитнес-клуб с уникальными круговыми тренировками.',
    'Для него требовалось разработать элементы фирменного стиля с упором на концепцию тренировочного процесса',
  ],
  shortDescription: 'брендинг для женского фитнес-клуба',

  relatedId: 'woman-sport-site',
  seo: {
    title: 'Брендинг для фитнес клуба Woman Sport',
    description:
      'Разработка логотипа и фирменного стиля (брендинг) для женского фитнес клуба Woman Sport включающий в себя дизайн карт абонементов, формы тренеров, спортивного инвентаря посетителей, дневников тренировок, папок, визиток, бланков, конвертов и т.д.',
    keywords: [
      'создание логотипа фитнес клуба',
      'брендинг женского клуба',
      'фирменный стиль фитнес клуба',
      'фирстиль спорт зала',
      'дизайн карт фитнес клуба',
      'форма и бейджи для тренеров',
      'дизайн дневника тренировок',
    ],
    openGraph: {
      type: 'article',
      title: 'Брендинг для фитнес клуба Woman Sport',
      description:
        'Разработка логотипа и фирменного стиля (брендинг) для женского фитнес клуба Woman Sport включающий в себя дизайн карт абонементов, формы тренеров, спортивного инвентаря посетителей, дневников тренировок, папок, визиток, бланков, конвертов и т.д.',
    },
  },
};

export default porfolioItem;
