import { usePortfolioItemAssets } from 'hooks/useAssets';
import { useViewPortfolioItemContext } from '../ViewPortfolioItem';
import ViewPortfolioItemAboutSection from '../ViewPortfolioItemAboutSection';
import ViewPortfolioItemSection from '../ViewPortfolioItemSection';
import RevealWhenInView from '../../../common/RevealInView/RevealWhenInView';
import { useTranslation } from 'react-i18next';
import VimeoPlayer from "../../../common/VimeoPlayer/VimeoPlayer";
import React from "react";

export interface ViewPortfolioSk10Props {}

const ViewPortfolioSk10Site = (props: ViewPortfolioSk10Props) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);
  const { t } = useTranslation();

  return (
    <>
      <ViewPortfolioItemAboutSection
        dynamicBackgroundColor={true}
        className="view-portfolio-item-about-section--type-sk10-site"
        caption={[t("portfolioItem:sk10-site.about.0"), t("portfolioItem:sk10-site.about.1"), t("portfolioItem:sk10-site.about.2"), ]}
        video={{
            src: 'https://vimeo.com/996100103/c60bc20173',
        }}
        style={{
          backgroundColor: '#264D9C',
          color: '#FFF',
          backgroundPosition: 'top left',
          backgroundSize: 'cover',
        }}
        name={data.name}
        description={data.description}
      />

      {/* 3ий экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#F3F4F4' }} className={'row view-portfolio-sk10-section portfolio-item-section--type-sk10-3'}>
          <div className={'col-half'}>
              <RevealWhenInView>
                  <img src={buildPageImageSrc(t('portfolioItem:sk10-site.section-3.image.0'))} alt={'sk10'}/>
              </RevealWhenInView>
          </div>
          <div className={'col-half'}>
              <RevealWhenInView>
                  <img src={buildPageImageSrc(t('portfolioItem:sk10-site.section-3.image.1'))} alt={'sk10'}/>
              </RevealWhenInView>
          </div>
      </ViewPortfolioItemSection>

      {/* 4ий экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#F3F4F4' }} className={'view-portfolio-sk10-section portfolio-item-section--type-sk10-4'}>
          <RevealWhenInView>
              <img src={buildPageImageSrc(t('portfolioItem:sk10-site.section-4.image'))} alt={'sk10'}/>
          </RevealWhenInView>
      </ViewPortfolioItemSection>

      {/* 5ий экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#264D9C' }} className={'view-portfolio-sk10-section portfolio-item-section--type-sk10-5'}>
          <RevealWhenInView>
              <div className={"u-ratio"}>
                  <VimeoPlayer  size="fill"  url={'https://vimeo.com/996190182/929ccd3b66'} play muted controls={false} loop={true} />
              </div>
          </RevealWhenInView>
      </ViewPortfolioItemSection>

      {/* 6ой экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#F3F4F4' }} className={'row view-portfolio-sk10-section portfolio-item-section--type-sk10-6'}>
            <div className={'col-11'}>
                <RevealWhenInView>
                    <img src={buildPageImageSrc(t('portfolioItem:sk10-site.section-6.image.0'))} alt={'sk10'}/>
                </RevealWhenInView>
            </div>
            <div className={'col-half'}>
                <RevealWhenInView>
                    <img src={buildPageImageSrc(t('portfolioItem:sk10-site.section-6.image.1'))} alt={'sk10'}/>
                </RevealWhenInView>
            </div>
            <div className={'col-half'}>
                <RevealWhenInView>
                    <img src={buildPageImageSrc(t('portfolioItem:sk10-site.section-6.image.2'))} alt={'sk10'}/>
                </RevealWhenInView>
            </div>
        </ViewPortfolioItemSection>

      {/* 7ой экран*/}
      <ViewPortfolioItemSection dynamicBackgroundColor={true} style={{ backgroundColor: '#F3F4F4' }}>
          <RevealWhenInView>
              <img src={buildPageImageSrc(t('portfolioItem:sk10-site.section-7.image'))} alt={'sk10'}/>
          </RevealWhenInView>
      </ViewPortfolioItemSection>

      {/* 8ой экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#F3F4F4' }} className={'row view-portfolio-sk10-section portfolio-item-section--type-sk10-8'}>
          <div className={'col-11'}>
              <RevealWhenInView>
                  <div className={"u-ratio"}>
                      <VimeoPlayer  size="fill"  url={'https://vimeo.com/996108385/da4d39ae0d'} play muted controls={false} loop={true} />
                  </div>
              </RevealWhenInView>
          </div>
          <div className={'col-half'}>
              <RevealWhenInView>
                  <img src={buildPageImageSrc(t('portfolioItem:sk10-site.section-8.image.1'))} alt={'sk10'}/>
              </RevealWhenInView>
          </div>
          <div className={'col-half'}>
              <RevealWhenInView>
                  <img src={buildPageImageSrc(t('portfolioItem:sk10-site.section-8.image.2'))} alt={'sk10'}/>
              </RevealWhenInView>
          </div>
          <div className={'over-img'}>
              <RevealWhenInView>
                  <img src={buildPageImageSrc(t('portfolioItem:sk10-site.section-8.image.0'))} alt={'sk10'}/>
              </RevealWhenInView>
          </div>
      </ViewPortfolioItemSection>

      {/* 9ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={false}
        style={{ backgroundColor: '#F3F4F4' }}>
          <RevealWhenInView>
              <img src={buildPageImageSrc(t('portfolioItem:sk10-site.section-9.image'))} alt={'sk10'}/>
          </RevealWhenInView>
      </ViewPortfolioItemSection>

      {/* 10ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={false}
        style={{ backgroundColor: '#F3F4F4' }} className={'row view-portfolio-sk10-section portfolio-item-section--type-sk10-10'}>
          <RevealWhenInView>
              <img src={buildPageImageSrc(t('portfolioItem:sk10-site.section-10.image'))} alt={'sk10'}/>
          </RevealWhenInView>
      </ViewPortfolioItemSection>

      {/* 11ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={false}
        style={{ backgroundColor: '#F3F4F4' }}>
          <RevealWhenInView>
              <img src={buildPageImageSrc(t('portfolioItem:sk10-site.section-11.image'))} alt={'sk10'}/>
          </RevealWhenInView>
      </ViewPortfolioItemSection>

      {/* 12ый экран*/}
      <ViewPortfolioItemSection dynamicBackgroundColor={false} style={{ backgroundColor: '#F3F4F4' }}
                                className={'row view-portfolio-sk10-section portfolio-item-section--type-sk10-12'}>
        <RevealWhenInView>
            <img src={buildPageImageSrc(t('portfolioItem:sk10-site.section-12.image'))} alt={'sk10'}/>
        </RevealWhenInView>
      </ViewPortfolioItemSection>

        {/* 13ый экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={false}
            style={{ backgroundColor: '#F3F4F4' }}>
            <RevealWhenInView>
                <img src={buildPageImageSrc(t('portfolioItem:sk10-site.section-13.image'))} alt={'sk10'}/>
            </RevealWhenInView>
        </ViewPortfolioItemSection>

        {/* 14ой экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#F3F4F4' }} className={'row view-portfolio-sk10-section portfolio-item-section--type-sk10-14'}>
            <div className={'col-half'}>
                <RevealWhenInView>
                    <img src={buildPageImageSrc(t('portfolioItem:sk10-site.section-14.image'))} alt={'sk10'}/>
                </RevealWhenInView>
            </div>
            <div className={'col-half'}>
                <RevealWhenInView>
                    <div className={"u-ratio"}>
                        <VimeoPlayer  size="fill"  url={'https://vimeo.com/997607411/ebcf9bc505'} play muted controls={false} loop={true} />
                    </div>
                </RevealWhenInView>
            </div>
        </ViewPortfolioItemSection>

        {/* 15ой экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#F3F4F4' }} className={'row view-portfolio-sk10-section portfolio-item-section--type-sk10-15'}>
            <div className={'col-half'}>
                <RevealWhenInView>
                    <img src={buildPageImageSrc(t('portfolioItem:sk10-site.section-15.image.0'))} alt={'sk10'}/>
                </RevealWhenInView>
            </div>
            <div className={'col-half'}>
                <RevealWhenInView>
                    <img src={buildPageImageSrc(t('portfolioItem:sk10-site.section-15.image.1'))} alt={'sk10'}/>
                </RevealWhenInView>
            </div>
        </ViewPortfolioItemSection>

        {/* 16ый экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#F3F4F4' }} className={'row view-portfolio-sk10-section portfolio-item-section--type-sk10-16'}>
            <RevealWhenInView>
                <img src={buildPageImageSrc(t('portfolioItem:sk10-site.section-16.image'))} alt={'sk10'}/>
            </RevealWhenInView>
        </ViewPortfolioItemSection>

        {/* 17ый экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#F3F4F4' }}>
            <RevealWhenInView>
                <img src={buildPageImageSrc(t('portfolioItem:sk10-site.section-17.image'))} alt={'sk10'}/>
            </RevealWhenInView>
        </ViewPortfolioItemSection>
    </>
  );
};

export default ViewPortfolioSk10Site;
