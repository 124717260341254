import { useRef } from "react";
import ViewPrivacyPolicyContentSection from "./ViewPrivacyPolicyContentSection";
import ViewPrivacyPolicyLeadSection from "./ViewPrivacyPolicyLeadSection";
import Footer from "components/layouts/Footer";
import View from "components/layouts/View";

import "./_privacy-policy.scss"

interface ViewPrivacyPolicyProps {}
const ViewPrivacyPolicy = (props: ViewPrivacyPolicyProps) => {
  const viewElRef = useRef<HTMLDivElement>(null);

  return (
    <View ref={viewElRef} className="privacy-policy">
      <ViewPrivacyPolicyLeadSection />
      <ViewPrivacyPolicyContentSection />
      <Footer />
    </View>
  );
};

export default ViewPrivacyPolicy;
