import ViewPortfolioSk10 from 'components/views/ViewPortfolioItem/ViewPortfolioSk10/ViewPortfolioSk10';
import { PortfolioItemDataWithComponent } from 'data/portfolio/types';

const porfolioItem: PortfolioItemDataWithComponent = {
  id: 'sk-10',
  component: ViewPortfolioSk10,
  title: 'sk10',
  name: 'sk-10',
  altName: 'construction company',
  year: 2022,
  awardsList: [],

  link: {
    label: 'sk10.ru',
    href: 'https://sk10.ru/',
  },

  socials: [
    {
      label: 'behance',
      href: 'https://www.behance.net/gallery/136321967/UX-CASE-Construction-company-SK-10',
    },
  ],

  preview: {
    label: 'corporate site',
    backgroundColor: '#1F1F21',
    media: {
      type: 'video',
      src: 'https://player.vimeo.com/video/812389979?h=ed8123b72c',
    },
  },

  poolOfWorks: ['Research', 'Job-stories', 'Cjm', 'Wireframing'],

  type: 'interfaces',
  subtype: 'interfaces',

  description: [
    'company engaged in the construction of residential spaces. Our task was to make a technical requirement for in-house work with hi-fi wireframes, create the logic of interaction with the user interface',
  ],
  shortDescription: 'website for the construction company',

  seo: {
    title: 'Designing UX and user experience for a large developer SK10',
    description:
      'We designed a website portal for a large developer of apartment buildings, where we created a unique user experience for buying apartments and apartments completely online. The unique personal account of the buyer of the apartment allows you to fully automate the purchase of an apartment via the Internet.',
    keywords: [
      'development of a website for a developer',
      "design of a developer's website",
      'a website for the sale of apartments',
      "ux design of a developer's website",
      "creation of a developer's website sk10",
    ],
    openGraph: {
      type: 'article',
      title: 'BDesigning UX and user experience for a large developer SK10',
      description:
        'We designed a website portal for a large developer of apartment buildings, where we created a unique user experience for buying apartments and apartments completely online. The unique personal account of the buyer of the apartment allows you to fully automate the purchase of an apartment via the Internet.',
    },
  },
};

export default porfolioItem;
