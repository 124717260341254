import DocumentLink from "components/common/DocumentLink/DocumentLink";
import s from "./index.module.scss";
import Section from "components/layouts/Section";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import RevealTextByLine from "components/common/RevealInView/RevealTextByLine";
import SlideFadeOutWhenInView from "components/views/ViewPortfolioItem/ViewPortfolioNovaGroup/SlideFadeOutWhenInView";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";

interface ViewDeveloperSitesPriceSectionProps {}

const sectionData = {
  title: "Цены",
  subtitle:
    "Ценообразование всегда максимально прозрачно и рассчитывается по простой формуле",
  price_block: [
    {
      title: "Стоимость",
      caption: "(ставка зависит от специализации и уровня специалиста)",
    },
    {
      title: "Кол-во часов",
      caption: "затраченное время на решение задач в рамках договора",
    },
    {
      title: "1800₽",
      caption: "стоимость часа работы",
    },
  ],

  pay_formats_block: {
    title: "Форматы оплаты",
    items: [
      {
        title: "Fix",
        subtitle:
          "В рамках этого формата работы мы предлагаем фиксированную стоимость за определённый проект или задачу.",
      },
      {
        title: "time material",
        subtitle:
          "Этот формат предоставляет максимальную гибкость. Вы платите только за фактически затраченное время и материалы на проект.",
      },
      {
        title: "retainer",
        subtitle:
          "Вы оплачиваете фиксированную сумму ежемесячно, и мы гарантируем приоритетное обслуживание и гибкий доступ к нашим ресурсам.",
      },
    ],
    // document: {
    //   name: "Расчет стоимости услуг",
    //   size: "592 Кб",
    //   href: "https://school38-ozersk.my1.ru/uchebnaya_deyat/perevod_exzam/matematika-10.pdf",
    //   date: "акт. до 30.11.2023",
    // },
  },
};

const ViewDeveloperSitesPriceSection = (
  props: ViewDeveloperSitesPriceSectionProps
) => {
  return (
    <DynamicBackgroundTrigger>
      <Section
        className={
          s["view-developer-sites-price-section"] + " background--white"
        }
      >
        <div className={" section-container container"}>
          <div className="view-developer-sites-price-section__inner">
            <header>
              <div
                className={
                  "row " + s["view-developer-sites-price-section__header"]
                }
              >
                <RevealWhenInView className={"col-xl-7 "}>
                  <h2 className={"heading " + s.title}>{sectionData.title}</h2>
                </RevealWhenInView>
                <div className=" offset-md-4 col-md offset-xl-0">
                  <RevealTextByLine
                    wordClassName={(l, w) =>
                      l === 0 && w === 0 && "offset-md-3"
                    }
                  >
                    {sectionData.subtitle}
                  </RevealTextByLine>
                </div>
              </div>
            </header>
            {/* Price per hour  */}
            <div
              className={
                "row " +
                s[
                  "view-developer-sites-price-section__price-per-hour-container"
                ] +
                " " +
                s["body-item"]
              }
            >
              {/* Price */}
              <div className="col-auto offset-0 col-md-7 offset-md-4 col-lg-6 offset-lg-0">
                <div
                  className={
                    "heading-2 col-auto col-md-6" + s["heading-2-margin"]
                  }
                >
                  <RevealWhenInView>
                  <p>{sectionData.price_block[0].title}</p>
                  </RevealWhenInView>
                </div>
                <div className="caption col-auto col-md-6">
                  <RevealWhenInView>
                    <p>{sectionData.price_block[0].caption}</p>
                  </RevealWhenInView>
                </div>
              </div>
              {/* per hour */}
              <div className="col-auto offset-0 col-md-7 offset-md-4 col-lg-5 offset-lg-0">
                <RevealWhenInView>
                  <header className={"row " + s["heading-2-margin"]}>
                    <p
                      className={
                        "heading-2 " +
                        s[
                          "view-developer-sites-price-section__heading-2-adaptive"
                        ]
                      }
                    >
                      {sectionData.price_block[1].title}
                    </p>
                    <div className="u-mx-auto row">
                      <p className="m-t-auto label">x</p>
                    </div>
                    <p
                      className={
                        "col-4 heading-2 " +
                        s[
                          "view-developer-sites-price-section__heading-2-adaptive"
                        ]
                      }
                    >
                      {sectionData.price_block[2].title}
                    </p>
                  </header>
                </RevealWhenInView>
                <RevealWhenInView>
                  <div className="row">
                    <p className="col-5 caption">
                      {sectionData.price_block[1].caption}
                    </p>
                    <p className="col-4 offset-2 caption">
                      {sectionData.price_block[2].caption}
                    </p>
                  </div>
                </RevealWhenInView>
              </div>
            </div>
            {/* Форматы оплаты */}
            <div
              className={
                "row view-developer-sites-price-section__pay-formats-container row " +
                s["body-item"]
              }
            >
              <div className={"col-6 order-1 heading-2 "}>
                <RevealWhenInView>
                  {sectionData.pay_formats_block.title}
                </RevealWhenInView>
              </div>
              {/* <div
                className={
                  "offset-0 col-11 offset-sm-auto col-sm-auto offset-md-0 col-md-6 order-3 " +
                  s[
                    "view-developer-sites-price-section__document-link-container"
                  ]
                }
              >
                <RevealWhenInView>
                  <DocumentLink
                    
                    {...sectionData.pay_formats_block.document}
                  />
                </RevealWhenInView>
              </div> */}
              <SlideFadeOutWhenInView>
                {({ setTarget, setTriggerEl }) => (
                  <div
                    ref={setTriggerEl}
                    className={"col-11 col-sm-5 row order-2 "}
                  >
                    <div ref={setTarget({ selector: ".selector-1" })}>
                      {sectionData.pay_formats_block.items.map(
                        (elem, index) => {
                          return (
                            <div
                              className={
                                "row col-11 selector-1 " +
                                s[
                                  "view-developer-sites-price-section__payment-steps-item"
                                ]
                              }
                              key={index}
                            >
                              <span
                                className={"side-brackets label " + s.title}
                              >
                                {elem.title}
                              </span>
                              <span className={"description"}>
                                {elem.subtitle}
                              </span>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
              </SlideFadeOutWhenInView>
            </div>
          </div>
        </div>
      </Section>
    </DynamicBackgroundTrigger>
  );
};

export default ViewDeveloperSitesPriceSection;
