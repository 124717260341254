import ViewPortfolioEnsembleBranding from 'components/views/ViewPortfolioItem/ViewPortfolioEnsembleBranding';
import { PortfolioItemDataWithComponent } from "data/portfolio/types";
import {buildPublicImageSrc} from "../../helpers/path";


const porfolioItem: PortfolioItemDataWithComponent = {
  id: "ensemble-branding",
  component: ViewPortfolioEnsembleBranding,
  title: "ensemble",
  name: "Ensemble",
  altName: "Новый исламский\nинтернет-банк во франции",
  year: 2021,
  link: {
    label: "",
    href: "",
  },
  vc: {
    label: "vc.ru",
    href: "https://vc.ru/design/444891-brending-dlya-francuzskogo-neobanka-ensemble",
  },

  socials: [
    {
      label: "",
      href:
        "",
    },
  ],

  preview: {
    label: "презентация",
    media: {
      type: "image",
      fromSrc: buildPublicImageSrc({
        path: "portfolios/ensemble-branding/preview",
        file: "1-1440w.webp",
      }),
      toSrc: buildPublicImageSrc({
        path: "portfolios/ensemble-branding/preview",
        file: "2-1440w.webp",
      }),
    },
  },

  poolOfWorks: ["Presentation"],
  relatedId: "ensemble",
  type: "branding",
  subtype: "branding",

  description: [
    "стремительно развивающийся французский стартап. Банк, финансовая деятельность которого основана на принципах шариата."
  ],
  shortDescription:
      "Оформление презентации французского онлайн банка Ensemble",
  seo: {
    title: "Создание презентации для французского онлайн банка Ensemble",
    description: "Разработка оформления красивой презентации для компании Ensemble. Пример созданной презентации для технологического стартапа с сфере банкинга",
    keywords: ["заказать дизайн презентации",
      "разработка презентации",
      "презентация для стартапа",
      "оформление презентации",
      "создание красивой презентации",
      "презентация powerpoint"],
    openGraph: {
      "type": "article",
      "title": "Создание презентации для французского онлайн банка Ensemble",
      "description": "Разработка оформления красивой презентации для компании Ensemble. Пример созданной презентации для технологического стартапа с сфере банкинга"
    }
  }

};

export default porfolioItem;
