import { useViewPortfolioItemContext } from "../ViewPortfolioItem";
import ViewPortfolioItemAboutSection from "../ViewPortfolioItemAboutSection";
import ViewPortfolioItemParallaxSection from "../ViewPortfolioItemParallaxSection";
import ViewPortfolioGosaptekaManagerMonitoringSection from "./ViewPortfolioGosaptekaManagerMonitoringSection";
import ViewPortfolioGosaptekaThemeSection from "./ViewPortfolioGosaptekaThemeSection";
import ViewPortfolioGosaptekaPharmacyListSection from "./ViewPortfolioGosaptekaPharmacyListSection";
import ViewPortfolioGosaptekaManagerMonitoringGallerySection from "./ViewPortfolioGosaptekaManagerMonitoringGallerySection";
import ViewPortfolioItemSection from "../ViewPortfolioItemSection";
import ViewPortfolioItemImage from "../ViewPortfolioItemImage";
import { usePortfolioItemAssets } from "hooks/useAssets";
import {useTranslation} from "react-i18next";

export interface ViewPortfolioGosaptekaProps { }

const ViewPortfolioGosapteka = (props: ViewPortfolioGosaptekaProps) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);
  const { t } = useTranslation();
  return (
    <>
      <ViewPortfolioItemAboutSection
        style={{ backgroundColor: "#DEDEE1" }}
        name={data.name}
        image={{
          src: buildPageImageSrc("screen-2")
        }}
        description={data.description}
      />

      <ViewPortfolioGosaptekaThemeSection />

      <ViewPortfolioGosaptekaManagerMonitoringSection
        image={{ src: buildPageImageSrc("screen-4") }}
        style={{ backgroundColor: "#DEDEE1" }}
        description={t('portfolioItem:gosapteka.screen-4.text')}

      />
      <ViewPortfolioGosaptekaPharmacyListSection
        style={{
          backgroundColor: "#f1f1f1"
        }}
        size={{
          top: "lg"
        }}
        image={{ src: buildPageImageSrc("screen-5") }}
        caption={[t('portfolioItem:gosapteka.screen-5.text.0'), t('portfolioItem:gosapteka.screen-5.text.1')]}
      />

      <ViewPortfolioItemSection>
        <ViewPortfolioItemImage
          revealAnimation={false}
          src={buildPageImageSrc("screen-6")}
        />
      </ViewPortfolioItemSection>

      <ViewPortfolioGosaptekaManagerMonitoringGallerySection
        style={{ backgroundColor: "#121314" }}
        images={[
          {
            src: buildPageImageSrc("screen-7-1-1"),
          },
          {
            src: buildPageImageSrc("screen-7-2-1")
          },
        ]}
      />
      <ViewPortfolioItemParallaxSection
        dynamicBackgroundColor={false}
        image={{ src: buildPageImageSrc("screen-8") }}
      />
    </>
  );
};

export default ViewPortfolioGosapteka;
