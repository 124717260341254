import ViewPortfolioSkess from "components/views/ViewPortfolioItem/ViewPortfolioSkess";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";

const skess: PortfolioItemDataWithComponent = {
  id: "skess",
  component: ViewPortfolioSkess,
  title: "skess",
  name: "Скэсс",
  altName: "крупнейшая в россии компания\nпо строительству элеваторов",
  year: 2020,
  link: {
    label: "skess.ru",
    href: "https://skess.ru",
  },

  socials: [
    {
      label: "behance",
      href:
        "https://www.behance.net/gallery/100999169/WEBSITE-Grain-silos-construction-company-SKESS",
    },
  ],

  preview: {
    label: "корпоративный сайт",
    media: {
      type: "video",
      src: "https://vimeo.com/542710891",
    },
  },

  poolOfWorks: ["UX|UI design", "Page making", "Integration with 1c-bitrix"],

  type: "site",
  subtype: "site",

  description:
    [
      "крупнейшая в России компания по строительству элеваторов, с собственным производством и проектным отделом.",
      "Нам нужно было создать корпоративный сайт, который бы выгодно отличался от компаний-конкурентов и показал бы все преимущества корпорации СКЭСС"
    ],

  shortDescription:
    "сайт для крупнейшей в России компании по строительству элеваторов",

  // description:
  //   "Нам нужно было создать корпоративный сайт, который бы выгодно отличался  от компаний-конкурентов и показал бы все преимущества корпорации СКЭСС",
  // colors: {
  //   primary: "#0242EC",
  //   primaryLight: "#215DFD",
  // },
  seo:
  {
    title: "Дизайн и разработка корпоративного сайта для СКЭСС ",
    description: "Мы разработали сайт для компании СКЭСС (СевКавЭлеваторСпецСтрой), который имеет 3 языковых версии, 3D-изображения в карточках оборудования, тендерный раздел и интерактивную карту с объектами строительства ",
    keywords: ["создание сайта для промышленной компании", "сайт для производителя оборудования", "сайт по строительству элеваторов", "разработка сайта для пром компании", "севкавэлеваторспецстрой", "корпоративный сайт на 1с битрикс", "3d фотографии в карточки товара"],
    openGraph: {
      type: "article",
      title: "Дизайн и разработка корпоративного сайта для СКЭСС ",
      description: "Мы разработали сайт для компании СКЭСС (СевКавЭлеваторСпецСтрой), который имеет 3 языковых версии, 3D-изображения в карточках оборудования, тендерный раздел и интерактивную карту с объектами строительства ",
    }
  },
  pageContent: {
    sections: {
      mobileVersion: {
        theme: {
          backgroundColor: "#5C9EFF",
          color: "#fff"
        },
        frameCount: 130
      }
    }
  }
  // page: {
  //   section: {
  //     about: {
  //       theme: {
  //         background: {
  //           color: "#fff",
  //         },
  //       },
  //       content: {
  //         topText: [
  //           "Посадочная страница сайта",
  //           "1440 px",
  //           "Проектирование, дизайн, анимация",
  //         ],
  //       },
  //     },
  //     mobileVersion: {
  //       theme: {
  //         text: "light",
  //         background: {\
  //           color: "#5C9EFF",
  //         },
  //       },
  //     },
  //     gallery: {
  //       theme: {
  //         text: "light",
  //         background: {
  //           color: "#191722",
  //         },
  //       },
  //     },
  //   },
  // },
};

export default skess;
