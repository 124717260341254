import ViewPortfolioDomaVekaSite from 'components/views/ViewPortfolioItem/ViewPortfolioDomaVekaSite';
import { PortfolioItemDataWithComponent } from 'data/portfolio/types';
import { buildPublicImageSrc } from '../../helpers/path';

const porfolioItem: PortfolioItemDataWithComponent = {
  id: 'doma-veka-site',
  component: ViewPortfolioDomaVekaSite,
  title: 'doma-veka',
  name: 'Дома века',
  altName: 'тульская строительно-архитектурная компания',
  year: 2021,
  awardsList: [
    { imgSrc: '/images/awards/gray-bg/awwwards.png' },
    { imgSrc: '/images/awards/gray-bg/special-kudos.png' },
    { imgSrc: '/images/awards/gray-bg/ux.png' },
    { imgSrc: '/images/awards/gray-bg/ui.png' },
    { imgSrc: '/images/awards/gray-bg/innovation.png' },
    { imgSrc: '/images/awards/gray-bg/DP.svg' },
    {imgSrc : "/images/awards/gray-bg/wda-gold.svg"},
  ],

  link: {
    label: 'doma-veka.ru',
    href: 'https://doma-veka.ru/',
  },

  socials: [
    {
      label: 'behance',
      href: 'https://www.behance.net/gallery/130912363/WEBSITE-Houses-of-century-construction-company',
    },
  ],
  preview: {
    label: 'корпоративный сайт',
    media: {
      type: 'image',
      fromSrc: buildPublicImageSrc({
        path: 'portfolios/wildberries/preview',
        file: '1-1440w.webp',
      }),
      toSrc: buildPublicImageSrc({
        path: 'portfolios/wildberries/preview',
        file: '2-1440w.webp',
      }),
    },
  },

  poolOfWorks: ['UX|UI design', 'Animation', 'Page making'],

  type: 'site',
  subtype: 'site',

  description: [
    'Мы изменили дизайн сайта и брендинга. Пожелания от заказчика были сделать простой в использовании сайт со сдержанной цветовой схемой, что мы и сделали.',
  ],
  shortDescription: 'сайт для Тульской строительно-архитектурной компании',
  relatedId: 'doma-veka',

  seo: {
    title: 'Разработка корпоративного сайта строительной компании Дома-Века',
    description:
      'Мы разработали уникальный корпоративный сайт для компании по строительству частных домов Дома-Века, который отвечате всем потребностям строительных компаний, имеет удобную систему администрирования сайта, что позволяет удобно управлять каталогом проектов и изменением контента на сайте.',
    keywords: [
      'разработка сайта',
      'корпоративный сайт',
      'строительная компания',
      'Дома-Века',
      'уникальный веб-сайт',
      'функциональный сайт',
      'сайт для строительной компании',
      'строительство частных домов',
      'разработка сайтов для застройщиков',
    ],
    openGraph: {
      type: 'article',
      title: 'Разработка корпоративного сайта строительной компании Дома-Века',
      description:
        'Мы разработали уникальный корпоративный сайт для компании по строительству частных домов Дома-Века, который отвечате всем потребностям строительных компаний, имеет удобную систему администрирования сайта, что позволяет удобно управлять каталогом проектов и изменением контента на сайте.',
    },
  },
};

export default porfolioItem;
