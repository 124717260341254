import ViewPortfolioDomaVeka from 'components/views/ViewPortfolioItem/ViewPortfolioDomaVeka';
import { PortfolioItemDataWithComponent } from 'data/portfolio/types';
import { buildPublicImageSrc } from 'helpers/path';

const skess: PortfolioItemDataWithComponent = {
  id: 'doma-veka',
  component: ViewPortfolioDomaVeka,
  title: 'doma-veka-en',
  name: 'Doma veka',
  altName: 'Tula architectural \nand construction company',
  year: 2020,
  link: {
    label: 'doma-veka.ru',
    href: 'https://doma-veka.ru',
  },

  preview: {
    label: 'corporate identity',
    backgroundColor: '#F1F1F1',
    media: {
      type: 'image',
      fromSrc: buildPublicImageSrc({
        path: 'portfolios/doma-veka',
        file: 'preview-from.jpg',
      }),
      toSrc: buildPublicImageSrc({
        path: 'portfolios/doma-veka',
        file: 'preview-to.jpg',
      }),
    },
  },

  socials: [
    {
      label: 'behance',
      href: 'https://www.behance.net/gallery/105153403/BRANDING-Construction-company-Doma-Veka',
    },
  ],

  poolOfWorks: ['Logotype', 'Brand style'],

  type: 'branding',
  subtype: 'branding',

  description: [
    'a Russian architectural and construction company based in Tula. It is a large company founded in 2007.',
    'The icon for the brand was created based on the concept of combining traditional and modern construction. This sign follows the contours of a high-tech building and at the same time follows the contours of a semi-traditional home.',
  ],
  shortDescription:
    'branding for the Tula architectural and construction company',
  relatedId: 'doma-veka-site',
  seo: {
    title: 'Branding for the construction company Doma veka',
    description:
      'Rebranding of the construction company Doma veka, including a complete redesign of the logo and the creation of all the necessary elements of the corporate identity (business cards, plates, envelopes, letterheads, brochures, etc.)',
    keywords: [
      'corporate identity for a construction company',
      'logo for a construction company',
      'design for builders',
      'order a logo for a construction company',
      'logo creation',
    ],
    openGraph: {
      type: 'article',
      title: 'Branding for the construction company Doma veka',
      description:
        'Rebranding of the construction company Doma veka, including a complete redesign of the logo and the creation of all the necessary elements of the corporate identity (business cards, plates, envelopes, letterheads, brochures, etc.)',
    },
  },
};

export default skess;
