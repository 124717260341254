import ViewPortfolioBanners from "components/views/ViewPortfolioItem/ViewPortfolioBanners/";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";

const porfolioItem: PortfolioItemDataWithComponent = {
  id: "banners",
  component: ViewPortfolioBanners,
  title: "bannersEn",
  name: "Banners",
  altName: "banners for various \n companies",
  year: '2023-2024',
  awardsList: [],

  link: {
    label: "",
    href: "",
  },

  socials: [
    {
      label: "",
      href: "",
    },
  ],

  preview: {
    label: "corporate identity",
    backgroundColor: "#00387A",
    media: {
      type: "video",
      src: "https://vimeo.com/1005381964/4d94c87543",
    },
  },

  poolOfWorks: ['Research', 'Design'],

  type: 'branding',
  subtype: 'branding',

  description: [
    "this project was created as part of the training of interns. Below are banners in the following categories fashion, cosmetics, pharmacology, entertainment, food, e-commerce, construction",
    "The project was created for non-commercial use. All rights belong to their authors."
  ],
  shortDescription: "Сайт-портфолио для студии художественного литья",

  seo: {
    title:
        "Creation of banner designs for various industries",
    description:
        "We implemented banner designs for a wide range of industries: fashion, cosmetics, medicine, marketplaces, real estate, and others. Creative design support for brands to enhance visual communication and promote products effectively",
    keywords: [
      "banners design ",
      "fashion banners",
      "cosmetics banners",
      "medicine banners",
      "marketplace banners",
      "e-commerce banners",
      "real estate banners",
      "e-commerce design",
      "visual advertising"
    ],
    openGraph: {
      type: "article",
      title:
          "Creation of banner designs for various industries",
      description:
          "We implemented banner designs for a wide range of industries: fashion, cosmetics, medicine, marketplaces, real estate, and others. Creative design support for brands to enhance visual communication and promote products effectively",
    },
  },
};

export default porfolioItem;
