import classNames from "classnames";
import { ElementType, ReactNode } from "react";

export interface DefinitionProps {
  /**
   * Подпись
   */
  label?: string;
  /**
   * Термин
   */
  term: string;
  /**
   * Описание
   */
  description?: string | string[] /* | [[string, string]] */;
  /**
   * @default "p"
   */
  tag?: ElementType<any>;
  className?: string;
  children?: ReactNode;
}

const Definition = ({
  tag: Tag = "div",
  label,
  term,
  description,
  className,
  children
}: DefinitionProps) => {
  return (
    //@ts-ignore
    <Tag className={classNames("definition", className)}>
      {label && (
        <div className="definition__label label side-brackets">{label}</div>
      )}
      <div className="definition__term">{term}&nbsp;&mdash;&nbsp;</div>
      <div className="definition__description">
        {children ? children : (Array.isArray(description)
          ? description.map((descriptionItem: string, i) => (
            <span key={i} className="definition__description--item">
              {descriptionItem}
            </span>
          ))
          : description)}
      </div>
    </Tag>
  );
};

export default Definition;
