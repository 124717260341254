import { usePortfolioItemAssets } from 'hooks/useAssets';
import { useViewPortfolioItemContext } from '../ViewPortfolioItem';
import ViewPortfolioItemAboutSection from '../ViewPortfolioItemAboutSection';
import VimeoPlayer from '../../../common/VimeoPlayer/VimeoPlayer';
import ViewPortfolioItemSection from '../ViewPortfolioItemSection';
import RevealWhenInView from '../../../common/RevealInView/RevealWhenInView';
import ViewPortfolioItemParallaxSection from '../ViewPortfolioItemParallaxSection';

export interface ViewPortfolioMynaretProps {}

const ViewPortfolioMynaret = (props: ViewPortfolioMynaretProps) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);

  return (
    <>
      <ViewPortfolioItemAboutSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#F3F4F4', color: '#1F1F21' }}
        name={data.name}
        image={{
          isLogo: true,
          src: buildPageImageSrc('about', 'svg'),
        }}
        description={data.description}
      />
      {/* 3ий экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={false}
        style={{ backgroundColor: '#F3F4F4' }}>
        <div>
          <RevealWhenInView>
            <img src={buildPageImageSrc('screen-3')} alt={'mynaret'} />
          </RevealWhenInView>
        </div>
      </ViewPortfolioItemSection>

      {/* 4ый экран*/}
      <ViewPortfolioItemParallaxSection
        dynamicBackgroundColor={false}
        image={{
          src: buildPageImageSrc('screen-4'),
          sources: [
            {
              type: 'webp',
              srcSet: buildPageImageSrc('screen-4'),
            },
          ],
        }}
      />
      {/* 5ый экран*/}
      <ViewPortfolioItemParallaxSection
        dynamicBackgroundColor={false}
        image={{
          src: buildPageImageSrc('screen-5'),
          sources: [
            {
              type: 'webp',
              srcSet: buildPageImageSrc('screen-5'),
            },
          ],
        }}
      />
      {/* 6ой экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={false}
        style={{ backgroundColor: '#e6e8ea' }}>
        <div>
          <RevealWhenInView>
            <img src={buildPageImageSrc('screen-6')} alt={'mynaret'} />
          </RevealWhenInView>
        </div>
      </ViewPortfolioItemSection>

      {/* 7ой экран*/}
      <ViewPortfolioItemSection
        style={{ backgroundColor: '#F3F4F4' }}
        dynamicBackgroundColor={true}
        className="portfolio-item-section--type-mynaret-7">
        <div className="mynaret-section-video">
          <VimeoPlayer
            size="fill"
            url="https://player.vimeo.com/video/672661069?h=f858614460"
            play
            muted
            controls={false}
            loop={true}
          />
        </div>
      </ViewPortfolioItemSection>
      {/* 8ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#F3F4F4' }}
        className="portfolio-item-section--type-mynaret-8 ">
        <RevealWhenInView>
          <img src={buildPageImageSrc('screen-8')} alt={'mynaret'} />
        </RevealWhenInView>
        <div className="mynaret-section-video">
          <VimeoPlayer
            size="fill"
            url="https://player.vimeo.com/video/674871237?h=ae4c29b0f9"
            play
            muted
            controls={false}
            loop={true}
          />
        </div>
      </ViewPortfolioItemSection>
      {/* 9ый экран*/}
      <ViewPortfolioItemParallaxSection
        dynamicBackgroundColor={false}
        image={{
          src: buildPageImageSrc('screen-9'),
          sources: [
            {
              type: 'webp',
              srcSet: buildPageImageSrc('screen-9'),
            },
          ],
        }}
      />
      {/* 10ый экран*/}
      <ViewPortfolioItemParallaxSection
        dynamicBackgroundColor={false}
        image={{
          src: buildPageImageSrc('screen-10'),
          sources: [
            {
              type: 'webp',
              srcSet: buildPageImageSrc('screen-10'),
            },
          ],
        }}
      />
    </>
  );
};

export default ViewPortfolioMynaret;
