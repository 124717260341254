import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { getDistortionShaderMaterialParameters } from "helpers/shaders/distortion";
import * as THREE from "three";
import gsap from "gsap";
import { debounce } from "debounce";
import Image, { ImageConfig } from "../Image/Image";
import { useMemo } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export interface DistortionImageProps {
  /**
   * Картинки
   */
  image: {
    /**
     * Первая
     */
    first: ImageConfig;
    /**
     * Вторая
     */
    second: ImageConfig;
    /**
     * Картинка задающая эффект деформации
     */
    displacement: ImageConfig;
  };

  /**
   * @default 1
   */
  intensity?: number;
  /**
   * Продолжительность эффекта
   *
   * @default 0.6
   */
  duration?: number;
  /**
   * @default "ease"
   */
  easing?: string | gsap.EaseFunction;
  /**
   * @default 0
   */
  to?: 0 | 1;

  className?: string;
}

type ImagesNamesType = keyof DistortionImageProps["image"];

const DistortionImage = ({
  image,
  intensity = 0.5,
  duration = 0.6,
  to,
  easing = "ease",
  className,
}: DistortionImageProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [material, setMaterial] = useState<THREE.ShaderMaterial | null>(null);
  const [textures, setTextures] = useState<Record<
    ImagesNamesType,
    THREE.Texture
  > | null>(null);
  const [isCanvas, setIsCanvas] = useState(true)
  const [loadedImages, setLoadedImages] = useState<
    Record<ImagesNamesType, string | null>
  >({
    first: null,
    second: null,
    displacement: null,
  });

  const isAllImagesLoaded = useMemo(() => {
    return (
      loadedImages.first !== null &&
      loadedImages.second !== null &&
      loadedImages.displacement !== null
    );
  }, [loadedImages]);

  const [sceneController, setSceneController] = useState<{
    scene: THREE.Scene;
    camera: THREE.OrthographicCamera;
    renderer: THREE.WebGL1Renderer;
    update: () => void;
    updateSize: () => void;
  } | null>(null);

  useEffect(() => {    
    const canvasEl = canvasRef.current!;

    canvasEl.addEventListener("webglcontextlost", (event) => {
      setIsCanvas(false)
    });
  }, []);

  // настройка камеры
  useEffect(() => {
    if(window.innerWidth < 768) {
      return
    }
    const containerElem = containerRef.current!;
    const canvasEl = canvasRef.current!;

    const scene = new THREE.Scene();
    const camera = new THREE.OrthographicCamera(
      containerElem.offsetWidth / -2,
      containerElem.offsetWidth / 2,
      containerElem.offsetHeight / 2,
      containerElem.offsetHeight / -2,
      0
    );

    const renderer = new THREE.WebGL1Renderer({
      // сглаживание
      antialias: true,
      // устанавливаем прозрачность, чтобы можно было видеть картинку за холстом,
      // на случай если будут какие проблемы с отрисовкой
      alpha: true,
      canvas: canvasEl,
    });

    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setClearColor(0xffffff, 1);

    const update = () => {
      renderer.render(scene, camera);
    };

    const updateSize = () => {
      const { width, height } = containerElem.getBoundingClientRect();

      renderer.setSize(width, height);
    };

    setSceneController({
      scene,
      camera,
      renderer,
      update,
      updateSize,
    });
  }, []);

  useEffect(() => {
    if(window.innerWidth < 768) {
      return
    }
    if (sceneController === null || !isAllImagesLoaded) {
      return;
    }

    const loader = new THREE.TextureLoader();
    const rendererCapabilities =
      sceneController.renderer.capabilities.getMaxAnisotropy();

    // устанавливаем дефолтные настройки для текстурки
    const adjustTexture = (texture: THREE.Texture) => {
      texture.minFilter = texture.magFilter = THREE.LinearFilter;
      texture.anisotropy = rendererCapabilities;
    };

    const loadTexture = ([name, src]: [string, string | null]) => {
      if (src === null) {
        return;
      }

      return new Promise<[string, THREE.Texture]>((resolve, reject) => {
        loader.load(
          src,
          (texture) => {
            adjustTexture(texture);

            resolve([name, texture]);
          },
          undefined,
          reject
        );
      });
    };

    // предзагрузка всех картинок
    Promise.all(
      Object.entries(loadedImages).map((textureEntry) =>
        loadTexture(textureEntry)
      )
    ).then((data) => {
      const textures = data.reduce((textures, loadedTexture) => {
        if (loadedTexture) {
          textures[loadedTexture[0] as ImagesNamesType] = loadedTexture[1];
        }

        return textures;
      }, {} as { [key in ImagesNamesType]: THREE.Texture });

      setTextures(textures);
    });
  }, [loadedImages, isAllImagesLoaded, sceneController]);

  // устанавливает обработчики и материл необходимый для создания эффекта
  useEffect(() => {
    if(window.innerWidth < 768) {
      return
    }
    if (sceneController === null || textures === null) {
      return;
    }
    const containerEl = containerRef.current!;
    textures.displacement.wrapS = textures.displacement.wrapT =
      THREE.RepeatWrapping;

    const material = new THREE.ShaderMaterial(
      getDistortionShaderMaterialParameters({
        intensity,
        fromTexture: textures.first,
        toTexture: textures.second,
        displacementTexture: textures.displacement,
        imageSize: new THREE.Vector2(
          textures.second.image.width,
          textures.second.image.height
        ),
        containerSize: new THREE.Vector2(
          containerEl.getBoundingClientRect().width,
          containerEl.getBoundingClientRect().height
        ),
      })
    );

    // Обновляет размеры текстур
    function updateTexturesSize() {
      if (textures === null) {
        return;
      }
      material.uniforms.imageSize.value = new THREE.Vector2(
        textures.second.image.width,
        textures.second.image.height
      );

      material.uniforms.containerSize.value = new THREE.Vector2(
        containerEl.getBoundingClientRect().width,
        containerEl.getBoundingClientRect().height
      );
    }

    const geometry = new THREE.PlaneBufferGeometry(
      containerEl.offsetWidth,
      containerEl.offsetHeight,
      1
    );

    const object = new THREE.Mesh(geometry, material);

    sceneController.scene.add(object);

    const update = () => {
      sceneController.updateSize();
      updateTexturesSize();
      sceneController.update();
    };

    update();

    setMaterial(material);

    let prevContainerSize = {
      width: containerEl.offsetWidth,
      height: containerEl.offsetHeight,
    };

    const handleWindowResize = debounce(() => {
      // Если размеры контейнера превью изменились, тогда обновляем само превью
      if (
        prevContainerSize.width !== containerEl.offsetWidth ||
        prevContainerSize.height !== containerEl.offsetHeight
      ) {
        update();

        prevContainerSize = {
          width: containerEl.offsetWidth,
          height: containerEl.offsetHeight,
        };
      }
    }, 200);

    ScrollTrigger.addEventListener("refresh", sceneController.updateSize);

    window.addEventListener("resize", handleWindowResize);

    return () => {
      sceneController.scene.clear();

      window.removeEventListener("resize", handleWindowResize);

      ScrollTrigger.removeEventListener("refresh", sceneController.updateSize);
    };

    // setTexture(texture);
  }, [textures, sceneController, intensity]);

  // активируем эффект после того как значение "to" изменилось
  useEffect(() => {
    if(window.innerWidth < 768) {
      return
    }
    if (sceneController === null || material === null) {
      return;
    }

    sceneController.update();

    gsap.to(material.uniforms.dispositionFactor, {
      value: to,
      ease: easing,
      duration,
      onUpdate() {
        sceneController.update();
      },
    });
  }, [to, duration, easing, material, sceneController]);

  const handleImageLoad =
    (imageName: ImagesNamesType) =>
    (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
      setLoadedImages((state) => {
        return {
          ...state,
          //@ts-ignore
          [imageName]: e.target.currentSrc,
        };
      });
    };

  return (
    <div
      ref={containerRef}
      className={classNames("distortion-image", className)}
    >
      {(["first", "second", "displacement"] as ImagesNamesType[]).map(
        (imageName, i) => {
          return (
            <Image
              key={i}
              className={classNames(
                "distortion-image__img",
                `distortion-image__img--${imageName}`
              )}
              onLoad={handleImageLoad(imageName)}
              {...image[imageName]}
            />
          );
        }
      )}
      {(window.innerWidth < 768 || isCanvas) && <canvas ref={canvasRef} className="distortion-image__canvas" />}
    </div>
  );
};

export default DistortionImage;
