import { usePortfolioItemAssets } from 'hooks/useAssets';
import { useViewPortfolioItemContext } from '../ViewPortfolioItem';
import ViewPortfolioItemSection from '../ViewPortfolioItemSection';
import RevealWhenInView from '../../../common/RevealInView/RevealWhenInView';
import { useTranslation } from 'react-i18next';

export interface ViewPortfolioRutubeSectionProps {
  quantity: number;
  startingSection: number;
}

const ViewPortfolioRutubeSection = (props: ViewPortfolioRutubeSectionProps) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);
  const { t } = useTranslation();

  const { quantity, startingSection } = props;

  return (
    <>
      {Array.from({ length: quantity }, (_, i) => {
        const currentScreen = i + startingSection;
        return (
          <ViewPortfolioItemSection
          style={{backgroundColor:"#FDFDFD"}}
            className={`portfolio-item-section--type-rutube-${currentScreen}`}>
            <RevealWhenInView>
              <img
                src={buildPageImageSrc(
                  t(`portfolioItem:rutube.section-${currentScreen}.image`)
                )}
                alt={'rutube'}
              />
            </RevealWhenInView>
          </ViewPortfolioItemSection>
        );
      })}
    </>
  );
};

export default ViewPortfolioRutubeSection;
