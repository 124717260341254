import React, {
  forwardRef,
  memo,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef
} from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Icon from "components/common/Icon";

import gsap from "gsap";

import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import Ticker from "components/common/Ticker";
import Print from "components/common/Print";
import isTouchDevice from "is-touch-device";
import i18n from "i18next";

interface SectionLinkProps {
  /**
   * Ссылка
   */
  to: string;
  /**
   * Текст бегущей строки
   */
  tickerText: string | string[];
  className?: string;
  /**
   * Подпись
   */
  label: string | (() => ReactNode);
  /**
   * Описание
   */
  description?: string | (() => ReactNode);
}

type SectionLinkRef = HTMLDivElement;

const SectionLink = ({
  to,
  className,
  tickerText,
  label,
  description,
}: SectionLinkProps, ref: Ref<SectionLinkRef>) => {
  const arrowRef = useRef<HTMLElement>(null);
  const arrowContainerRef = useRef<HTMLDivElement>(null);
  const sectionRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => sectionRef.current!);

  useEffect(() => {
    if (isTouchDevice()) {
      return;
    }

    const arrowEl = arrowRef.current!;
    const arrowSvgEl = arrowEl.querySelector("svg");
    const sectionEl = sectionRef.current!;


    const handleMouseMove = (e: MouseEvent) => {
      const arrowElRect = arrowEl.getBoundingClientRect();

      const x = e.clientX - arrowElRect.left - arrowElRect.width / 2;
      const y = e.clientY - arrowElRect.top - arrowElRect.height / 2;

      gsap.to(arrowSvgEl, {
        x,
        y,
        scale: 2,
        force3D: true,
        duration: 0.2,
        ease: "ease",
      });
    };

    const handleMouseLeave = (e: MouseEvent) => {
      gsap.to(arrowSvgEl, {
        x: 0,
        y: 0,
        force3D: true,
        duration: 0.8,
        scale: 1,
      });
    };

    sectionEl.addEventListener("mousemove", handleMouseMove);
    sectionEl.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      sectionEl.removeEventListener("mouseleave", handleMouseLeave);
      sectionEl.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  // Каждый раз когда компонент обновляется, сбрасываем позицию стрелки
  useEffect(() => {
    const arrowEl = arrowRef.current!;

    gsap.to(arrowEl, {
      x: 0,
      y: "-30%",
      force3D: true,
      duration: 0.8,
      scale: 1,
    });
  });

  const renderTickerTextItem = useCallback((textItem) => {
    return (
      <p className="title section-link__sliding-text-item">
        <span>
          <Print data={textItem} />
        </span>
        <span className="section-link__sliding-text-item-dot">
          ■
        </span>
      </p>
    );
  }, [tickerText]);

  const  nextLangCode = useMemo(() => {
    return i18n.language === "en" ? "/en" : "";
  }, [i18n.language]);
  return (

    <section
      ref={sectionRef}
      className={classNames("section-link background--white", className)}
    >
      <Link to={nextLangCode + to.replace('/:locale( |en)?', "")} className="section-link__container link">
        <div className="section-link__main container row">
          <div
            ref={arrowContainerRef}
            className="section-link__col-with-arrow col col-md-4 col-xl-5"
          >
            <RevealWhenInView
              from={{
                x: -30,
                opacity: 0,
              }}
              to={{
                x: 0,
                opacity: 1,
              }}
            >
              <div>

                <Icon
                  ref={arrowRef}
                  name="arrow-right"
                  className="section-link__arrow"
                />

              </div>
            </RevealWhenInView>
          </div>

          <div className="section-link__col-with-label col-auto col-md-3 col-xl-3">
            {typeof label === "function" ? (
              label()
            ) : (
              <RevealWhenInView>
                <p className="section-link__label label link link--normal">
                  <Print data={label} />
                </p>
              </RevealWhenInView>
            )}
          </div>

          <div className="section-link__col-with-additional col-11 col-md col-xl-3">
            {typeof description === "function" ? (
              description()
            ) : (
              <RevealWhenInView>
                <p className="section-link__additional">
                  <Print data={description!} />
                </p>
              </RevealWhenInView>
            )}
          </div>
        </div>
        <RevealWhenInView>
          <div>
            <Ticker
              duration={20}
              text={tickerText}
              renderTextItem={renderTickerTextItem}
            />
          </div>
        </RevealWhenInView>
      </Link>
    </section>
  );
};

export default memo(forwardRef<SectionLinkRef, SectionLinkProps>(SectionLink));
