import ViewPortfolioSk10 from "components/views/ViewPortfolioItem/ViewPortfolioSk10/ViewPortfolioSk10";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";

const porfolioItem: PortfolioItemDataWithComponent = {
  id: "sk-10",
  component: ViewPortfolioSk10,
  title: "sk10",
  name: "sk-10",
  altName: "Ростовская строительная компания",
  year: 2022,
  awardsList: [],

  link: {
    label: "sk10.ru",
    href: "https://sk10.ru/",
  },

  socials: [
    {
      label: "behance",
      href: "https://www.behance.net/gallery/136321967/UX-CASE-Construction-company-SK-10",
    },
  ],

  preview: {
    label: "корпоративный сайт",
    backgroundColor: "#1F1F21",
    media: {
      type: "video",
      src: "https://player.vimeo.com/video/812389979?h=ed8123b72c",
    },
  },

  poolOfWorks: ["Research", "Job-stories", "Cjm", "Wireframing"],

  type: "interfaces",
  subtype: "interfaces",

  description: [
    "российская компания, занимающаяся строительством многоэтажных жилых комплексов. Нам необходимо было сделать техническое задание с высокодетализированными вайерфреймами, проработать логику взаимодействия пользователя с интерфейсом.",
  ],
  shortDescription: "сайт для Ростовской строительной компании",

  seo: {
    title:
      "Проектирование UX и пользовательского опыта для крупного застройщика СК10",
    description:
      "Мы спроектировали сайт портал для крупного застрощика многоквартирных домов, где создали уникальный пользовательский опыт по покупке и квартир полностью онлайн. Уникальный личный кабинет покупателя квартиры позволяет полностью автоматизировать покупку квартиры через интернет.",
    keywords: [
      "разработка сайта для застройщика",
      "проектирование сайта застройщка",
      "сайт по продаже квартир",
      "ux дизайн сайта застройщика",
      "создание сайта застройщика ск10",
    ],
    openGraph: {
      type: "article",
      title:
        "Проектирование UX и пользовательского опыта для крупного застройщика СК10",
      description:
        "Мы спроектировали сайт портал для крупного застрощика многоквартирных домов, где создали уникальный пользовательский опыт по покупке и квартир полностью онлайн. Уникальный личный кабинет покупателя квартиры позволяет полностью автоматизировать покупку квартиры через интернет.",
    },
  },
};

export default porfolioItem;
