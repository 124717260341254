import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import classNames from "classnames";

import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { MorphSvgWord } from "components/common/MorphSvg";
import isTouchDevice from "is-touch-device";
import { WordsType } from "components/common/MorphSvg/words";
import { useLocation } from 'react-router-dom';

export type ViewHomeLeadSectionSlideWords = string[]; // Array<keyof typeof svgWords>;

interface ViewHomeLeadSectionSliderProps {
  items: WordsType[];
  onChange: (itemIndex: number) => void;
}
let initialSwiper = {};
const ViewHomeLeadSectionSlider = memo(
  forwardRef<HTMLDivElement, ViewHomeLeadSectionSliderProps>(
    ({ items, onChange }, ref) => {

      const [activeSlideIndex, setActiveSlideIndex] = useState(0);

      const handleSlideChange = useCallback(
        (swiper: SwiperCore) => {
          // так как тире представляет собой отдельный слайд, то чтобы найти индекс текущего слайда,
          // нужно реальный индекс (индекс бзе учета дубликатов) поделить на 2
          // const slideIndex = swiper.realIndex / 2;
          onChange(swiper.realIndex);
        },
        [onChange]
      );

      const handleUpdate = (swiper: SwiperCore) => {
          initialSwiper = swiper;
      }


      const [morphSvg, setMorphSvg] = useState(true);
      const [changeSlide, setChangeSlide] = useState(false);


      const handleSlideChangeTransitionEnd = (swiper: SwiperCore) => {
        setActiveSlideIndex(swiper.realIndex);
        setChangeSlide(true);
      };

      useEffect(() => {
        if (isTouchDevice()) {
          return;
        }

      }, []);

      useEffect(() => {
        let timerMorphSvg = setTimeout(()=>{
          setMorphSvg(!morphSvg);
        }, 5000);
        if(changeSlide){
            clearTimeout(timerMorphSvg);
            setMorphSvg(true);
            setChangeSlide(false);
            timerMorphSvg = setTimeout(()=>{
                setMorphSvg(!morphSvg);
            }, 5000);
        }
        return () => clearTimeout(timerMorphSvg);
      },[setMorphSvg, morphSvg, changeSlide]);


      const location = useLocation();
      useEffect(() => {
          //@ts-ignore
          initialSwiper.update();
      }, [location])
      return (
        <>
          <Swiper
            onInit={handleUpdate}
            loop
            initialSlide={activeSlideIndex}
            navigation
            roundLengths
            onSlideChangeTransitionEnd={handleSlideChangeTransitionEnd}
            onSlideChange={handleSlideChange}
            speed={1800}
            className={
                classNames(
                    "view-home-lead-section__slider container "
                )
            }
            slidesPerView="auto"
            loopPreventsSlide={true}
            loopedSlides={4}
            loopFillGroupWithBlank
          >
            {items.map((slideWord, i) => {
              return (
                <SwiperSlide
                  key={i}
                  className={classNames(
                    "view-home-lead-section__slide"
                  )}
                >
                  <div
                    className={classNames("col-auto")}
                  >
                    <MorphSvgWord
                      word={slideWord}
                      morph={activeSlideIndex === i && morphSvg}
                      className={classNames("view-home-lead-section__slide-svg view-home-lead-section__slide-svg--word")}
                    />
                  </div>
                  <div
                    className={classNames(
                      "container",
                      "view-home-lead-section__slide-col--dash"
                    )}
                  >
                    <MorphSvgWord
                      word="-"
                      className={classNames(
                        "view-home-lead-section__slide-svg",
                        `view-home-lead-section__slide-svg--dash`
                      )}
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </>
      );
    }
  )
);

export default ViewHomeLeadSectionSlider;
