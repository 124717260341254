import ViewPortfolioAtlantic from 'components/views/ViewPortfolioItem/ViewPortfolioAtlantic';
import { PortfolioItemDataWithComponent } from 'data/portfolio/types';

const porfolioItem: PortfolioItemDataWithComponent = {
  id: 'atlantic',
  component: ViewPortfolioAtlantic,
  title: 'atlantic',
  name: 'Атлантика',
  altName: 'Медицинский реабилитационный центр',
  year: 2019,
  text: 'rca-med.ru',

  socials: [
    {
      label: 'behance',
      href: 'https://www.behance.net/gallery/76559231/mir-zdorovja-medicinskij-centr',
    },
  ],

  preview: {
    label: 'корпоративный сайт',
    media: {
      type: 'video',
      src: 'https://vimeo.com/542707424',
    },
  },

  poolOfWorks: [
    'UX|UI design',
    'Page making',
    'Integration with CMS',
    'Version for the visually impaired',
  ],

  type: 'site',
  subtype: 'site',

  description: [
    'медицинский реабилитационный центр в Ростове-на-Дону, специализирующийся на бальнеологии.',
    'Перед нами стояла задача разработать сайт с удобным интерфейсом и оригинальным дизайном, отражающим основные направления клиники',
  ],
  shortDescription: 'сайт для медицинского реабилитационного центра',
  seo: {
    title: 'Разработка сайта медицинского центра Атлатника',
    description:
      'Создание сайта для реабилитационного медицинского центра Атлантика, включает в себя версии для планшетов, смартфонов. Сайт разрабатывался с учетом всех требований минздрава и имеет версию для слабовидящих с гибкими настройками',
    keywords: [
      'сайт для мед центра',
      'создание сайта клиники',
      'сайт по стандартам минздрава',
      'создание сайта мед центра',
      'медицинский сайт',
      'создание сайта реабилитационного центра',
    ],
    openGraph: {
      type: 'article',
      title: 'Разработка сайта медицинского центра Атлатника',
      description:
        'Создание сайта для реабилитационного медицинского центра Атлантика, включает в себя версии для планшетов, смартфонов. Сайт разрабатывался с учетом всех требований минздрава и имеет версию для слабовидящих с гибкими настройками',
    },
  },
};

export default porfolioItem;
