import React, { memo, useEffect, useRef } from "react";

interface MorphSvgPathProps {
  from: string;
  to?: string;
  duration?: string;
  morph?: boolean;
}

const MorphSvgPath = ({ from, to, duration, morph }: MorphSvgPathProps) => {
  const animateFromElRef = useRef<SVGElement>(null);
  const animateToElRef = useRef<SVGElement>(null);

  useEffect(() => {
    if (!to) {
      return;
    }

    const animateFromEl = animateFromElRef.current!;
    const animateToEl = animateToElRef.current!;

    const animateTargetEl = morph ? animateToEl : animateFromEl;

    //@ts-ignore
    if (typeof animateTargetEl.beginElement === "function") {
      //@ts-ignore
      animateTargetEl.beginElement();
    }
  }, [morph, to]);

  return (
    <path d={from} fill="currentcolor">
      {to && (
        <>
          <animate
            ref={animateFromElRef}
            to={from}
            dur={duration}
            attributeName="d"
            fill="freeze"
            begin="indefinite"
          />
          <animate
            ref={animateToElRef}
            to={to}
            dur={duration}
            attributeName="d"
            fill="freeze"
            begin="indefinite"
          />
        </>
      )}
    </path>
  );
};

export default memo(MorphSvgPath);
