import ViewPortfolioWomanSport from 'components/views/ViewPortfolioItem/ViewPortfolioWomanSportBranding';
import { PortfolioItemDataWithComponent } from 'data/portfolio/types';
import { buildPublicImageSrc } from 'helpers/path';

const porfolioItem: PortfolioItemDataWithComponent = {
  id: 'woman-sport-branding',
  component: ViewPortfolioWomanSport,
  title: 'woman-sport',
  name: 'Woman sport',
  altName: "Women's\nsports club",
  year: 2019,

  text: 'wsportclub.ru',

  socials: [
    {
      label: 'behance',
      href: 'https://www.behance.net/gallery/88442093/Woman-Sport-Branding',
    },
  ],

  preview: {
    label: 'logo and corporate identity',
    media: {
      type: 'image',
      fromSrc: buildPublicImageSrc({
        path: 'portfolios/woman-sport-branding',
        file: 'preview-from.jpg',
      }),
      toSrc: buildPublicImageSrc({
        path: 'portfolios/woman-sport-branding',
        file: 'preview-to.jpg',
      }),
    },
  },

  poolOfWorks: ['Logotype', 'Brand style'],

  type: 'branding',
  subtype: 'branding',

  description: [
    "women's sports club with circuit trainings.",
    'We had to design brand identity elements with training process metaphor',
  ],
  shortDescription: "branding for women's fitness club",

  relatedId: 'woman-sport-site',
  seo: {
    title: "Brand identity for women's sports club Woman sport",
    description:
      "Logo and Brand Identity design for women's sports club Woman Sport which includes the following elements: gym membership, coach uniform, sports equipment, folders, business cards, envelops, etc.",
    keywords: [
      'fitness club logo design',
      'sports club logo design',
      'fitness club brand identity',
      'sports club brand identity',
      'gym membership design',
      'coach uniform',
    ],
    openGraph: {
      type: 'article',
      title: "Brand identity for women's sports club Woman sport",
      description:
        "Logo and Brand Identity design for women's sports club Woman Sport which includes the following elements: gym membership, coach uniform, sports equipment, folders, business cards, envelops, etc.",
    },
  },
};

export default porfolioItem;
