import ViewPortfolioItemAboutSection from "../ViewPortfolioItemAboutSection";

import ViewPortfolioAtlanticInnerPagesSection from "./ViewPortfolioAtlanticInnerPagesSection";
import ViewPortfolioAtlanticArticlesPageSection from "./ViewPortfolioAtlanticArticlesPageSection";
import ViewPortfolioAtlanticSpecialistCardSection from "./ViewPortfolioAtlanticSpecialistCardSection";
import { useViewPortfolioItemContext } from "../ViewPortfolioItem";
import ViewPortfolioItemSection from "../ViewPortfolioItemSection";
import { usePortfolioItemAssets } from "hooks/useAssets";
import {useTranslation} from "react-i18next";

export interface ViewPortfolioAtlanticProps { }

const ViewPortfolioAtlantic = (props: ViewPortfolioAtlanticProps) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);
  const { t } = useTranslation();
  return (
    <>
      <ViewPortfolioItemAboutSection
        name={data.name}
        video={{
          src: "https://vimeo.com/542707424"
        }}
        description={data.description}
        caption={[
            t("portfolioItem:atlantic.about.0"),
            t("portfolioItem:atlantic.about.1"),
            t("portfolioItem:atlantic.about.2"),
        ]}
        style={{ backgroundColor: "#DDDDDF" }}
      />
      <ViewPortfolioAtlanticInnerPagesSection
        size={{
          top: "md",
          bottom: "xl",
        }}
        caption={[
            t("portfolioItem:atlantic.inner-section.0"),
          [
              t("portfolioItem:atlantic.inner-section.1.0"),
              t("portfolioItem:atlantic.inner-section.1.1"),
              t("portfolioItem:atlantic.inner-section.1.2")
          ],
        ]}
        images={Array.from({ length: 5 }, (_, i) => {
          return {
            src: buildPageImageSrc(`screen-3-${i + 1}`)
          }
          //  {
          //   src: {
          //     path: `portfolios/${data.id}/page`,
          //     file: {
          //       name: "inner-pages-" + (i + 1),
          //       ext: "jpg",
          //     },
          //   },
          // };
        })}
      />
      <ViewPortfolioAtlanticArticlesPageSection
        style={{ backgroundColor: "#E0E0E0" }}
        size="sm"
        caption={[
            t("portfolioItem:atlantic.articles-section.0"),
            t("portfolioItem:atlantic.articles-section.1")
        ]}
        images={Array.from({ length: 2 }, (_, i) => {
          return {
            src: buildPageImageSrc(`screen-4-${i + 1}`)
          }
        })}
      />
      <ViewPortfolioAtlanticSpecialistCardSection
        size={{
          top: "md",
          bottom: "lg",
        }}
        style={{ backgroundColor: "#161114", color: "#fff" }}
        caption={[
            t("portfolioItem:atlantic.card-section"),
          [1920, 1360, 768, 375].map((s) => s + " px"),
        ]}
        images={Array.from({ length: 7 }, (_, i) => {
          return {
            src: buildPageImageSrc(`screen-5-${i + 1}`)
          };
        })}
      />
      <ViewPortfolioItemSection
        size={{
          top: "sm",
        }}
        style={{
          backgroundColor: "#BEEEF0",
        }}
        className="view-portfolio-atlantic-prices-cards-section"
      >
        <div
          className="view-portfolio-atlantic-prices-cards-section__background"
          style={{
            backgroundImage: `url(${buildPageImageSrc("screen-6")})`,
          }}
        />
      </ViewPortfolioItemSection>
    </>
  );
};

export default ViewPortfolioAtlantic;
