import { useCallback, useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import List from "components/common/List";
import PortfolioItem from "components/layouts/PortfolioItem/PortfolioItem";
import {
  PortfolioItemData,
  PortfolioItemSubtype,
  PortfolioItemTitlesType,
  PortfolioItemType,
} from "data/portfolio/types";
import { useElemRef } from "hooks/useElemRef";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";
import portfolio from "data/portfolio";
import Print from "components/common/Print";
import i18n from "i18next";

import "./_portfolio-showcase-section.scss";
import { LangCodeType } from "i18n/types";
import SlideFadeOutWhenInView from "components/views/ViewPortfolioItem/ViewPortfolioNovaGroup/SlideFadeOutWhenInView";

interface ViewPortfolioShowcaseSectionProps {}

const getPortfolioSubtypeFiltersWithCount = (
  portfolio: PortfolioItemData[]
): { all?: number } & {
  [key in PortfolioItemSubtype]?: number;
} => {
  const result: { all: number } & {
    [key in PortfolioItemSubtype]?: number;
  } & { [key in PortfolioItemType]?: number } = {
    all: 0,
  };

  for (const { subtype, type } of portfolio) {
    ++result.all;

    if (subtype in result) {
      ++result[subtype]!;
      continue;
    }
    result[subtype] = 1;
  }

  return {
    all: result.all,
    site: result.site,
    branding: result.branding,
    application: result.application,
    portal: result.portal,
    interfaces: result.interfaces,
    "e-commerce": result["e-commerce"],
  };
};

const filtersBySubtypeWithCount = getPortfolioSubtypeFiltersWithCount(
  portfolio.ru
);

const ViewPortfolioShowcaseSection = (
  props: ViewPortfolioShowcaseSectionProps
) => {
  const [filter, setFilter] =
    useState<keyof typeof filtersBySubtypeWithCount>("all");

  // Делаем из фильтров массив и перемещаем фильтр "all" в его начало
  const subtypeFilterAndCountList = useMemo(
    () => Object.entries(filtersBySubtypeWithCount),
    []
  );

  let filteredPortfolio = useMemo(() => {
    const language = i18n.language as LangCodeType;

    let portfolioItems = portfolio[language];

    if (filter === "all") {
      return portfolioItems;
    }
    return [...portfolioItems].sort((a, b) =>
      a.subtype === filter && a.type === filter
        ? -1
        : b.subtype === filter && b.type === filter
        ? 1
        : 0
    );
  }, [filter, i18n.language]);

  const scrollTo = () => {
    ScrollTrigger.refresh();
    gsap.to(window, {
      duration: 1,
      scrollTo: ".portfolio-showcase-section__portfolio-items",
    });
    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 1100);
  };

  return (
    <DynamicBackgroundTrigger>
      <section className="portfolio-showcase-section background--gray">
        <div className="portfolio-showcase-section__container">
          <div className="portfolio-showcase-section__header container row">
            <div className="portfolio-showcase-section__filter col-11 offset-md-3 col-md-8 offset-xl-6 col-xl-4">
              <SlideFadeOutWhenInView>
                {({ setTriggerEl, setTarget }) => (
                  <div ref={setTriggerEl} className={"row"}>
                    <div className="col-5 col-sm-4 col-xl-3">
                      <p className="label side-brackets">
                        <Print data="view:portfolio.section.showcase.filter.label" />
                      </p>
                    </div>

                    <List
                      ref={setTarget({ selector: ".list__item" })}
                      items={subtypeFilterAndCountList}
                      className="col-6 col-sm-7 col-xl"
                    >
                      {([filter, count], i) => (
                        <button
                          onClick={() => {
                            setFilter(filter as PortfolioItemSubtype);
                            scrollTo();
                          }}
                          className="portfolio-showcase-section__filter-button button label "
                        >
                          <span className="link--normal">
                            <Print
                              data={`view:portfolio.section.showcase.filter.type.${filter}`}
                            />
                            {/* <span className="side-brackets">{count}</span> */}
                          </span>
                        </button>
                      )}
                    </List>
                  </div>
                )}
              </SlideFadeOutWhenInView>
            </div>
          </div>
          <ul className="list portfolio-showcase-section__portfolio-items row">
            {filteredPortfolio.map((portfolioItem, i) => {
              const isActive =
                portfolioItem.subtype === filter || filter === "all";
              const isWide = !((i % 4) % 3);
              return (
                <li
                  key={portfolioItem.id}
                  className={classNames(
                    `portfolio-showcase-section__col-with-portfolio-item container col-11`,
                    isWide ? "col-md-6" : "col-md-5",
                    `portfolio-showcase-section__col-with-portfolio-item--${
                      !isActive && "filtered"
                    }`
                  )}
                >
                  <PortfolioItem
                    id={portfolioItem.id}
                    name={portfolioItem.name}
                    preview={portfolioItem.preview}
                    subtype={portfolioItem.subtype}
                    trigger={{ type: "scroll" }}
                    noPadding={portfolioItem.noPadding}
                    className={classNames(
                      "portfolio-showcase-section__portfolio-item",
                      `portfolio-showcase-section__portfolio-item--${
                        isWide ? "wide" : "narrow"
                      }`
                    )}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </section>
    </DynamicBackgroundTrigger>
  );
};

export default ViewPortfolioShowcaseSection;
