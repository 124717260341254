import React, { CSSProperties, memo, ReactNode } from "react";
import classNames from "classnames";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";

type ViewPortfolioItemSectionSizeType =
  | "xxs"
  | "xs"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "xxl";

export interface ViewPortfolioItemSectionConfigProps {
  style?: CSSProperties;
  dynamicBackgroundColor?: boolean;
  size?:
  | ViewPortfolioItemSectionSizeType
  | {
    top?: ViewPortfolioItemSectionSizeType;
    bottom?: ViewPortfolioItemSectionSizeType;
  };
}

export interface ViewPortfolioItemSectionProps
  extends ViewPortfolioItemSectionConfigProps {
  className?: string;
  children: ReactNode;

  theme?: {
    text?: "light";
    background?: {
      color?: string;
      dynamic?: boolean;
    };
  };
}

const ViewPortfolioItemSection = memo(
  ({
    className,
    children,
    size,
    style,
    dynamicBackgroundColor = true,
  }: ViewPortfolioItemSectionProps) => {
    const section = (
      <section
        style={style}
        className={classNames(
          "view-portfolio-item-section section",
          typeof size === "object"
            ? {
              [`view-portfolio-item-section--size-top-${size.top}`]: size.top,
              [`view-portfolio-item-section--size-bottom-${size.bottom}`]: size.bottom,
            }
            : {
              [`view-portfolio-item-section--size-${size}`]: size,
            },
          className
        )}
      >
        {children}
      </section>
    );

    return dynamicBackgroundColor ? (
      <DynamicBackgroundTrigger>{section}</DynamicBackgroundTrigger>
    ) : (
      section
    );
  }
);

export default ViewPortfolioItemSection;
