import ViewPortfolioDomaVeka from 'components/views/ViewPortfolioItem/ViewPortfolioDomaVeka';
import { PortfolioItemDataWithComponent } from 'data/portfolio/types';
import { buildPublicImageSrc } from 'helpers/path';

const skess: PortfolioItemDataWithComponent = {
  id: 'doma-veka',
  component: ViewPortfolioDomaVeka,
  title: 'doma-veka',
  name: 'Дома века',
  altName: 'Тульская архитектурно-\nстроительная компания',
  year: 2020,

  link: {
    label: 'doma-veka.ru',
    href: 'https://doma-veka.ru',
  },
  vc: {
    label: 'vc.ru',
    href: 'https://vc.ru/dev/491141-spa-prilozheniya-na-1s-bitriks-vue-js',
  },

  preview: {
    label: 'фирменный стиль',
    backgroundColor: '#F1F1F1',
    media: {
      type: 'image',
      fromSrc: buildPublicImageSrc({
        path: 'portfolios/doma-veka',
        file: 'preview-from.jpg',
      }),
      toSrc: buildPublicImageSrc({
        path: 'portfolios/doma-veka',
        file: 'preview-to.jpg',
      }),
    },
  },

  socials: [
    {
      label: 'behance',
      href: 'https://www.behance.net/gallery/105153403/BRANDING-Construction-company-Doma-Veka',
    },
  ],

  poolOfWorks: ['Logotype', 'Brand style'],

  type: 'branding',
  subtype: 'branding',

  description: [
    'российская архитектурно-строительная компания, базирующаяся в Туле. Это крупная компания, основанная в 2007 году.',
    'Иконка бренда создавалась на основе концепции сочетания традиционного и современного строительства. Этот знак повторяет контуры здания в стиле хай-тек и в то же время повторяет контуры полутрадиционного дома.',
  ],
  shortDescription: 'брендинг для Тульской архитектурно-строительной компании',
  relatedId: 'doma-veka-site',
  seo: {
    title: 'Брендинг для строительной компании Дома Века',
    description:
      'Ребрендинг строительной компании Дома Века, включающий полный редизайн логотипа и создание всех необходимых элементов фирменного стиля (визитки, таблички, конверты, бланки, брошюры и т.д.)',
    keywords: [
      'фирменный стиль для строительной компании',
      'логотип для строительной компании',
      'дизайн для строителей',
      'заказать логотип для строительной компании',
      'создание логотипа',
    ],
    openGraph: {
      type: 'article',
      title: 'Брендинг для строительной компании Дома Века',
      description:
        'Ребрендинг строительной компании Дома Века, включающий полный редизайн логотипа и создание всех необходимых элементов фирменного стиля (визитки, таблички, конверты, бланки, брошюры и т.д.)',
    },
  },
};

export default skess;
