import React, {
  forwardRef,
  memo,
  ReactElement,
  ReactNode,
  Ref,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import gsap from "gsap";

import {
  useSafeContext,
  createSafeConsumer,
  createSafeContext,
} from "contexts/helpers";

interface ContextValue {
  // setImages: (images: string[]) => void;
}

const Context = createSafeContext<ContextValue>();

export const useTransitionOverlay = () => useSafeContext(Context);
export const TransitionOverlayConsumer = createSafeConsumer(Context);

interface TransitionOverlayProps {
  children: ReactNode;
  onBeforeEnd?: () => void;
  images: string[];
  trigger: any;
  activator: () => boolean;
}

export interface TransitionOverlayRef {
  timeline: gsap.core.Timeline;
}

/**
 * Позволяет контролировать блокировать прокрутку
 */
const TransitionOverlay = (
  { children, images, onBeforeEnd, trigger, activator }: TransitionOverlayProps,
  ref: Ref<TransitionOverlayRef>
): ReactElement => {
  const transitionOverlayElRef = useRef<HTMLDivElement>(null);
  const layerRef = useRef<{
    containerElms: (HTMLDivElement | null)[];
    imageElms: (HTMLDivElement | null)[];
  }>({ imageElms: [], containerElms: [] });
  const timelineRef = useRef<gsap.core.Timeline | null>(null);

  const value: ContextValue = useMemo(() => ({}), []);

  useImperativeHandle(ref, () => {
    return {
      timeline: timelineRef.current!,
    };
  });

  const [imgInclude, setImgInclude] = useState(false);
  const [pathTrigger, setPathTrigger] = useState("");

  // переменные для сбора информации о первой загрузке
  const [isFirstLoad, setIsFirstLoad] = useState(false);

  useEffect(() => {
    const timeline = (timelineRef.current = gsap.timeline({ paused: true }));


    // это чтобы при загрузке прелодера плавный переход не проигрывался
    if (!isFirstLoad) {
      if(onBeforeEnd){
        onBeforeEnd()
      }
      setIsFirstLoad(true);
      return
    }


    const transitionOverlayEl = transitionOverlayElRef.current!;
    const layer = layerRef.current;
    const lastLayerIndex = layer.containerElms.length - 1;

    const showLayerDuration = 0.8;
    const hideLayerDuration = 0.8;

    // Устанавливаем начальные значения
    timeline.set(layer.containerElms, {
      yPercent: 101,
      opacity: 1,
    });

    timeline.set(layer.imageElms, {
      yPercent: -101,
    });

    timeline.set(transitionOverlayEl, {
      opacity: 1,
      zIndex: 400,
    });

    // появление слоев
    timeline.to(layer.containerElms, {
      yPercent: 0,
      duration: showLayerDuration,
      stagger: 0.2,
      ease: "Power2.easeInOut",
    });

    timeline.to(
      layer.imageElms,
      {
        yPercent: 0,
        duration: showLayerDuration,
        stagger: 0.2,
        ease: "Power2.easeInOut",
      },
      "<"
    );

    // прячем все слои кроме последнего
    timeline.set(layer.containerElms.slice(0, lastLayerIndex), {
      opacity: 0,
    });

    if (onBeforeEnd) {
      timeline.add(onBeforeEnd);
    }

    // прячем последний слой
    timeline.to(layer.containerElms[lastLayerIndex], {
      yPercent: -101,
      duration: hideLayerDuration,
      ease: "Expo.easeInOut",
    });

    timeline.to(
      layer.imageElms[lastLayerIndex],
      {
        yPercent: 101,
        duration: hideLayerDuration,
        ease: "Expo.easeInOut",
      },
      "<"
    );

    // убираем оверлей
    timeline.set(transitionOverlayEl, {
      clearProps: "all",
    });

    return () => {
      timeline.kill();
    };
  }, [images, onBeforeEnd]);

  useEffect(() => {
    const timeline = timelineRef.current!;
    if (timeline) {
      if (trigger !== pathTrigger) {
        setImgInclude(activator);
        timeline.play(0);
        setPathTrigger(trigger);
      }
    }
  }, [trigger, activator, imgInclude]);

  layerRef.current.containerElms = [];
  layerRef.current.imageElms = [];

  return (
    <Context.Provider value={value}>
      {children}
      <div ref={transitionOverlayElRef} className="transition-overlay">
        {images.map((src, i) => {
          const lightness = ((i + 1) / images.length) * 90;
          const backgroundColor = `hsl(180, 4%, ${lightness}%)`;
          return (
            <div
              key={i}
              ref={(el) => (layerRef.current.containerElms[i] = el)}
              className="transition-overlay__item"
              style={{
                backgroundColor,
              }}
            >
              <div
                ref={(el) => (layerRef.current.imageElms[i] = el)}
                className="transition-overlay__item-image"
              >
                {imgInclude && <img src={src} alt="" />}
              </div>
            </div>
          );
        })}
      </div>
    </Context.Provider>
  );
};

export default memo(
  forwardRef<TransitionOverlayRef, TransitionOverlayProps>(TransitionOverlay)
);
