import ViewPortfolioDomaVekaSite from 'components/views/ViewPortfolioItem/ViewPortfolioDomaVekaSite';
import { PortfolioItemDataWithComponent } from 'data/portfolio/types';
import { buildPublicImageSrc } from '../../helpers/path';

const porfolioItem: PortfolioItemDataWithComponent = {
  id: 'doma-veka-site',
  component: ViewPortfolioDomaVekaSite,
  title: 'doma-veka-en',
  name: 'Doma veka',
  altName: 'Tula architectural &  construction company',
  year: 2021,
  awardsList: [
    { imgSrc: '/images/awards/gray-bg/awwwards.png' },
    { imgSrc: '/images/awards/gray-bg/special-kudos.png' },
    { imgSrc: '/images/awards/gray-bg/ux.png' },
    { imgSrc: '/images/awards/gray-bg/ui.png' },
    { imgSrc: '/images/awards/gray-bg/innovation.png' },
    { imgSrc: '/images/awards/gray-bg/DP.svg' },
    {imgSrc : "/images/awards/gray-bg/wda-gold.svg"},
  ],

  link: {
    label: 'doma-veka.ru',
    href: 'https://doma-veka.ru/',
  },

  socials: [
    {
      label: 'behance',
      href: 'https://www.behance.net/gallery/130912363/WEBSITE-Houses-of-century-construction-company',
    },
  ],

  preview: {
    label: 'corporate site',
    media: {
      type: 'image',
      fromSrc: buildPublicImageSrc({
        path: 'portfolios/wildberries/preview',
        file: '1-1440w.webp',
      }),
      toSrc: buildPublicImageSrc({
        path: 'portfolios/wildberries/preview',
        file: '2-1440w.webp',
      }),
    },
  },

  poolOfWorks: ['UX|UI design', 'Animation', 'Web development'],

  type: 'site',
  subtype: 'site',

  description: [
    'Doma veka, ‘Houses of the century’ is construction and design company based in Russia. We redesigned website and branding. this site was designed easy-to-use website with constrained color pallette',
  ],
  shortDescription:
    'website for the Tula architectural &  construction company',
  relatedId: 'doma-veka',

  seo: {
    title:
      'Development of the corporate website of the Doma-Veka construction company',
    description:
      'We have developed a unique corporate website for the company for the construction of private houses of the House-Century, which meets all the needs of construction companies. The site has a convenient site CMS, which allows you to conveniently manage the catalog of projects and change the content on the site.',
    keywords: [
      'website development',
      'corporate website',
      'construction company',
      'doma veka',
      'unique website',
      'website, functional website',
      'website for a construction company',
      'construction of private houses',
      'website development for developers',
    ],
    openGraph: {
      type: 'article',
      title:
        'Development of the corporate website of the Doma-Veka construction company',
      description:
        'We have developed a unique corporate website for the company for the construction of private houses of the House-Century, which meets all the needs of construction companies. The site has a convenient site CMS, which allows you to conveniently manage the catalog of projects and change the content on the site.',
    },
  },
};

export default porfolioItem;
