import { PortfolioItemDataWithComponent } from "data/portfolio/types";
import ViewPortfolioCfps from "components/views/ViewPortfolioItem/ViewPortfolioCfps";

const porfolioItem: PortfolioItemDataWithComponent = {
    id: "cfs",
    component: ViewPortfolioCfps,
    title: "сfps",
    name: "CFS",
    altName: "mobile app \n for financial control",
    year: 2023,
    link: {
        label: "bankapp.pro",
        href: "https://bankapp.pro/",
    },
    type: "site",
    subtype: "site",

    preview: {
        label: "corporate site",
        backgroundColor: "#1F1F21",
        media: {
            type: "video",
            src: "https://vimeo.com/914703645?share=copy",
        },
    },

    socials: [
        {
            label: "behance",
            href: "https://www.behance.net/gallery/167598297/CFPS-Neobank-website",
        },
    ],

    poolOfWorks: [
        "UX|UI design",
        "Page making"
    ],


    description: [
        "An online neobank from the UK that provides services to its clients without the need to visit an office and allows payments to be made through the application",
        "We developed a corporate website that demonstrates all the advantages of the application and helps users interact with it, as well as informs current clients and investors about the status of the application and the bank"
    ],
    shortDescription:
        "Website development for a neobank from the UK",
    seo: {
        title: "Website development for a neobank from the UK",
        description: "We designed and developed a corporate website for an online banking application in the UK, which includes a detailed description of the company’s product, a section with information about technical support, vacancies, news and articles",
        keywords: ["website development, website design, startup website development, online bank website, neobank, neo bank, bank corporate website"],
        openGraph: {
            type: "article",
            title: "Website development for a neobank from the UK",
            description: "We designed and developed a corporate website for an online banking application in the UK, which includes a detailed description of the company’s product, a section with information about technical support, vacancies, news and articles",
        }
    },
};

export default porfolioItem;
