import novaGroup from "./nova-group";
import novaGroupEn from "./nova-group-en";

import csd from "./csd";
import csdEn from "./csd-en";

import atlantic from "./atlantic";
import atlanticEn from "./atlantic-en";

import womanSportBranding from "./woman-sport-branding";
import womanSportBrandingEn from "./woman-sport-branding-en";

import womanSportSite from "./woman-sport-site";
import womanSportSiteEn from "./woman-sport-site-en";

import skess from "./skess";
import skessEn from "./skess-en";

import domaVeka from "./doma-veka";
import domaVekaEn from "./doma-veka-en";

import gosapteka from "./gosapteka";
import gosaptekaEn from "./gosapteka-en";

import rostBranding from "./rost-branding";
import rostBrandingEn from "./rost-branding-en";

import novaGroupBrandStyle from "./nova-group-brand-style";
import novaGroupBrandStyleEn from "./nova-group-brand-style-en";

import globeItSite from "./globe-it-site";
import globeItSiteEn from "./globe-it-site-en";

import ensemble from "./ensemble";
import ensembleEn from "./ensemble-en";

import ensembleBranding from "./ensemble-branding";
import ensembleBrandingEn from "./ensemble-branding-en";

import icr from "./icr";
import icrEn from "./icr-en";

import mumu from "./mumu";
import mumuEn from "./mumu-en";

import wildberries from "./wildberries";
import wildberriesEn from "./wildberries-en";

import domaVekaSite from "./doma-veka-site";
import domaVekaSiteEn from "./doma-veka-site-en";

import sibur from "./sibur";
import siburEn from "./sibur-en";

import opzero from "./opzero";
import opzeroEn from "./opzero-en";

import mynaret from "./mynaret";
import mynaretEn from "./mynaret-en";

import sk10 from "./sk-10";
import sk10En from "./sk-10-en";

import rutube from "./rutube";
import rutubeEn from "./rutube-en";

import сfps from "./сfps";
import сfpsEn from "./сfps-en";

import aist from "./aist";
import aistEn from "./aist-en";

import sk10Site from "./sk-10-site";
import sk10SiteEn from "./sk-10-site-en";

import cryptoro from "./cryptoro";
import cryptoroEn from "./cryptoro-en";

import v6Group from "./v6-group";
import v6GroupEn from "./v6-group-en";

import cns from "./cns";
import cnsEn from "./cns-en";

import cuprum from "./cuprum";
import cuprumEn from "./cuprum-en";

import banners from "./banners";
import bannersEn from "./banners-en";

import clt from "./clt";
import cltEn from "./clt-en";

import aag from "./aag";
import aagEn from "./aag-en";

const portfolio = {
  en: [
    //aagEn,
    cltEn,
    bannersEn,
    cuprumEn,
    cnsEn,
    v6GroupEn,
    sk10SiteEn,
    cryptoroEn,
    siburEn,
    domaVekaSiteEn,
    aistEn,
    сfpsEn,
    skessEn,
    opzeroEn,
    globeItSiteEn,
    sk10En,
    rutubeEn,
    mynaretEn,
    wildberriesEn,
    mumuEn,
    csdEn,
    ensembleEn,
    gosaptekaEn,
    novaGroupEn,
    domaVekaEn,
    rostBrandingEn,
    novaGroupBrandStyleEn,
    icrEn,
    ensembleBrandingEn,
    womanSportBrandingEn,
    womanSportSiteEn,
    atlanticEn,
  ],
  ru: [
    //aag,
    clt,
    banners,
    cuprum,
    cns,
    v6Group,
    sk10Site,
    cryptoro,
    sibur,
    domaVekaSite,
    aist,
    сfps,
    skess,
    opzero,
    globeItSite,
    sk10,
    rutube,
    mynaret,
    wildberries,
    mumu,
    csd,
    ensemble,
    gosapteka,
    novaGroup,
    domaVeka,
    rostBranding,
    novaGroupBrandStyle,
    icr,
    ensembleBranding,
    womanSportBranding,
    womanSportSite,
    atlantic,
  ],
};

export default portfolio;
