import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import "scss/index.scss";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import rootReducer from "./redux_";
import i18n from "i18n";
import { I18nextProvider } from "react-i18next";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { ParallaxProvider } from "react-scroll-parallax";
import { createStore } from "redux";

SwiperCore.use([Autoplay, Navigation]);

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const store = createStore(
  rootReducer,
  // включаем расширение хрома, для дебага redux
  //@ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const Router = BrowserRouter;

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <ParallaxProvider>
        <Router>
          <App />
        </Router>
      </ParallaxProvider>
    </I18nextProvider>
  </Provider>,
  document.getElementById("root")
);
