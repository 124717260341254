import Section from "components/layouts/Section";

import s from "./index.module.scss";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import { SwiperSlide, Swiper } from "swiper/react";

import * as ReactDOMServer from "react-dom/server";

import SwiperCore, { Pagination } from "swiper";
import RevealTextByLine from "components/common/RevealInView/RevealTextByLine";

SwiperCore.use([Pagination]);

const sectionData = {
  title: "отзывы клиентов",
  subtitle:
    "Мы ответственны в своём деле и всегда нацелены на достижение лучшего результата",
  reviews: [
    {
      title: "Мартынович Михаил",
      subtitle: "руководитель ИТ департамента - застройщик ооо СК10",
      point: 5,
      text: "Команда студии SAGIROV.com показала себя надежным подрядчиком, ответственно, оперативно и квалифицированно выполняющим работы по проектированию, сопровождению и обслуживанию сайта. Мы очень довольны результатами проделанной работы и надеемся на дальнейшее совместное сотрудничество.",
      icon: "sk10-paginator.svg",
    },

    {
      title: "Георги Атоян",
      subtitle: "Генеральный директор Дома-Века",
      point: 5,
      text: "Нам очень нравится результат, который мы получили. Мы давно хотели для себя сайт, который не будет похож на другие, типовые сайты из нашей отрасли. Нашим клиентам он тоже очень нравится. Самое главное, что он получился информативным, современным и понятным. Многие наши конкуренты тоже переделали свои сайты и позаимствовали у нас много идей.",
      icon: "doma-veka-paginator.svg",
    },
    {
      title: "Ильин Игорь",
      subtitle: "Учередитель ООО Новация",
      point: 5,
      text: "Выражаем благодарность Студии Микаэла Сагирова за разработку сайт для нашей компании - быстро и качественно, в процессе работы оперативно вносили правки. Подсказывали как будет лучше и какие вещи нам не подходят на основе аналитики и опыта. По итогу работы разработали дизайн, сайт, выполнили ряд интеграций. Работой довольны, готовы рекомендовать Студию как надежного подрядчика.",
      icon: "nova-group-paginator.svg",
    },
    {
      title: "Екатерина Цыганкова",
      subtitle: "Маркетолог Корпорации СКЭСС",
      point: 5,
      text: "На протяжении сотрудничества команда Студии SAGIROV.co демонстрирует высокий профессионализм, гибкость и быстрое реагирование в решении поставленных задач по поддержке и техническому обслуживанию сайта, индивидуальный подход в решении поставленных задач, а также их своевременное выполнение. Нам удалось построить эффективную коммуникацию, дружеские и доверительные отношения.",
      icon: "skess-paginator.svg",
    },
  ],

  footerText: (
    <>
      следующий <br /> отзыв
    </>
  ),
};

function ViewDeveloperSitesCustomerReviewsSection() {
  return (
    <Section
      className={
        "background--body " + s["view-developer-sites-customer-reviews-section"]
      }
    >
      <div className={"section-container container "}>
        <header>
          <div
            className={
              "row " +
              s["view-developer-sites-customer-reviews-section__header"]
            }
          >
            <RevealWhenInView className="col-xl-7">
              <h2 className="heading">{sectionData.title}</h2>
            </RevealWhenInView>
            <div className=" offset-md-4 col-md offset-xl-0">
              <RevealTextByLine
                wordClassName={(l, w) => l === 0 && w === 0 && "offset-md-3"}
              >
                {sectionData.subtitle}
              </RevealTextByLine>
            </div>
          </div>
        </header>
        <Swiper
          loop
          navigation={{
            nextEl: ".next-el-button",
          }}
          pagination={{
            clickable: true,
            el: ".swiper-pagination",
            bulletActiveClass: s.active,
            bulletClass: s["paginator-item"],
            renderBullet: function (index, className) {
              return ReactDOMServer.renderToStaticMarkup(
                <div className={className}>
                  <img
                    key={index}
                    className={"awards-img "}
                    src={`/images/projects-icon/${sectionData.reviews[index].icon}`}
                    width="70"
                    height="70"
                  />
                </div>
              );
            },
          }}
        >
          {sectionData.reviews.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <header
                  className={
                    s[
                      "view-developer-sites-customer-reviews-section__slider-header"
                    ] + " row"
                  }
                >
                  <RevealWhenInView className="col-7 italic-text text-uppercase">
                    <p className="heading-4 italic-text">{item.title}</p>
                    <RevealTextByLine className="sublabel--secondary">
                      {item.subtitle}
                    </RevealTextByLine>
                  </RevealWhenInView>
                  <RevealWhenInView className={"offset-auto"}>
                    <div
                      className={
                        s[
                          "view-developer-sites-customer-reviews-section__award-tag"
                        ]
                      }
                    >
                      <p>{`${item.point}.0`}</p>
                      <img src="/images/sprite-icons/star.svg" />
                    </div>
                  </RevealWhenInView>
                </header>
                <footer>
                  <span className={"subheading " + s['view-developer-sites-customer-reviews-section__review-footer-text']}>
                    <RevealTextByLine>{item.text}</RevealTextByLine>
                  </span>
                </footer>
              </SwiperSlide>
            );
          })}
          <RevealWhenInView>
            <footer
              className={
                "row " +
                s["view-developer-sites-customer-reviews-section__footer"]
              }
            >
              <div
                className={
                  "sprite-icon sprite-icon--arrow-down next-el-button " +
                  s[
                    "view-developer-sites-customer-reviews-section__footer-icon"
                  ]
                }
              />

              <p
                className={
                  s[
                    "view-developer-sites-customer-reviews-section__footer-text"
                  ] + " next-el-button "
                }
              >
                {sectionData.footerText}
              </p>

              <div
                className={
                  "row swiper-pagination " +
                  s[
                    "view-developer-sites-customer-reviews-section__paginator"
                  ] +
                  " next-el-button"
                }
              ></div>
            </footer>
          </RevealWhenInView>
        </Swiper>
      </div>
    </Section>
  );
}

export default ViewDeveloperSitesCustomerReviewsSection;
