import ViewPortfolioItemAboutSection from "../ViewPortfolioItemAboutSection";
import { useViewPortfolioItemContext } from "../ViewPortfolioItem";
import ViewPortfolioItemSection from "../ViewPortfolioItemSection";
import { usePortfolioItemAssets } from "hooks/useAssets";
import VimeoPlayer from "../../../common/VimeoPlayer/VimeoPlayer";
import ViewPortfolioItemParallaxSection from "../ViewPortfolioItemParallaxSection";
import {useTranslation} from "react-i18next";

export interface ViewPortfolioAtlanticProps { }

const ViewPortfolioAtlantic = (props: ViewPortfolioAtlanticProps) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);
  const { t } = useTranslation();
  return (
    <>
      <ViewPortfolioItemAboutSection
        name={data.name}
        video={{
          src: "https://vimeo.com/714041740"
        }}
        description={data.description}
        style={{ backgroundColor: "#F3F3F3"}}
      />

      <ViewPortfolioItemSection style={{backgroundColor: "#FFF"}} className="view-portfolio-globe-it-section">
          <div className="portfolio-item-section__inner">
              <div className="view-portfolio-globe-it-about row">
                  <div className="view-portfolio-globe-it-about__block col order-md-0 order-0 white">
                      <div className="view-portfolio-globe-it-about__img-cont col-4">
                          <img src={buildPageImageSrc('screen-3-1')} alt={'globe-it-phone'}/>
                      </div>
                  </div>
                  <div className="view-portfolio-globe-it-about__block col order-md-1 order-1 blue">
                      <div className="view-portfolio-globe-it-about__img-cont col-6">
                          <img src={buildPageImageSrc('screen-3-2')} alt={'globe-it-phone'}/>
                      </div>
                  </div>
                  <div className="view-portfolio-globe-it-about__block col order-md-2 order-3  yellow">
                      <div className="view-portfolio-globe-it-about__img-cont col-6">
                          <img src={buildPageImageSrc('screen-3-3')} alt={'globe-it-phone'}/>
                      </div>
                  </div>
                  <div className="view-portfolio-globe-it-about__block col order-md-3 order-2 white">
                      <div className="view-portfolio-globe-it-about__img-cont col-4">
                          <img src={buildPageImageSrc('screen-3-4')} alt={'globe-it-phone'}/>
                      </div>
                  </div>
              </div>
          </div>
      </ViewPortfolioItemSection>

      <ViewPortfolioItemSection className="view-portfolio-globe-it-section portfolio-item-section--type-git-4">
          <div className={"u-ratio u-ratio--9/16"}>
            <VimeoPlayer size="fill" className="view-portfolio-item-about-section__video" url={'637804007'} play muted controls={false} loop={true} />
          </div>
          <div className="">
              <img src={buildPageImageSrc('screen-4-2')} alt={'globe-it-site'}/>
          </div>
          <div className="">
              <img src={buildPageImageSrc('screen-4-3')} alt={'globe-it-site'}/>
          </div>
          <div className="">
              <img src={buildPageImageSrc('screen-4-4')} alt={'globe-it-site'}/>
          </div>
      </ViewPortfolioItemSection>

      <ViewPortfolioItemSection className="view-portfolio-globe-it-section portfolio-item-section--type-git-6">
          <div className={"u-ratio u-ratio--10_752/16"}>
              <VimeoPlayer size="fill" className="view-portfolio-item-about-section__video" url={'660852493'} play muted controls={false} loop={true} />
          </div>
      </ViewPortfolioItemSection>


      <ViewPortfolioItemSection style={{backgroundColor: "#FFF"}} className="view-portfolio-globe-it-section portfolio-item-section--type-git-7">
          <div className="portfolio-item-section__inner">
              <div className="view-portfolio-globe-it-pages row">
                  <div className="col offset-lg-3 col-lg-5 offset-md-2 col-md-7 offset-sm-1 col-sm-9 offset-auto col-10 row__1">
                      <img src={buildPageImageSrc('screen-7-1')} alt={'globe-it-site'}/>
                  </div>
                  <div className="col offset-sm-1 col-sm-5 offset-2 col-8 row__2">
                      <img src={buildPageImageSrc('screen-7-2')} alt={'globe-it-site'}/>
                  </div>
                  <div className="col offset-lg-2 col-lg-2 offset-sm-1 col-sm-3 offset-auto col-5 row__3">
                      <img src={buildPageImageSrc('screen-7-3')} alt={'globe-it-site'}/>
                  </div>
                  <div className="col offset-lg-3 col-lg-5 offset-sm-2 col-sm-7 col-10 offset-auto row__4">
                      <div className="row">
                          <div className="col col-5">
                              <img src={buildPageImageSrc('screen-7-4')} alt={'globe-it-site'}/>
                          </div>
                          <div className="col offset-1 col-5">
                              <img src={buildPageImageSrc('screen-7-5')} alt={'globe-it-site'}/>
                          </div>
                      </div>
                  </div>
                  <div className="col offset-sm-1 col-lg-4 col-sm-5 col-9 offset-auto row__5">
                      <img src={buildPageImageSrc('screen-7-6')} alt={'globe-it-site'}/>
                  </div>
                  <div className="col offset-sm-2 col-sm-3 offset-auto col-6 darken">
                      <img src={buildPageImageSrc('screen-7-7')} alt={'globe-it-site'}/>
                  </div>
                  <div className="col offset-1 col-lg-2 offset-sm-1 col-sm-3 offset-4 col-6 row__6">
                      <img src={buildPageImageSrc('screen-7-8')} alt={'globe-it-site'}/>
                  </div>
                  <div className="col offset-lg-2 offset-sm-1 col-sm-5 offset-auto col-8 row__7">
                      <img src={buildPageImageSrc('screen-7-9')} alt={'globe-it-site'}/>
                  </div>
                  <div className="col offset-lg-2 col-lg-7 offset-sm-1 col-sm-9 offset-auto col-10">
                      <img src={buildPageImageSrc('screen-7-10')} alt={'globe-it-site'}/>
                  </div>
              </div>
          </div>
      </ViewPortfolioItemSection>

      <ViewPortfolioItemSection style={{backgroundColor: "#1E1E1E"}} className="view-portfolio-globe-it-section portfolio-item-section--type-git-8">
          <div className="portfolio-item-section__inner">
              <div className="view-portfolio-globe-it-contact-404 row">
                  <div className="col offset-lg-2 col-lg-7 offset-sm-1 col-sm-9 offset-auto col-10 row__1">
                      <img src={buildPageImageSrc('screen-8-1')} alt={'globe-it-site'}/>
                  </div>
                  <div className="col col-11 view-portfolio-globe-it-contact-404__content row">
                      <div className="col offset-auto col-lg-11 col-11 row__2">
                          <div className={"u-ratio"} style={{paddingTop: '98%'}}>
                              <VimeoPlayer size="fill" className="view-portfolio-item-about-section__video" url={'637898878'} play muted controls={false} loop={true} />
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </ViewPortfolioItemSection>
      <ViewPortfolioItemSection style={{backgroundColor: "#FFF"}} className="view-portfolio-globe-it-section portfolio-item-section--type-git-9">
          <div className="portfolio-item-section__inner">
              <div className="view-portfolio-globe-it-logos row">
                  <div className="col row">
                      <div className="col-md-3 col-3">
                          <img src={buildPageImageSrc('screen-9-before')} alt={'globe-it-logo'}/>
                          <span className="info">{t("portfolioItem:globe-it.section-9.text.0")}</span>
                      </div>
                  </div>
                  <div className="col row">
                      <div className="col-md-3 col-3">
                          <img src={buildPageImageSrc('screen-9-after')} alt={'globe-it-logo'}/>
                          <span className="info">{t("portfolioItem:globe-it.section-9.text.1")}</span>
                      </div>
                  </div>
              </div>
          </div>
      </ViewPortfolioItemSection>

        <ViewPortfolioItemParallaxSection
            dynamicBackgroundColor={false}
            image={{src: buildPageImageSrc("transition")
        }}/>
    </>
  );
};

export default ViewPortfolioAtlantic;
