import ViewPortfolioMynaret from 'components/views/ViewPortfolioItem/ViewPortfolioMynaret';
import { PortfolioItemDataWithComponent } from 'data/portfolio/types';
import { buildPublicImageSrc } from '../../helpers/path';

const porfolioItem: PortfolioItemDataWithComponent = {
  id: 'mynaret',
  component: ViewPortfolioMynaret,
  title: 'mynaret',
  name: 'Mynaret',
  altName: 'Аpp for charity French Muslims',
  year: 2023,
  awardsList: [],

  socials: [
    {
      label: 'behance',
      href: 'https://www.behance.net/gallery/136467857/BRANDING-Mosque-donation-app',
    },
  ],

  preview: {
    label: 'mobile app',
    //поменять
    media: {
      type: 'image',
      fromSrc: buildPublicImageSrc({
        path: 'portfolios/wildberries/preview',
        file: '1-1440w.webp',
      }),
      toSrc: buildPublicImageSrc({
        path: 'portfolios/wildberries/preview',
        file: '2-1440w.webp',
      }),
    },
  },

  poolOfWorks: ['UX|UI design', 'Brand identity'],

  type: 'site',
  subtype: 'application',

  description: [
    'Is an application that belongs to the new france bank – Ensemble. The application was created to unite the mosques of france in one place',
    'Anyone can connect to the application, make a donation or offer volunteer help to any mosque that is in the application',
  ],
  shortDescription: 'Аpp for charity French Muslims',

  seo: {
    title: 'Branding of the Muslim mobile app Minaret',
    description:
      'We have developed a unique corporate website for the company for the construction of private houses of the House-Century, which meets all the needs of construction companies. The site has a convenient site CMS, which allows you to conveniently manage the catalog of projects and change the content on the site.',
    keywords: [
      'branding',
      'muslim application',
      'Minaret',
      'organization of mosques',
      'collection of donations',
      'corporate identity of Islam',
      'logo for mobile application',
      'mobile application for mosques',
    ],
    openGraph: {
      type: 'article',
      title: 'Branding of the Muslim mobile app Minaret',
      description:
        'We have developed a unique corporate website for the company for the construction of private houses of the House-Century, which meets all the needs of construction companies. The site has a convenient site CMS, which allows you to conveniently manage the catalog of projects and change the content on the site.',
    },
  },
};

export default porfolioItem;
