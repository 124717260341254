import { forwardRef, ReactNode, useEffect, useRef } from "react";
import SimpleParallax from "simple-parallax-js";
import Image, { ImageConfig } from "../Image";

export interface ParallaxProps {
  speed?: number;
  /**
   * параметры параллакса
   */
  className?: string;
  children?: ReactNode;
  image: ImageConfig;
}

/**
 * Создает параллакс эффект
 */
const Parallax = forwardRef<HTMLDivElement, ParallaxProps>(
  ({ className, speed = 0.5, image }, ref) => {
    const imageElRef = useRef<HTMLImageElement>(null);
    const parallaxRef = useRef<SimpleParallax | null>(null);

    useEffect(() => {
      const imageEl = imageElRef.current!;
      const parallax = (parallaxRef.current = new SimpleParallax(imageEl, {
        overflow: false,
      }));

      return () => {
        parallax.destroy();
      };
    }, [speed]);

    return (
      <Image
        ref={imageElRef}
        {...image}
        onLoad={() => {
          if (parallaxRef.current) parallaxRef.current.refresh();
        }}
        className={"u-full-height " + className}
      />
    );
  }
);

export default Parallax;
