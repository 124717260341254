import { AppActionTypes, AppState, CLOSE_MENU, OPEN_MENU } from "./types";

const initialState: AppState = {
  menuOpen: false,
};

const reducer = (state = initialState, action: AppActionTypes): AppState => {
  switch (action.type) {
    case OPEN_MENU: {
      return {
        ...state,
        menuOpen: true,
      };
    }

    case CLOSE_MENU: {
      return {
        ...state,
        menuOpen: false,
      };
    }

    default:
      return state;
  }
};

export default reducer;
