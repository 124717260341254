import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { loadImage, loadImages } from "helpers/common/dom";
import { makeCancelable } from "helpers/common/promises";
import useBreakpointData, { BreakpointDataType } from "hooks/useBreakpointData";

import FlipBookCanvas, { FlipBookCanvasRef } from "./FlipBookCanvas";

export interface FlipBookProps {
  /**
   * @default "div"
   */
  tag?: React.ElementType<any>;

  /**
   * Кадры (src картинок)
   */
  frames: string[];
  breakpointsFrames?: BreakpointDataType<string[]>;
  alt?: string;

  /**
   * Если true, тогда размер картинок будут рассчитываться
   * как свойство background-image со значением contain, в противном случае cover
   */
  contain?: boolean;
  framesPerScreen?: number;
  className?: string;
  clearBeforeDraw?: boolean;
  onHeightUpdate?: (height: number) => void;
}

/**
 * Создает анимацию смены фреймов при скролле
 */
const FlipBook = ({
  tag: Tag = "div",
  contain,
  frames,
  alt,
  className,
  framesPerScreen = 80,
  breakpointsFrames,
  clearBeforeDraw,
  onHeightUpdate
}: FlipBookProps) => {
  const breakpointFrames = useBreakpointData(breakpointsFrames || {}, frames);

  const [state, setState] = useState<{
    loading: boolean;
    previewImage: HTMLImageElement | null;
    framesImages: HTMLImageElement[];
  }>({
    loading: false,
    previewImage: null,
    framesImages: [],
  });

  const flipBookElRef = useRef<HTMLDivElement>(null);
  const innerElRef = useRef<HTMLDivElement>(null);
  const flipBookCanvasRef = useRef<FlipBookCanvasRef>(null);

  // эффект подгружает фреймы
  useEffect(() => {
    if (!breakpointFrames) {
      return;
    }

    setState((state) => ({
      ...state,
      loading: true,
      previewImage: null,
    }));

    const loadingImages = makeCancelable(loadImages(breakpointFrames));
    const loadingPreviewImage = makeCancelable(loadImage(breakpointFrames[0]));

    loadingPreviewImage.promise
      .then((image) => {
        setState((state) => ({
          ...state,
          previewImage: image,
        }));
      })
      .catch(() => { });

    loadingImages.promise
      .then((images) => {
        setState((state) => ({
          ...state,
          framesImages: images,
        }));
      })
      .catch((img) => {
        console.warn("frame loading error", img);
      })
      .finally(() => {
        setState((state) => ({
          ...state,
          loading: false
        }));
      });

    return () => {
      loadingImages.cancel();
      loadingPreviewImage.cancel();
    };
  }, [breakpointFrames]);

  // эффект обновляет высоту флип бука в зависимости от кол-во всех прогруженных фреймов
  useEffect(() => {
    const flipBookEl = flipBookElRef.current!;

    const height =
      (frames.length / framesPerScreen) * window.innerHeight;

    flipBookEl.style.height = height + "px";

    ScrollTrigger.refresh();

    if (onHeightUpdate) {
      onHeightUpdate(height);
    }
  }, [frames, framesPerScreen, onHeightUpdate]);

  // эффект инициализирует смену кадров при скролле
  useEffect(() => {
    // ничего не делаем если флип бук не инициализирован или картинки грузятся или если картинок нет
    if (state.loading || state.framesImages.length === 0) {
      return;
    }

    const flipBookCanvas = flipBookCanvasRef.current!;
    const innerEl = innerElRef.current!;
    const flipBookEl = flipBookElRef.current!;

    const updateCanvasSize = () => {
      flipBookCanvas.resize(innerEl.offsetWidth, innerEl.offsetHeight);
    };

    const drawFrame = (img: HTMLImageElement) => {
      if (clearBeforeDraw) {
        flipBookCanvas.clear();
      }

      flipBookCanvas.drawImage(img, contain ? "contain" : "cover");
    };

    // последний отрисованный фрейм
    let lastFrameIndex = 0;

    const handleScrollTriggerRefresh = () => {
      updateCanvasSize();

      const frameImage = state.framesImages[lastFrameIndex];

      if (frameImage) {
        drawFrame(frameImage);
      }
    };

    const handleFrameUpdate = (st: gsap.plugins.ScrollTriggerInstance) => {
      const frameImageIndex = Math.round(st.progress * (state.framesImages.length - 1))

      if (frameImageIndex !== lastFrameIndex) {
        const frameImage =
          state.framesImages[(lastFrameIndex = frameImageIndex)];

        if (frameImage) {
          drawFrame(frameImage);
        }
      }
    };

    const scrollTrigger = ScrollTrigger.create({
      trigger: flipBookEl,
      start: "top top",
      end: "bottom bottom",
      onRefresh: handleScrollTriggerRefresh,
      onUpdate: handleFrameUpdate,
    })

    return () => {
      scrollTrigger.kill();
    };
  }, [state, contain, clearBeforeDraw]);

  useEffect(() => {
    if (state.previewImage === null) {
      return;
    }

    const flipBookCanvas = flipBookCanvasRef.current!;
    const innerEl = innerElRef.current!;

    flipBookCanvas.clear();
    flipBookCanvas.resize(innerEl.offsetWidth, innerEl.offsetHeight);
    flipBookCanvas.drawImage(state.previewImage, contain ? "contain" : "cover");
  }, [state.previewImage, contain]);

  // путь к картинке, которая будет отображаться пока грузятся фреймы
  // const posterSrc = useMemo(() => {
  //   return (
  //     breakpointFrames && breakpointFrames.length > 0 && breakpointFrames[0]
  //   );
  // }, [breakpointFrames]);

  return (
    //@ts-ignore
    <Tag
      ref={flipBookElRef}
      className={classNames("flip-book", className)}
    >
      <div
        ref={innerElRef}
        className="flip-book__inner"
      >
        {/* {posterSrc && (
          <img
            className="flip-book__image"
            style={{ objectFit: contain ? "contain" : "cover" }}
            src={posterSrc}
            alt={alt}
          />
        )} */}
        <FlipBookCanvas ref={flipBookCanvasRef} />
      </div>
    </Tag>
  );
};

export default FlipBook;
