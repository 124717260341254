import { PortfolioItemDataWithComponent } from "data/portfolio/types";
import ViewPortfolioCfps from "components/views/ViewPortfolioItem/ViewPortfolioCfps";

const porfolioItem: PortfolioItemDataWithComponent = {
    id: "cfs",
    component: ViewPortfolioCfps,
    title: "сfps",
    name: "CFS",
    altName: "мобильное приложение \n для контроля финансов",
    year: 2023,
    link: {
        label: "bankapp.pro",
        href: "https://bankapp.pro/",
    },
    type: "site",
    subtype: "site",

    preview: {
        label: "корпоративный сайт",
        backgroundColor: "#1F1F21",
        media: {
            type: "video",
            src: "https://vimeo.com/914703645?share=copy",
        },
    },

    socials: [
        {
            label: "behance",
            href: "https://www.behance.net/gallery/167598297/CFPS-Neobank-website",
        },
    ],

    poolOfWorks: [
        "UX|UI design",
        "Page making"
    ],


    description: [
        "Онлайн необанк из Великобритании, предоставляющий услуги своим клиентам без необходимости посещать офис и позволяющий совершать платежи через приложение",
        "Мы разработали корпоративный сайт, который демонстрирует все плюсы приложения и помогает пользователям с ним взаимодействовать, а также информирует текущих клиентов и инвесторов о состоянии приложения и банка"
    ],
    shortDescription:
        "Разработка сайта для необанка из Великобритании",
    seo: {
        title: "Разработка сайта для необанка из Великобритании CFS",
        description: "Создали дизайн и разработали корпоративный сайт для приложения онлайн банка в Великобритании, который включает в себя детальное описание продукта компании, раздел с информацией о технической поддержке, вакансии, новости и статьи",
        keywords: ["разработка сайта, дизайн сайта, разработка сайта стартапа, сайт онлайн банка, необанк, нео банк, корпоративный сайт банка"],
        openGraph: {
            type: "article",
            title: "Разработка сайта для необанка из Великобритании CFS",
            description: "Создали дизайн и разработали корпоративный сайт для приложения онлайн банка в Великобритании, который включает в себя детальное описание продукта компании, раздел с информацией о технической поддержке, вакансии, новости и статьи",
        }
    },
};

export default porfolioItem;
