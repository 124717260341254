/**
 * Позволяет создать промис выполнение которого можно прервать
 *
 * @param promise
 * @returns
 */
export const makeCancelable = <T extends any>(promise: Promise<T>) => {
  let isCanceled = false;

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise
      .then((val) => (isCanceled ? reject({ isCanceled }) : resolve(val)))
      .catch((error) => (isCanceled ? reject({ isCanceled }) : reject(error)));
  });

  return {
    promise: wrappedPromise,
    cancel() {
      isCanceled = true;
    },
  };
};
