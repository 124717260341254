import ViewPortfolioCns from "components/views/ViewPortfolioItem/ViewPortfolioCns/";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";

const porfolioItem: PortfolioItemDataWithComponent = {
  id: "cns",
  component: ViewPortfolioCns,
  title: "cns",
  name: "cns",
  altName: "Бренд сумок \n из Ростова-на-дону",
  year: '2023-2024',
  awardsList: [],

  link: {
    label: "cnsbrand.ru",
    href: "https://cnsbrand.ru/",
  },

  socials: [
    {
      label: "behance",
      href: "https://www.behance.net/gallery/193024329/SK10-Construction-company-Real-estate-developer",
    },
  ],

  preview: {
    label: "корпоративный сайт",
    backgroundColor: "#E9E3D7",
    media: {
      type: "video",
      src: "https://vimeo.com/1002641325/8506cd9069",
    },
  },

  poolOfWorks: ["Research", 'UX|UI design', "refinements-of-the-design-system"],

  type: 'site',
  subtype: 'site',

  description: [
    "в рамках нашего проекта мы разработали дизайн интернет-магазина, продающего сумки из натуральной кожи. Мы создали сайт, который сочетает элегантность и функциональность, позволяя пользователям легко находить и приобретать желаемые товары"
  ],
  shortDescription: "Бренд сумок из Ростова-на-дону",

  seo: {
    title:
        "Разработка высоконагруженного интернет-магазина для бренда аксессуаров CNS с интеграциями",
    description:
        "Мы разработали интернет-магазин для женского бренда CNS с интеграцией 1С, онлайн-платежами и доставкой. Реализация позволила увеличить конверсию на 174%, повысить средний чек и автоматизировать обработку заказов, сокращая время работы менеджеров более чем в 4 раза",
    keywords: [
      "разработка интернет-магазина",
      "кейс CNS",
      "интеграция 1С для e-commerce",
      "онлайн-магазин аксессуаров",
      "создание e-commerce сайтов",
      "создание интернет магазина женских сумок",
      "автоматизация заказов",
      "создание высоконагруженного интернет магазина",
      "дизайн онлайн магазина"
    ],
    openGraph: {
      type: "article",
      title:
          "Разработка высоконагруженного интернет-магазина для бренда аксессуаров CNS с интеграциями",
      description:
          "Мы разработали интернет-магазин для женского бренда CNS с интеграцией 1С, онлайн-платежами и доставкой. Реализация позволила увеличить конверсию на 174%, повысить средний чек и автоматизировать обработку заказов, сокращая время работы менеджеров более чем в 4 раза",
    },
  },
};

export default porfolioItem;
