import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import classNames from "classnames";

import MorphSvgPath from "components/common/MorphSvg/MorphSvgPath";
import usePrevState from "hooks/usePrevState";

import { lerp, Position, Size } from "helpers/common/math";

import cursorPath from "./cursorPath";
import Print from "../Print";

export type CursorType = "default" | "pointer" | "arrow-right" | "arrow-left" | "button" | "cross" | "none";

const Cursor = (props: {}) => {
  const [type, setType] = useState<CursorType>("none");
  const prevType = usePrevState(type);
  const cursorElRef = useRef<HTMLDivElement>(null);
  const outlineElRef = useRef<HTMLDivElement>(null);
  const cursorBodyElRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // if (!isTouchDevice()) {
    //   return;
    // }

    const cursorEl = cursorElRef.current!;;
    const outlineEl = outlineElRef.current!;
    const cursorBodyEl = cursorBodyElRef.current!;

    const mousePosition: Position = {
      x: 0,
      y: 0
    }

    const cursorPosition: {
      current: Position,
      actual: Position,
      speed: number
    } = {
      current: {
        x: 0,
        y: 0
      },
      actual: {
        x: 0,
        y: 0
      },
      speed: 0.65
    }

    const outline: {
      position: {
        actual: Position,
        current: Position,
      },
      size: {
        actual: Size,
        current: Size,
      },
      opacity: {
        current: number,
        actual: number,
        speed: number
      },
      padding: number,
      speed: number
    } = {
      position: {
        actual: {
          x: 0,
          y: 0
        },
        current: {
          x: 0,
          y: 0
        },
      },
      size: {
        actual: {
          width: 0,
          height: 0
        },
        current: {
          width: 0,
          height: 0
        },
      },
      padding: 10,
      opacity: {
        current: 0,
        actual: 0,
        speed: 0.15
      },
      speed: 0.1
    }

    const MIN_VALUE_TO_ZERO = 0.01;

    let outlineTargetEl: HTMLElement | null;

    const cursorTypeAndSelector: { [key in CursorType]?: string } = {
      pointer: ".link--normal, .link--normal, button.language-switcher__button",
      button: "a.portfolio-item",
      none: "a.section-link__container, .showreel-button",
      cross: [
        "button.menu__toggle--opened",
        "button.contact-modal__close-btn",
        ".showreel-button--fullscreen",
        ".cookie-policy__accept-button"
      ].join(","),
      "arrow-left": ".swiper-button-prev",
      "arrow-right": ".swiper-button-next",
    }

    const getCursorTypeAndActualTarget = (target: HTMLElement) => {
      let result: {
        cursorType: CursorType,
        actualTarget: HTMLElement | null
      } = {
        cursorType: "default",
        actualTarget: null
      }

      if (target) {
        for (const key in cursorTypeAndSelector) {
          const cursorType = key as CursorType
          const targetSelector = cursorTypeAndSelector[cursorType];

          if (targetSelector && target.nodeName.indexOf('#') < 0) {
            if (target.matches(targetSelector)) {
              result = {
                cursorType: cursorType,
                actualTarget: target
              }
            } else {
              const closestTarget: HTMLElement | null = target.closest(targetSelector);

              if (closestTarget) {
                result = {
                  cursorType: cursorType,
                  actualTarget: closestTarget
                }
              }
            }
          }
        }
      }

      return result;
    }

    const setCursorPosition = ({ x, y }: Position) => {
      cursorEl.style.transform = `translate3d(${x}px, ${y}px, 0)`;
    }

    const updateCursorPosition = ({ x, y }: Position, speed: number = cursorPosition.speed) => {
      const { actual, current } = cursorPosition;

      actual.x = x;
      actual.y = y;

      current.x = lerp(current.x, actual.x, speed);
      current.y = lerp(current.y, actual.y, speed);

      setCursorPosition(current);
    }

    const updateCursor = () => {
      updateCursorPosition(mousePosition);
    }

    const setOutlinePosition = ({ x, y }: Position) => {
      outlineEl.style.transform = `translate3d(${x}px, ${y}px, 0)`;
    }

    const setOutlineSize = ({ width, height }: Size) => {
      outlineEl.style.width = width + "px";
      outlineEl.style.height = height + "px";
    }

    const setOutlineOpacity = (opacity: number) => {
      outlineEl.style.opacity = opacity + "";
    }

    const updateOutlineOpacity = (targetOpacity: number, speed: number = outline.opacity.speed) => {
      const { opacity } = outline;

      opacity.actual = targetOpacity;
      opacity.current = lerp(opacity.current, opacity.actual, speed);

      if (opacity.current <= MIN_VALUE_TO_ZERO) {
        opacity.current = 0;
      }

      setOutlineOpacity(opacity.current);
    }

    const updateOutlinePosition = ({ x, y }: Position, speed: number = outline.speed) => {
      const { position, padding } = outline;

      position.actual = {
        x: x - padding / 2,
        y: y - padding / 2
      }

      position.current = {
        x: lerp(position.current.x, position.actual.x, speed),
        y: lerp(position.current.y, position.actual.y, speed)
      }

      setOutlinePosition(position.current);
    }

    const updateOutlineSize = ({ width, height }: Size, speed: number = outline.speed) => {
      const { size, padding } = outline;

      size.actual = {
        width: width + padding,
        height: height + padding
      }

      size.current = {
        width: lerp(size.current.width, size.actual.width, speed),
        height: lerp(size.current.height, size.actual.height, speed)
      }

      setOutlineSize(size.current);
    }

    /**
     * Обновляет позицию обводки
     */
    const updateOutline = () => {
      const outlineTarget = outlineTargetEl || cursorBodyEl;
      const opacity = outlineTarget === cursorBodyEl ? 0 : 1;

      const { left, top, width, height } = outlineTarget.getBoundingClientRect();

      if (opacity === 1 && outline.opacity.current === 0) {
        updateOutlinePosition({
          ...cursorPosition.current
        }, 1);
      }

      updateOutlineOpacity(opacity);

      if (outline.opacity.current !== 0) {
        updateOutlinePosition({
          x: left,
          y: top
        });

        updateOutlineSize({
          width,
          height
        });
      }
    }

    let requestAnimationFrameId = -1;

    const render = () => {
      updateCursor();
      updateOutline();

      requestAnimationFrameId = requestAnimationFrame(render)
    }

    document.body.classList.add("hide-cursor");

    render();

    const updateMousePosition = (e: MouseEvent) => {
      mousePosition.x = e.clientX;
      mousePosition.y = e.clientY;
    }

    const updateCursorType = (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      let { cursorType, actualTarget } = getCursorTypeAndActualTarget(target);

      outlineTargetEl = cursorType === "pointer" ? actualTarget : null;

      setType(cursorType);
    }

    const handleWindowMouseOut = (e: MouseEvent) => {
      // если за пределами окна, тогда изменяем тип курсора на none
      if (e.relatedTarget === null) {
        setType("none")
      }
    }

    const handleWindowMouseOver = (e: MouseEvent) => {
      // если за в окне, тогда изменяем тип курсора на default
      if (e.relatedTarget === null) {
        updateCursorPosition(e, 1);
        setType("default");
      } else {
        updateCursorType(e);
      }
    }


    const handleWindowMouseMove = (e: MouseEvent) => {
      //console.log(e)
      updateCursorType(e);
      updateMousePosition(e);
    }

    window.addEventListener("mouseover", handleWindowMouseOver);
    window.addEventListener("mouseout", handleWindowMouseOut);
    window.addEventListener("mousemove", handleWindowMouseMove);

    return () => {
      document.body.classList.remove("hide-cursor");

      window.removeEventListener("mouseover", handleWindowMouseOver);
      window.removeEventListener("mouseout", handleWindowMouseOut);
      window.removeEventListener("mousemove", handleWindowMouseMove);

      cancelAnimationFrame(requestAnimationFrameId);
    }
  }, []);

  const morphVariant = useMemo(() => {
    return cursorPath[type] || cursorPath[prevType];
  }, [type, prevType]);

  const morph = ["arrow-left", "arrow-right", "cross"].includes(type);

  return (
    <>
      <div ref={cursorElRef} className={classNames("cursor", `cursor--type-${type}`)}>
        <div className="cursor__inner">
          {morphVariant ? <svg className="cursor__svg" width={40} height={34.2857} viewBox="0 0 40 34.2857">
            <MorphSvgPath {...morphVariant} morph={morph} duration="0.2s" />
          </svg> : null}
          <div ref={cursorBodyElRef} className="cursor__body">
            {!morphVariant && <div className="cursor__tray" />}

            <div className="cursor__label label">
              <Print data="layout:cursor.button-label" />
            </div>
          </div>
        </div>
      </div>
      <div ref={outlineElRef} className="cursor-outline" />
    </>
  )
};

export default Cursor;
