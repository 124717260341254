import ViewPortfolioGlobeItSite from 'components/views/ViewPortfolioItem/ViewPortfolioGlobeItSite';
import { PortfolioItemDataWithComponent } from "data/portfolio/types";

const porfolioItem: PortfolioItemDataWithComponent = {
  id: "globe-it-site",
  component: ViewPortfolioGlobeItSite,
  title: "globe-it",
  name: "Globe IT",
  altName: "manufacturer\nof pumping equipment",
  year: 2020,
  awardsList: [
    {imgSrc : "/images/awards/gray-bg/awwwards.png"},
    {imgSrc : "/images/awards/gray-bg/awwwards.png"},
    {imgSrc : "/images/awards/gray-bg/special-kudos.png"},
    {imgSrc : "/images/awards/gray-bg/ux.png"},
    {imgSrc : "/images/awards/gray-bg/ui.png"},
    {imgSrc : "/images/awards/gray-bg/innovation.png"},
    {imgSrc : "/images/awards/gray-bg/wda-gold.svg"},
  ],
  link: {
    label: "globus-it.ru",
    href: "https://globus-it.ru",
  },

  socials: [
    {
      label: "behance",
      href:
        "https://www.behance.net/gallery/129666069/WEBSITE-Globe-IT-pumping-station-production",
    },
  ],

  preview: {
    label: "corporate website",
    media: {
      type: "video",
      src: "https://vimeo.com/714041740",
    },
  },

  poolOfWorks: ["Logotype", "UX|UI design", "Page making", "Integration with 1c-bitrix"],

  type: "site",
  subtype: "branding",

  description: [
    "one of the biggest manufacturer of industrial equipment and pumping stations. The Company wanted to make website redesign and lightly refresh logotype lightly to stand out from competitors.",
  ],
  shortDescription:
    "Website development for manufacturer of industrial equipment Globe-IT",
  seo: {
    title: "Website development for manufacturer of industrial equipment Globe-IT",
    description: "Website development for Globe-IT company – the biggest manufacturer of industrial equipment and pumping stations. Corporate website works on technology Single Page Application",
    keywords: ["manufacturer of industrial equipment website",
      "website development for industrial company",
      "corporate website development",
      "corporate website development with equipment catalog",
      "website design for industrial company"],
    openGraph: {
      type: "article",
      title: "Website development for manufacturer of industrial equipment Globe-IT",
      description: "Website development for Globe-IT company – the biggest manufacturer of industrial equipment and pumping stations. Corporate website works on technology Single Page Application",
    }
  }

};

export default porfolioItem;
