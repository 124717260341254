import classNames from "classnames";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import { buildPublicImageSrc, FileFullName } from "helpers/path";

interface ViewPortfolioItemImageDataFile extends FileFullName {
  adaptive?: number[];
}

type ViewPortfolioItemImageDataSrcType =
  | {
    path: string;
    file: ViewPortfolioItemImageDataFile | string;
  }
  | string;

export interface ViewPortfolioItemImageData {
  src: ViewPortfolioItemImageDataSrcType;
  /**
   * @default ""
   */
  alt?: string;
}

export interface ViewPortfolioItemImageProps
  extends ViewPortfolioItemImageData {
  className?: string;
  imageClassName?: string;
  revealAnimation?: boolean;
}

const IMAGE_MEDIA_BREAKPOINTS = [1440, 768, 414];

const ViewPortfolioItemImage = ({
  src,
  alt = "",
  className,
  revealAnimation = true,
  imageClassName,
}: ViewPortfolioItemImageProps) => {
  const getImageSrc = (
    src: ViewPortfolioItemImageDataSrcType,
    breakpoint?: number
  ) => {
    if (typeof src === "string") {
      return src;
    }

    return buildPublicImageSrc({
      path: src.path,
      file:
        typeof src.file === "string"
          ? src.file
          : {
            name: breakpoint
              ? `${src.file.name}-${breakpoint}w`
              : src.file.name,
            ext: src.file.ext,
          },
    });
  };

  const image = <>
    {typeof src === "object" &&
      typeof src.file === "object" &&
      src.file.ext ? (
      <picture>
        {IMAGE_MEDIA_BREAKPOINTS.map((breakpoint, i) => {
          return (
            <source
              key={i}
              media={`(min-width: ${breakpoint}px)`}
              srcSet={getImageSrc(src, breakpoint)}
            />
          );
        })}
        <img
          className={classNames("view-portfolio-item__image u-w-full", imageClassName)}
          src={getImageSrc(src, IMAGE_MEDIA_BREAKPOINTS[2])}
          alt={alt}
        />
      </picture>
    ) : (
      <img
        className={classNames("view-portfolio-item__image u-w-full", imageClassName)}
        src={getImageSrc(src)}
        alt={alt}
      />
    )}
  </>

  return (
    <div className={className}>
      {
        revealAnimation ? <RevealWhenInView>
          {image}
        </RevealWhenInView> :
          image
      }


    </div>
  );
};

export default ViewPortfolioItemImage;
