import ViewPortfolioEnsemble from 'components/views/ViewPortfolioItem/ViewPortfolioEnsemble';
import { PortfolioItemDataWithComponent } from "data/portfolio/types";
import {buildPublicImageSrc} from "../../helpers/path";


const porfolioItem: PortfolioItemDataWithComponent = {
  id: "ensemble",
  component: ViewPortfolioEnsemble,
  title: "ensemble",
  name: "Ensemble",
  altName: "New Islamic\nonline-banking in France",
  year: 2021,
  link: {
    label: "getensemble.io",
    href: "https://getensemble.io/",
  },

  socials: [
    {
      label: "behance",
      href:
        "https://www.behance.net/gallery/123617149/Ensemble-French-Islamic-neobank",
    },
  ],

  preview: {
    label: "site online-banking",
    media: {
      type: "image",
      fromSrc: buildPublicImageSrc({
        path: "portfolios/ensemble/preview",
        file: "1-1440w.webp",
      }),
      toSrc: buildPublicImageSrc({
        path: "portfolios/ensemble/preview",
        file: "2-1440w.webp",
      }),
    },
  },

  poolOfWorks: ["Logotype", "UX|UI design", "Page making", "Branding"],

  type: "site",
  subtype: "site",

  description: [
    "Rapidly developing French startup bank, which financial activities is based on sharia principles.",
    "We should to create minimalistic logotype which reflects values of the company and shows advantages of Islamic banking."
  ],
  shortDescription:
    "Website development and brand identity for French online bank Ensemble",
  seo: {
    title: "Website development and brand identity for French online bank Ensemble",
    description: "We created brand identity, website and mobile app concept for French online bank, including ux ui design",
    keywords: ["Website for bank",
      "Fintech development",
      "Brand identity for bank",
      "Mobile application development for bank"],
    openGraph: {
      "type": "article",
      "title": "Website development and brand identity for French online bank Ensemble",
      "description": "We created brand identity, website and mobile app concept for French online bank, including ux ui design"
    }
  }

};

export default porfolioItem;
