import { usePortfolioItemAssets } from 'hooks/useAssets';
import { useViewPortfolioItemContext } from '../ViewPortfolioItem';
import ViewPortfolioItemAboutSection from '../ViewPortfolioItemAboutSection';
import VimeoPlayer from '../../../common/VimeoPlayer/VimeoPlayer';
import ViewPortfolioItemSection from '../ViewPortfolioItemSection';
import RevealWhenInView from '../../../common/RevealInView/RevealWhenInView';
import { useTranslation } from 'react-i18next';
import Parallax from 'components/common/Parallax/Parallax';
import ViewPortfolioItemParallaxSection from '../ViewPortfolioItemParallaxSection';

export interface ViewPortfolioOpzeroProps {}

const ViewPortfolioOpzero = (props: ViewPortfolioOpzeroProps) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);
  const { t } = useTranslation();

  return (
    <>
      <ViewPortfolioItemAboutSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#9FD9F0', color: '#fff' }}
        name={data.name}
        image={{
          isLogo: true,
          src: buildPageImageSrc('about', 'svg'),
        }}
        description={data.description}
      />
      {/* 3ий экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#9FD9F0' }}
        className="portfolio-item-section--type-opzero-3">
        <div>
          <RevealWhenInView delay={0.5} duration={1.5}>
            <img
              src={buildPageImageSrc(t('portfolioItem:opzero.section-3.image'))}
              alt={'opzero'}
            />
          </RevealWhenInView>
        </div>
      </ViewPortfolioItemSection>

      {/* 4ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#F3F4F4' }}
        className="portfolio-item-section--type-opzero-4 container">
        <RevealWhenInView delay={0.5} duration={1.5}>
          <img
            src={buildPageImageSrc(t('portfolioItem:opzero.section-4.image'))}
            alt={'opzero'}
          />
        </RevealWhenInView>
      </ViewPortfolioItemSection>
      {/* 5ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#F3F4F4' }}
        className="portfolio-item-section--type-opzero-5 container">
        <RevealWhenInView delay={0.5} duration={1.5}>
          <img src={buildPageImageSrc('screen-5')} alt={'opzero'} />
        </RevealWhenInView>
      </ViewPortfolioItemSection>
      {/* 6ой экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={false}
        style={{ backgroundColor: '#F3F4F4' }}
        className="portfolio-item-section--type-opzero-6">
        <Parallax
          image={{
            src: buildPageImageSrc('screen-6'),
            sources: [
              {
                type: 'webp',
                srcSet: buildPageImageSrc('screen-6'),
              },
            ],
          }}
        />
      </ViewPortfolioItemSection>
      {/* 7ой экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#F3F4F4' }}
        className="portfolio-item-section--type-opzero-7 container">
        <RevealWhenInView delay={0.5} duration={1.5}>
          <img src={buildPageImageSrc('screen-7')} alt={'opzero'} />
        </RevealWhenInView>
      </ViewPortfolioItemSection>
      {/* 8ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#F3F4F4' }}
        className="portfolio-item-section--type-opzero-8 container">
        <RevealWhenInView delay={0.5} duration={1.5}>
          <img
            src={buildPageImageSrc(t('portfolioItem:opzero.section-8.image'))}
            alt={'opzero'}
          />
        </RevealWhenInView>
      </ViewPortfolioItemSection>
      {/* 9ый экран*/}
      <ViewPortfolioItemParallaxSection
        dynamicBackgroundColor={false}
        image={{
          src: buildPageImageSrc('screen-9'),
          sources: [
            {
              type: 'webp',
              srcSet: buildPageImageSrc('screen-9'),
            },
          ],
        }}
      />
      {/* 10ый экран*/}
      <ViewPortfolioItemSection
        style={{ backgroundColor: '#9FD9F0' }}
        dynamicBackgroundColor={true}
        className="portfolio-item-section--type-opzero-10">
        <RevealWhenInView>
          <div className="opzero-section-video">
            <VimeoPlayer
              size="fill"
              url="https://player.vimeo.com/video/784366250?h=95cd824bfc"
              play
              muted
              controls={false}
              loop={true}
            />
          </div>
        </RevealWhenInView>
      </ViewPortfolioItemSection>
    </>
  );
};

export default ViewPortfolioOpzero;
