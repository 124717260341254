import classNames from "classnames";

interface ToggleSwitchProps {
  /**
   * Состояние переключателя (вкл. или выкл.)
   */
  toggled?: boolean;
  /**
   * Событие вызывающиеся при переключении
   */
  onToggle?: () => void;
  className?: string;
}

/**
 * Создает переключатель, который имеет состояние вкл. и выкл.
 */
const ToggleSwitch = ({ toggled, onToggle, className }: ToggleSwitchProps) => {
  return (
    <label className={classNames("toggle-switch", className)}>
      <input
        onChange={onToggle}
        checked={toggled}
        type="checkbox"
        className="toggle-switch__input"
      />
      <span className="toggle-switch__slider" />
    </label>
  );
};

export default ToggleSwitch;
