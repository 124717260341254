
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";

import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";
import Print from "components/common/Print";
import RevealTextByLine from "components/common/RevealInView/RevealTextByLine";
import { useViewAssets } from "hooks/useAssets";
// import { Parallax, Background } from "react-parallax";
// import { Parallax } from "react-scroll-parallax";
import Parallax from "components/common/Parallax";

interface ViewAboutServicesSectionProps { }

const ViewAboutServicesSection = (props: ViewAboutServicesSectionProps) => {
  const viewAssets = useViewAssets("about");

  return (
    <DynamicBackgroundTrigger>
      <section className="view-about-services-section section background--gray">
        <div className="view-about-services-section__container section__container container">
          <header className="view-about-services-section__header">
            <p className="view-about-services-section__overline label">
              <RevealWhenInView
                tag="span"
                targetTag="span"
                targetClassName="side-brackets"
                duration={0.6}
              >
                <Print data="view:about.services.label" />
              </RevealWhenInView>
            </p>
            <h2 className="view-about-services-section__heading heading">
              <span className="view-about-services-section__heading-term">
                <RevealWhenInView tag="span" targetTag="span" duration={0.6}>
                  <Print data="view:about.services.title.part-0" />
                  &nbsp;&mdash;&nbsp;
                </RevealWhenInView>
              </span>
              <span className="view-about-services-section__heading-description">
                <Print data="view:about.services.title.part-1">
                  {(text) => {
                    return <RevealTextByLine tag="span">
                      {text}
                    </RevealTextByLine>
                  }}
                </Print>
              </span>
            </h2>
          </header>
          <div className="view-about-services-section__body row">
            <div className="view-about-services-section__body-col-with-image col-11 order-lg-1 offset-lg-1 col-lg-6">
            
              <Parallax image={{
                src: viewAssets.getImageSrc("office", "jpg", 2022),
                srcSet: [
                  [viewAssets.getImageSrc("office", "jpg", 2022), "1x"],
                  [viewAssets.getImageSrc("office", "jpg", 4044), "2x"],
                ],
                sources: [
                  {
                    media: "(max-width: 767px)",
                    srcSet: [
                      [viewAssets.getImageSrc("office", "jpg", 620), "1x"],
                      [viewAssets.getImageSrc("office", "jpg", 1582), "2x"],
                    ],
                  },
                  {
                    media: "(max-width: 1199px)",
                    srcSet: [
                      [viewAssets.getImageSrc("office", "jpg", 1582), "1x"],
                      [viewAssets.getImageSrc("office", "jpg", 4044), "2x"],
                    ],
                  }
                ]
              }
              } />

            </div>

            <div className="view-about-services-section__body-col-with-description col-11 row col-lg order-lg-0">
              <div className="view-about-services-section__description col offset-md-2 col-md-7 offset-lg-0 col-lg">
                {Array.from({ length: 3 }, (_, i) => (

                  <Print key={i} data={`view:about.services.about-us.part-${i}`}>
                    {(data) => {
                      return <RevealTextByLine
                        key={i}
                        className="view-about-services-section__description-item"
                        wordClassName={(l, w) => l === 0 && w === 0 ? "offset-lg-3" : undefined}
                      >
                        {data}
                      </RevealTextByLine>
                    }}
                  </Print>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </DynamicBackgroundTrigger>
  );
};

export default ViewAboutServicesSection;
