import ViewPortfolioRost from "components/views/ViewPortfolioItem/ViewPortfolioRost";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";
import { buildPublicImageSrc } from "helpers/path";

const rost: PortfolioItemDataWithComponent = {
  id: "rost-branding",
  component: ViewPortfolioRost,
  title: "rostEn",
  name: "Rost",
  altName: "industrial\nconstruction company",
  year: 2020,

  link: {
    label: "ngrost.ru",
    href: "https://ngrost.ru",
  },

  socials: [
    {
      label: "behance",
      href: "https://www.behance.net/gallery/77548159/rost",
    },
  ],

  preview: {
    label: "logo and corporate identity",
    media: {
      type: "image",
      fromSrc: buildPublicImageSrc({
        path: "portfolios/rost",
        file: "preview-from.jpg",
      }),
      toSrc: buildPublicImageSrc({
        path: "portfolios/rost",
        file: "preview-to.jpg",
      }),
    },
  },

  relatedId: "rost-site",

  poolOfWorks: ["Logotype", "Brand style"],

  type: "branding",
  subtype: "branding",

  description: [
    "The company provides services for the construction of industrial facilities and customized solutions in the construction industry.",
    "The strict minimalistic design of the project is intended to inspire the trust of future clients, helps to build confidence in the choice of the organization."
  ],
  shortDescription:
    "branding for an industrial construction company",
  seo:
  {
    title: "Branding for the construction company ROST",
    description: "Creation of a corporate identity for an industrial construction company ROST, including the development of a logo, business cards, letterheads, envelopes, notebooks, badges, and a full brand book",
    keywords: ["industrial company logo",
      "logo development",
      "industrial company branding",
      "corporate style industrial construction",
      "corporate identity of an industrial company",
      "branding of an industrial company",
      "corporate identity development"],
    openGraph: {
      type: "article",
      title: "Branding for the construction company ROST",
      description: "Creation of a corporate identity for an industrial construction company ROST, including the development of a logo, business cards, letterheads, envelopes, notebooks, badges, and a full brand book",
    }
  }

};

export default rost;
