import ViewPortfolioSkess from "components/views/ViewPortfolioItem/ViewPortfolioSkess";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";

const skess: PortfolioItemDataWithComponent = {
  id: "skess",
  component: ViewPortfolioSkess,
  title: "skessEn",
  name: "Skess",
  altName: "Russia's largest\nelevator construction company",
  year: 2020,
  link: {
    label: "skess.ru",
    href: "https://skess.ru",
  },

  socials: [
    {
      label: "behance",
      href:
        "https://www.behance.net/gallery/100999169/WEBSITE-Grain-silos-construction-company-SKESS",
    },
  ],

  preview: {
    label: "corporate website",
    media: {
      type: "video",
      src: "https://vimeo.com/542710891",
    },
  },

  poolOfWorks: ["UX|UI design", "Page making", "Integration with 1c-bitrix"],

  type: "site",
  subtype: "site",

  description:
    [
      "the largest elevator construction company in Russia, with its own production and project department.",
      "We had to create a corporate website that would compare favorably with competing companies and show all the advantages of the SKESS corporation"
    ],

  shortDescription:
    "website for Russia's largest elevator construction company",

  seo:
  {
    title: "Design and development of a corporate website for the SKESS company",
    description: "We have developed a website for the SKESS company, which has 3 language versions, 3D images in equipment cards, a tender section and an interactive map with construction objects",
    keywords: ["website for industrial company", "website for equipment manufacturer", "site for the construction of elevators", "skess", "3d photos in product cards"],
    openGraph: {
      type: "article",
      title: "Design and development of a corporate website for the SKESS company",
      description: "We have developed a website for the SKESS company, which has 3 language versions, 3D images in equipment cards, a tender section and an interactive map with construction objects",
    }
  },
  pageContent: {
    sections: {
      mobileVersion: {
        theme: {
          backgroundColor: "#5C9EFF",
          color: "#fff"
        },
        frameCount: 130
      }
    }
  }
  // page: {
  //   section: {
  //     about: {
  //       theme: {
  //         background: {
  //           color: "#fff",
  //         },
  //       },
  //       content: {
  //         topText: [
  //           "Посадочная страница сайта",
  //           "1440 px",
  //           "Проектирование, дизайн, анимация",
  //         ],
  //       },
  //     },
  //     mobileVersion: {
  //       theme: {
  //         text: "light",
  //         background: {\
  //           color: "#5C9EFF",
  //         },
  //       },
  //     },
  //     gallery: {
  //       theme: {
  //         text: "light",
  //         background: {
  //           color: "#191722",
  //         },
  //       },
  //     },
  //   },
  // },
};

export default skess;
