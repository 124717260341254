import React, {
  ElementType,
  forwardRef,
  HTMLAttributes,
  ReactNode,
  Ref,
  useImperativeHandle,
  useRef,
} from "react";
import classNames from "classnames";

import { useBrowserInterfaceVisibility } from "contexts/BrowserInterfaceVisibility";
import { useEffect } from "react";

interface SectionProps extends HTMLAttributes<HTMLDivElement> {
  tag?: ElementType<any>;
  children: ReactNode;
  viewportHeight?: boolean;
  isLead?: boolean;
}

export type SectionRef = HTMLElement;

const Section = ({ viewportHeight, children, className, isLead, tag: TagName = "section" }: SectionProps, ref: Ref<SectionRef>) => {
  const sectionElRef = useRef<HTMLDivElement>(null);
  const browserInterface = useBrowserInterfaceVisibility();

  useEffect(() => {
    const sectionEl = sectionElRef.current!;

    sectionEl.style.height = viewportHeight ? `calc(100vh - ${browserInterface.lastDetectedInterfaceHeight}px)` : "";
  }, [browserInterface.lastDetectedInterfaceHeight, viewportHeight]);

  useImperativeHandle(ref, () => sectionElRef.current!);

  return (
    //@ts-ignore
    <TagName
      ref={sectionElRef}
      className={classNames("section", { "section--lead": isLead }, className)}
    >
      {children}
    </TagName>
  );
};

export default forwardRef<SectionRef, SectionProps>(Section);
