import { forwardRef, memo, Ref, useImperativeHandle, useRef } from "react";
import {
  getScaleFactorToCover,
  getScaleFactorToContain,
} from "helpers/common/canvas";

interface FlipBookCanvasProps { }

export interface FlipBookCanvasRef {
  /**
   * Обновляет размер холста с учетом соотношения пикселей устройства
   */
  resize: (width: number, height: number) => void;
  /**
   * Отрисовывает картинку image на холсте, применя масштабирование
   */
  drawImage: (img: HTMLImageElement, scale?: "cover" | "contain") => void;
  clear: () => void;
}

/**
 * Отвечает за отрисовку фреймов для компонента FlipBook
 */
const FlipBookCanvas = (
  props: FlipBookCanvasProps,
  ref: Ref<FlipBookCanvasRef>
) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useImperativeHandle(
    ref,
    () => {
      const canvasEl = canvasRef.current!;

      const ctx = canvasEl.getContext("2d")!;
      const dpr = Math.ceil(window.devicePixelRatio);

      const resize = (width: number, height: number) => {
        canvasEl.width = width * dpr;
        canvasEl.height = height * dpr;
      };

      const clear = () => ctx.clearRect(0, 0, canvasEl.width, canvasEl.height);

      const drawImage = (
        image: HTMLImageElement,
        scale: "cover" | "contain" = "cover"
      ) => {
        const getScaleFactor =
          scale === "cover" ? getScaleFactorToCover : getScaleFactorToContain;

        const scaleFactor = getScaleFactor(
          canvasEl.width,
          canvasEl.height,
          image.width,
          image.height
        );

        // размеры картинки с учетом увелечения
        const width = Math.floor(scaleFactor * image.width);
        const height = Math.floor(scaleFactor * image.height);

        // позиция отрисовки в центре холста
        const x = -Math.round((width - canvasEl.width) / 2);
        const y = -Math.round((height - canvasEl.height) / 2);

        ctx.drawImage(image, x, y, width, height);
      };

      return {
        resize,
        drawImage,
        clear,
      };
    },
    []
  );

  return <canvas ref={canvasRef} className="flip-book__canvas" />;
};

export default memo(
  forwardRef<FlipBookCanvasRef, FlipBookCanvasProps>(FlipBookCanvas)
);
