import React, { ReactChild, useCallback } from "react";

import Icon from "components/common/Icon";

import gsap from "gsap";

interface ScrollDownButtonProps {
  duration?: number;
  scrollTo: gsap.TweenVars["scrollTo"];
  ease?: "ease-out";
  children?: ReactChild;
  className?: string;
}

const ScrollDownButton = ({
  duration = 0.6,
  ease,
  scrollTo,
  children,
  className,
}: ScrollDownButtonProps) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      gsap.to(window, {
        scrollTo,
        ease,
        duration,
      });
    },
    [scrollTo, ease, duration]
  );

  return (
    <button
      onClick={handleClick}
      className={"button row scroll-down-button " + className}
    >
      {children && <>{children}</>}

      <Icon name="arrow-down" className="scroll-down-button__icon" />
    </button>
  );
};

export default ScrollDownButton;
