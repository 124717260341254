import ViewServicesLeadSection from "./ViewServicesLeadSection";
import ViewServicesServiceSection from "./ViewServicesServiceSection";
import PortfolioLinkSection from "components/layouts/PortfolioLinkSection";
import View from "components/layouts/View";
import Footer from "components/layouts/Footer";

const serviceSitesAndMobile = {
  name: "view:services.section.sites-and-mobile.title",
  description: "view:services.section.sites-and-mobile.description",
  services: {
    types: ["Web-applications", "Landings", "Online-shops", "Ios", "Android"],
    items: [
      "Audit and analytics",
      "Planning",
      "Design",
      "Development and launch of a new product",
      "Redesign of an existing product",
      "Filling with content",
      "Maintenance",
    ],
  },
  integrations: [
    "bitrix 24",
    "amocrm",
    "1c-fitness",
    "roistat",
    "comagic",
    "mango",
  ],
  cms: [
    "1c-bitrix",
    "modx",
    "wordpress",
    "joomla",
    "tilda",
    "webflow",
    "readymag",
  ],
  technologies: [
    "html + js + css/scss/sass",
    "react.js",
    "redux",
    "next.js",
    "node.js",
    "vue.js",
    "vueex",
    "nuxt.js",
    "typescript",
    "python",
    "django",
    "swift",
    "php",
    "sql"
  ],
};

const sectionBranding = {
  name: "view:services.section.branding.title",
  description: "view:services.section.branding.description",
  services: {
    types: ["Logotype", "Brand style", "Brand book", "Packing"],
    items: [
      "audit and analysis",
      "corporate identity development",
      "Infographic design",
      "Redesign of the existing corporate identity",
      "Content for a site or application",
      "Promotional materials",
    ],
  },
};

const sectionDesign = {
  name: "view:services.section.design.title",
  description: "view:services.section.design.description",
  services: {
    types: ["Logotype", "Brand style", "Presentations", "Advertising banners", "Illustration", "Design ux", "Development TS", "Mvp"],
    items: [
      "Grocery ux/ui",
      "Identity",
      "Packaging",
      "Website design",
      "Application design",
      "Advertising creatives",
      "3D modeling",
      "Motion",
    ],
  },
  work: [
    "Seamlessly integrated into your processes",
    "Own staff of designers of different profiles",
    "Transparency of work at all stages",
    "Daily performance reports",
  ],
};

interface ViewServicesProps { }

const ViewServices = (props: ViewServicesProps) => {
  return (
    <View className="view--services">
      <ViewServicesLeadSection />
      <ViewServicesServiceSection
        {...serviceSitesAndMobile}
        className="background--gray"
      />
      <ViewServicesServiceSection {...sectionBranding} />
      <ViewServicesServiceSection
          {...sectionDesign}
          className="background--gray"
      />
      <PortfolioLinkSection className="view-services-portfolio-link-section" />
      <Footer />
    </View>
  );
};

export default ViewServices;
