import ViewPortfolioGosapteka from "components/views/ViewPortfolioItem/ViewPortfolioGosapteka";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";
import { buildPublicImageSrc } from "helpers/path";

const gosapteka: PortfolioItemDataWithComponent = {
  id: "gosapteka",
  component: ViewPortfolioGosapteka,
  title: "gosapteka",
  name: "Госаптека",
  altName: "федеральная сеть\nгосударственных аптек",
  year: 2020,

  socials: [
    {
      label: "behance",
      href: "https://www.behance.net/gallery/95078235/iOS-Pharmacy-dashboard",
    },
  ],

  preview: {
    label: "iOS-приложение",
    media: {
      type: "image",
      fromSrc: buildPublicImageSrc({
        path: "portfolios/gosapteka/preview",
        file: "1-1440w.jpg",
      }),
      toSrc: buildPublicImageSrc({
        path: "portfolios/gosapteka/preview",
        file: "2-1440w.jpg",
      }),
    },
  },

  poolOfWorks: ["UX|UI design", "User flow optimization"],

  type: "interfaces",
  subtype: "application",

  description: [
    "Требовалось создать внутреннее корпоративное мобильное приложение для федеральной сети аптек.",
    "Приложение позволяет анализировать финансовые показатели и предназначено для менеджеров и руководителей",
  ],
  shortDescription:
    "приложение для федеральной сети государственных аптек",
  seo:
  {
    title: "Разработка iOS приложения для ГосАптека",
    description: "Разработка дизайна для iOS приложения сети аптек ГосАптека. Проработаны пути взаимодейтсвия и интерфейс приложения полностью для мобильных и планшетных устройств. Новые виды диаграм и удобные таблицы, а также дневной и ночной режим приложения.",
    keywords: ["создание приложения для аптеки", "ios приложения для фарм компании", "разработка мобильного приложения аптеки", "дизайн приложения аптеки", "ux ui дизайн ios", "ios разработчик для фарм компаний"],
    openGraph: {
      type: "article",
      title: "Разработка iOS приложения для ГосАптека",
      description: "Разработка дизайна для iOS приложения сети аптек ГосАптека. Проработаны пути взаимодейтсвия и интерфейс приложения полностью для мобильных и планшетных устройств. Новые виды диаграм и удобные таблицы, а также дневной и ночной режим приложения.",
    }
  }

};

export default gosapteka;
