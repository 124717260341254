import ViewPortfolioOpzero from 'components/views/ViewPortfolioItem/ViewPortfolioOpzero';
import { PortfolioItemDataWithComponent } from 'data/portfolio/types';
import { buildPublicImageSrc } from '../../helpers/path';

const porfolioItem: PortfolioItemDataWithComponent = {
  id: 'opzero',
  component: ViewPortfolioOpzero,
  title: 'opzero',
  name: 'Operation zero',
  altName: 'Платформа для приобретения zero-day эксплоитов',
  year: 2022,
  awardsList: [{ imgSrc: '/images/awards/gray-bg/DP-graphic.svg' }],

  vc: {
    label: 'vc.ru',
    href: 'https://vc.ru/design/581248-operation-zero-stilnyy-brending-dlya-hakerov',
  },

  socials: [
    {
      label: 'behance',
      href: 'https://www.behance.net/gallery/159933693/Operation-zero',
    },
  ],
  preview: {
    label: 'фирменный стиль',
    media: {
      type: 'image',
      fromSrc: buildPublicImageSrc({
        path: 'portfolios/wildberries/preview',
        file: '1-1440w.webp',
      }),
      toSrc: buildPublicImageSrc({
        path: 'portfolios/wildberries/preview',
        file: '2-1440w.webp',
      }),
    },
  },
  poolOfWorks: ['Logotype', 'Brand style'],
  type: 'branding',
  subtype: 'branding',
  description: [
    'платформа для приобретения так называемых эксплоитов нулевого дня. Платформа, созданная профессионалами в области безопасности, предоставляет клиентам беспрецедентные технологии для защиты программного обеспечения',
  ],
  shortDescription:
    'брендинг для Платформа для приобретения zero-day эксплоитов',

  seo: {
    title: 'Разработка фирменного стиля Operation Zero',
    description:
      'Мы создали уникальный фирменный стиль для ИТ компании Operation Zero, которая разработала платформу по продаже zero day exploits. В рамках работ мы разработали логотип, элементы фирменного стиля, презентацию для использования на конференция,  плакаты, офрмление соц сетей и дизайн полиграфии и печатной продукции',
    keywords: [
      'фирменный стиль для it компании',
      'брендинг для ит компаний',
      'фирменный стиль для стартапов',
      'разработка логотипа',
      'дизайн презентации',
      'дизайн постов в социальных сетях',
      'шрифтовой логотип для компании',
    ],
    openGraph: {
      type: 'article',
      title: 'Разработка фирменного стиля Operation Zero',
      description:
        'Мы создали уникальный фирменный стиль для ИТ компании Operation Zero, которая разработала платформу по продаже zero day exploits. В рамках работ мы разработали логотип, элементы фирменного стиля, презентацию для использования на конференция,  плакаты, офрмление соц сетей и дизайн полиграфии и печатной продукции',
    },
  },
};

export default porfolioItem;
