import ViewPortfolioMumu from 'components/views/ViewPortfolioItem/ViewPortfolioMumu';
import { PortfolioItemDataWithComponent } from "data/portfolio/types";
import {buildPublicImageSrc} from "../../helpers/path";


const porfolioItem: PortfolioItemDataWithComponent = {
  id: "mumu",
  component: ViewPortfolioMumu,
  title: "mumu",
  name: "mu mu",
  altName: "художественный музей мусора",
  year: 2022,
  awardsList: [
    {imgSrc : "/images/awards/DP-graphic.svg"},
  ],

  link: {
    label: "art-mumu.ru",
    href: "https://art-mumu.ru/",
  },

  socials: [
    {
      label: "behance",
      href:
        "https://www.behance.net/gallery/153944813/BRANDING-Garbage-museum",
    },
  ],

  preview: {
    label: "фирменный стиль",
    media: {
      type: "image",
      fromSrc: buildPublicImageSrc({
        path: "portfolios/mumu/preview",
        file: "1-1440w.webp",
      }),
      toSrc: buildPublicImageSrc({
        path: "portfolios/mumu/preview",
        file: "2-1440w.webp",
      }),
    },
  },

  poolOfWorks: ["Logotype", "Brand style"],



  type: "branding",
  subtype: "branding",

  description: [
    "художественный музей мусора, миссия которого заключается в привлечении внимания к идее разумного потребления.",
    "Это крупнейшая выставка предметов искусства, сделанных из мусора."
  ],
  shortDescription:
    "Разработка брендинга для художественного Музея Мусора (МуМу)",
  seo: {
    title: "Разработка брендинга для художественного Музея Мусора (МуМу)",
    description: "Создали концепт элементов брендинга и фирменного стиля (плакаты, мерч, визитки, билеты, навигацию) для музея МуМу, который использует формы и силуеты мусорных отходов в качестве графическиъ элементов",
    keywords: ["брендинг музея",
      "разработка фирменного стиля музея",
      "брендинг для современного музея",
      "графический дизайн для художественного музея"],
    openGraph: {
      "type": "article",
      "title": "Разработка брендинга для художественного Музея Мусора (МуМу)",
      "description": "Создали концепт элементов брендинга и фирменного стиля (плакаты, мерч, визитки, билеты, навигацию) для музея МуМу, который использует формы и силуеты мусорных отходов в качестве графическиъ элементов"
    }
  }

};

export default porfolioItem;
