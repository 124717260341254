import Icon from "components/common/Icon";
import List from "components/common/List";
import { useEffect, useRef } from "react";

import gsap from "gsap";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import { useTranslation } from "react-i18next";

const services = [
  {
    type: "Sites",
    items: ["Web-applications", "Landings", "Online-shops"],
  },
  {
    type: "Mobile",
    items: ["Ios", "Android"],
  },
  {
    type: "Branding",
    items: ["Logotype", "Corporate style", "Brand book", "Packing"],
  },
];

interface ViewServicesLeadSectionProps {}

const ViewServicesLeadSection = (props: ViewServicesLeadSectionProps) => {
  const { t } = useTranslation();
  const titleRef = useRef<HTMLHeadingElement>(null);
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const servicesGroupsRef = useRef<(HTMLElement | null)[]>([]);
  const costBtnRef = useRef<HTMLButtonElement>(null);
  const costBottomBtnRef = useRef<HTMLButtonElement>(null);
  const bottomTitleRef = useRef<HTMLParagraphElement>(null);
  const arrowRef = useRef<HTMLElement>(null);

  const servicesGroupsLabelsRef = useRef<(HTMLElement | null)[]>([]);
  const servicesGroupsListsRef = useRef<(HTMLElement | null)[]>([]);

  useEffect(() => {
    const titleElem = titleRef.current!;
    const descriptionElem = descriptionRef.current!;
    const servicesGroupsElem = servicesGroupsRef.current!;
    const costBtnElem = costBtnRef.current!;
    const costBottomBtnElem = costBottomBtnRef.current!;
    const bottomTitleElem = bottomTitleRef.current!;
    const arrowElem = arrowRef.current!;

    const servicesGroupsLabelsElem = servicesGroupsLabelsRef.current!;
    const servicesGroupsListsElem = servicesGroupsListsRef.current!;

    const timeline = gsap.timeline({
      defaults: { ease: "ease-out", duration: 0.6 },
    });
    const isTablet = matchMedia("(min-width: 768px)").matches;

    const getServicesGroupsTimeline = () => {
      const servicesGroupsTimeline = gsap.timeline({ ease: "none" });

      servicesGroupsElem.forEach((servicesGroupElem, i) => {
        const servicesGroupLabelElem = servicesGroupElem?.children[0];
        const servicesItemsElems = servicesGroupElem?.children[1].querySelectorAll(
          ".list__item"
        );
        const servicesGroupTimeline = gsap.timeline({
          ease: "none",
          defaults: { ease: "ease-out", duration: 0.8 },
        });

        servicesGroupTimeline.from(servicesGroupElem, {
          y: 25,
          duration: (servicesItemsElems?.length || 1) * 0.2 + 0.8 - 0.2,
          // delay: 0.8 * i,
        });

        if (servicesGroupLabelElem) {
          servicesGroupTimeline.from(
            servicesGroupLabelElem,
            {
              y: 15,
              opacity: 0,
            },
            "<"
          );
        }

        if (servicesItemsElems) {
          servicesGroupTimeline.from(
            servicesItemsElems,
            {
              y: 15,
              opacity: 0,
              stagger: 0.2,
            },
            "<"
          );
        }

        servicesGroupsTimeline.add(servicesGroupTimeline, 0);
      });

      return servicesGroupsTimeline;
    };

    if (isTablet) {
      timeline.from(descriptionElem, {
        y: 15,
        opacity: 0,
        delay: 0.5,
      });

      timeline.from(
        costBtnElem,
        {
          y: 15,
          opacity: 0,
          delay: 0.15,
        },
        "<"
      );

      timeline.from(
        servicesGroupsLabelsElem,
        {
          y: 15,
          opacity: 0,
          stagger: 0.2,
        },
        "<"
      );

      timeline.from(
        servicesGroupsListsElem,
        {
          y: 15,
          opacity: 0,
          stagger: 0.2,
        },
        "<"
      );

      // timeline.add(getServicesGroupsTimeline(), "appear");

      timeline.from(
        bottomTitleElem,
        {
          y: 15,
          opacity: 0,
        },
        "<"
      );

      timeline.from(
        arrowElem,
        {
          y: -15,
          opacity: 0,
        },
        "<"
      );
    } else {
      timeline.from(titleElem, {
        y: 15,
        opacity: 0,
        delay: 0.5,
      });

      timeline.add(getServicesGroupsTimeline(), "<");

      timeline.from(
        costBottomBtnElem,
        {
          y: 15,
          opacity: 0,
        },
        "<"
      );

      timeline.from(
        arrowElem,
        {
          y: -15,
          opacity: 0,
        },
        "<"
      );
    }
  }, []);

  return (
    <section className="view-services-lead-section lead-section space--header">
      <div className="view-services-lead-section__container lead-section__container container">
        <div className="view-services-lead-section__inner lead-section__inner">
          <header className="view-services-lead-section__header">
            <h1
              ref={titleRef}
              className="view-services-lead-section__title lead-section__title title"
            >
              <RevealWhenInView>
                {t("view:services.section.lead.title")}
              </RevealWhenInView>
            </h1>
          </header>

          <div className="view-services-lead-section__body lead-section__body">
            <div className="view-services-lead-section__body-inner row">
              <div className="view-services-lead-section__body-top row offset-xxl-4 col-xxl-4">
                <div className="view-services-lead-section__col-with-description col-7 col-xxl-11">
                  <p
                    ref={descriptionRef}
                    className="view-services-lead-section__description text--indent-md-quarter text--indent-xxl-half u-max-w-480"
                  >
                    <RevealWhenInView tag="span">
                      {t("view:services.section.lead.description")}
                    </RevealWhenInView>
                  </p>
                </div>

                <div className="view-services-lead-section__col-with-cost-btn col-auto">
                  <button
                    ref={costBtnRef}
                    className="button link link--normal label"
                  >
                    <RevealWhenInView tag="span">
                      {t("view:services.section.lead.costBtn")}
                    </RevealWhenInView>
                  </button>
                </div>
              </div>

              <div className="view-services-lead-section__body-middle offset-md-4 col-auto offset-xxl-0">
                <List
                  items={services}
                  itemClassName="view-services-lead-section__services-group"
                >
                  {(service, i) => (
                    <RevealWhenInView>
                      <div
                        ref={(el) => (servicesGroupsRef.current[i] = el)}
                        className="row"
                      >
                        <div
                          ref={(el) =>
                            (servicesGroupsLabelsRef.current[i] = el)
                          }
                          className="view-services-lead-section__col-with-service-type col-4"
                        >
                          <p className="label side-brackets">
                            {t(service.type)}
                          </p>
                        </div>

                        <List
                          ref={(el) => (servicesGroupsListsRef.current[i] = el)}
                          items={service.items}
                          className="view-services-lead-section__col-with-service-list col"
                        >
                          {(serviceItem) => (
                            <p className="label">{t(serviceItem)}</p>
                          )}
                        </List>
                      </div>
                    </RevealWhenInView>
                  )}
                </List>
              </div>

              <div className="view-services-lead-section__body-bottom col-xxl-11">
                <button
                  ref={costBottomBtnRef}
                  className="view-services-lead-section__body-bottom-cost-btn button link link--normal label"
                >
                  <RevealWhenInView tag="span">
                    {t("view:services.section.lead.costBtn")}
                  </RevealWhenInView>
                </button>
                <p
                  ref={bottomTitleRef}
                  className="view-services-lead-section__title view-services-lead-section__body-bottom-title display title--line-height-none"
                >
                  <RevealWhenInView tag="span">
                    {t("view:services.section.lead.title")}
                  </RevealWhenInView>
                </p>
                <RevealWhenInView>
                  <Icon
                    ref={arrowRef}
                    className="view-services-lead-section__arrow lead-section__arrow"
                    name="arrow-down"
                  />
                </RevealWhenInView>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ViewServicesLeadSection;
