import { PortfolioItemData } from "data/portfolio/types";
import { buildLinkToPortfolioItem } from "routes/helpers";
import SectionLink from "components/layouts/SectionLink";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import { useMemo, useRef } from "react";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";
import {useTranslation} from "react-i18next";

interface ViewPortfolioItemNextSectionProps {
  portfolioItem: PortfolioItemData;
}

const ViewPortfolioItemNextSection = ({
  portfolioItem,
}: ViewPortfolioItemNextSectionProps) => {
  const { id, name, shortDescription } = portfolioItem;

  const tickerText = useMemo(() => [name], [name])

  const sectionLinkRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  return (
    <DynamicBackgroundTrigger triggerRef={sectionLinkRef}>
      <SectionLink
        ref={sectionLinkRef}
        className="view-portfolio-item-next-section"
        to={buildLinkToPortfolioItem(id)}
        label={t("Next work")}
        tickerText={tickerText}
        description={() => (
          <RevealWhenInView className="view-portfolio-item-next-section__description offset-4 offset-md-0">
            <p className="u-max-w-360">{shortDescription}</p>
          </RevealWhenInView>
        )}
      />
    </DynamicBackgroundTrigger>
  );
};

export default ViewPortfolioItemNextSection;
