import React, { useEffect, useRef } from 'react'

import classNames from "classnames";

import gsap from 'gsap';

interface TerminalLoadingProps {
  text: string,
  className?: string,
  duration?: number
}

const TerminalLoading = ({ text, className, duration = 0.8 }: TerminalLoadingProps) => {
  const lettersElmsRef = useRef<(HTMLSpanElement | null)[]>([]);

  useEffect(() => {
    const lettersElms = lettersElmsRef.current;
    const repeatDelay = duration / text.length;
    const timeline = gsap.timeline({ repeat: -1, repeatDelay })

    timeline.fromTo(lettersElms, {
      opacity: 0
    }, {
      stagger: {
        amount: duration
      },
      duration: 0.4,
      opacity: 1,
    })

    timeline.to(lettersElms, {
      delay: repeatDelay,
      stagger: {
        amount: duration
      },
      duration: 0.4,
      opacity: 0,
    })

    return () => {
      timeline.kill();
    }
  }, [duration, text.length]);

  return (
    <p className={classNames("view-not-found__terminal-loading", className)}>
      {Array.from(text).map((letter, i) => {
        return <span key={i} ref={(el) => lettersElmsRef.current[i] = el} className="view-not-found__terminal-loading-letter">
          {letter}
        </span>
      })}
    </p>
  )
}

export default TerminalLoading
