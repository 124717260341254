import classNames from "classnames";
import List from "components/common/List";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";
import SlideFadeOutWhenInView from "../ViewPortfolioItem/ViewPortfolioNovaGroup/SlideFadeOutWhenInView";
import Print from "components/common/Print";
import RevealTextByLine from "components/common/RevealInView/RevealTextByLine";

interface ViewServicesSectionProps {
  name: string;
  description: string;
  services: {
    types: string[];
    items: string[];
  };
  technologies?: string[];
  work?: string[];
  integrations?: string[];
  cms?: string[];
  backgroundColor?: string;
  className?: string;
}

const ViewServicesSection = ({
  name,
  description,
  className,
  services,
  integrations,
  cms,
  technologies,
  work,
}: ViewServicesSectionProps) => {
  return (
    <DynamicBackgroundTrigger>
      <section
        className={classNames("view-services-section section", className)}
      >
        <div className="view-services-section__container container">
          <header className="view-services-section__header">
            <div className="row">
              <RevealWhenInView className="view-services-section__col-with-heading col-xl-7">
                <h2 className="view-services-section__heading heading">
                  <Print data={name} />
                </h2>
              </RevealWhenInView>
              <div className="view-services-section__col-with-description offset-md-4 col-md offset-xl-0">
                <Print data={description}>
                  {(data) => (
                    <RevealTextByLine
                      wordClassName={(l, w) =>
                        l === 0 && w === 0 && "offset-md-3"
                      }
                    >
                      {data}
                    </RevealTextByLine>
                  )}
                </Print>
              </div>
            </div>
          </header>
          <div className="view-services-section__body row text--lowercase">
            <div className="view-services-section__col-with-services-types col-11 order-md-0 col-md-4 col-xl-5">
              <SlideFadeOutWhenInView>
                {({ setTriggerEl, setTarget }) => (
                  <div ref={setTriggerEl} className="row">
                    <div className="view-services-section__col-with-services-types-label view-services-section__col-with-label col-11">
                      <p className="label side-brackets">
                        {<Print data="We do" />}
                      </p>
                    </div>
                    <List
                      ref={setTarget({ selector: ".list__item" })}
                      items={services.types}
                      className="view-services-section__services-types-list sublabel sublabel--secondary col-11"
                    >
                      {(label) => <Print data={label} />}
                    </List>
                  </div>
                )}
              </SlideFadeOutWhenInView>
            </div>

            <div className="view-services-section__col-with-services col-11 order-md-1 col-md-7 col-xl-6">
              <SlideFadeOutWhenInView>
                {({ setTriggerEl, setTarget }) => (
                  <div ref={setTriggerEl} className="row">
                    <div
                      ref={setTarget()}
                      className="view-services-section__col-with-label col-11 col-xl-3"
                    >
                      <p className="label side-brackets">
                        {<Print data="We do" />}
                      </p>
                    </div>
                    <List
                      ref={setTarget({ selector: ".list__item" })}
                      items={services.items.map((servicesItem) => (
                        <Print data={servicesItem} />
                      ))}
                      marked
                      gap="lg"
                      className="view-services-section__services-list col-11 col-xl"
                    />
                  </div>
                )}
              </SlideFadeOutWhenInView>
            </div>

            {work && (
              <div className="view-services-section__col-with-work order-2 col-11 order-md-1 col-md-7 col-xl-6 offset-md-4 offset-xl-5">
                <SlideFadeOutWhenInView>
                  {({ setTriggerEl, setTarget }) => (
                    <div ref={setTriggerEl} className="row">
                      <div className="view-services-section__col-with-label col-11 col-xl-3">
                        <p ref={setTarget()} className="label side-brackets">
                          <Print data="Principles of operation" />
                        </p>
                      </div>
                      <List
                        ref={setTarget({ selector: ".list__item" })}
                        items={work}
                        marked
                        gap="lg"
                        className="view-services-section__integrations-list col-11 col-xl"
                      >
                        {(label) => <Print data={label} />}
                      </List>
                    </div>
                  )}
                </SlideFadeOutWhenInView>
              </div>
            )}

            {(integrations || cms) && (
              <div className="col-11 order-md-3 col-md-7 col-xl-6 row">
                {integrations && (
                  <div className="view-services-section__col-with-integrations">
                    <SlideFadeOutWhenInView>
                      {({ setTriggerEl, setTarget }) => (
                        <div ref={setTriggerEl} className="row">
                          <div className="view-services-section__col-with-label col-11 col-xl-3">
                            <p
                              ref={setTarget()}
                              className="label side-brackets"
                            >
                              <Print data="Integrations" />
                            </p>
                          </div>
                          <List
                            ref={setTarget({ selector: ".list__item" })}
                            items={integrations}
                            marked
                            gap="lg"
                            className="view-services-section__integrations-list col-11 col-xl"
                          >
                            {(label) => <Print data={label} />}
                          </List>
                        </div>
                      )}
                    </SlideFadeOutWhenInView>
                  </div>
                )}
                {cms && (
                  <div className="view-services-section__col-with-cms">
                    <SlideFadeOutWhenInView>
                      {({ setTriggerEl, setTarget }) => (
                        <div ref={setTriggerEl} className="row">
                          <div className="view-services-section__col-with-label col-11 col-xl-3">
                            <p
                              ref={setTarget()}
                              className="label side-brackets"
                            >
                              <Print data="CMS" />
                            </p>
                          </div>
                          <List
                            ref={setTarget({ selector: ".list__item" })}
                            items={cms}
                            marked
                            gap="lg"
                            className="view-services-section__integrations-list col-11 col-xl"
                          >
                            {(label) => <Print data={label} />}
                          </List>
                        </div>
                      )}
                    </SlideFadeOutWhenInView>
                  </div>
                )}
              </div>
            )}
            {technologies && (
              <div className="view-services-section__col-with-technologies col-11 order-md-2 col-md-4 col-xl-5">
                <SlideFadeOutWhenInView>
                  {({ setTarget, setTriggerEl }) => (
                    <div ref={setTriggerEl} className="row">
                      <div className="view-services-section__col-with-technologies-label col-11">
                        <p ref={setTarget()}>
                          <span className="view-services-section__technologies-label sublabel sublabel--secondary">
                            <Print data="Technology stack" />
                          </span>
                        </p>
                      </div>
                      <List
                        ref={setTarget({ selector: ".list__item" })}
                        items={technologies}
                        marked
                        className="view-services-section__technologies-list sublabel sublabel--secondary col-11"
                      >
                        {(label) => <Print data={label} />}
                      </List>
                    </div>
                  )}
                </SlideFadeOutWhenInView>
              </div>
            )}
          </div>
        </div>
      </section>
    </DynamicBackgroundTrigger>
  );
};

export default ViewServicesSection;
