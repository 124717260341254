import classNames from "classnames";
import gsap from "gsap";

import { useDispatch, useSelector } from "react-redux";
import { isMenuOpen } from "redux_/store/app/selectors";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  BodyScrollLockerOffset,
  useBodyScrollLocker,
} from "contexts/BodyScrollLocker";
import { useCurrentRoute } from "routes/helpers";
import { closeMenu } from "redux_/store/app/actions";
import { useElemRef } from "hooks/useElemRef";

import List from "components/common/List";
import { NavLink } from "react-router-dom";
import Copyright from "../Copyright";

import * as route from "routes";
import { contact } from "data/contacts";
import Print from "components/common/Print";
import { MorphSvgWord } from "components/common/MorphSvg";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

interface MenuProps {
  className?: string;
}

const menuContacts = [contact.email, contact.telegram];

/**
 * Меню
 */
const Menu = ({ className }: MenuProps) => {
  const [morphTitle, setMorphTitle] = useState(false);
  const menuOpen = useSelector(isMenuOpen);
  const dispatch = useDispatch();
  const currentRoute = useCurrentRoute();
  const [activeRoute, setActiveRoute] = useState(currentRoute);
  const { toggleLockScroll } = useBodyScrollLocker();
  const menuRef = useRef<HTMLElement>(null);

  const { elemRef: animateRef, setElemRef } = useElemRef({
    title: null,
    copyright: null,
    contacts: [],
    navLinksWithoutActive: [],
    activeNavLink: null,
    contactsContainer: null,
    navLinksContainer: null,
    contactsLabels: [],
    contactsLinks: [],
  });

  useEffect(() => {
    if (menuOpen) {
      dispatch(closeMenu());
    } else {
      setActiveRoute(currentRoute);
    }
  }, [currentRoute, dispatch]);

  useEffect(() => {
    toggleLockScroll(menuOpen);
  }, [menuOpen, toggleLockScroll]);

  useEffect(() => {
    const menuElem = menuRef.current!;
    const animateElem = animateRef.current;

    const defaults = {
      ease: menuOpen ? "ease-out" : "ease-in",
      duration: 0.8,
      stagger: 0.1,
    };

    const timeline = gsap.timeline({
      paused: true,
      defaults: { ...defaults, stagger: 0 },
    });

    const isMinxl = matchMedia("(min-width: 1440px)").matches;

    if (menuOpen) {
      const showMenuTween = gsap.fromTo(
        menuElem,
        {
          display: "block",
          opacity: 0,
        },
        {
          opacity: 1,
          force3D: true,
        }
      );

      const showContactsLabelsTween = gsap.fromTo(
        animateElem.contactsLabels,
        {
          x: 20,
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
        }
      );

      const showContactsLinksTween = gsap.fromTo(
        animateElem.contactsLinks,
        {
          x: isMinxl ? 20 : -20,
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
        }
      );

      const showActiveNavLinkTween = gsap.fromTo(
        animateElem.activeNavLink,
        {
          x: 20,
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
        }
      );

      const showNavLinksWithoutActiveTween = gsap.fromTo(
        (animateElem.navLinksWithoutActive as (HTMLElement | null)[]).filter(
          (elem) => elem
        ),
        {
          x: -20,
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
        }
      );

      const showTitleTween = gsap.fromTo(
        animateElem.title,
        {
          x: 20,
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
        }
      );
      const showCopyrightTween = gsap.fromTo(
        animateElem.copyright,
        {
          x: -20,
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
        }
      );

      timeline.add(showMenuTween);

      timeline.add(showActiveNavLinkTween);
      timeline.add(showNavLinksWithoutActiveTween, "<");

      timeline.add(showContactsLabelsTween, "<");
      timeline.add(showContactsLinksTween, "<");

      timeline.add(showTitleTween, "<");
      timeline.add(showCopyrightTween, "<");

      timeline.add(() => setMorphTitle(true));
    } else {
      const hideMenuTween = gsap.to(menuElem, {
        opacity: 0,
        display: "none",
      });

      const hideActiveLinkTween =
        animateElem.activeNavLink &&
        gsap.to(animateElem.activeNavLink, {
          x: -20,
          opacity: 0,
          delay: 0.2,
        });

      const hideNavLinksWithoutActiveTween = gsap.fromTo(
        (animateElem.navLinksWithoutActive as (HTMLElement | null)[]).filter(
          (elem) => elem
        ),
        {
          x: 0,
          opacity: 1,
        },
        {
          x: 20,
          opacity: 0,
        }
      );

      const hideContactsLabelsTween = gsap.fromTo(
        animateElem.contactsLabels,
        {
          x: 0,
          opacity: 1,
        },
        {
          x: -20,
          opacity: 0,
        }
      );

      const hideContactsLinksTween = gsap.fromTo(
        animateElem.contactsLinks,
        {
          x: 0,
          opacity: 1,
        },
        {
          x: isMinxl ? -20 : 20,
          opacity: 0,
        }
      );

      const hideTitleTween = gsap.fromTo(
        animateElem.title,
        {
          x: 0,
          opacity: 1,
        },
        {
          x: -20,
          opacity: 0,
        }
      );
      const hideCopyrightTween = gsap.fromTo(
        animateElem.copyright,
        {
          x: 0,
          opacity: 1,
        },
        {
          x: 20,
          opacity: 0,
        }
      );

      const updateActiveRoute = () => {
        if (activeRoute !== currentRoute) {
          setActiveRoute(currentRoute);
        }
      };

      if (hideActiveLinkTween) {
        timeline.add(hideActiveLinkTween);
      }

      timeline.add(hideNavLinksWithoutActiveTween, "<");
      timeline.add(hideContactsLabelsTween, "<");
      timeline.add(hideContactsLinksTween, "<");
      timeline.add(hideTitleTween, "<");
      timeline.add(hideCopyrightTween, "<");

      timeline.add(hideMenuTween);

      timeline.add(updateActiveRoute);

      timeline.add(() => setMorphTitle(false));
    }

    timeline.play();

    return () => {
      timeline.kill();
    };
    //  eslint-disable-next-line
  }, [menuOpen]);

  const nextLangCode = useMemo(() => {
    return i18n.language === "en" ? "/en" : "";
  }, [i18n.language]);

  const { t } = useTranslation();

  return (
    <nav ref={menuRef} className={classNames("menu", className)}>
      <BodyScrollLockerOffset className="menu__scroll">
        <div className="menu__container container">
          <div className="menu__inner space--header">
            <div className="menu__body">
              <div className="menu__body-inner row">
                <nav className="menu__nav col-11 offset-md-4 col-md-7 offset-xl-0 order-xl-last col-xl-4">
                  <ul
                    ref={setElemRef("navLinksContainer")}
                    className="list list--gap-md menu__nav-list row"
                  >
                    {route.navRoutes.map(({ path, exact, nav }, i) => {
                      const isActive =
                        activeRoute?.path === path ||
                        // Если это элемент портфолио, тогда делаем активным ссылку, которая ведет на все портфолио
                        (activeRoute?.path === route.portfolioItem.path &&
                          route.portfolio.path === path);

                      return (
                        nav && (
                          <li
                            key={path}
                            className={classNames(
                              isActive
                                ? "col-11"
                                : "list__item offset-3 col-8 offset-md-2 col-md-9"
                            )}
                          >
                            <NavLink
                              ref={
                                isActive
                                  ? setElemRef("activeNavLink")
                                  : setElemRef("navLinksWithoutActive", i)
                              }
                              className={classNames(
                                "menu__nav-link link label label--large side-brackets",
                                {
                                  "text--muted": isActive,
                                  "side-brackets--hover": !isActive,
                                }
                              )}
                              to={
                                nextLangCode +
                                path.replace("/:locale( |en)?", "")
                              }
                            >
                              <Print data={nav.label} />
                            </NavLink>
                          </li>
                        )
                      );
                    })}
                  </ul>
                </nav>

                <List
                  ref={setElemRef("contactsContainer")}
                  className="menu__contacts col-11 offset-md-4 col-md-7 offset-xl-4 col-xl-3 order-xl-first"
                  itemClassName="menu__contacts-item"
                  gap="md"
                  items={menuContacts}
                >
                  {({ name, label, href }, i) => {
                    return (
                      <div ref={setElemRef("contacts", i)} className="row">
                        <div className="menu__contacts-col-with-label col-3 col-md-4">
                          <p
                            ref={setElemRef("contactsLabels", i)}
                            className="menu__contacts-label label side-brackets"
                          >
                            <Print data={name!} />
                          </p>
                        </div>
                        <div className="offset-3 col-8 offset-md-0 col-md-7">
                          <a
                            ref={setElemRef("contactsLinks", i)}
                            href={href}
                            className="menu__contacts-link link link--normal label"
                          >
                            {label}
                          </a>
                        </div>
                      </div>
                    );
                  }}
                </List>
              </div>
            </div>

            <div className="menu__footer">
              <div className="menu__footer-inner row">
                <p
                  ref={setElemRef("title")}
                  className="menu__title title title--adaptive col col-xl-7 u-euclid-flex-line-height-none"
                >
                  <MorphSvgWord
                    className="svg-title"
                    word={t("MenuSvg")}
                    morph={morphTitle}
                  />
                </p>
                <div className="menu__copyright col-auto col-xl">
                  <Copyright
                    ref={setElemRef("copyright")}
                    className="offset-xl-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </BodyScrollLockerOffset>
    </nav>
  );
};

export default Menu;
