import VimeoPlayer from 'components/common/VimeoPlayer'

interface ViewTestProps {

}

const ViewTest = (props: ViewTestProps) => {


  return (
    <div className="view-test" style={{
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
      <VimeoPlayer play={false} id={569469499} loop muted controls />
    </div>
  )
}

export default ViewTest;