import ViewPortfolioNovaGroupBrandStyle from "components/views/ViewPortfolioItem/ViewPortfolioNovaGroupBrandStyle";
import {
  ImageDataType,
  PortfolioItemDataWithComponent,
} from "data/portfolio/types";
import { buildPublicImageSrc } from "helpers/path";

export interface PortfolioItemDataPageSectionAboutContent {
  topText?: string[];
  image: ImageDataType | {};
}

const porfolioItem: PortfolioItemDataWithComponent = {
  id: "nova-group-brand-style",
  component: ViewPortfolioNovaGroupBrandStyle,
  title: "nova-group",
  name: "Nova group",
  altName: "Moscow architectural\nconstruction company",
  year: 2020,
  link: "novagroup.ru",
  type: "branding",
  subtype: "branding",
  preview: {
    label: "corporate identity",
    media: {
      type: "image",
      fromSrc: buildPublicImageSrc({
        path: "portfolios/nova-group-brand-style",
        file: "preview-from.jpg",
      }),
      toSrc: buildPublicImageSrc({
        path: "portfolios/nova-group-brand-style",
        file: "preview-to.jpg",
      }),
    },
  },

  socials: [
    {
      label: "behance",
      href:
        "https://www.behance.net/gallery/91228201/Nova-Group-Brand-identity-design",
    },
  ],

  poolOfWorks: ["Logotype", "Brand style"],

  relatedId: "nova-group",

  description: [
    "an architectural and construction company engaged in the construction of private houses in Moscow and the Moscow region.",
    "The challenge was to develop a corporate identity that demonstrates the company as a reliable and responsible contractor."
  ],
  shortDescription:
    "branding for the Moscow architectural and construction company",
  seo:
  {
    title: "Branding for the construction company Nova Group",
    description: "Development of a logo and all elements of corporate identity from scratch for the architectural and construction company Nova Group",
    keywords: ["corporate identity of a construction company",
      "logo of a Moscow construction company",
      "creating a logo for builders",
      "logo design for private houses",
      "building houses logo",
      "logo for nova group"],
    openGraph: {
      type: "article",
      title: "Branding for the construction company Nova Group",
      description: "Development of a logo and all elements of corporate identity from scratch for the architectural and construction company Nova Group",
    }
  }
};

export default porfolioItem;
