import Section from "components/layouts/Section";
import s from "./index.module.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import List from "components/common/List";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import Button from "components/common/Button";
import RevealTextByLine from "components/common/RevealInView/RevealTextByLine";
import SlideFadeOutWhenInView from "components/views/ViewPortfolioItem/ViewPortfolioNovaGroup/SlideFadeOutWhenInView";
import { useEffect, useState } from "react";

const sectionData = {
  slides: [
    {
      title: "SK10",
      subtitle: "Разработка и поддержка проекта с 2019 года по Н.В.",
      headerItems: [
        "разработка сайта",
        "UI/UX дизайн",
        "Тех поддержка",
        "Разработка функционала",
      ],
      body: {
        title: "Задача",
        items: [
          "проектирование и разработка сайта",
          "техническая поддержка сайта",
          "Дизайн и разработка новых разделов",
        ],
      },
      logo: "/images/developer-sites/sk10/logo",
      background: "/images/developer-sites/sk10/background",
      nextSlideButton: <>Следующий проект</>,
    },
    {
      title: "Дома века",
      subtitle: "Разработка и поддержка проекта с 2018 года по Н.В.",
      headerItems: [
        "разработка сайта",
        "UI/UX дизайн",
        "Тех поддержка",
        "Разработка функционала",
      ],
      body: {
        title: "Задача",
        items: [
          "проектирование и разработка сайта",
          "техническая поддержка сайта",
          "Дизайн и разработка новых разделов",
        ],
      },
      logo: "/images/developer-sites/doma-veka/logo",
      background: "/images/developer-sites/doma-veka/background",
      nextSlideButton: (
        <>
          Следующий <br /> проект{" "}
        </>
      ),
    },
    {
      title: "AAG",
      subtitle: "Разработка и поддержка проекта с 2023 года по Н.В.",
      headerItems: ["Тех поддержка", "UI/UX дизайн", "Разработка функционала"],
      body: {
        title: "Задача",
        items: [
          "техническая поддержка сайта",
          "Дизайн и разработка новых разделов",
        ],
      },
      logo: "/images/developer-sites/aag/logo",
      background: "/images/developer-sites/aag/background",
      nextSlideButton: (
        <>
          Следующий <br /> проект{" "}
        </>
      ),
    },
    {
      title: "НОВАЦИЯ",
      subtitle: "Разработка и поддержка проекта с 2014 года по Н.В.",
      headerItems: [
        "разработка сайта",
        "UI/UX дизайн",
        "Тех поддержка",
        "Разработка функционала",
      ],
      body: {
        title: "Задача",
        items: [
          "проектирование и разработка сайта",
          "техническая поддержка сайта",
          "Дизайн и разработка новых разделов",
        ],
      },
      logo: "/images/developer-sites/nova-group/logo",
      background: "/images/developer-sites/nova-group/background",
      nextSlideButton: (
        <>
          Следующий <br /> проект{" "}
        </>
      ),
    },
  ],
};

function ViewDeveloperSitesSwiperSection() {
  const [backModifier, setBackModifier] = useState(".png");

  function move() {
    const viewport_width = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );
    if (viewport_width <= 20000) {
      setBackModifier(".png");
    }

    if (viewport_width <= 992) {
      setBackModifier("-tab.png");
    }

    if (viewport_width <= 414) {
      setBackModifier("-mob.png");
    }
  }

  useEffect(() => {
    move()
    window.addEventListener("resize", move);
  }, []);

  return (
    <Section
      viewportHeight
      className={
        s["view-developer-sites-swiper-section"] +
        " text--light view-developer-sites-swiper-section"
      }
    >
      <Swiper
        className={s["view-developer-sites-swiper-section__swiper"]}
        navigation={{
          nextEl: ".button.navigation",
        }}
        loop
      >
        {sectionData.slides.map((item, index) => {
          return (
            <SwiperSlide
              key={index}
              className={
                "row " + s["view-developer-sites-swiper-section__slide"]
              }
              style={{
                backgroundImage: `url(${item.background + backModifier})`,
              }}
            >
              <div
                className={
                  s["view-developer-sites-swiper-section__slide-container"]
                }
              >
                <div>
                  <header className={"row "}>
                    <div className=" col-11 col-md-6 order-1">
                      <RevealWhenInView
                        className={
                          "heading col-11 " +
                          s["view-developer-sites-swiper-section__title"]
                        }
                      >
                        <span>{item.title}</span>
                      </RevealWhenInView>
                    </div>
                    <div className="col-11 col-md-6 order-3">
                      <RevealTextByLine
                        className="col-11"
                        wordClassName={(l, w) =>
                          l === 0 && w === 0 ? "" : undefined
                        }
                      >
                        {item.subtitle}
                      </RevealTextByLine>
                    </div>
                    <div
                      className={
                        "row col-11 col-md-6 offset-0 col-lg-3 offset-lg-2 order-2 "
                      }
                    >
                      <SlideFadeOutWhenInView>
                        {({ setTriggerEl, setTarget }) => (
                          <div ref={setTriggerEl}>
                            <div
                              className={
                                "row " +
                                s[
                                  "view-developer-sites-swiper-section__right-top-list"
                                ]
                              }
                              ref={setTarget({ selector: ".side-brackets" })}
                            >
                              {item.headerItems.map((item, index) => (
                                <span
                                  key={index + item}
                                  className={
                                    "side-brackets col-auto col-lg-11 label "
                                  }
                                >
                                  {item}
                                </span>
                              ))}
                            </div>
                          </div>
                        )}
                      </SlideFadeOutWhenInView>
                    </div>
                  </header>

                  <SlideFadeOutWhenInView>
                    {({ setTriggerEl, setTarget }) => (
                      <div
                        ref={setTriggerEl}
                        className={
                          "row " +
                          s["view-developer-sites-swiper-section__list"]
                        }
                      >
                        <div className="col-4 col-sm-3 col-md-2 col-lg-1 side-brackets label">
                          {item.body.title}
                        </div>
                        <List
                          ref={setTarget({ selector: ".list__item" })}
                          items={item.body.items}
                          gap="lg"
                          marked
                          className="col-6 col-md-3"
                        >
                          {(item) => (
                            <span className="text--secondary">{item}</span>
                          )}
                        </List>
                      </div>
                    )}
                  </SlideFadeOutWhenInView>
                </div>

                <footer className={"row "}>
                  <RevealWhenInView
                    className={
                      s["view-developer-sites-swiper-section__footer-logo"] +
                      " row"
                    }
                  >
                    <div className={" row"}>
                      <img src={item.logo + ".svg"} />
                    </div>
                  </RevealWhenInView>
                  <RevealWhenInView
                    className={
                      "col-auto offset-auto " +
                      s[
                        "view-developer-sites-swiper-section__next-slide-button"
                      ]
                    }
                  >
                    <Button
                      className={"footnote navigation"}
                      renderRight={() => (
                        <div
                          className={
                            "sprite-icon sprite-icon--arrow-down-white "
                          }
                        />
                      )}
                    >
                      <p>Следующий проект</p>
                    </Button>
                  </RevealWhenInView>
                </footer>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Section>
  );
}

export default ViewDeveloperSitesSwiperSection;
