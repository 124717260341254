import { usePortfolioItemAssets } from "hooks/useAssets";
import { useViewPortfolioItemContext } from "../ViewPortfolioItem";
import ViewPortfolioItemAboutSection from "../ViewPortfolioItemAboutSection";
import ViewPortfolioItemParallaxSection from "../ViewPortfolioItemParallaxSection";
import ViewPortfolioItemSection from "../ViewPortfolioItemSection";
import RevealWhenInView from "../../../common/RevealInView/RevealWhenInView";
import {useTranslation} from "react-i18next";

export interface ViewPortfolioMumuProps { }

const ViewPortfolioMumu = (props: ViewPortfolioMumuProps) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);
  const { t } = useTranslation();

  return (
    <>
      <ViewPortfolioItemAboutSection
        style={{ backgroundColor: "#000000", color: "#fff" }}
        name={data.name}
        image={{
          isLogo: true,
          src: buildPageImageSrc("logo", "svg"),
        }}
        description={data.description}
      />


      <ViewPortfolioItemParallaxSection
          dynamicBackgroundColor={false}
          image={{
              src: buildPageImageSrc("screen-3"),
              sources: [
                  {
                      type: "webp",
                      srcSet: buildPageImageSrc("screen-3")
                  }
              ]
          }}
      />
      <ViewPortfolioItemSection
          style={{ backgroundColor: "#000" }}
          className="view-portfolio-mumu-section portfolio-item-section--type-mumu-4">
          <RevealWhenInView>
              <img src={buildPageImageSrc( t("portfolioItem:mumu.section-4.image") )} alt={'mumu'} />
          </RevealWhenInView>
      </ViewPortfolioItemSection>

      <ViewPortfolioItemSection
            style={{ backgroundColor: "#000", color: "#fff" }}
            className="view-portfolio-mumu-section portfolio-item-section--type-mumu-5">
            <div className="row">
                <div className="offset-md-2 col-md-7 offset-1 col-9 poster-version">
                    <RevealWhenInView>
                        <img src={buildPageImageSrc( 'screen-5-0' )} alt={'mumu'} />
                    </RevealWhenInView>
                    <RevealWhenInView>
                        <p>{t("portfolioItem:mumu.section-5.poster.0")}</p>
                    </RevealWhenInView>
                </div>
                <div className="offset-md-2 col-md-7 offset-1 col-9 poster-version">
                    <RevealWhenInView>
                        <img src={buildPageImageSrc( 'screen-5-1' )} alt={'mumu'} />
                    </RevealWhenInView>
                    <RevealWhenInView>
                        <p>{t("portfolioItem:mumu.section-5.poster.1")}</p>
                    </RevealWhenInView>
                </div>
                <div className="offset-md-2 col-md-7 offset-1 col-9 poster-version">
                    <RevealWhenInView>
                        <img src={buildPageImageSrc( 'screen-5-2' )} alt={'mumu'} />
                    </RevealWhenInView>
                    <RevealWhenInView>
                        <p>{t("portfolioItem:mumu.section-5.poster.2")}</p>
                    </RevealWhenInView>
                </div>
            </div>
      </ViewPortfolioItemSection>

      <ViewPortfolioItemParallaxSection
          dynamicBackgroundColor={false}
          image={{
              src: buildPageImageSrc("screen-6"),
              sources: [
                  {
                      type: "webp",
                      srcSet: buildPageImageSrc("screen-6")
                  }
              ]
          }}
      />

      <ViewPortfolioItemSection
            style={{ backgroundColor: "#000", color: "#fff" }}
            className="view-portfolio-mumu-section portfolio-item-section--type-mumu-7">
            <div className="row">
                <div className="col-11">
                    <RevealWhenInView>
                        <img src={buildPageImageSrc( 'screen-7-0' )} alt={'mumu'} />
                    </RevealWhenInView>
                </div>
                <div className="col-11">
                    <RevealWhenInView>
                        <img src={buildPageImageSrc( 'screen-7-1' )} alt={'mumu'} />
                    </RevealWhenInView>
                </div>
            </div>
       </ViewPortfolioItemSection>

      <ViewPortfolioItemSection
            style={{ backgroundColor: "#111", color: "#fff" }}
            className="view-portfolio-mumu-section portfolio-item-section--type-mumu-8">
          <div className="offset-xl-3 col-xl-5 offset-md-2 col-md-7 offset-1 col-9">
              <RevealWhenInView>
                  <img src={buildPageImageSrc( 'screen-8' )} alt={'mumu'} />
              </RevealWhenInView>
          </div>
      </ViewPortfolioItemSection>

      <ViewPortfolioItemSection
            style={{ backgroundColor: "#121212", color: "#fff" }}
            className="view-portfolio-mumu-section portfolio-item-section--type-mumu-9">
            <RevealWhenInView>
                <img src={buildPageImageSrc( t("portfolioItem:mumu.section-9") )} alt={'mumu'} />
            </RevealWhenInView>
      </ViewPortfolioItemSection>

      <ViewPortfolioItemSection
            style={{ backgroundColor: "#121212", color: "#fff" }}
            className="view-portfolio-mumu-section portfolio-item-section--type-mumu-10">
            <RevealWhenInView>
                <img src={buildPageImageSrc( t("portfolioItem:mumu.section-10") )} alt={'mumu'} />
            </RevealWhenInView>
      </ViewPortfolioItemSection>

      <ViewPortfolioItemSection
            style={{ backgroundColor: "#fff"}}
            className="view-portfolio-mumu-section portfolio-item-section--type-mumu-11">
            <RevealWhenInView>
                <img src={buildPageImageSrc( 'screen-11' )} alt={'mumu'} />
            </RevealWhenInView>
      </ViewPortfolioItemSection>

      <ViewPortfolioItemParallaxSection
            dynamicBackgroundColor={false}
            image={{
                src: buildPageImageSrc("screen-12"),
                sources: [
                    {
                        type: "webp",
                        srcSet: buildPageImageSrc("screen-12")
                    }
                ]
            }}
      />

    </>
  );
};

export default ViewPortfolioMumu;
