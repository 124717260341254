import Parallax from "components/common/Parallax";
import { buildPublicImageSrc } from "helpers/path";

import s from "./index.module.scss";

interface ViewDeveloperSitesPhotoSectionProps {
  imageSrc: string;
  path: string;
}

function ViewDeveloperSitesPhotoSection({
  imageSrc,
  path,
}: ViewDeveloperSitesPhotoSectionProps) {
  return (
    <section className={s["view-developer-sites-photo-section"]}>
      <div className={s['view-developer-sites-photo-section__container']}>
      <Parallax
        className={" " + s["view-developer-sites-photo-section__image"] + " "}
        image={{
          src: buildPublicImageSrc({
            path: path,
            file: imageSrc,
          }),
          sources: [
            {
              srcSet: buildPublicImageSrc({
                path: path,
                file: imageSrc,
              }),
              media: "(min-width: 1440px)",
            },
            {
              srcSet: buildPublicImageSrc({
                path: path,
                file: imageSrc,
              }),
              media: "(min-width: 768px)",
            },
          ],
        }}
      />
      </div>
    </section>
  );
}

export default ViewDeveloperSitesPhotoSection;
