import { memo, useRef } from "react";

import Footer from "components/layouts/Footer";
import View from "components/layouts/View";
import ViewDeveloperSitesLeadSection from "./ViewDeveloperSitesLeadSection/ViewDeveloperSitesLeadSection";
import ViewDeveloperSitesStagesSection from "./ViewDeveloperSitesStagesSection/ViewDeveloperSitesStagesSection";
import ViewDeveloperSitesPriceSection from "./ViewDeveloperSitesPriceSection/ViewDeveloperSitesPriceSection";
import ViewDeveloperSitesJobsSection from "./ViewDeveloperSitesJobsSection/ViewDeveloperSitesJobsSection";

import "./index.scss";
import ViewDeveloperSitesPhotoSection from "./ViewDeveloperSitePhotoSection/ViewDeveloperSitesPhotoSectionPhotoSection";
import ViewDeveloperFaqsSection from "./ViewDeveloperSiteFaqsSection/ViewDeveloperFaqsSection";
import ViewDeveloperSitesOurExperienceSection from "./ViewDeveloperSitesOurExpireance/ViewDeveloperSitesOurExpireanceSection";
import ViewDeveloperSitesSwiperSection from "./ViewDeveloperSitesSwiperSection/ViewDeveloperSitesSwiperSection";
import ViewDeveloperSitesCustomerReviewsSection from "./ViewDeveloperSitesCustomerRreviewsSection/ViewDeveloperSitesCustomerReviewsSection";

interface ViewDeveloperSitesProps {}

const ViewDeveloperSites = (props: ViewDeveloperSitesProps) => {
  const viewElRef = useRef<HTMLDivElement>(null);

  return (
    <View ref={viewElRef} className="view-developer-sites">
      <ViewDeveloperSitesLeadSection />
      <ViewDeveloperSitesPhotoSection
        path="views/developer-sites"
        imageSrc="town-homes.jpeg"
      />
      <ViewDeveloperSitesOurExperienceSection />
      <ViewDeveloperSitesSwiperSection />
      <ViewDeveloperSitesCustomerReviewsSection/>
      <ViewDeveloperSitesPhotoSection
        path="views/developer-sites"
        imageSrc="beautiful-home-1.jpeg"
      />
      <ViewDeveloperSitesJobsSection />
      <ViewDeveloperSitesPriceSection />
      <ViewDeveloperSitesStagesSection />
      <ViewDeveloperFaqsSection />
      <Footer className="background--gray" />
    </View>
  );
};

export default memo(ViewDeveloperSites);
