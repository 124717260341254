import classNames from "classnames";
import { ReactNode } from "react";

interface ListItemProps {
  className?: string;
  children?: ReactNode;
}

const ListItem = ({ className, children }: ListItemProps) => {
  return <li className={classNames("list__item", className)}>{children}</li>;
};

export default ListItem;
