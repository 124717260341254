import ViewPortfolioNovaGroup from "components/views/ViewPortfolioItem/ViewPortfolioNovaGroup";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";

const porfolioItem: PortfolioItemDataWithComponent = {
  id: "nova-group",
  component: ViewPortfolioNovaGroup,
  title: "nova-group",
  name: "Nova group",
  altName: "Moscow architectural\nconstruction company",
  year: 2020,
  awardsList: [
    {imgSrc : "/images/awards/gray-bg/reiting-runeta.png"},
  ],
  link: "novagroup.ru",
  type: "site",
  subtype: "e-commerce",

  preview: {
    label: "corporate website, e-commerce",
    backgroundColor: "#1F1F21",
    media: {
      type: "video",
      src: "https://vimeo.com/542710265",
    },
  },

  socials: [
    {
      label: "behance",
      href:
        "https://www.behance.net/gallery/93319129/WEBSITE-Construction-company-Nova-Group",
    },
  ],

  poolOfWorks: [
    "UX|UI design",
    "Branding",
    "Page making",
    "Integration with 1c-bitrix",
  ],

  relatedId: "nova-group-brand-style",

  description:
    [
      "an architectural and construction company engaged in the construction of private houses in Moscow and the Moscow region.",
      "It was necessary to create a website that demonstrates the company's high-quality approach to construction, to tell in detail about the process of interaction between the client and the company."
    ],
  shortDescription:
    "website for the Moscow architectural and construction company",
  seo: {
    title: "Website development for the construction company NovaGroup",
    description: "Website design and development for the Moscow architectural and construction company Nova Group specializing in private construction. Analytics and study of the user flow of the target audience in the development of site design, integration with CMS 1C Bitrix and end-to-end analytics",
    keywords: ["creation of a site for a construction company",
      "example of a site for a construction company",
      "the best websites for builders",
      "website development for builders in Moscow",
      "construction of private houses",
      "construction website design",
      "website development by nova group"],
    openGraph: {
      type: "article",
      title: "Website development for the construction company NovaGroup",
      description: "Website design and development for the Moscow architectural and construction company Nova Group specializing in private construction. Analytics and study of the user flow of the target audience in the development of site design, integration with CMS 1C Bitrix and end-to-end analytics",
    }
  },
  pageContent: {
    sections: {
      mobileVersion: {
        theme: {
          color: "#fff", backgroundColor: "#0242EC"
        },
        frameCount: 161
      }
    }
  }
};

export default porfolioItem;
