import Section from "components/layouts/Section";

import s from "./index.module.scss";
import RevealInView from "components/common/RevealInView";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import ScrollDownButton from "components/layouts/ScrollDownButton";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";

const sectionData = {
  title: "Faqs",
  items: [
    {
      title: "Практикуете ли вы постоплату?",
      text: "Да. О сроках договариваемся индивидуально.",
    },
    {
      title: "можем ли мы начать работу без договора?",
      text: "Оформление договора часто затягивается, в таких случаях мы приступаем к работе по гарантийному письму.",
    },
    {
      title: "Как быстро вы выполняете задачи?",
      text: (
        <>
          {" "}
          Потоковые задачи — в режиме ASAP. Но с каждым клиентом отдельно
          обсуждаем скорость реагирования и выполнения. <br />
          <br />
          <br /> Если клиент самостоятельно прописывает дедлайны при постановке
          задачи, мы оцениваем нагрузку и согласовываем финальный тайминг.
        </>
      ),
    },
    {
      title: "Где фиксируются задачи?",
      text: (
        <>
          {" "}
          Мы используем Битрикс24, где фиксируем дату постановки задачи, ТЗ,
          дедлайн и её стоимость, ответственное лицо с вашей стороны. У каждой
          задачи свой статус — в работе, на согласовании, завершено. <br />
          <br />
          <br /> Задачи вы можете вносить самостоятельно. Также нам можно
          направить задачи в свободной форме, и мы самостоятельно внесём вводные
          в Битрикс24.
        </>
      ),
    },
    {
      title: "КАК хранятся все результаты работ?",
      text: "Мы загружаем их в облако. Ссылки на конечные макеты прикрепляем в соответствующей задаче или выгружаем на демо-сервер для внутреннего тестирования.",
    },
  ],
};

function ViewDeveloperFaqsSection() {
  return (
    <DynamicBackgroundTrigger>
      <Section
        className={"background--white " + s["view-developer-faqs-section"]}
      >
        <div className={" section-container container"}>
          <div className={"row"}>
            <header
              className={
                "row col-11 col-md-3 col-lg-5 " +
                s["view-developer-faqs-section__header"]
              }
            >
              <RevealInView>
                <h2 className={"heading col-11"}>{sectionData.title}</h2>
              </RevealInView>
            </header>

            <div className=" row col-11 col-md-8  col-lg-6">
              {sectionData.items.map((item, index) => {
                return (
                  <div
                    className={s["view-developer-faqs-section__item"] + " col-11 row"}
                    key={index}
                  >
                    <div className="col-11 row">
                      <div className="col-2 col-sm-3">
                        <RevealInView>
                          <span className="side-brackets text--secondary caption">
                            {"0" + (index + 1)}
                          </span>
                        </RevealInView>
                      </div>
                      <div className="col-8 row">
                        <div className="col-11">
                          <RevealInView>
                            <p className="side-brackets label">{item.title}</p>
                          </RevealInView>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        "col-9 offset-2 offset-sm-3 row " +
                        s["view-developer-faqs-section__text-container"]
                      }
                    >
                      <div className="col-2">
                        <RevealInView>
                          <span>+</span>
                        </RevealInView>
                      </div>
                      <div className="col-9 sm:col-8">
                        <RevealInView>
                          <p className={s["view-developer-faqs-section__text"]}>
                            {item.text}
                          </p>
                        </RevealInView>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={s.aunchor}>
              <RevealWhenInView>
                <ScrollDownButton
                  scrollTo=".footer"
                  duration={0.5}
                  className={"scroll-down-button-with-text"}
                >
                  <p>
                    Связаться с<br /> нами
                  </p>
                </ScrollDownButton>
              </RevealWhenInView>
            </div>
          </div>
        </div>
      </Section>
    </DynamicBackgroundTrigger>
  );
}

export default ViewDeveloperFaqsSection;
