import ViewPortfolioRutube from 'components/views/ViewPortfolioItem/ViewPortfolioRutube';
import { PortfolioItemDataWithComponent } from 'data/portfolio/types';
import { buildPublicImageSrc } from '../../helpers/path';

const porfolioItem: PortfolioItemDataWithComponent = {
  id: 'rutube',
  component: ViewPortfolioRutube,
  title: 'rutube',
  name: 'Rutube',
  altName: 'Ведущий российский видеопортал',
  year: 2023,
  awardsList: [],

  link: {
    label: 'rutube.ru',
    href: 'https://rutube.ru/',
  },

  socials: [
    {
      label: 'behance',
      href: 'https://www.behance.net/gallery/159728019/RUTUBE-APP-REDESIGN',
    },
  ],

  preview: {
    label: 'портал',
    media: {
      type: 'image',
      fromSrc: buildPublicImageSrc({
        path: 'portfolios/wildberries/preview',
        file: '1-1440w.webp',
      }),
      toSrc: buildPublicImageSrc({
        path: 'portfolios/wildberries/preview',
        file: '2-1440w.webp',
      }),
    },
  },

  poolOfWorks: ['UX|UI design'],

  type: 'branding',
  subtype: 'site',

  description: [
    'это российский видеохостинг, основанный на собственной платформе. Российский видеохостинг, который постепенно внедряет новые функции для привлечения новой аудитории. В его арсенале различные форматы you-каналы, пользовательские видео, прямые трансляции, фильмы и сериалы.',
  ],
  shortDescription: 'брендинг для Ведущего российского видеопортала',

  seo: {
    title: 'Дизайн мобильного приложения для видео хостинга RuTube',
    description:
      'Разработали с нуля дизайн приложения для российского видео хостинга rutube. Улучшили пользовательский опыт сделали полный редизайн приложения начиная с логотипа и заказчивая кнопкой лайка под видео',
    keywords: [
      'дизайн rutube',
      'дизайн мобильного приложения видео хостинга',
      'рутьюб',
      'рутуб',
      'приложение для просмотра видео',
      'интерфейс приложения rutube',
      'разработка дизайна мобильных приложений',
      'редизайн rutube',
    ],
    openGraph: {
      type: 'article',
      title: 'Дизайн мобильного приложения для видео хостинга RuTube',
      description:
        'Разработали с нуля дизайн приложения для российского видео хостинга rutube. Улучшили пользовательский опыт сделали полный редизайн приложения начиная с логотипа и заказчивая кнопкой лайка под видео',
    },
  },
};

export default porfolioItem;
