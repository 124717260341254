import ViewPortfolioEnsemble from 'components/views/ViewPortfolioItem/ViewPortfolioEnsemble';
import { PortfolioItemDataWithComponent } from "data/portfolio/types";
import {buildPublicImageSrc} from "../../helpers/path";


const porfolioItem: PortfolioItemDataWithComponent = {
  id: "ensemble",
  component: ViewPortfolioEnsemble,
  title: "ensemble",
  name: "Ensemble",
  altName: "Новый исламский\nинтернет-банк во франции",
  year: 2021,
  link: {
    label: "getensemble.io",
    href: "https://getensemble.io/",
  },
  vc: {
    label: "vc.ru",
    href: "https://vc.ru/design/444891-brending-dlya-francuzskogo-neobanka-ensemble",
  },

  socials: [
    {
      label: "behance",
      href:
        "https://www.behance.net/gallery/123617149/Ensemble-French-Islamic-neobank",
    },
  ],

  preview: {
    label: "сайт интернет-банка",
    media: {
      type: "image",
      fromSrc: buildPublicImageSrc({
        path: "portfolios/ensemble/preview",
        file: "1-1440w.webp",
      }),
      toSrc: buildPublicImageSrc({
        path: "portfolios/ensemble/preview",
        file: "2-1440w.webp",
      }),
    },
  },

  poolOfWorks: ["Logotype", "UX|UI design", "Page making", "Branding"],



  type: "site",
  subtype: "site",

  description: [
    "стремительно развивающийся французский стартап банк, финансовая деятельность которого основана на принципах шариата.",
    "Нам нужно было разработать лаконичный  логотип, который бы отражал ценности компании, и сайт, который будет показывать преимущества исламского банка."
  ],
  shortDescription:
    "Разработка сайта и брендинга для французского онлайн банка Ensemble",
  seo: {
    title: "Разработка сайта и брендинга для французского онлайн банка Ensemble",
    description: "Создали брендинг, фирменный стиль, сайт и мобильное приложение для онлайн банка во франции, включающий работы по ux ui дизайну и административные дашборды",
    keywords: ["сайт для банка",
      "разработка для финтех",
      "брендинг для банка",
      "фирменный стиль для банка",
      "разработка дизайна приложения банка"],
    openGraph: {
      "type": "article",
      "title": "Разработка сайта и брендинга для французского онлайн банка Ensemble",
      "description": "Создали брендинг, фирменный стиль, сайт и мобильное приложение для онлайн банка во франции, включающий работы по ux ui дизайну и административные дашборды"
    }
  }

};

export default porfolioItem;
