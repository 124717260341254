import ViewPortfolioItemImage, {
  ViewPortfolioItemImageData,
} from "../ViewPortfolioItemImage";
import ViewPortfolioItemSection, {
  ViewPortfolioItemSectionConfigProps,
} from "../ViewPortfolioItemSection";

interface ViewPortfolioGosaptekaManagerMonitoringGallerySectionProps
  extends ViewPortfolioItemSectionConfigProps {
  images: ViewPortfolioItemImageData[];
}

const ViewPortfolioGosaptekaManagerMonitoringGallerySection = ({
  images,
  ...sectionProps
}: ViewPortfolioGosaptekaManagerMonitoringGallerySectionProps) => {
  return (
    <ViewPortfolioItemSection
      {...sectionProps}
      className="view-portfolio-item-section--layout-gosapteka-manager-monitoring-gallery"
    >
      <div className="view-portfolio-item-section__container">
        <div className="view-portfolio-item-section__inner row">
          <div className="view-portfolio-item-section__col-image offset-auto col-10 col-mg-8 col-lg-7">
            <ViewPortfolioItemImage {...images[0]} />
          </div>

          <div className="view-portfolio-item-section__col-image col-9 col-md-8 col-lg-7 col-xl-6 col-xxl-5 container">
            <ViewPortfolioItemImage {...images[1]} />
          </div>
        </div>
      </div>
    </ViewPortfolioItemSection>
  );
};

export default ViewPortfolioGosaptekaManagerMonitoringGallerySection;
