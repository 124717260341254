import React, {useCallback } from 'react'
import Icon from '../../../../../common/Icon'
import gsap from "gsap";

interface ArrowDiagonalProps {
    duration?: number,
    scrollTo: gsap.TweenVars["scrollTo"],
    ease?: "ease-out"
}

const ArrowDiagonal = ({ duration = 0.6, ease, scrollTo }: ArrowDiagonalProps) => {
    const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        gsap.to(window, {
            scrollTo,
            ease,
            duration,
        })
    }, [scrollTo, ease, duration]);

    return (
        <button onClick={handleClick} className="button arrow-diagonal-button">
            <Icon
                name="arrow-diagonal"
                className="arrow-diagonal-button__icon"
            />
        </button>
    )
}

export default ArrowDiagonal