import ViewPortfolioMynaret from 'components/views/ViewPortfolioItem/ViewPortfolioMynaret';
import { PortfolioItemDataWithComponent } from 'data/portfolio/types';
import { buildPublicImageSrc } from '../../helpers/path';

const porfolioItem: PortfolioItemDataWithComponent = {
  id: 'mynaret',
  component: ViewPortfolioMynaret,
  title: 'mynaret',
  name: 'Mynaret',
  altName: 'Приложение для пожертвования французким мусульманам',
  year: 2023,
  awardsList: [],

  socials: [
    {
      label: 'behance',
      href: 'https://www.behance.net/gallery/136467857/BRANDING-Mosque-donation-app',
    },
  ],
  preview: {
    label: 'iOS-приложение',
    //поменять
    media: {
      type: 'image',
      fromSrc: buildPublicImageSrc({
        path: 'portfolios/wildberries/preview',
        file: '1-1440w.webp',
      }),
      toSrc: buildPublicImageSrc({
        path: 'portfolios/wildberries/preview',
        file: '2-1440w.webp',
      }),
    },
  },
  poolOfWorks: ['UX|UI design', 'Brand style'],
  type: 'branding',
  subtype: 'application',
  description: [
    'Это приложение, принадлежащее новому банку Франции – Ensemble. Приложение было создано для того, чтобы объединить мечети Франции в одном месте',
    'Любой желающий может подключиться к приложению, сделать пожертвование или предложить волонтерскую помощь любой мечети, находящейся в приложении',
  ],
  shortDescription: 'Приложение для пожертвования французким мусульманам',

  seo: {
    title: 'Брендинг мусульманского мобильного приложения Mynaret',
    description:
      'Создали логотип, фирменный стиль и интерфейс мобильного iOS приложения для сбора пожертвований в мусульманский мечетей во Франции для компании Mynaret',
    keywords: [
      'брендинг',
      'мусульманское приложение',
      'Mynaret',
      'организация мечетей',
      'сбор пожертвований',
      'фирменный стиль мусульманство',
      'логотип для мобильного приложения',
      'мобильное приложение для мечетей',
    ],
    openGraph: {
      type: 'article',
      title: 'Брендинг мусульманского мобильного приложения Mynaret',
      description:
        'Создали логотип, фирменный стиль и интерфейс мобильного iOS приложения для сбора пожертвований в мусульманский мечетей во Франции для компании Mynaret',
    },
  },
};

export default porfolioItem;
