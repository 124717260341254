import { useEffect, useRef } from "react";

import List from "components/common/List";
import ViewPortfolioItemImage, {
  ViewPortfolioItemImageData,
} from "../ViewPortfolioItemImage";
import ViewPortfolioItemSection from "../ViewPortfolioItemSection";
import gsap from "gsap";
import SlideFadeOutWhenInView from "./SlideFadeOutWhenInView";
import {useTranslation} from "react-i18next";

interface ViewPortfolioNovaGroupInnerPagesSectionProps {
  images: ViewPortfolioItemImageData[];
}

const ViewPortfolioNovaGroupInnerPagesSection = ({
  images,
}: ViewPortfolioNovaGroupInnerPagesSectionProps) => {
  const { t } = useTranslation();
  const shownPagesElRef = useRef<HTMLDivElement>(null);

  useEffect(() => {


    const colShownPagesEl = shownPagesElRef.current!;
    const shownPagesLabelEl = colShownPagesEl.querySelector(
      ".view-portfolio-nova-group-gallery-section__shown-pages-label"
    );
    const shownPagesListItemsElms = colShownPagesEl.querySelectorAll(
      ".view-portfolio-nova-group-gallery-section__shown-pages-list .list__item"
    );

    const shownPagesTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: colShownPagesEl,
        toggleActions: "play reset play reset",
      },
    });

    shownPagesTimeline.from(colShownPagesEl, {
      y: 30,
      ease: "ease-out",
      duration: 0.6 + 0.2 * (shownPagesListItemsElms.length - 1),
    });

    shownPagesTimeline.from(
      shownPagesLabelEl,
      {
        y: 10,
        opacity: 0,
        stagger: 0.2,
        ease: "ease-out",
        duration: 0.6,
      },
      "<"
    );

    shownPagesTimeline.from(
      shownPagesListItemsElms,
      {
        y: 10,
        opacity: 0,
        stagger: 0.2,
        ease: "ease-out",
        duration: 0.6,
      },
      "<"
    );

    return () => {
      shownPagesTimeline.kill();
      // captionTimeline.kill();
    };
  }, []);

  return (
    <ViewPortfolioItemSection
      size={{
        top: "md",
        bottom: "xl",
      }}
      className="view-portfolio-nova-group-gallery-section__first-group view-portfolio-nova-group-gallery-section__inner-pages-group"
    >
      <div className="view-portfolio-nova-group-gallery-section__first-group-container container">
        <div className="view-portfolio-nova-group-gallery-section__first-group-inner gaps--medium-md row">
          <SlideFadeOutWhenInView>
            {({ setTarget, setTriggerEl }) => (
              <div
                // ref={colInfoElRef}
                ref={setTriggerEl}
                className="view-portfolio-nova-group-gallery-section__first-group-col-with-info row col-11 col-xl-6"
              >
                <p
                  ref={setTarget()}
                  className="col-11 view-portfolio-nova-group-gallery-section__caption view-portfolio-item__text"
                >
                  {t('portfolioItem:nova-group.inner-section.caption.0')}
                </p>
                <div className="col-11 col-sm-auto offset-sm-auto view-portfolio-nova-group-gallery-section__first-group-info-description">
                  <p
                    ref={setTarget()}
                    className="view-portfolio-nova-group-gallery-section__caption"
                  >
                    {t('portfolioItem:nova-group.inner-section.caption.1')}
                  </p>
                  <List
                    ref={setTarget({
                      selector: ".list__item",
                    })}
                    className="view-portfolio-nova-group-gallery-section__first-group-info-size-list"
                    items={[1440, 768, 414].map((size) => size + " PX")}
                    itemClassName="label"
                  />
                </div>
              </div>
            )}
          </SlideFadeOutWhenInView>

          <div className="view-portfolio-nova-group-gallery-section__first-group-col-with-image col-11 col-xl-5 offset-xl-auto">
            <div className="row">
              <div className="col-11 col-md-9 offset-md-1 col-xl-11 offset-xl-0">
                <ViewPortfolioItemImage
                  imageClassName="view-portfolio-nova-group-gallery-section__first-group-image"
                  {...images[0]}
                />
              </div>
            </div>
          </div>
          <div className="view-portfolio-nova-group-gallery-section__first-group-col-with-image col-11 col-xl-4 offset-xl-3">
            <div className="row">
              <div className="col-11 col-md-9 offset-md-1 col-xl-11 offset-xl-0">
                <ViewPortfolioItemImage
                  imageClassName="view-portfolio-nova-group-gallery-section__first-group-image"
                  {...images[1]}
                />
              </div>
            </div>
          </div>
          <div className="view-portfolio-nova-group-gallery-section__first-group-col-with-image col-11 col-xl-4">
            <div className="row">
              <div className="col-11 col-md-9 offset-md-1 col-xl-11 offset-xl-0">
                <div className="row gaps--horizontal-medium">
                  <div className="col-7">
                    <ViewPortfolioItemImage
                      imageClassName="view-portfolio-nova-group-gallery-section__first-group-image"
                      {...images[2]}
                    />
                  </div>
                  <div className="col">
                    <ViewPortfolioItemImage
                      imageClassName="view-portfolio-nova-group-gallery-section__first-group-image"
                      {...images[3]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="view-portfolio-nova-group-gallery-section__first-group-col-with-image col-11 col-xl-2">
            <div className="row">
              <div className="col-11 col-md-7 offset-md-2 col-xl-11 offset-xl-0">
                <ViewPortfolioItemImage
                  imageClassName="view-portfolio-nova-group-gallery-section__first-group-image"
                  {...images[4]}
                />
              </div>
            </div>
          </div>
          <div className="view-portfolio-nova-group-gallery-section__first-group-col-with-image view-portfolio-nova-group-gallery-section__first-group-col--move-below-xl col-11 col-xl-4">
            <div className="row">
              <div className="col-11 col-md-9 offset-md-1 col-xl-11 offset-xl-0">
                <ViewPortfolioItemImage
                  imageClassName="view-portfolio-nova-group-gallery-section__first-group-image"
                  {...images[5]}
                />
              </div>
            </div>
          </div>
          <div className="view-portfolio-nova-group-gallery-section__first-group-col-with-shown-pages view-portfolio-nova-group-gallery-section__first-group-col--move-below-xl col">
            <div ref={shownPagesElRef} className="row gaps--horizontal-medium">
              <div className="col-auto">
                {/* <RevealWhenInView> */}
                <p className="view-portfolio-nova-group-gallery-section__shown-pages-label label side-brackets">
                  {t('portfolioItem:nova-group.inner-section.list-page.caption')}
                </p>
                {/* </RevealWhenInView> */}
              </div>
              <div className="col-auto">
                {/* <RevealWhenInView> */}
                <List
                  className="view-portfolio-nova-group-gallery-section__shown-pages-list"
                  items={[
                      t('portfolioItem:nova-group.inner-section.list-page.list.0'),
                      t('portfolioItem:nova-group.inner-section.list-page.list.1'),
                      t('portfolioItem:nova-group.inner-section.list-page.list.2'),
                      t('portfolioItem:nova-group.inner-section.list-page.list.3')
                  ]}
                  itemClassName="label label--uppercase-none"
                />
                {/* </RevealWhenInView> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ViewPortfolioItemSection>
  );
};

export default ViewPortfolioNovaGroupInnerPagesSection;
