import { cloneElement, memo, ReactElement, RefObject, useEffect, useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useDynamicBackground } from "./DynamicBackground";

interface DynamicBackgroundProps {
  children: ReactElement;
  /**
   * Ссылка на элемент, который будет триггерить смену цвета
   */
  triggerRef?: RefObject<HTMLElement>;
  /**
   * Ссылка на элемент у которого задан background-color
   */
  backgroundRef?: RefObject<HTMLElement>;
  start?: string;
  end?: string;
  color?: string;
}


//TODO: Написать отдельных хук
const DynamicBackgroundTrigger = ({
  children,
  triggerRef,
  backgroundRef,
  start = "top center",
  end = "bottom center",
  color,
}: DynamicBackgroundProps) => {
  const { defaultBackgroundColor, setBackgroundColor } = useDynamicBackground();
  const childTriggerRef = useRef<HTMLElement>(null);
  const backgroundColorRef = useRef("");

  useEffect(() => {
    const triggerEl = triggerRef
      ? triggerRef.current!
      : childTriggerRef.current!;

    let activeColor = getComputedStyle(triggerEl).backgroundColor;

    backgroundColorRef.current = activeColor;
  }, [triggerRef]);

  useEffect(() => {
    const triggerEl = triggerRef
      ? triggerRef.current!
      : childTriggerRef.current!;
    const backgroundElem = backgroundRef ? backgroundRef.current! : triggerEl;
    backgroundElem.style.backgroundColor = "transparent";

    const scrollTrigger = ScrollTrigger.create({
      trigger: triggerEl,
      start,
      end,
      // markers: true,
      onToggle: (sr) => {
        if (sr.isActive) {
          setBackgroundColor(backgroundColorRef.current);
        }
      },
    });

    return () => {
      scrollTrigger.kill();
    };
  }, [setBackgroundColor, triggerRef, backgroundRef, defaultBackgroundColor, start, end, color]);

  return triggerRef
    ? children
    : cloneElement(children, {
      ref: childTriggerRef,
    });
};

export default memo(DynamicBackgroundTrigger)