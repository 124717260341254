import ViewPortfolioGlobeItSite from 'components/views/ViewPortfolioItem/ViewPortfolioGlobeItSite';
import { PortfolioItemDataWithComponent } from "data/portfolio/types";

const porfolioItem: PortfolioItemDataWithComponent = {
  id: "globe-it-site",
  component: ViewPortfolioGlobeItSite,
  title: "globe-it",
  name: "Globe IT",
  altName: "производитель\nнасосного оборудования",
  year: 2020,
  awardsList: [
    {imgSrc : "/images/awards/gray-bg/awwwards.png"},
    {imgSrc : "/images/awards/gray-bg/awwwards.png"},
    {imgSrc : "/images/awards/gray-bg/special-kudos.png"},
    {imgSrc : "/images/awards/gray-bg/ux.png"},
    {imgSrc : "/images/awards/gray-bg/ui.png"},
    {imgSrc : "/images/awards/gray-bg/innovation.png"},
    {imgSrc : "/images/awards/gray-bg/innovation.png"},
    {imgSrc : "/images/awards/gray-bg/wda-gold.svg"},
  ],
  link: {
    label: "globus-it.ru",
    href: "https://globus-it.ru",
  },
  vc: {
    label: "vc.ru",
    href: "https://vc.ru/design/444896-nasosnye-stancii-na-awwwards",
  },

  socials: [
    {
      label: "behance",
      href:
        "https://www.behance.net/gallery/129666069/WEBSITE-Globe-IT-pumping-station-production",
    },
  ],

  preview: {
    label: "корпоративный сайт",
    media: {
      type: "video",
      src: "https://vimeo.com/714041740",
    },
  },

  poolOfWorks: ["Logotype", "UX|UI design", "Page making", "Integration with 1c-bitrix"],

  type: "site",
  subtype: "branding",

  description: [
    "один из крупнейших в россии производителей насосного оборудования. Компания хотела сделать редизайн сайта и слегка обновить логотип, чтобы выделяться на фоне конкурентов.",
  ],
  shortDescription:
    "Разработка сайта для производителя пром оборудования Глобус",
  seo: {
    title: "Разработка сайта для производителя пром оборудования Глобус",
    description: "Создание сайта для компании Глобус - крупнейшего производителя промышленного оборудования и насосных станций. Корпоративный сайт работает по технологии Single Page Application",
    keywords: ["сайт производителя оборудования",
      "разработка сайта промышленной компании",
      "создание корпоративного сайта",
      "создание корпоративного сайт с каталогом оборудования",
      "сайт для промкомпании",
      "дизайн промышленного сайта"],
    openGraph: {
      type: "article",
      title: "Разработка сайта для производителя пром оборудования Глобус",
      description: "Создание сайта для компании Глобус - крупнейшего производителя промышленного оборудования и насосных станций. Корпоративный сайт работает по технологии Single Page Application",
    }
  }

};

export default porfolioItem;
