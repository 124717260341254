import RevealTextByLine from "components/common/RevealInView/RevealTextByLine";
import s from "./index.module.scss";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import SlideFadeOutWhenInView from "components/views/ViewPortfolioItem/ViewPortfolioNovaGroup/SlideFadeOutWhenInView";
import List from "components/common/List";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";

const sectionData = {
  title: "виды работ",
  subtitle:
    "Решим все задачи по разработке и дизайну с детальным погружением в ваш бизнес",
  items: [
    {
      title: "Техническая поддержка",
      caption: ["сайты", "хостинги и серверы", "работа с функционалом"],
      items: [
        {
          type: "titled-list",
          title: "разработка",
          items: [
            "внедрение новых функций",
            "корректировки существующего функционала",
            "интеграции со сторонними сервисами",
            "обеспечение совместимости с различными браузерами и устройствами",
          ],
        },
        {
          type: "titled-list",
          title: "поддержка",
          items: [
            "управление хостингом и серверами",
            "резервное копирование и восстановление данных",
            "защита от атак и вирусов",
            "обеспечение совместимости с различными браузерами и устройствами",
          ],
        },
        {
          type: "double-titled-list",
          title: "стэк",
          items: [
            "html + js + css/scss/sass",
            "django",
            "react",
            "swift",
            "redux",
            "php",
            "vuex",
            "sql",
            "nuxt.js",
            "node.js",
            "typescript",
            "vue.js",
            "next.js",
            "python",
          ],
        },
      ],
    },
    {
      title: "Дизайн-поддержка",
      caption: [
        "дизайн интерфейсов",
        "графический дизайн",
        "визуальные эффекты",
      ],
      items: [
        {
          type: "heading-titled-list",
          title: "дизайн-сопровождение маркетинговых коммуникаций",
          items: [
            "Баннеры",
            "корректировки существующего функционала",
            "интеграции со сторонними сервисами",
            "обеспечение совместимости с различными браузерами и устройствами",
          ],
        },
        {
          type: "list",
          items: [
            "Дизайн новых разделов сайта",
            "Бизнес-коммуникация",
            "отчеты",
            "Коммерческие предложения",
          ],
        },
      ],
    },
  ],
};

function ViewDeveloperSitesJobsSection() {
  return (
    <DynamicBackgroundTrigger>
      <section
        className={
          s["view-developer-sites-jobs-section"] + " background--dark-white-2"
        }
      >
        <div className={" section-container container"}>
          <div className={""}>
            <header>
              <div
                className={
                  "row " + s["view-developer-sites-jobs-section__header"]
                }
              >
                <RevealWhenInView className="col-xl-7">
                  <h2 className="heading">{sectionData.title}</h2>
                </RevealWhenInView>
                <div className=" offset-md-4 col-md offset-xl-0">
                  <RevealTextByLine
                    wordClassName={(l, w) =>
                      l === 0 && w === 0 && "offset-md-3"
                    }
                  >
                    {sectionData.subtitle}
                  </RevealTextByLine>
                </div>
              </div>
            </header>
            {sectionData.items.map((item, index) => {
              return (
                <div
                  className={
                    s["view-developer-sites-jobs-section__items-block"] + " row"
                  }
                  key={"id_" + Math.random().toString(36).substr(2, 9)}
                >
                  <div
                    className={
                      "col-lg-5 " +
                      s["view-developer-sites-jobs-section__items-block-head"]
                    }
                  >
                    <RevealWhenInView>
                      <span className={"heading-2"}>{item.title}</span>
                    </RevealWhenInView>
                    <SlideFadeOutWhenInView>
                      {({ setTriggerEl, setTarget }) => (
                        <div ref={setTriggerEl}>
                          <div
                            ref={setTarget({ selector: "p" })}
                            className={s["caption-container"] + " row "}
                          >
                            {item.caption.map((captionItem, captionIndex) => {
                              return (
                                <p key={captionIndex} className={s.caption}>
                                  {captionItem}
                                </p>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </SlideFadeOutWhenInView>
                  </div>

                  <div className="col-lg-6 row ">
                    {item.items.map((item, index) => {
                      if (item.type === "titled-list") {
                        return (
                          <SlideFadeOutWhenInView key={index}>
                            {({ setTriggerEl, setTarget }) => (
                              <div
                                ref={setTriggerEl}
                                className={
                                  "row " +
                                  s[
                                    "view-developer-sites-jobs-section__item-block"
                                  ]
                                }
                              >
                                <div className={"row col-11 col-md-4"}>
                                  <p className={"side-brackets label"}>
                                    {item.title}
                                  </p>
                                </div>
                                <List
                                  ref={setTarget({ selector: ".list__item" })}
                                  items={item.items}
                                  gap="lg"
                                  marked
                                  className="row col-11 col-md-7"
                                >
                                  {(item) => (
                                    <span className="col-11">{item}</span>
                                  )}
                                </List>
                              </div>
                            )}
                          </SlideFadeOutWhenInView>
                        );
                        //Лист с заголовком но в два столбца
                      } else if (item.type === "double-titled-list") {
                        return (
                          <SlideFadeOutWhenInView key={index}>
                            {({ setTriggerEl, setTarget }) => (
                              <div
                                className={
                                  s[
                                    "view-developer-sites-jobs-section__item-block"
                                  ] + " row col-11"
                                }
                                ref={setTriggerEl}
                              >
                                <div className="row col-4">
                                  <span className="side-brackets heading-3">
                                    {item.title}
                                  </span>
                                </div>
                                <div
                                  ref={setTarget({
                                    selector: ".list-selector",
                                  })}
                                  className="row col-11 col-md-6"
                                >
                                  {item.items.map((item, index) => {
                                    return (
                                      <p
                                        className={
                                          (index % 2 === 0
                                            ? "col-5 col-md-7 "
                                            : "col-5 col-md-3 offset-1 ") +
                                          s["cursive-text-item"] +
                                          " caption text--secondary list-selector"
                                        }
                                        key={index}
                                      >
                                        +{"  "}
                                        <span className={"side-brackets "}>
                                          {item}
                                        </span>
                                      </p>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </SlideFadeOutWhenInView>
                        );
                        // Лист c заголовком сверху
                      } else if (item.type === "heading-titled-list") {
                        return (
                          <div
                            className={
                              " offset-0  offset-md-2  offset-lg-0 g-0 " +
                              s[
                                "view-developer-sites-jobs-section__item-block"
                              ] +
                              " "
                            }
                            key={index}
                          >
                            <SlideFadeOutWhenInView>
                              {({ setTriggerEl, setTarget }) => (
                                <div
                                  key={
                                    "id_" +
                                    Math.random().toString(36).substr(2, 9)
                                  }
                                  className={" row"}
                                  ref={setTriggerEl}
                                >
                                  <div
                                    className={
                                      "row col-11 " +
                                      s["heading-titled-list__title"]
                                    }
                                  >
                                    <span className="heading-3">
                                      {item.title}
                                    </span>
                                  </div>
                                  <List
                                    ref={setTarget({ selector: ".list__item" })}
                                    items={item.items}
                                    gap="lg"
                                    marked
                                    className="row col-6"
                                  >
                                    {(item) => (
                                      <span className="col-11">{item}</span>
                                    )}
                                  </List>
                                </div>
                              )}
                            </SlideFadeOutWhenInView>
                          </div>
                        );
                        // Лист без заголовка
                      } else if (item.type === "list") {
                        return (
                          <SlideFadeOutWhenInView key={index}>
                            {({ setTriggerEl, setTarget }) => (
                              <div
                                key={index}
                                ref={setTriggerEl}
                                className={
                                  s[
                                    "view-developer-sites-jobs-section__item-block"
                                  ] + " row offset-0 offset-md-2  offset-lg-0"
                                }
                              >
                                <div
                                  className="row col-11"
                                  ref={setTarget({ selector: ".heading-3" })}
                                >
                                  {item.items.map((item) => {
                                    return (
                                      <span
                                        className={
                                          "heading-3 col-11 " + s["list-item"]
                                        }
                                        key={
                                          "id_" +
                                          Math.random()
                                            .toString(36)
                                            .substr(2, 9)
                                        }
                                      >
                                        {item}
                                      </span>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </SlideFadeOutWhenInView>
                        );
                      }
                      return <></>;
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </DynamicBackgroundTrigger>
  );
}

export default ViewDeveloperSitesJobsSection;
