import { PortfolioItemDataWithComponent } from "data/portfolio/types";
import ViewPortfolioAist from "../../components/views/ViewPortfolioItem/ViewPortfolioAist";
import {buildPublicImageSrc} from "../../helpers/path";

const porfolioItem: PortfolioItemDataWithComponent = {
    id: "aist",
    component: ViewPortfolioAist,
    title: "aist",
    name: "АИСТ24 - логистика",
    altName: "Автоматизированная \n информационная система \n транспорта",
    year: 2023,
    type: "design",
    subtype: "portal",

    preview: {
        label: "портал",
        media: {
            type: 'image',
            fromSrc: buildPublicImageSrc({
                path: '',
                file: '',
            }),
            toSrc: buildPublicImageSrc({
                path: '',
                file: '',
            }),
        },
    },

    socials: [
        {
            label: "behance",
            href: "https://www.behance.net/gallery/169647361/UX-case-Aist24",
        },
    ],

    poolOfWorks: [
        "UX-research",
        "Job-stories",
        "Cjm",
        "Wireframing"
    ],


    description: [
        "Это ІТ-платформа для автоматизации перевозок сельскохозяйственной продукции.",
        "Конечным продуктом данной компании является CRM-сервис с возможностью размещения заказов на бирже от клиента и полным регулированием поездок водителей по заявкам."
    ],
    shortDescription: "Автоматизированная информационная система транспорта",
    seo: {
        title:
            "Разработка онлайн сервиса логистики по перевозки сельхоз грузов АИСТ24",
        description:
            "Кейс по разработке онлайн сервиса для логистической компании АИСТ24. Студия Sagirov.com спроектировала веб-приложение для автоматизации перевозок сельхоз грузов, увеличив производительность и прибыль. Оптимизация для бизнес-результатов, удобства пользователей и роста конверсий",
        keywords: [
            "разработка онлайн сервисов для логистики",
            "кейс АИСТ24",
            "веб-решения для бизнеса",
            "автоматизация логистических процессов",
            "логистический сервис для сельхоз грузов",
            "оптимизация ИТ-инфраструктуры",
            "улучшение эффективности бизнеса"
        ],
        openGraph: {
            type: "article",
            title:
                "Разработка онлайн сервиса логистики по перевозки сельхоз грузов АИСТ24",
            description:
                "Кейс по разработке онлайн сервиса для логистической компании АИСТ24. Студия Sagirov.com спроектировала веб-приложение для автоматизации перевозок сельхоз грузов, увеличив производительность и прибыль. Оптимизация для бизнес-результатов, удобства пользователей и роста конверсий",
        },
    },
};

export default porfolioItem;
