import ViewPortfolioRutube from 'components/views/ViewPortfolioItem/ViewPortfolioRutube';
import { PortfolioItemDataWithComponent } from 'data/portfolio/types';
import { buildPublicImageSrc } from '../../helpers/path';

const porfolioItem: PortfolioItemDataWithComponent = {
  id: 'rutube',
  component: ViewPortfolioRutube,
  title: 'rutube',
  name: 'Rutube',
  altName: 'Russia"s leading video portal',
  year: 2023,
  awardsList: [],

  link: {
    label: 'rutube.ru',
    href: 'https://rutube.ru/',
  },

  socials: [
    {
      label: 'behance',
      href: 'https://www.behance.net/gallery/159728019/RUTUBE-APP-REDESIGN',
    },
  ],

  preview: {
    label: 'portal',
    media: {
      type: 'image',
      fromSrc: buildPublicImageSrc({
        path: 'portfolios/wildberries/preview',
        file: '1-1440w.webp',
      }),
      toSrc: buildPublicImageSrc({
        path: 'portfolios/wildberries/preview',
        file: '2-1440w.webp',
      }),
    },
  },

  poolOfWorks: ['UX|UI design'],

  type: 'branding',
  subtype: 'site',

  description: [
    'is a Russian video hosting service based on its own platform. A Russian video hosting service that is gradually introducing new features to attract a new audience. Its arsenal includes various formats you-channels, custom videos, live broadcasts, movies and TV series',
  ],
  shortDescription: 'branding for the Russia"s leading video portal',

  seo: {
    title: 'Design of a mobile application for video hosting RuTUBE',
    description:
      'We have developed an application design for the Russian video hosting rutube. Improved the user experience made a complete redesign of the application starting with the logo and ending with the like button under the video',
    keywords: [
      'rutube design',
      'video hosting mobile application design',
      'video viewing application',
      'рутуб',
      'rutube application interface',
      'mobile application design development',
      'rutube redesign',
    ],
    openGraph: {
      type: 'article',
      title: 'Design of a mobile application for video hosting RuTUBE',
      description:
        'We have developed an application design for the Russian video hosting rutube. Improved the user experience made a complete redesign of the application starting with the logo and ending with the like button under the video',
    },
  },
};

export default porfolioItem;
