import { memo, useEffect, useRef } from "react";
import ViewPortfolioLeadSection from "./ViewPortfolioLeadSection/ViewPortfolioLeadSection";
import ViewPortfolioShowcaseSection from "./ViewPortfolioShowcaseSection/ViewPortfolioShowcaseSection";
import gsap from "gsap";
import View from "components/layouts/View";
import Footer from "components/layouts/Footer";

export interface ViewPortfolioProps {}

const ViewPortfolio = (props: ViewPortfolioProps) => {
  const viewRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const viewElem = viewRef.current!;

    /* lead section */
    const leadSectionTitle = viewElem.querySelector(
      ".portfolio-lead-section__title"
    );
    const leadSectionSubtitle = viewElem.querySelector(
      ".portfolio-lead-section__col-with-label"
    );
    const leadSectionDescription = viewElem.querySelector(
      ".portfolio-lead-section__text"
    );
    const leadSectionArrow = viewElem.querySelector(
      ".portfolio-lead-section__arrow"
    );

    const leadSectionStatCounterElms = viewElem.querySelectorAll(
      ".portfolio-lead-section__stat-counter"
    );
    const leadSectionStatLabelElms = viewElem.querySelectorAll(
      ".portfolio-lead-section__stat-label"
    );

    const timelineDefaults = {
      defaults: { ease: "ease-out", duration: 0.6 },
      delay: 0.8,
    };

    const timeline = gsap.timeline(timelineDefaults);

    /* lead section animations */

    const showLeadSectionTitleTween = gsap.from(leadSectionTitle, {
      y: 20,
      opacity: 0,
      duration: timelineDefaults.defaults.duration,
      ease: "ease-out",
    });

    const showLeadSectionSubtitleTween = gsap.from(leadSectionSubtitle, {
      y: 20,
      opacity: 0,
      duration: timelineDefaults.defaults.duration,
      ease: "ease-out",
    });

    const showLeadSectionDescriptionTween = gsap.from(leadSectionDescription, {
      y: 20,
      opacity: 0,
      duration: timelineDefaults.defaults.duration,
      ease: "ease-out",
    });

    const showLeadSectionArrow = gsap.from(leadSectionArrow, {
      y: -20,
      opacity: 0,
      duration: timelineDefaults.defaults.duration,
      ease: "ease-out",
    });

    const showLeadSectionStatCountersTween = gsap.from(
      leadSectionStatCounterElms,
      {
        y: 20,
        opacity: 0,
        duration: timelineDefaults.defaults.duration,
        ease: "ease-out",
      }
    );
    const showLeadSectionStatLabelsTween = gsap.from(leadSectionStatLabelElms, {
      x: -20,
      opacity: 0,
      duration: timelineDefaults.defaults.duration,
      ease: "ease-out",
    });

    timeline.add([
      showLeadSectionTitleTween,
      showLeadSectionSubtitleTween,
      showLeadSectionDescriptionTween,
      showLeadSectionArrow,
      showLeadSectionStatCountersTween,
      showLeadSectionStatLabelsTween,
    ]);

    // timeline.add(showLeadSectionStatsTimelines, "<");
  }, []);

  return (
    <View ref={viewRef} dynamicBackground className="view view--portfolio">
      <ViewPortfolioLeadSection />
      <ViewPortfolioShowcaseSection />
      <Footer />
    </View>
  );
};

export default memo(ViewPortfolio);
