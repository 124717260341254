import { useRef, useState } from 'react'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'
import Button from 'components/common/Button'
import {useTranslation} from "react-i18next";

interface CookiePolicyProps {

}


const CookiePolicy = (props: CookiePolicyProps) => {
  const [accepted, setAccepted] = useState(() => !!localStorage.getItem("cookie"));
  const cookiePolicyElRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();
  const onAccept = () => {
    localStorage.setItem("cookie", "1");
    setAccepted(true);
  }

  return (
    <CSSTransition nodeRef={cookiePolicyElRef} unmountOnExit timeout={200} in={!accepted}>
      <div ref={cookiePolicyElRef} className={classNames("cookie-policy")}>
        <div className="cookie-policy__container">
          <div className="cookie-policy__inner">
            <p className="cookie-policy__text text-secondary">{t("Cookie text")}</p>
            <Button onClick={onAccept} className="cookie-policy__accept-button side-brackets label">
              ok
            </Button>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export default CookiePolicy
