import { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";

import Head from "./layouts/Head";
import Menu from "./layouts/Menu";
import Header from "./layouts/Header";
import Main from "./layouts/Main";
import Cursor from "components/common/Cursor";

import { BrowserInterfaceVisibilityProvider } from "contexts/BrowserInterfaceVisibility";

import { BodyScrollLockerProvider } from "contexts/BodyScrollLocker";
import isTouchDevice from "is-touch-device";
import Preloader from "./layouts/Preloader";

import CookiePolicy from "./layouts/CookiePolicy";
interface AppProps {}

const App = (props: AppProps) => {
  useEffect(() => {
    const isMac = navigator.platform.toLowerCase().indexOf("mac") !== -1;
    if (window.innerWidth > 1199 && !isMac) {
      document.documentElement.classList.add("custom-scroll");
    }
  }, []);

  const hasCursor = !isTouchDevice();

  return (
    <HelmetProvider>
      <Head />
      <BrowserInterfaceVisibilityProvider>
        <BodyScrollLockerProvider>
          <Preloader />

          <Header />
          <Menu />
          <Main />
        </BodyScrollLockerProvider>
      </BrowserInterfaceVisibilityProvider>

      <CookiePolicy />
      {hasCursor ? <Cursor /> : null}
    </HelmetProvider>
  );
};

export default App;
