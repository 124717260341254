import React, { forwardRef, Ref, useEffect, useRef } from "react";
import Vimeo, { Options } from "@vimeo/player";
import classNames from "classnames";
import { useImperativeHandle } from "react";

interface VimeoPlayerProps
  extends Pick<
    Options,
    "id" | "url" | "loop" | "autoplay" | "muted" | "controls"
  > {
  play?: boolean;
  className?: string;
  size?: "fill";
}

type VimeoPlayerRef = HTMLDivElement;

const VimeoPlayer = (
  { play, size, className, ...vimeoOptions }: VimeoPlayerProps,
  ref: Ref<VimeoPlayerRef>
) => {
  const vimeoPlayerElRef = useRef<HTMLDivElement>(null);
  const vimeoRef = useRef<Vimeo | null>(null);

  useImperativeHandle(ref, () => vimeoPlayerElRef.current!);

  useEffect(() => {
    const options: Options = {
      ...vimeoOptions,
      autopause: false,
      muted: vimeoOptions.muted ?? true, // Мьют по умолчанию для соответствия требованиям iOS
    };

    const destroyVimeo = () => {
      if (vimeoRef.current) {
        try {
          vimeoRef.current.destroy();
        } catch (e) {
          console.log("Error destroying Vimeo player:", e);
        }
      }
    };

    destroyVimeo();

    vimeoRef.current = new Vimeo(vimeoPlayerElRef.current!, options);

    return () => {
      destroyVimeo();
    };
  }, [vimeoOptions.url]);

  useEffect(() => {
    const vimeo = vimeoRef.current;
    if (vimeo) {
      if (play) {
        vimeo.play().catch((e) => console.log("Error playing video:", e));
      } else {
        vimeo.pause().catch((e) => console.log("Error pausing video:", e));
      }
    }
  }, [play]);

  return (
    <div
      ref={vimeoPlayerElRef}
      className={classNames(
        "vimeo-player",
        {
          [`vimeo-player--size-${size}`]: size,
          "vimeo-player--controls-off": !vimeoOptions.controls,
        },
        className
      )}
    />
  );
};

export default forwardRef<VimeoPlayerRef, VimeoPlayerProps>(VimeoPlayer);
