import ViewPortfolioRost from "components/views/ViewPortfolioItem/ViewPortfolioRost";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";
import { buildPublicImageSrc } from "helpers/path";

const rost: PortfolioItemDataWithComponent = {
  id: "rost-branding",
  component: ViewPortfolioRost,
  title: "rost",
  name: "Рост",
  altName: "промышленная\nстроительная компания",
  year: 2020,

  link: {
    label: "ngrost.ru",
    href: "https://ngrost.ru",
  },

  socials: [
    {
      label: "behance",
      href: "https://www.behance.net/gallery/77548159/rost",
    },
  ],

  preview: {
    label: "логотип и фирменный стиль",
    media: {
      type: "image",
      fromSrc: buildPublicImageSrc({
        path: "portfolios/rost",
        file: "preview-from.jpg",
      }),
      toSrc: buildPublicImageSrc({
        path: "portfolios/rost",
        file: "preview-to.jpg",
      }),
    },
  },

  relatedId: "rost-site",

  poolOfWorks: ["Logotype", "Brand style"],

  type: "branding",
  subtype: "branding",

  description: [
    "Компания предоставляет услуги по возведению промышленных объектов и индивидуальные решения в сфере строительства.",
    "Строгий минималистичный дизайн проекта призван вызвать доверие будущих клиентов, помогает сформировать уверенность в выборе организаци",
  ],
  shortDescription:
    "брендинг для промышленной строительной компании",
  seo:
  {
    title: "Брендинг для строительной компании РОСТ",
    description: "Создание фирменного стиля для промышленной строительной компании РОСТ, включащий разработку логотипа, визиток, бланков, конвертов, блокнотов, бейджев, и полноценного брендбука",
    keywords: ["логотип пром компании", "разработка логотипа", "брендинг промышленной компании", "фирстиль пром строительство", "фирменный стиль промышленной компании", "брендинг пром компании", "разработка фирменного стиля"],
    openGraph: {
      type: "article",
      title: "Брендинг для строительной компании РОСТ",
      description: "Создание фирменного стиля для промышленной строительной компании РОСТ, включащий разработку логотипа, визиток, бланков, конвертов, блокнотов, бейджев, и полноценного брендбука",
    }
  }

  // page: {
  //   section: {
  //     about: {
  //       // yPadding: ""
  //       theme: {
  //         text: "light",
  //         background: {
  //           color: "#111111",
  //         },
  //       },
  //       content: {
  //         image: {
  //           type: "logo",
  //           file: "about.svg",
  //         },
  //       },
  //     },
  //     gallery: {
  //       content: {
  //         items: [
  //           {
  //             image: {
  //               file: "gallery-1.jpg",
  //             },
  //             backgroundColor: "#fff",
  //           },
  //           {
  //             image: {
  //               file: "gallery-2.jpg",
  //             },
  //             backgroundColor: "#111111",
  //           },
  //           {
  //             image: {
  //               file: "gallery-3.png",
  //             },
  //           },
  //         ],
  //       },
  //     },
  //   },
  // },
};

export default rost;
