import ViewPortfolioNovaGroupBrandStyle from "components/views/ViewPortfolioItem/ViewPortfolioNovaGroupBrandStyle";
import {
  ImageDataType,
  PortfolioItemDataWithComponent,
} from "data/portfolio/types";
import { buildPublicImageSrc } from "helpers/path";

export interface PortfolioItemDataPageSectionAboutContent {
  topText?: string[];
  image: ImageDataType | {};
}

const porfolioItem: PortfolioItemDataWithComponent = {
  id: "nova-group-brand-style",
  component: ViewPortfolioNovaGroupBrandStyle,
  title: "nova-group",
  name: "Nova group",
  altName: "Московская архитектурно-\nстроительная компания",
  year: 2020,
  link: "novagroup.ru",
  type: "branding",
  subtype: "branding",
  preview: {
    label: "фирменный стиль",
    media: {
      type: "image",
      fromSrc: buildPublicImageSrc({
        path: "portfolios/nova-group-brand-style",
        file: "preview-from.jpg",
      }),
      toSrc: buildPublicImageSrc({
        path: "portfolios/nova-group-brand-style",
        file: "preview-to.jpg",
      }),
    },
  },

  socials: [
    {
      label: "behance",
      href:
        "https://www.behance.net/gallery/91228201/Nova-Group-Brand-identity-design",
    },
  ],

  poolOfWorks: ["Logotype", "Brand style"],

  relatedId: "nova-group",

  description: [
    "архитектурно-строительная компания, занимающаяся строительством частных домов в Москве и Московской области.",
    "Была задача разработать фирменный стиль, демонстрирующий компанию как надежного и ответственного подрядчика."
  ],
  shortDescription:
    "брендинг для Московской архитектурно-строительной компании",
  seo:
  {
    title: "Брендинг для строительной компании Nova Group",
    description: "Разработка с нуля логотипа и всех элементов фирменного стиля для архитектурно-строительной компании Nova Group",
    keywords: ["фирстиль строительной компании", "логотип московской строительной компании", "создание логотипа для строителей", "разработка логотипа частные дома", "строительство домов лого", "логотип для nova group"],
    openGraph: {
      type: "article",
      title: "Брендинг для строительной компании Nova Group",
      description: "Разработка с нуля логотипа и всех элементов фирменного стиля для архитектурно-строительной компании Nova Group",
    }
  }
};

export default porfolioItem;
