import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import {
  useSafeContext,
  createSafeConsumer,
  createSafeContext,
} from "contexts/helpers";

interface ContextValue {
  setBackgroundColor: (color: string) => void;
  backgroundColor?: string;
  defaultBackgroundColor: string;
}

const Context = createSafeContext<ContextValue>();

export const useDynamicBackground = () => useSafeContext(Context);
export const DynamicBackgroundConsumer = createSafeConsumer(Context);

interface DynamicBackgroundProps {
  children: ReactNode;
  className?: string;
}

/**
 * Отвечает за динамическую смену цвета заднего фона
 */
const DynamicBackground = ({
  children,
  className,
}: DynamicBackgroundProps) => {
  const backgroundElRef = useRef<HTMLDivElement>(null);
  const [state, setState] = useState<Pick<ContextValue, "backgroundColor" | "defaultBackgroundColor">>({
    backgroundColor: undefined,
    defaultBackgroundColor: "rgba(0, 0, 0, 0)",
  });

  useEffect(() => {
    const backgroundEl = backgroundElRef.current!;
    const backgroundColor = getComputedStyle(backgroundEl).backgroundColor;

    setState({
      backgroundColor,
      defaultBackgroundColor: backgroundColor
    });
  }, []);

  const setBackgroundColor = useCallback((color: string) => {
    setState((state) => ({
      ...state,
      backgroundColor: color
    }));
  }, []);

  const value: ContextValue = useMemo(
    () => ({
      backgroundColor: state.backgroundColor,
      defaultBackgroundColor: state.defaultBackgroundColor,
      setBackgroundColor
    }),
    [state.backgroundColor, state.defaultBackgroundColor, setBackgroundColor]
  );

  return (
    <Context.Provider value={value}>
      <div
        ref={backgroundElRef}
        style={{ backgroundColor: state.backgroundColor }}
        className={classNames("dynamic-background", className)}
      />
      {children}
    </Context.Provider>
  );
};

export default DynamicBackground;