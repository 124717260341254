import { usePortfolioItemAssets } from "hooks/useAssets";
import { useViewPortfolioItemContext } from "../ViewPortfolioItem";
import ViewPortfolioItemAboutSection from "../ViewPortfolioItemAboutSection";
import ViewPortfolioItemParallaxSection from "../ViewPortfolioItemParallaxSection";
import ViewPortfolioItemSection from "../ViewPortfolioItemSection";
import RevealWhenInView from "../../../common/RevealInView/RevealWhenInView";
import Print from "../../../common/Print";

export interface ViewPortfolioIcrProps { }

const ViewPortfolioIcr = (props: ViewPortfolioIcrProps) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);

  return (
    <>
      <ViewPortfolioItemAboutSection
        style={{ backgroundColor: "#234989", color: "#fff" }}
        name={data.name}
        image={{
          isLogo: true,
          src: buildPageImageSrc("description", "svg"),
        }}
        description={data.description}
      />

        <ViewPortfolioItemParallaxSection
            className="view-portfolio-icr-section portfolio-item-section--icr-1"
            dynamicBackgroundColor={true}
            style={{ backgroundColor: "#234989"}}
            image={{
                src: buildPageImageSrc("screen-1"),
                sources: [
                    {
                        type: "webp",
                        srcSet: buildPageImageSrc("screen-1")
                    }
                ]
            }}
        />

        <ViewPortfolioItemParallaxSection
            className="view-portfolio-icr-section portfolio-item-section--icr-2"
            dynamicBackgroundColor={true}
            style={{ backgroundColor: "#234989"}}
            image={{
                src: buildPageImageSrc("screen-2"),
                sources: [
                    {
                        type: "webp",
                        srcSet: buildPageImageSrc("screen-2")
                    }
                ]
            }}
        />

        <ViewPortfolioItemParallaxSection
            className="view-portfolio-icr-section portfolio-item-section--icr-3 container"
            dynamicBackgroundColor={true}
            style={{ backgroundColor: "#234989"}}
            image={{
                src: buildPageImageSrc("screen-3"),
                sources: [
                    {
                        type: "webp",
                        srcSet: buildPageImageSrc("screen-3")
                    }
                ]
            }}
        />

        <ViewPortfolioItemParallaxSection
            className="view-portfolio-icr-section portfolio-item-section--icr-4 container"
            dynamicBackgroundColor={true}
            style={{ backgroundColor: "#234989"}}
            image={{
                src: buildPageImageSrc("screen-4"),
                sources: [
                    {
                        type: "webp",
                        srcSet: buildPageImageSrc("screen-4")
                    }
                ]
            }}
        />

        <ViewPortfolioItemParallaxSection
            className="view-portfolio-icr-section portfolio-item-section--icr-5"
            dynamicBackgroundColor={true}
            style={{ backgroundColor: "#C0C0C0"}}
            image={{
                src: buildPageImageSrc("screen-5"),
                sources: [
                    {
                        type: "webp",
                        srcSet: buildPageImageSrc("screen-5")
                    }
                ]
            }}
        />

        <ViewPortfolioItemParallaxSection
            className="view-portfolio-icr-section portfolio-item-section--icr-6 container"
            dynamicBackgroundColor={true}
            style={{ backgroundColor: "#C0C0C0"}}
            image={{
                src: buildPageImageSrc("screen-6"),
                sources: [
                    {
                        type: "webp",
                        srcSet: buildPageImageSrc("screen-6")
                    }
                ]
            }}
        />

        <ViewPortfolioItemParallaxSection
            className="view-portfolio-icr-section portfolio-item-section--icr-7 container"
            dynamicBackgroundColor={true}
            style={{ backgroundColor: "#C0C0C0"}}
            image={{
                src: buildPageImageSrc("screen-7"),
                sources: [
                    {
                        type: "webp",
                        srcSet: buildPageImageSrc("screen-7")
                    }
                ]
            }}
        />

        <ViewPortfolioItemSection style={{ backgroundColor: "#E0E0E0"}}
                                  dynamicBackgroundColor={true}
                                  className="view-portfolio-icr-section container portfolio-item-section--icr-8">
            <div className="portfolio-item-section__inner row">
                <div className="col-xl-6 wrap-img">
                    <RevealWhenInView>
                        <img src={buildPageImageSrc('screen-8')} alt={'ensemble'}/>
                    </RevealWhenInView>
                </div>
                <div className="col-xl-4 offset-xl-1 row">
                    <div className="text col-11">
                        <RevealWhenInView>
                            <span>
                                <Print data={"portfolioItem:icr.section-8.text"}/>
                            </span>
                        </RevealWhenInView>
                    </div>
                    <div className="caption col-auto offset-auto">
                        <RevealWhenInView>
                            <span>
                                <Print data={"portfolioItem:icr.section-8.caption"}/>
                            </span>
                        </RevealWhenInView>
                    </div>
                </div>
            </div>
        </ViewPortfolioItemSection>

        <ViewPortfolioItemParallaxSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: "#C0C0C0"}}
            image={{
                src: buildPageImageSrc("screen-9"),
                sources: [
                    {
                        type: "webp",
                        srcSet: buildPageImageSrc("screen-9")
                    }
                ]
            }}
        />

    </>
  );
};

export default ViewPortfolioIcr;
