import { useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { portfolio, routes } from "./";

/**
 * Создает url по id портфолио
 */
export const buildLinkToPortfolioItem = (
  portfolioItemId: string | number
): string => {

  return `${portfolio.path.replace('/:locale( |en)?', "")}/${portfolioItemId}`;
};

/**
 * Хук возвращает текущий раут
 */
export const useCurrentRoute = () => {
  const location = useLocation();

  const currentRoute = useMemo(() => {
    return routes.find((route) => matchPath(location.pathname, route));
  }, [location.pathname]);
  return currentRoute;
};
