import ViewPortfolioGosapteka from "components/views/ViewPortfolioItem/ViewPortfolioGosapteka";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";
import { buildPublicImageSrc } from "helpers/path";

const gosapteka: PortfolioItemDataWithComponent = {
  id: "gosapteka",
  component: ViewPortfolioGosapteka,
  title: "gosaptekaEn",
  name: "GosApteka",
  altName: "federal network\nof state pharmacies",
  year: 2020,

  socials: [
    {
      label: "behance",
      href: "https://www.behance.net/gallery/95078235/iOS-Pharmacy-dashboard",
    },
  ],

  preview: {
    label: "iOS app",
    media: {
      type: "image",
      fromSrc: buildPublicImageSrc({
        path: "portfolios/gosapteka/preview",
        file: "1-1440w.jpg",
      }),
      toSrc: buildPublicImageSrc({
        path: "portfolios/gosapteka/preview",
        file: "2-1440w.jpg",
      }),
    },
  },

  poolOfWorks: ["UX|UI design", "User flow optimization"],

  type: "interfaces",
  subtype: "application",

  description: [
    "It was required to create an internal corporate mobile application for a federal network of pharmacies.",
    "The application allows you to analyze financial performance and is intended for managers and executives"
  ],
  shortDescription:
    "application for the federal network of state pharmacies",
  seo:
  {
    title: "Development of an iOS application for the Pharmacy company",
    description: "Development of design for iOS application for the GosApteka pharmacy chain. The interaction paths and the application interface have been fully developed for mobile and tablet devices. New types of charts and convenient tables, as well as day and night modes of the application",
    keywords: ["creating an application for a pharmacy",
      "ios applications for a pharmaceutical company",
      "development of a mobile application for a pharmacy",
      "pharmacy application design",
      "ux ui design ios",
      "ios developer for pharmaceutical companies"],
    openGraph: {
      type: "article",
      title: "Development of an iOS application for the Pharmacy company",
      description: "Development of design for iOS application for the GosApteka pharmacy chain. The interaction paths and the application interface have been fully developed for mobile and tablet devices. New types of charts and convenient tables, as well as day and night modes of the application",
    }
  }

};

export default gosapteka;
