import React from "react";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";
import { useTranslation } from "react-i18next";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";

const AwardsSection = () => {
    const { t } = useTranslation();
    let content = {
        title: t("layout:awards-section.title"),
        subtitle: t("layout:awards-section.subtitle"),
        awardsCount: t("layout:awards-section.awardsCount"),
        awardsProjectLists: {
            listSecondary: t("layout:awards-section.awardsProjectLists.listSecondary"),
            listPrimary: [],
        },
        awardsList: [],
    };

    content.awardsProjectLists.listPrimary = t("layout:awards-section.awardsProjectLists.listPrimary",{ returnObjects: true });
    content.awardsList = t("layout:awards-section.awardsList",{ returnObjects: true });


    return (
        <DynamicBackgroundTrigger>
            <section className="awards-section section container">
                <div className="awards-header row">
                    <div className="awards-title col-xl-5 col-11">
                        <RevealWhenInView delay={0.15}>
                            <h2 className="heading">{content.title}</h2>
                        </RevealWhenInView>
                    </div>
                    <div className="awards-subtitle col-xl-6 col-md-7 col-11 offset-xl-0 offset-md-4 offset-0">
                        <RevealWhenInView delay={0.2}>
                            <p className="u-max-w-480 u-text-indent-md-quarter">{content.subtitle}</p>
                        </RevealWhenInView>
                    </div>
                </div>
                <div className="awards-body row">
                    <div className="awards-body-left col-xxl-5 col-11">
                        <div className="row">
                            <div className="awards-count col-xxl-11 col-xs-4">
                                <RevealWhenInView delay={0.2}>
                                    <div className="number">{content.awardsCount}</div>
                                </RevealWhenInView>
                            </div>
                            <div className="awards-project-lists col-xxl-11 col-xs-7">
                                <div className="list-primary">
                                    <RevealWhenInView delay={0.25}>
                                        <ul>
                                            {content.awardsProjectLists.listPrimary.map((item, index) => (
                                                <li key={index}>{item}</li>
                                            ))}
                                        </ul>
                                    </RevealWhenInView>
                                </div>
                                <div className="list-secondary">
                                    <RevealWhenInView delay={0.3}>
                                        <p dangerouslySetInnerHTML={{__html: content.awardsProjectLists.listSecondary}}/>
                                    </RevealWhenInView>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="awards-body-right col-xxl-5 col-11">
                        <div className="awards-list">
                            {content.awardsList.map((awards:{
                                "imgSrc" : string,
                                "name": string,
                                "count": string
                            }, index) => (
                                <RevealWhenInView delay={0.25} key={index}>
                                    <div className="awards-item">
                                        <img className="awards-img" src={awards.imgSrc} width="210" height="210"/>
                                        <p dangerouslySetInnerHTML={{__html: awards.name}}/>
                                        <span>{awards.count}</span>
                                    </div>
                                </RevealWhenInView>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </DynamicBackgroundTrigger>
    )
}

export default AwardsSection