import React, { Ref, forwardRef, DOMAttributes } from 'react'
import classNames from 'classnames';
import { isArray, isString } from 'lodash';


interface ImageSizesItem {
  media?: string;
  value: string;
}

interface ImageSrcSetItem {
  src: string;
  size?: string;
}

type ImageSizes = string | (string | [string, string] | ImageSizesItem)[];
type ImageSrcSet = string | (string | [string, string] | ImageSrcSetItem)[];

interface ImageSourcesItem {
  type?: "webp" | "png" | "jpg";
  media?: string;
  srcSet: ImageSrcSet
  sizes?: ImageSizes
}

interface ImageProps extends Omit<DOMAttributes<ImageRef>, "children"> {
  sources?: ImageSourcesItem[]
  className?: string;
  alt?: string;
  src: string;
  sizes?: ImageSizes;
  srcSet?: ImageSrcSet;
  width?: number | "auto";
  height?: number;
  cover?: boolean;
}

export interface ImageConfig extends Pick<ImageProps, "alt" | "src" | "srcSet" | "sources"> {

}


type ImageRef = HTMLImageElement;

const isImageSizesItem = (obj: any): obj is ImageSizesItem => "value" in obj && isString(obj["value"]);

const normalizeImageSizesOrSrcSet = (imageSizesOrSrcSet: ImageSizes | ImageSrcSet): string => {
  if (isString(imageSizesOrSrcSet)) {
    return imageSizesOrSrcSet;
  }

  const sizes = (imageSizesOrSrcSet as (string | [string, string] | ImageSrcSetItem | ImageSizesItem)[]).map((sizeOrSrcSetItem) => {
    if (isString(sizeOrSrcSetItem)) {
      return sizeOrSrcSetItem;
    } else if (isArray(sizeOrSrcSetItem)) {
      return sizeOrSrcSetItem.join(" ");
    } else if (isImageSizesItem(sizeOrSrcSetItem)) {
      const { media, value } = sizeOrSrcSetItem;

      return media ? `${media} ${value}` : value
    } else {
      const { size, src } = sizeOrSrcSetItem;

      return size ? `${src} ${size}` : src
    }
  });

  return sizes.join(", ");
}

const Image = ({ alt = "", className, src, sizes, srcSet, sources, width, height, cover, ...otherProps }: ImageProps, ref: Ref<ImageRef>) => {
  const image = <img
    {...otherProps}
    className={classNames("image", {
      "image--cover": cover,
      "image--width-auto": width === "auto"
    }, className)}
    ref={ref}
    alt={alt}
    src={src}
    width={typeof width === "number" ? width : undefined}
    height={height}
    srcSet={srcSet && normalizeImageSizesOrSrcSet(srcSet)}
    sizes={sizes && normalizeImageSizesOrSrcSet(sizes)}
  />;

  return sources ? (<picture>
    {sources.map(({ srcSet, sizes, type, media }, i) => (
      <source
        key={i}
        srcSet={normalizeImageSizesOrSrcSet(srcSet)}
        sizes={sizes && normalizeImageSizesOrSrcSet(sizes)}
        type={type && `image/${type}`}
        media={media}
      />
    ))}
    {image}
  </picture>) : image
}

export default forwardRef<ImageRef, ImageProps>(Image)
