import { forwardRef, ReactNode, Ref, useEffect, useRef } from "react";
import gsap from "gsap";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";

export interface PrivacyPolicyChapterProps {
  name: string;
  children: ReactNode;
}

export type PrivacyPolicyChapterRef = HTMLLIElement;

const PrivacyPolicyChapter = (
  { name, children }: PrivacyPolicyChapterProps,
  ref: Ref<PrivacyPolicyChapterRef>
) => {
  const bodyElRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const bodyEl = bodyElRef.current!;

    const contentElms = gsap.utils.toArray<HTMLElement>(
      bodyEl.querySelectorAll("p, li")
    );

    gsap.from(contentElms, {
      y: 40,
      opacity: 0,
      scrollTrigger: {
        trigger: bodyEl,
        toggleActions: "play reset play reset",
      },
      stagger: 0.15,
      ease: "ease-out",
      duration: 0.6,
    });
    // contentElms.forEach((contentEl) => {
    //   gsap.from(contentEl, {
    //     y: 40,
    //     opacity: 0,
    //     scrollTrigger: {
    //       trigger: contentEl,
    //       toggleActions: "play reset play reset",
    //     },
    //     ease: "ease-out",
    //     duration: 0.6,
    //   });
    // });

    // gsap.to(headerEl, {

    // })

    // gsap.timeline({});
  }, []);

  return (
    <li ref={ref} className="privacy-policy__chapter space--header">
      <div className="privacy-policy__chapter-header">
        <RevealWhenInView>
          <h2 className="privacy-policy__chapter-heading heading">{name}</h2>
        </RevealWhenInView>
      </div>

      <div ref={bodyElRef} className="privacy-policy__chapter-body">
        <div className="row">
          <div className="col-11 col-lg offset-lg-3 offset-xl-4 offset-xl-5">
            {children}
          </div>
        </div>
      </div>
    </li>
  );
};

export default forwardRef<PrivacyPolicyChapterRef, PrivacyPolicyChapterProps>(
  PrivacyPolicyChapter
);
