import { useRef } from "react";
import { StageItems } from "./StageItems";
import s from "./index.module.scss";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";

const sectionData = {
  title: "ЭТАПЫ РАБОТЫ",
  description:
    "Настраиваем рабочий процесс для комфортного взаимодействия с командой поддержки",
  items: [
    {
      tickerText: "ПЕРВЫЙ ЭТАП",
      subtitle: "включаемся в работу",
      max_step_number: 6,
      items: [
        {
          title: "Брифинг",
          subtitle: "",
          step_number: 1,
          text: "начальный этап, на котором мы встречаемся с вами, чтобы понять ваши потребности и ожидания от поддержки. Мы обсуждаем основные задачи и цели проекта",
        },
        {
          title: "Подключение",
          subtitle: "к таск-мессенджеру",
          step_number: 2,
          text: "Для удобства и прозрачности сотрудничества мы предоставляем доступ к таск-трекерам, где вы можете отслеживать весь процесс работы или мы интегрируемся в ваши корпоративные системы управления задачами и проектами",
        },
        {
          title: "Перенос",
          subtitle: "от прошлого подрядчика",
          step_number: 3,
          text: "Запрашиваем все необходимые данные от предыдущего подрядчика: доступы, документацию и инструкции",
        },
        {
          title: "Ознакомление",
          subtitle: "проектом",
          step_number: 4,
          text: "Разворачиваем локальные копии, настраиваем системы контроля версий, проводит аудит текущего состояния проект, формируем бэклог задач",
        },
      ],
    },
    {
      tickerText: "второй этап",
      subtitle: "работа над проектом",
      max_step_number: 6,
      items: [
        {
          title: "Обсуждение",
          subtitle: "процесса",
          step_number: 5,
          text: "Мы обсуждаем с вами план действий, задачи, которые необходимо решить быстро сейчас и согласования долгосрочного плана работ по развитию всего проекта",
        },
        {
          title: "Работа",
          subtitle: "по спринтам и дедлайнам",
          step_number: 6,
          text: "Мы разбиваем задачи на спринты с чёткими сроками выполнения, что позволяет управлять временем и ресурсами более эффективно",
        },
      ],
    },
  ],
};

interface ViewDeveloperSitesStagesSectionProps {}

const ViewDeveloperSitesStagesSection = (
  props: ViewDeveloperSitesStagesSectionProps
) => {
  const triggerRef = useRef<HTMLDivElement>(null);

  return (
    <DynamicBackgroundTrigger>
      <section
        ref={triggerRef}
        className={
          " section text--light section--with-scroll-appear-background background--dark-blue " +
          s["view-developer-sites-stages-section"]
        }
      >
        <div className="section-container container">
          <div className="view-developer-sites-stages-section__inner">
            <header
              className={
                "row " + s["view-developer-sites-stages-section__header"]
              }
            >
              <div className="col-11 col-xl-7 heading">
                <RevealWhenInView>
                  <h2 className="heading">{sectionData.title}</h2>
                </RevealWhenInView>
              </div>
              <div className="col-md-7 offset-md-4 offset-xl-0 col-xl-4">
                <RevealWhenInView>
                  <p className="text--indent-md-quarter">
                    {sectionData.description}
                  </p>
                </RevealWhenInView>
              </div>
            </header>
          </div>
        </div>
        <StageItems {...sectionData.items[0]} />
        <StageItems {...sectionData.items[1]} />
      </section>
    </DynamicBackgroundTrigger>
  );
};

export default ViewDeveloperSitesStagesSection;
