import ViewPortfolioAtlantic from 'components/views/ViewPortfolioItem/ViewPortfolioAtlantic';
import { PortfolioItemDataWithComponent } from 'data/portfolio/types';

const porfolioItem: PortfolioItemDataWithComponent = {
  id: 'atlantic',
  component: ViewPortfolioAtlantic,
  title: 'atlanticEn',
  name: 'Atlantica',
  altName: 'Medical Rehabilitation Center',
  year: 2019,
  text: 'rca-med.ru',

  socials: [
    {
      label: 'behance',
      href: 'https://www.behance.net/gallery/76559231/mir-zdorovja-medicinskij-centr',
    },
  ],

  preview: {
    label: 'corporate website, e-commerce',
    media: {
      type: 'video',
      src: 'https://vimeo.com/542707424',
    },
  },

  poolOfWorks: [
    'UX|UI design',
    'Page making',
    'Integration with CMS',
    'Version for the visually impaired',
  ],

  type: 'site',
  subtype: 'site',

  description: [
    'medical rehabilitation center in Rostov-on-Don, specializing in balneology.',
    'Our task was to develop a website with a user-friendly interface and original design, reflecting the main directions of the clinic',
  ],
  shortDescription:
    'website for the Moscow architectural and construction company',
  seo: {
    title: 'Website development for the Atlantica Medical Center',
    description:
      'Website development for the Atlantica Rehabilitation Medical Center, includes versions for tablets and smartphones. The site was developed taking into account all the requirements of the Ministry of Health and has a version for the visually impaired with flexible settings',
    keywords: [
      'website for a medical center',
      'creating a website for the clinic',
      'website according to the standards of the Ministry of Health',
      'creation of a site for a medical center',
      'medical website',
      'creation of a website for a rehabilitation center',
    ],
    openGraph: {
      type: 'article',
      title: 'Website development for the Atlantica Medical Center',
      description:
        'Website development for the Atlantica Rehabilitation Medical Center, includes versions for tablets and smartphones. The site was developed taking into account all the requirements of the Ministry of Health and has a version for the visually impaired with flexible settings',
    },
  },
};

export default porfolioItem;
