import { PortfolioItemDataWithComponent } from "data/portfolio/types";
import ViewPortfolioAist from "../../components/views/ViewPortfolioItem/ViewPortfolioAist";
import {buildPublicImageSrc} from "../../helpers/path";

const porfolioItem: PortfolioItemDataWithComponent = {
    id: "aist",
    component: ViewPortfolioAist,
    title: "aist",
    name: "АИСТ24",
    altName: "Automated \n  information system \n transport",
    year: 2023,
    type: "design",
    subtype: "portal",

    preview: {
        label: "portal",
        media: {
            type: 'image',
            fromSrc: buildPublicImageSrc({
                path: '',
                file: '',
            }),
            toSrc: buildPublicImageSrc({
                path: '',
                file: '',
            }),
        },
    },

    socials: [
        {
            label: "behance",
            href: "https://www.behance.net/gallery/169647361/UX-case-Aist24",
        },
    ],

    poolOfWorks: [
        "UX-research",
        "Job-stories",
        "Cjm",
        "Wireframing"
    ],


    description: [
        "Это ІТ-платформа для автоматизации перевозок сельскохозяйственной продукции.",
        "Конечным продуктом данной компании является CRM-сервис с возможностью размещения заказов на бирже от клиента и полным регулированием поездок водителей по заявкам."
    ],
    shortDescription: "Разработка сайта для необанка из Великобритании",
    seo: {
        title:
            "Development of an online logistics service for the transportation of agricultural cargo",
        description:
            "Case study on the development of an online service for the logistics company AIST24. The Sagirov.com studio designed a web application to automate agricultural cargo transportation, increasing productivity and profits. The optimization focused on business results, user convenience, and conversion growth",
        keywords: [
            "development of online services for logistics",
            "web solutions for business",
            "automation of logistics processes",
            "logistics service for agricultural cargo",
            "IT infrastructure optimization",
            "improving business efficiency"
        ],
        openGraph: {
            type: "article",
            title:
                "Development of an online logistics service for the transportation of agricultural cargo",
            description:
                "Case study on the development of an online service for the logistics company AIST24. The Sagirov.com studio designed a web application to automate agricultural cargo transportation, increasing productivity and profits. The optimization focused on business results, user convenience, and conversion growth",
        },
    },
};

export default porfolioItem;
