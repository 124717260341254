import classNames from "classnames";
import { useBodyScrollLocker } from "contexts/BodyScrollLocker";
import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import preloaderSvg from "assets/preloader.svg";
import { useCurrentRoute } from "routes/helpers";
import { notFound } from "routes";

interface PreloaderProps {}

const Preloader = (props: PreloaderProps) => {
  const currentRoute = useCurrentRoute();
  const isNotFoundRoute = currentRoute && currentRoute.id === notFound.id;
  const [playing, setPlaying] = useState(!isNotFoundRoute);
  const [loading, setLoading] = useState(false);
  const { lockScroll, unlockScroll } = useBodyScrollLocker();
  const isActive = playing || loading;

  useEffect(() => {
    if (isActive) {
      lockScroll();
    } else {
      unlockScroll();
    }
  }, [isActive, unlockScroll, lockScroll]);

  const handleObjectLoad: React.ReactEventHandler<HTMLImageElement> =
    useCallback(
      (el) => {

        const animateEl = el.currentTarget;

        if (!animateEl) {
          return;
        }

        lockScroll();

        const handleEnd = () => {
          unlockScroll();
          setPlaying(false);
        };

        window.addEventListener("load", (event) => {
          handleEnd()
        });

        setTimeout(()=>{
          handleEnd()
        }, 1000)

      },
      [lockScroll, unlockScroll, isNotFoundRoute]
    );

  return (
    <div
      className={classNames("preloader", {
        "is-active": !isNotFoundRoute && isActive,
      })}
    >
      <img
        alt=" "
        className={classNames("preloader__svg", {
          "is-blinking": !playing && loading,
        })}
        width={107}
        height={120}
        src={preloaderSvg}
        onLoad={handleObjectLoad}
      />
    </div>
  );
};

export default Preloader;
