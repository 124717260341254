import ViewPortfolioSk10Site from "components/views/ViewPortfolioItem/ViewPortfolioSk10Site/";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";

const porfolioItem: PortfolioItemDataWithComponent = {
  id: "sk-10-site",
  component: ViewPortfolioSk10Site,
  title: "sk10",
  name: "sk-10",
  altName: "Ростовская строительная компания",
  year: '2023 - 2024',
  awardsList: [],

  link: {
    label: "sk10.ru",
    href: "https://sk10.ru/",
  },

  socials: [
    {
      label: "behance",
      href: "https://www.behance.net/gallery/193024329/SK10-Construction-company-Real-estate-developer",
    },
  ],

  preview: {
    label: "корпоративный сайт",
    backgroundColor: "#1F1F21",
    media: {
      type: "video",
      src: "https://vimeo.com/996100103/c60bc20173",
    },
  },

  poolOfWorks: ["Research", 'UX|UI design', "refinements-of-the-design-system"],

  type: 'site',
  subtype: 'site',

  description: [
    "российская компания, занимающаяся строительством многоэтажных жилых комплексов. Нам необходимо было доработать дизайн сайта и создать новые разделы для него.",
  ],
  shortDescription: "сайт для Ростовской строительной компании",

  seo: {
    title:
        "Дизайн и поддержка сайта для застройщика №1 СК10 | Кейс студии SAGIROV.com",
    description:
        "Техническое обслуживание и дизайн поддержка сайта для девелопера СК10. Полное решение для презентации жилых комплексов, повышения конверсии и улучшения пользовательского опыта, реализация функционала оформления онлайн ипотеки и покупки квартиры через сайт",
    keywords: [
      "сайт для девелопера",
      "кейс СК10",
      "сайты для застройщиков",
      "веб-дизайн жилые комплексы",
      "сайт строительной компании",
    ],
    openGraph: {
      type: "article",
      title:
          "Дизайн и поддержка сайта для застройщика №1 СК10 | Кейс студии SAGIROV.com",
      description:
          "Техническое обслуживание и дизайн поддержка сайта для девелопера СК10. Полное решение для презентации жилых комплексов, повышения конверсии и улучшения пользовательского опыта, реализация функционала оформления онлайн ипотеки и покупки квартиры через сайт",
    },
  },
};

export default porfolioItem;
