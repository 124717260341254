import { useEffect, useMemo, useRef } from "react";

import gsap from "gsap";


import ViewPortfolioItemSection, {
  ViewPortfolioItemSectionConfigProps,
} from "./ViewPortfolioItemSection";

import FlipBook from "components/common/FlipBook";
import RevealTextByLine from "components/common/RevealInView/RevealTextByLine";

import { ReactComponent as WordMobile } from "assets/images/word-mobile.svg";
import { ReactComponent as WordVersion } from "assets/images/word-version.svg";
import { ReactComponent as WordMobileVertical } from "assets/images/word-mobile-md.svg";
import { ReactComponent as WordVersionVertical } from "assets/images/word-version-md.svg";

import { ReactComponent as WordMobileEn } from "assets/images/word-mobile-en.svg";
import { ReactComponent as WordVersionEn } from "assets/images/word-version-en.svg";
import { ReactComponent as WordMobileVerticalEn } from "assets/images/word-mobile-md-en.svg";
import { ReactComponent as WordVersionVerticalEn } from "assets/images/word-version-md-en.svg";

import { PortfolioItemData } from "data/portfolio/types";
import { usePortfolioItemAssets } from "hooks/useAssets";
import {useTranslation} from "react-i18next";
import i18n from "i18next";

interface ViewPortfolioItemMobileVersionSectionProps
  extends ViewPortfolioItemSectionConfigProps {
  portfolioId: PortfolioItemData["id"]
  frameCount: number;
}

const ViewPortfolioItemMobileVersionSection = ({
  portfolioId,
  frameCount,
  ...sectionProps
}: ViewPortfolioItemMobileVersionSectionProps) => {
  const { buildSrc } = usePortfolioItemAssets(portfolioId)
  const headerElRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const titleElRef = useRef<HTMLDivElement>(null);
  const titleFirstWordElRef = useRef<HTMLDivElement>(null);
  const titleSecondWordElRef = useRef<HTMLDivElement>(null);

  const flipBookFrames = useMemo(() => Array.from({ length: frameCount - 1 }, (_, i) => {
    return buildSrc({ name: i.toString().padStart(3, "0"), ext: "webp" }, ["page", "mobile-version"]);
  }), [frameCount, buildSrc]);

  useEffect(() => {
    const headerEl = headerElRef.current!;
    const titleEl = titleElRef.current!;
    const titleFirstWordEl = titleFirstWordElRef.current!;
    const canPlay = matchMedia("(min-width: 768px)").matches

    let timeline: gsap.core.Timeline;

    if (canPlay) {
      timeline = gsap.timeline({
        scrollTrigger: {
          trigger: headerEl,
          start: "top top",
          end: "bottom bottom",
          scrub: true,
          pin: titleEl
        }
      });

      timeline.to(titleFirstWordEl, {
        xPercent: -30,
        duration: 0.7,
      });

      timeline.to(titleFirstWordEl, {
        delay: 0.2,
        xPercent: 0,
      });

      timeline.to(headerEl, {
        opacity: 0
      }, "<");
    }

    return () => {
      if (timeline) {
        timeline.kill();
      }
    }
  }, []);

  const  langCode = useMemo(() => {
    return i18n.language;
  }, []);

  return (
    <ViewPortfolioItemSection
      {...sectionProps}
      className="view-portfolio-item-mobile-version-section"
    >
      <header ref={headerElRef} className="view-portfolio-item-mobile-version-section__header">
        <div ref={titleElRef} className="view-portfolio-item-mobile-version-section__title">
          <div ref={titleFirstWordElRef} className="view-portfolio-item-mobile-version-section__title-first-word">
            { langCode === 'ru' ? (
              <WordMobile className="view-portfolio-item-mobile-version-section__title-svg" />
            ) : (
              <WordMobileEn className="view-portfolio-item-mobile-version-section__title-svg" />
            )}
            { langCode === 'ru' ? (
              <WordMobileVertical className="view-portfolio-item-mobile-version-section__title-svg--md" />
            ) : (
              <WordMobileVerticalEn className="view-portfolio-item-mobile-version-section__title-svg--md" />
            )}

          </div>
          <div ref={titleSecondWordElRef} className="view-portfolio-item-mobile-version-section__title-second-word">
            { langCode === 'ru' ? (
              <WordVersion className="view-portfolio-item-mobile-version-section__title-svg"/>
            ) : (
              <WordVersionEn className="view-portfolio-item-mobile-version-section__title-svg"/>
            )}
            { langCode === 'ru' ? (
              <WordVersionVertical className="view-portfolio-item-mobile-version-section__title-svg--md"/>
            ) : (
              <WordVersionVerticalEn className="view-portfolio-item-mobile-version-section__title-svg--md"/>
            )}
          </div>
        </div>
      </header>

      <div className="view-portfolio-item-mobile-version-section__container container">
        <div className="view-portfolio-item-mobile-version-section__preview-and-description row">
          <div className="view-portfolio-item-mobile-version-section__col-preview col-11 col-md-6 offset-md-1 col-lg-4">
            <FlipBook framesPerScreen={50} frames={flipBookFrames} contain clearBeforeDraw />
          </div>
          <div className="view-portfolio-item-mobile-version-section__col-description col-11 col-md-7 offset-md-2 offset-lg-0 col-lg-4 u-max-w-lg-480">
            <RevealTextByLine>
              {t('Adaptive mobile')}
            </RevealTextByLine>
          </div>
        </div>
      </div>
    </ViewPortfolioItemSection>
  );
};

export default ViewPortfolioItemMobileVersionSection;
