import { useEffect, useRef } from "react";
import gsap from "gsap";
import ScrollDownButton from "components/layouts/ScrollDownButton";
import Section from "components/layouts/Section";

interface ViewPrivacyPolicyLeadSectionProps { }

const ViewPrivacyPolicyLeadSection = (
  props: ViewPrivacyPolicyLeadSectionProps
) => {
  const titleElRef = useRef<HTMLHeadingElement>(null);
  const yearElRef = useRef<HTMLDivElement>(null);
  const footnoteElRef = useRef<HTMLParagraphElement>(null);
  const arrowElRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const titleEl = titleElRef.current!;
    const yearEl = yearElRef.current!;
    const footnoteEl = footnoteElRef.current!;
    const arrowEl = arrowElRef.current!;

    const duration = 0.6;
    const stagger = duration - 0.15;

    const timeline = gsap.timeline({ delay: 0.6 });

    const showTitleTween = gsap.from(titleEl, {
      y: 20,
      opacity: 0,
      ease: "ease-out",
      duration,
    });

    const showYearTween = gsap.from(yearEl, {
      y: 20,
      opacity: 0,
      ease: "ease-out",
      duration,
    });

    const showFootnoteTween = gsap.from(footnoteEl, {
      y: 20,
      opacity: 0,
      ease: "ease-out",
      duration,
    });

    const showArrowTween = gsap.from(arrowEl, {
      y: -20,
      opacity: 0,
      ease: "ease-out",
      duration,
    });

    const isTablet = window.matchMedia("(min-width: 768px)").matches;

    timeline.add(showTitleTween);
    timeline.add(showYearTween, isTablet ? "<" : `-=${stagger}`);
    timeline.add([showFootnoteTween, showArrowTween], `-=${stagger}`);

    return () => {
      timeline.kill();
    };
  }, []);

  return (
    <Section viewportHeight isLead className="view-privacy-policy-lead-section">
      <div className="view-privacy-policy-lead-section__container lead-section__container container">
        <div className="view-privacy-policy-lead-section__inner lead-section__inner">
          <div className="view-privacy-policy-lead-section__header lead-section__header row">
            <div className="view-privacy-policy-lead-section__header-col-left col-11 col-md-9">
              <h1
                ref={titleElRef}
                className="view-privacy-policy-lead-section__title title"
              >
                Политика Конфиден—циальности
              </h1>
            </div>

            <div
              ref={yearElRef}
              className="view-privacy-policy-lead-section__header-col-right col-auto offset-auto"
            >
              <span className="view-privacy-policy-lead-section__title title title--indent-fix-off">
                <span className="side-brackets label">2021</span>
              </span>
            </div>
          </div>

          <div className="view-privacy-policy-lead-section__footer row">
            <div className="view-privacy-policy-lead-section__footer-left col">
              <p
                ref={footnoteElRef}
                className="lead-section__footnote footnote"
              >
                Политика в отношении обработки
                <br />
                персональных данных
              </p>
            </div>
            <div
              ref={arrowElRef}
              className="view-privacy-policy-lead-section__footer-right col-auto"
            >
              <ScrollDownButton scrollTo=".view-privacy-policy-main-section " />
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default ViewPrivacyPolicyLeadSection;
