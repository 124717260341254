import ViewPortfolioCsd from "components/views/ViewPortfolioItem/ViewPortfolioCsd";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";

const porfolioItem: PortfolioItemDataWithComponent = {
    id: "csd",
    component: ViewPortfolioCsd,
    title: "csd",
    name: "CSD",
    altName: "Одна из ведущих \n компаний-дистрибьюторов сапра",
    year: 2022,
    awardsList: [
        {imgSrc : "/images/awards/gray-bg/awwwards.png"},
        {imgSrc : "/images/awards/gray-bg/special-kudos.png"},
        {imgSrc : "/images/awards/gray-bg/ux.png"},
        {imgSrc : "/images/awards/gray-bg/ui.png"},
        {imgSrc : "/images/awards/gray-bg/innovation.png"},
    ],
    vc: {
      label: 'vc.ru',
      href: 'https://vc.ru/design/501074-bez-chetkogo-tz-rezultat-nagrada-na-awwwards-ili-instrukciya-kak-razrabotat-dizayn-infoportala-po-bim',
    },
    link: {
        label: "csd.sagirov.com",
        href: "https://csd.sagirov.com/ru/",
    },
    type: "site",
    subtype: "portal",

    preview: {
        label: "корпоративный сайт",
        backgroundColor: "#1F1F21",
        media: {
            type: "video",
            src: "https://vimeo.com/745422344?h=84960c2055",
        },
    },

    socials: [
        {
            label: "behance",
            href:
                "https://www.behance.net/gallery/136473287/WEBSITE-CSD-BIM-3D-construction-modeling",
        },
    ],

    poolOfWorks: [
        "UX|UI design",
        "Animation",
        "Page making"
    ],


    description:
        [
            "ведущий дистрибьютор в\u00A0области систем автоматизированного проектирования, а\u00A0также мониторинга строительства объектов",
            "Мы\u00A0создали сайт, который отражает весь спектр услуг и\u00A0программных продуктов, предоставляемых компанией"

        ],
    shortDescription:
        "Разработка информационного портала по BIM технологиям",
    seo: {
        title: "Разработка информационного портала по BIM технологиям",
        description: "Создание сайта информационного портала по BIM проектированию и применению программного обеспечения Autodesk с описанием подробных примеров, вебинарами и статьями",
        keywords: ["разработка сайта, создание информационного сайта, бим проектирование, бим технологии, разработка сайта bim, сайт по bim моделированию, пример сайта по проектированию, сайт компании csd"],
        openGraph: {
            type: "article",
            title: "Разработка информационного портала по BIM технологиям",
            description: "Создание сайта информационного портала по BIM проектированию и применению программного обеспечения Autodesk с описанием подробных примеров, вебинарами и статьями",
        }
    },
    pageContent: {
        sections: {
            mobileVersion: {
                theme: {
                    color: "#fff", backgroundColor: "#0242EC"
                },
                frameCount: 161
            }
        }
    }
};

export default porfolioItem;
