import ViewPortfolioIcr from 'components/views/ViewPortfolioItem/ViewPortfolioIcr';
import { PortfolioItemDataWithComponent } from "data/portfolio/types";
import {buildPublicImageSrc} from "../../helpers/path";


const porfolioItem: PortfolioItemDataWithComponent = {
  id: "icr",
  component: ViewPortfolioIcr,
  title: "icr",
  name: "ICR",
  altName: "промышленная\nстроительная компания",
  year: 2021,
  awardsList: [
    {imgSrc : "/images/awards/DP-branding.svg"},
  ],
  link: {
    label: "",
    href: "",
  },

  socials: [
    {
      label: "",
      href:
        "",
    },
  ],

  preview: {
    label: "фирменный стиль",
    media: {
      type: "image",
      fromSrc: buildPublicImageSrc({
        path: "portfolios/ensemble/preview",
        file: "1-1440w.webp",
      }),
      toSrc: buildPublicImageSrc({
        path: "portfolios/ensemble/preview",
        file: "2-1440w.webp",
      }),
    },
  },

  poolOfWorks: ["Logotype", "Brand style"],



  type: "branding",
  subtype: "branding",

  description: [
    "компания, предоставляющая услуги по проектированию и строительству промышленных объектов.",
    "Лаконичный и сдержанный дизайн логотипа отражает подход компании к работе, дополняя общую концепцию высоких стандартов качества."
  ],
  shortDescription:
    "брендинг для промышленной строительной компании",
  seo: {
    title: "Брендинг для промышленной строительной компании ICR",
    description: "Разработка брендинга и элементов фирменного стиля (логотип, визитки, бланки, папки, одежды и формы сотрудников) с нуля для промышленной строительной компании ICR",
    keywords: ["фирменный стиль промышленной строительной компании",
      "логотип промышленной строительной компании",
      "дизайн визиток",
      "дизайн одежды",
      "брендирование зданий складов",
      "дизайн папки для документов",
      "дизайн фирменного бланка",
      "логотип для ICR",
      "industrial construction rus"],
    openGraph: {
      "type": "article",
      "title": "Брендинг для промышленной строительной компании ICR",
      "description": "Разработка брендинга и элементов фирменного стиля (логотип, визитки, бланки, папки, одежды и формы сотрудников) с нуля для промышленной строительной компании ICR"
    }
  }

};

export default porfolioItem;
