import { Image } from "types/global";
import ViewPortfolioItemImage from "../ViewPortfolioItemImage";

import ViewPortfolioItemSection, {
  ViewPortfolioItemSectionConfigProps,
} from "../ViewPortfolioItemSection";

export interface ViewPortfolioWomanSportSiteMainPagesSectionProps
  extends ViewPortfolioItemSectionConfigProps {
  images: Image[];
}

const ViewPortfolioWomanSportSiteMainPagesSection = ({
  images,
  ...sectionProps
}: ViewPortfolioWomanSportSiteMainPagesSectionProps) => {
  return (
    <ViewPortfolioItemSection
      {...sectionProps}
      className="view-portfolio-item-section view-portfolio-item-section--layout-gallery-wss-4"
    >
      <div className="view-portfolio-item-section__container container">
        <div className="view-portfolio-item-section__body view-portfolio-item-section__row row">
          <div className="col-11 offset-md-2 col-md-8">
            <ViewPortfolioItemImage {...images[0]} />
          </div>
          <div className="col-11">
            <div className="view-portfolio-item-section__row row">
              <div className="col offset-md-1 col-md-4">
                <ViewPortfolioItemImage {...images[1]} />
              </div>
              <div className="col col-md-4">
                <ViewPortfolioItemImage {...images[2]} />
              </div>
            </div>
          </div>
          <div className="col-11">
            <div className="view-portfolio-item-section__row row">
              <div className="col offset-md-3 col-md-4">
                <ViewPortfolioItemImage {...images[3]} />
              </div>
              <div className="col col-md-4">
                <ViewPortfolioItemImage {...images[4]} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ViewPortfolioItemSection>
  );
};

export default ViewPortfolioWomanSportSiteMainPagesSection;
