import { CursorType } from "./Cursor";

const cursorPath: {
  [key in CursorType]?: {
    from: string, to?: string
  } | undefined
} = {
  // "default": {
  //   from: "M 12.5 9.6428 L 27.5 9.6428 L 27.5 13.5103 L 27.5 15.1261 L 27.5 15.1261 L 27.5 19.1597 L 27.5 19.1597 L 27.5 20.7758 L 27.5 24.6428 L 12.5 24.6428 Z",
  // },
  "arrow-left": {
    from: "M 12.5 9.6428 L 27.5 9.6428 L 27.5 13.5103 L 27.5 15.1261 L 27.5 15.1261 L 27.5 19.1597 L 27.5 19.1597 L 27.5 20.7758 L 27.5 24.6428 L 12.5 24.6428 Z",
    to: "M 0 17.1429 L 17 0 L 19.6667 2.8011 L 7.4444 15.1261 L 40 15.1261 L 40 19.1597 L 7.4444 19.1597 L 19.6667 31.5406 L 17 34.2857 L 0 17.1429 Z"
  },
  "arrow-right": {
    from: "M 27.5 9.6428 L 12.5 9.6428 L 12.5 13.51 L 12.5 15.1261 L 12.5 15.1261 L 12.5 19.1597 L 12.5 19.1597 L 12.5 20.7758 L 12.5 24.6428 L 27.5 24.6428 Z",
    to: "M 40 17.1429 L 23 0 L 20.3333 2.8011 L 32.5556 15.1261 L 0 15.1261 L 0 19.1597 L 32.5556 19.1597 L 20.3333 31.5406 L 23 34.2857 L 40 17.1429 Z"
  },
  cross: {
    from: "M 25.5 9.6428 L 27.5 9.6428 L 27.5 11.6428 L 27.5 17.1428 L 27.5 22.6428 L 27.5 24.6428 L 25.6 24.6428 L 20 24.6428 L 14.5 24.6428 L 12.5 24.6428 L 12.5 22.6428 L 12.5 17.1428 L 12.5 11.6428 L 12.5 9.6428 L 14.5 9.6428 L 20 9.6428 L 25.5 9.6428 Z",
    to: "M 32.728 1.5865 L 34.1422 3.0008 L 35.5564 4.415 L 22.8285 17.1429 L 35.5564 29.8709 L 34.1422 31.285 L 32.728 32.6992 L 20 19.9713 L 7.2721 32.6992 L 5.8579 31.285 L 4.4437 29.8709 L 17.1716 17.1429 L 4.4437 4.415 L 5.8579 3.0008 L 7.2721 1.5865 L 20 14.3145 L 32.728 1.5865 Z"
  }
}

export default cursorPath;