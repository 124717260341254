import { memo, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { debounce } from "debounce";
import gsap from "gsap";

interface TickerProps {
  /**
   * Текст бегущей строки
   */
  text: string | string[];
  /**
   * Продолжительность
   *
   * @default 10
   */
  duration?: number;
  /**
   * Задает то как будет рендериться текст
   */
  renderTextItem?: (text: string, i: number) => JSX.Element;

  className?: string;
  innerClassName?: string;
}

const inArray = <T,>(item: T | T[]): T[] =>
  Array.isArray(item) ? item : [item];
/**
 * Создает бегущую строку
 */
const Ticker = ({
  text,
  duration = 10,

  renderTextItem,

  className,
  innerClassName,
}: TickerProps) => {
  const tickerRef = useRef<HTMLDivElement>(null);
  const tickerInnerRef = useRef<HTMLDivElement>(null);
  const tickerTextRef = useRef<HTMLDivElement>(null);

  const [tickerText, setTickerText] = useState<string[] | null>(null);

  useEffect(() => {
    setTickerText(inArray(text));
  }, [text, renderTextItem]);

  useEffect(() => {
    if (
      tickerText === null ||
      tickerText.length === 0 ||
      tickerText.join("") === ""
    ) {
      return;
    }

    const tickerEl = tickerRef.current!;
    const tickerInnerEl = tickerInnerRef.current!;
    const tickerTextEl = tickerTextRef.current!;

    const timeline = gsap.timeline({ repeat: -1 });

    timeline.fromTo(
      tickerInnerEl,
      {
        x: 0,
      },
      {
        ease: "linear",
        duration: duration,
        x: "-100%",
      }
    );

    const updateTickerText = () => {
      if (tickerEl.offsetWidth > tickerTextEl.offsetWidth) {
        // Находим кол-во повторений текста, чтобы полностью заполнить экран по ширине
        const repeatCount = Math.ceil(
          tickerEl.offsetWidth / tickerTextEl.offsetWidth
        );
        // Создаем новый массив с текстом
        const newTickerText = Array.from({ length: repeatCount }, () => [
          ...tickerText,
        ]).reduce((acc, cur) => [...acc, ...cur], []);

        setTickerText(newTickerText);
      }
    };

    const handleWindowResize = debounce(() => {
      updateTickerText();
    }, 200);

    updateTickerText();

    window.addEventListener("resize", handleWindowResize);

    return () => {
      timeline.kill();

      window.removeEventListener("resize", handleWindowResize);
    };
  }, [duration, tickerText]);

  const textItems = useMemo(() => {
    return (tickerText || inArray(text)).map((tickerTextItem, i) => {
      return (
        <div className="ticker__text-item" key={i}>
          {renderTextItem ? renderTextItem(tickerTextItem, i) : tickerTextItem}
        </div>
      );
    })
  }, [renderTextItem, text, tickerText]);

  return (
    <div className={classNames("ticker", className)} ref={tickerRef}>
      <div
        className={classNames("ticker__inner", innerClassName)}
        ref={tickerInnerRef}
      >
        <div className="ticker__text" ref={tickerTextRef}>
          {textItems}
        </div>

        <div className="ticker__text ticker__text--duplicate">{textItems}</div>
      </div>
    </div>
  );
};

export default memo(Ticker);
