import ViewPortfolioWildberries from 'components/views/ViewPortfolioItem/ViewPortfolioWildberries';
import { PortfolioItemDataWithComponent } from 'data/portfolio/types';
import { buildPublicImageSrc } from '../../helpers/path';

const porfolioItem: PortfolioItemDataWithComponent = {
  id: 'wildberries',
  component: ViewPortfolioWildberries,
  title: 'wildberriesEn',
  name: 'wildberries',
  altName: 'international marketplace',
  year: 2023,
  awardsList: [],

  link: {
    label: 'wildberries.ru',
    href: 'https://wildberries.ru/',
  },

  socials: [
    {
      label: 'behance',
      href: 'https://www.behance.net/gallery/159994759/WEBSITE-WILDBERRIES-website-redesign',
    },
  ],

  preview: {
    label: 'corporate identity, mobile app',
    media: {
      type: 'image',
      fromSrc: buildPublicImageSrc({
        path: 'portfolios/wildberries/preview',
        file: '1-1440w.webp',
      }),
      toSrc: buildPublicImageSrc({
        path: 'portfolios/wildberries/preview',
        file: '2-1440w.webp',
      }),
    },
  },

  poolOfWorks: ['UX|UI design', 'Brand style'],

  type: 'branding',
  subtype: 'branding',

  description: [
    'is an international marketplace of goods. Works in Russia, Poland,  Kazakhstan, Armenia, France and other countries',
    'The customer audience of the marketplace for the year is more than 100 million users',
  ],
  shortDescription: 'Brand identity and mobile app redesign',
  seo: {
    title:
      'Brand identity and iOS app design for international ecommerce platform Wildberries',
    description:
      'We have created a corporate identity and design for an iOS mobile app for international ecommerce platform Wildberries. The main task of the redesign was to optimize the application interface to provide a better user experience and increase the conversion of the app',
    keywords: [
      'brand identity',
      'design',
      'mobile app',
      'Wildberries',
      'iOS',
      'ecommerce',
      'interface design',
      'user experience',
      'mobile app design',
      'wildberries brand identity',
      'brand identity',
    ],
    openGraph: {
      type: 'article',
      title:
        'Brand identity and iOS app design for international ecommerce platform Wildberries',
      description:
        'We have created a corporate identity and design for an iOS mobile app for international ecommerce platform Wildberries. The main task of the redesign was to optimize the application interface to provide a better user experience and increase the conversion of the app',
    },
  },
};

export default porfolioItem;
