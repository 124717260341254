import ViewPortfolioIcr from 'components/views/ViewPortfolioItem/ViewPortfolioIcr';
import { PortfolioItemDataWithComponent } from "data/portfolio/types";
import {buildPublicImageSrc} from "../../helpers/path";


const porfolioItem: PortfolioItemDataWithComponent = {
  id: "icr",
  component: ViewPortfolioIcr,
  title: "icr",
  name: "ICR",
  altName: "industrial\nconstruction company",
  year: 2021,
  awardsList: [
    {imgSrc : "/images/awards/DP-branding.svg"},
  ],
  link: {
    label: "",
    href: "",
  },

  socials: [
    {
      label: "",
      href:
        "",
    },
  ],

  preview: {
    label: "brand identity",
    media: {
      type: "image",
      fromSrc: buildPublicImageSrc({
        path: "portfolios/ensemble/preview",
        file: "1-1440w.webp",
      }),
      toSrc: buildPublicImageSrc({
        path: "portfolios/ensemble/preview",
        file: "2-1440w.webp",
      }),
    },
  },

  poolOfWorks: ["Logotype", "Brand style"],



  type: "branding",
  subtype: "branding",

  description: [
    "A company that provides services for the design and construction of industrial facilities.",
    "The laconic and discreet logo design reflects the company's approach to work, complementing the overall concept of high quality standards."
  ],
  shortDescription:
      "brand-identity for industrial construction company",
  seo: {
    title: "Brand-identity for industrial construction company ICR",
    description: "Creation of branding and corporate identity elements (logo, business cards, letterheads, folders, clothes and uniforms of employees) from scratch for an industrial construction company ICR",
    keywords: ["brand identity of industrial construction company",
      "business card design",
      "clothes design",
      "warehouse building branding",
      "folders design",
      "letterhead design",
      "logotype for ICR",
      "industrial construction rus"],
    openGraph: {
      "type": "article",
      "title": "Brand-identity for industrial construction company ICR",
      "description": "Creation of branding and corporate identity elements (logo, business cards, letterheads, folders, clothes and uniforms of employees) from scratch for an industrial construction company ICR"
    }
  }

};

export default porfolioItem;
