import { SeoLang } from "types/global";
import home from "./home.json";
import portfolio from "./portfolio.json";
import services from "./services.json";
import about from "./about.json";
import vacancies from "./vacancies.json";
import contacts from "./contacts.json";
import developerSites from "./developer-sites.json";

const seo: Record<
  | "home"
  | "portfolio"
  | "services"
  | "about"
  | "vacancies"
  | "contacts"
  | "developerSites",
  SeoLang
> = {
  home,
  portfolio,
  services,
  about,
  vacancies,
  contacts,
  developerSites,
};

export default seo;
