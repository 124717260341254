import { ElementType, forwardRef, memo, Ref, useEffect, useImperativeHandle, useRef } from "react";

import { debounce } from "debounce";
import classNames from "classnames";
import FontFaceObserver from "fontfaceobserver";

import { useForceUpdate } from "hooks/useForceUpdate";
import SplitTextInner, { SplitTextInnerProps } from "./SplitTextInner";

export interface SplitTextProps extends SplitTextInnerProps {
  tag?: ElementType<any>;
  className?: string;
}

const SplitText = ({ tag: Tag = "p", className, children, onUpdate, lineClassName, wordClassName }: SplitTextProps, ref: Ref<HTMLElement>) => {
  const [forceUpdate, forceUpdateCounter] = useForceUpdate();
  const splitTextElRef = useRef<HTMLElement | null>(null);

  useImperativeHandle(ref, () => splitTextElRef.current!, []);

  useEffect(() => {
    let splitTextElPrevWidth = splitTextElRef.current!.offsetWidth;

    const handleWindowResize = debounce(() => {
      const splitTextEl = splitTextElRef.current;

      if (!splitTextEl) {
        return;
      }

      const currentSplitTextElPrevWidth = splitTextEl.offsetWidth;

      if (splitTextElPrevWidth !== currentSplitTextElPrevWidth) {
        splitTextElPrevWidth = currentSplitTextElPrevWidth;

        forceUpdate();
      }
    }, 300);

    const timeoutId = setTimeout(handleWindowResize, 0);

    window.addEventListener("resize", handleWindowResize);

    return () => {
      clearTimeout(timeoutId);

      window.removeEventListener("resize", handleWindowResize);
    }
  }, [forceUpdate]);

  useEffect(() => {
    const splitTextEl = splitTextElRef.current!;
    const mainFontFamily = getComputedStyle(splitTextEl).fontFamily.split(",")[0];
    const fontFaceObserver = new FontFaceObserver(mainFontFamily);

    fontFaceObserver.load().finally(() => {
      // setFontFamilyLoaded(true)
      forceUpdate();
    });
  }, [forceUpdate]);


  return( //@ts-ignore
   <Tag ref={splitTextElRef} className={classNames("split-text", className)}>
    <SplitTextInner onUpdate={onUpdate} lineClassName={lineClassName} wordClassName={wordClassName} key={forceUpdateCounter}>
      {children}
    </SplitTextInner>
  </Tag>)
}

export default memo(forwardRef<HTMLElement, SplitTextProps>(SplitText));
