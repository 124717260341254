import React, { useEffect, useRef } from "react";
import {
  PrivacyPolicyChapterProps,
  PrivacyPolicyChapterRef,
} from "./PrivacyPolicyChapter";
import gsap from "gsap";


interface PrivacyPolicyProps {
  children: React.ReactElement<PrivacyPolicyChapterProps>[];
}

const PrivacyPolicy = ({ children }: PrivacyPolicyProps) => {
  const chapterElmsRef = useRef<PrivacyPolicyChapterRef[]>([]);
  const navElRef = useRef<HTMLDivElement>(null);
  const navInnerElRef = useRef<HTMLDivElement>(null);

  const getChapterLinkClickHandler = (index: number) => (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const chapterEl = chapterElmsRef.current[index];

    gsap.to(window, {
      scrollTo: chapterEl,
    });

    e.preventDefault();
  };

  useEffect(() => {
    const navEl = navElRef.current!;
    const navInnerEl = navInnerElRef.current!;
    const navLabelEl = navEl.querySelector(".privacy-policy__nav-label");
    const navItemsElms = navEl.querySelectorAll(".privacy-policy__nav-item");

    const stagger = 0.1;
    const duration = 0.8;
    const ease = "ease-out";

    const timeline = gsap.timeline({
      scrollTrigger: {
        toggleActions: "play reset play reset",
        trigger: navEl,
      },
      delay: 0.2,
      defaults: { ease: "ease-out", duration },
    });

    const showNavTween = gsap.from(navInnerEl, {
      y: 50,
      duration: duration + stagger * (navItemsElms.length - 1),
      ease,
    });

    const showNavLabelTween = gsap.from(navLabelEl, {
      y: 20,
      opacity: 0,
      stagger,
      ease,
    });

    const showNavItemsTween = gsap.from(navItemsElms, {
      y: 20,
      opacity: 0,
      stagger,
      ease,
    });

    timeline.add([showNavTween, showNavLabelTween, showNavItemsTween]);

    return () => {
      // @ts-ignore
      timeline.scrollTrigger.kill();
      timeline.kill();
    };
  }, []);

  return (
    <div className="privacy-policy">
      <nav ref={navElRef} className="privacy-policy__nav">
        <div ref={navInnerElRef} className="row">
          <div className="col-11 col-md-2 col-lg-1 offset-lg-3 offset-xl-4 offset-xl-5">
            <p className="privacy-policy__nav-label label side-brackets">
              Содержание
            </p>
          </div>
          <div className="col offset-1 offset-md-0">
            <ul className="privacy-policy__nav-list list">
              {React.Children.map(children, ({ props }, i) => {
                return (
                  <li key={i} className="privacy-policy__nav-item">
                    <button
                      onClick={getChapterLinkClickHandler(i)}
                      className="privacy-policy__nav-link button label"
                    >
                      <span className="link link--normal">
                        {props.name}

                      </span>
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </nav>
      <ul className="privacy-policy__content list">
        {React.Children.map(children, (child, i) => {
          return React.cloneElement(child, {
            ...child.props,
            //@ts-ignore
            ref: (el) => (chapterElmsRef.current[i] = el),
          });
        })}
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
