import {
  createElement,
  ElementType,
  ReactNode,
  useEffect,
  useRef,
} from "react";
import classNames from "classnames";
import gsap from "gsap";

import { isElementInViewport } from "helpers/common/dom";


interface RevealWhenInViewProps {
  tag?: ElementType<any>;
  targetTag?: ElementType<any>;
  inline?: boolean;
  children: ReactNode;
  /**
   * @default
   * {
   *    duration: 0.6,
   *    opacity: 0,
   *    y: 50,
   * }
   */
  from?: gsap.TweenVars;
  /**
   * @default
   * {
   *    opacity: 1,
   *    y: 0,
   * }
   */
  to?: gsap.TweenVars;
  /**
   * @default false
   */
  markers?: boolean;
  playImmediately?: boolean;
  className?: string;
  targetClassName?: string;

  /**
   * @default 0.8
   */
  duration?: number;

  /**
   * @default 0.25
   */
  delay?: number;

  scrollTriggerConfig?: Exclude<
    gsap.plugins.ScrollTriggerStaticVars,
    "trigger"
  >;
  resetScrollTriggerConfig?: Pick<
    gsap.plugins.ScrollTriggerStaticVars,
    "start" | "end"
  >;
}

const RevealWhenInView = ({
  tag = "div",
  targetTag,
  className,
  targetClassName,

  children,
  from = {
    autoAlpha: 0,
    y: 30,
  },
  duration = 0.8,
  delay = 0,
  to = {
    autoAlpha: 1,
    ease: "ease-out",
    y: 0,
  },

  scrollTriggerConfig,
  resetScrollTriggerConfig,

  inline,

  markers,
  playImmediately = false,
}: RevealWhenInViewProps) => {
  const triggerRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const targetElement = targetRef.current!;
    const triggerElement = triggerRef.current!;

    const timeline = gsap.timeline({
      paused: true,
      scrollTrigger: {
        trigger: triggerElement,
        // start: "top center",
        // end: "bottom center",
        ...scrollTriggerConfig,
        toggleActions: "play none play none",
      },
    });

    const resetTimeline = () => timeline.pause(0);

    const resetScrollTrigger = gsap.timeline({
      scrollTrigger: {
        ...resetScrollTriggerConfig,
        trigger: triggerElement,
        onLeave: resetTimeline,
        onLeaveBack: resetTimeline,
        markers,
      }
    });

    timeline.fromTo(targetElement, from, {
      ...to,
      duration,
      delay,
    });

    if (isElementInViewport(triggerElement)) {
      if (!playImmediately) {
        timeline.progress(1);
      }
    } else {
      resetTimeline();
    }

    return () => {
      //@ts-ignore
      resetScrollTrigger.scrollTrigger.kill();
      resetScrollTrigger.kill();
      //@ts-ignore
      timeline.scrollTrigger.kill();
      timeline.kill();
    };
  }, [
    playImmediately,
    from,
    to,
    markers,
    scrollTriggerConfig,
    resetScrollTriggerConfig,
    duration,
    delay,
  ]);

  const computedTargetTag = targetTag ? targetTag : tag;

  return createElement(
    tag,
    {
      className: classNames(
        "reveal-when-in-view",
        { "reveal-when-in-view--inline": inline },
        className
      ),
      ref: triggerRef,
    },
    createElement(
      computedTargetTag,
      {
        className: classNames("reveal-when-in-view__target", targetClassName),
        ref: targetRef,
      },
      children
    )
  );

};

export default RevealWhenInView;
