import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import { Image } from "types/global";
import ViewPortfolioItemImage from "../ViewPortfolioItemImage";
import ViewPortfolioItemSection, {
  ViewPortfolioItemSectionConfigProps,
} from "../ViewPortfolioItemSection";

export interface ViewPortfolioWomanSportSiteElementsSectionProps
  extends ViewPortfolioItemSectionConfigProps {
  label: string;
  description: string;
  images: Image[];
}

const ViewPortfolioWomanSportSiteElementsSection = ({
  label,
  description,
  images,
  ...sectionProps
}: ViewPortfolioWomanSportSiteElementsSectionProps) => {
  return (
    <ViewPortfolioItemSection
      {...sectionProps}
      className="view-portfolio-item-section view-portfolio-item-section--layout-gallery-wss-3"
    >
      <div className="view-portfolio-item-section__container container">
        <header className="view-portfolio-item-section__header row">
          <div className="col-11 col-sm-3">
            <RevealWhenInView>
              <p className="label side-brackets">{label}</p>
            </RevealWhenInView>
          </div>
          <div className="col-11 col-sm-7 offset-sm-1 offset-xl-2 col-xl-6">
            <RevealWhenInView>
              <p className="u-text-indent-sm-quarter u-max-w-sm-480">
                {description}
              </p>
            </RevealWhenInView>
          </div>
        </header>
        <div className="view-portfolio-item-section__body row">
          <ViewPortfolioItemImage
            className="col-6 offset-md-0 col-md-5"
            {...images[0]}
          />
          {/* <div className="offset-1 col-5 offset-sm-1 col-sm-5 offset-md-0 col-md-5">
            <RevealWhenInView>
              <img className="u-w-full" {...images[0]} />
            </RevealWhenInView>
          </div> */}

          <div className="offset-1 col-4 offset-md-4 col-md-2 row">
            <div className="col-11 view-portfolio-item-section__col--with-marker">
              <div className="view-portfolio-item-section__marker--dot" />
            </div>
            <ViewPortfolioItemImage
              className="view-portfolio-item-section__col view-portfolio-item-section__col--align-end col-11"
              {...images[1]}
            />

            {/* <div className="view-portfolio-item-section__col view-portfolio-item-section__col--align-end col-11">
              <RevealWhenInView>
                <img className="u-w-full" {...images[1]} />
              </RevealWhenInView>
            </div> */}
          </div>

          <div className="view-portfolio-item-section__col view-portfolio-item-section__col--with-marker view-portfolio-item-section__col--justify-end col-2">
            <div className="view-portfolio-item-section__marker--dot" />
          </div>

          <ViewPortfolioItemImage
            className="offset-md-2 col-5"
            {...images[2]}
          />
          {/* <div className="offset-1 offset-md-2 col-4">
            <RevealWhenInView>
              <img className="u-w-full" {...images[2]} />
              </RevealWhenInView>
            </div> */}

          <ViewPortfolioItemImage className="offset-1 col-5" {...images[3]} />

          {/* <div className="offset-1 col-4">
            <RevealWhenInView>
            <img className="u-w-full" {...images[3]} />
            </RevealWhenInView>
          </div> */}

          <div className="view-portfolio-item-section__col view-portfolio-item-section__col--with-marker view-portfolio-item-section__col--align-end offset-2 col-2">
            <div className="view-portfolio-item-section__marker--dot" />
          </div>

          <ViewPortfolioItemImage
            className="view-portfolio-item-section__col view-portfolio-item-section__col--align-end col-4 offset-md-0 col-md-2"
            {...images[4]}
          />
          {/* <div className="view-portfolio-item-section__col view-portfolio-item-section__col--align-end offset-1 col-3 offset-md-0 col-md-2">
            <RevealWhenInView>
            <img className="u-w-full" {...images[4]} />
            </RevealWhenInView>
          </div> */}

          <div className="view-portfolio-item-section__col view-portfolio-item-section__col--with-marker view-portfolio-item-section__col--align-end offset-2 col-2">
            <div className="view-portfolio-item-section__marker--dot" />
          </div>
          <ViewPortfolioItemImage className="offset-1 col-6" {...images[5]} />

          {/* <div className="offset-1 col-5">
            <RevealWhenInView>
              <img className="u-w-full" {...images[5]} />
            </RevealWhenInView>
          </div> */}
        </div>
      </div>
    </ViewPortfolioItemSection>
  );
};

export default ViewPortfolioWomanSportSiteElementsSection;
