import ViewPortfolioCuprum from "components/views/ViewPortfolioItem/ViewPortfolioCuprum/";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";

const porfolioItem: PortfolioItemDataWithComponent = {
  id: "cuprum",
  component: ViewPortfolioCuprum,
  title: "cuprumEn",
  name: "Copper golem",
  altName: "Сайт-портфолио \n для студии\nхудожественного литья",
  year: '2023',
  awardsList: [],

  link: {
    label: "rostov-golem.ru",
    href: "https://rostov-golem.ru/",
  },

  socials: [
    {
      label: "behance",
      href: "https://www.behance.net/gallery/203561147/WEBSITE-sopper-golem-foundry-industry",
    },
  ],

  preview: {
    label: "corporate site",
    backgroundColor: "#E9E3D7",
    media: {
      type: "video",
      src: "https://vimeo.com/1004478943/75682bc7bc",
    },
  },

  poolOfWorks: ['UX|UI design', 'Page making'],

  type: 'site',
  subtype: 'site',

  description: [
    "сайт-презентация для компании, которая занимается художественным литьем и изготовлением скульптур из меди.",
    "Сайт работает как портфолио и позволяет легко делиться ссылкой с клиентами, что делает процесс демонстрации работ проще и приятнее"
  ],
  shortDescription: "Сайт-портфолио для студии художественного литья",

  seo: {
    title:
        "Development of a website for the art foundry company 'Copper Golem' | Monuments and memorial complexes",
    description:
        "We created a website for the company 'Copper Golem,' specializing in the creation of monuments and memorials. The company's works are installed in the Galsky Park and other notable locations. The company also engages in the reconstruction, beautification, and lighting of memorial complexes",
    keywords: [
      "development of a website for the art foundry company",
      "website for a foundry workshop",
      "beautification of memorial complexes",
      "website for memorial restoration",
      "decorative lighting"
    ],
    openGraph: {
      type: "article",
      title:
          "Development of a website for the art foundry company 'Copper Golem' | Monuments and memorial complexes",
      description:
          "We created a website for the company 'Copper Golem,' specializing in the creation of monuments and memorials. The company's works are installed in the Galsky Park and other notable locations. The company also engages in the reconstruction, beautification, and lighting of memorial complexes",
    },
  },
};

export default porfolioItem;
