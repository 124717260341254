import { useEffect, useRef } from "react";
import ViewAboutLeadSection from "./ViewAboutLeadSection";
import ViewAboutLinkSection from "./ViewAboutLinkSection";
import ViewAboutObjectivesSection from "./ViewAboutObjectivesSection";
import ViewAboutPortraitSection from "./ViewAboutPortraitSection";
import ViewAboutServicesSection from "./ViewAboutServicesSection";
import gsap from "gsap";
import View from "components/layouts/View";
import Footer from "components/layouts/Footer";
import AwardsSection from "../../layouts/AwardsSection";

export interface ViewAboutProps { }

const ViewAbout = (props: ViewAboutProps) => {
  const viewRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const viewElem = viewRef.current!;
    const timeline = gsap.timeline({
      delay: 0.5,
      defaults: { ease: "ease-out", duration: 0.8 },
    });

    const leadSectionBackgroundContainer = viewElem.querySelector(
      ".view-about-lead-section__background-container"
    );
    const leadSectionBackgroundParallax = viewElem.querySelector(
      ".view-about-lead-section__background-parallax"
    );

    const leadSectionHeaderTitle = viewElem.querySelector(
      ".view-about-lead-section__header  .view-about-lead-section__title"
    );
    const leadSectionFooterTitle = viewElem.querySelector(
      ".view-about-lead-section__footer  .view-about-lead-section__title"
    );
    const leadSectionArrow = viewElem.querySelector(
      ".view-about-lead-section__arrow"
    );

    const leadSectionDescription = viewElem.querySelector(
      ".view-about-lead-section__description"
    );
    const leadSectionStatNumbers = viewElem.querySelectorAll(
      ".view-about-lead-section__stat-number"
    );
    const leadSectionStatLabels = viewElem.querySelectorAll(
      ".view-about-lead-section__stat-label"
    );

    const isDesktop = matchMedia("(min-width: 992px)").matches;
    const isMaxMd = matchMedia("(max-width: 767px)").matches;

    if (isDesktop) {
      // console.log("descrio")
      timeline.from(leadSectionBackgroundContainer, {
        xPercent: -100,
      });

      timeline.from(
        leadSectionBackgroundParallax,
        {
          transformOrigin: "50% 50%",
          scale: 1.5,
        },
        "<"
      );
    }

    timeline.addLabel("backgroundAppeared");

    timeline.from(
      isMaxMd ? leadSectionHeaderTitle : leadSectionFooterTitle,
      {
        y: 15,
        opacity: 0,
      },
      "backgroundAppeared"
    );

    if (isDesktop) {
      timeline.from(
        leadSectionArrow,
        {
          y: -15,
          opacity: 0,
        },
        "<"
      );
    }

    timeline.from(
      leadSectionStatNumbers,
      {
        y: 15,
        opacity: 0,
        duration: 0.6,
        force3D: true,
      },
      "backgroundAppeared"
    );

    timeline.from(
      leadSectionStatLabels,
      {
        y: 15,
        opacity: 0,
        duration: 0.6,
        force3D: true,
      },
      "<"
    );

    timeline.from(
      leadSectionDescription,
      {
        y: 20,
        opacity: 0,
        duration: 0.6,
      },
      "backgroundAppeared"
    );
  }, []);

  return (
    <View ref={viewRef} className="view view-about">
      <ViewAboutLeadSection />
      <AwardsSection />
      <ViewAboutPortraitSection />
      <ViewAboutServicesSection />
      <ViewAboutObjectivesSection />
      <ViewAboutLinkSection />
      <Footer />
    </View>
  );
};

export default ViewAbout;
