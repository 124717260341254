import { usePortfolioItemAssets } from 'hooks/useAssets';
import { useViewPortfolioItemContext } from '../ViewPortfolioItem';
import ViewPortfolioItemAboutSection from '../ViewPortfolioItemAboutSection';
import ViewPortfolioItemSection from '../ViewPortfolioItemSection';
import RevealWhenInView from '../../../common/RevealInView/RevealWhenInView';
import { useTranslation } from 'react-i18next';
import React from "react";

export interface ViewPortfolioBannersProps {}

const ViewPortfolioBanners = (props: ViewPortfolioBannersProps) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);
  const { t } = useTranslation();

  return (
    <>
      <ViewPortfolioItemAboutSection
        dynamicBackgroundColor={true}
        className="view-portfolio-item-about-section--type-banners"
        caption={[t("portfolioItem:banners.about.0"), t("portfolioItem:banners.about.1"), t("portfolioItem:banners.about.2"), ]}
        video={{
            src: 'https://vimeo.com/1005381964/4d94c87543',
        }}
        style={{
          backgroundColor: '#00387A',
          color: '#FFF',
          backgroundPosition: 'top left',
          backgroundSize: 'cover',
        }}
        name={data.name}
        description={data.description}
      />

      {/* 3ий экран*/}
      <ViewPortfolioItemSection dynamicBackgroundColor={true} style={{ backgroundColor: '#00387A' }}
                                className={'container view-portfolio-banners-section portfolio-item-section--type-banners-3'}>
          <div className={'img-wrap'}>
              <RevealWhenInView>
                  <img src={buildPageImageSrc(t('portfolioItem:banners.section-3.image.0'), 'gif')} alt={'banners'}/>
              </RevealWhenInView>
          </div>
          <div className={'img-wrap'}>
              <RevealWhenInView>
                  <img src={buildPageImageSrc(t('portfolioItem:banners.section-3.image.1'), 'gif')} alt={'banners'}/>
              </RevealWhenInView>
          </div>
      </ViewPortfolioItemSection>

      {/* 4ий экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#E22626' }} className={' container view-portfolio-banners-section'}>
          <div className={'img-wrap'}>
              <RevealWhenInView>
                  <img src={buildPageImageSrc(t('portfolioItem:banners.section-4.image.0'))} alt={'banners'}/>
              </RevealWhenInView>
          </div>
          <div className={'img-wrap'}>
              <RevealWhenInView>
                  <img src={buildPageImageSrc(t('portfolioItem:banners.section-4.image.1'))} alt={'banners'}/>
              </RevealWhenInView>
          </div>
      </ViewPortfolioItemSection>

      {/* 5ой экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#3FB00A'}}
            className={' container view-portfolio-banners-section portfolio-item-section--type-banners-5'}>
            <RevealWhenInView>
                <img src={buildPageImageSrc(t('portfolioItem:banners.section-5.image'))} alt={'banners'}/>
            </RevealWhenInView>
        </ViewPortfolioItemSection>

      {/* 6ой экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#FE7B9B' }} className={' container view-portfolio-banners-section'}>
            <div className={'img-wrap'}>
                <RevealWhenInView>
                    <img src={buildPageImageSrc(t('portfolioItem:banners.section-6.image.0'))} alt={'banners'}/>
                </RevealWhenInView>
            </div>
            <div className={'img-wrap'}>
                <RevealWhenInView>
                    <img src={buildPageImageSrc(t('portfolioItem:banners.section-6.image.1'))} alt={'banners'}/>
                </RevealWhenInView>
            </div>
            <div className={'img-wrap'}>
                <RevealWhenInView>
                    <img src={buildPageImageSrc(t('portfolioItem:banners.section-6.image.2'))} alt={'banners'}/>
                </RevealWhenInView>
            </div>
        </ViewPortfolioItemSection>

      {/* 7ой экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#FF9329' }} className={' container view-portfolio-banners-section'}>
            <div className={'img-wrap'}>
                <RevealWhenInView>
                    <img src={buildPageImageSrc(t('portfolioItem:banners.section-7.image.0'))} alt={'banners'}/>
                </RevealWhenInView>
            </div>
            <div className={'img-wrap'}>
                <RevealWhenInView>
                    <img src={buildPageImageSrc(t('portfolioItem:banners.section-7.image.1'))} alt={'banners'}/>
                </RevealWhenInView>
            </div>
        </ViewPortfolioItemSection>

      {/* 8ый экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#5B01B5' }} className={' container view-portfolio-banners-section'}>
            <div className={'img-wrap'}>
                <RevealWhenInView>
                    <img src={buildPageImageSrc(t('portfolioItem:banners.section-8.image.0'))} alt={'banners'}/>
                </RevealWhenInView>
            </div>
            <div className={'img-wrap'}>
                <RevealWhenInView>
                    <img src={buildPageImageSrc(t('portfolioItem:banners.section-8.image.1'))} alt={'banners'}/>
                </RevealWhenInView>
            </div>
            <div className={'img-wrap'}>
                <RevealWhenInView>
                    <img src={buildPageImageSrc(t('portfolioItem:banners.section-8.image.2'))} alt={'banners'}/>
                </RevealWhenInView>
            </div>
        </ViewPortfolioItemSection>

        {/* 9ой экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#000000' }} className={' container view-portfolio-banners-section'}>
            <RevealWhenInView>
                <img src={buildPageImageSrc(t('portfolioItem:banners.section-9.image'))} alt={'banners'}/>
            </RevealWhenInView>
        </ViewPortfolioItemSection>

    </>
  );
};

export default ViewPortfolioBanners;
