import ViewPortfolioItemAboutSection from "../ViewPortfolioItemAboutSection";

import ViewPortfolioItemParallaxSection from "../ViewPortfolioItemParallaxSection";
import { useViewPortfolioItemContext } from "../ViewPortfolioItem";
import ViewPortfolioItemSection from "../ViewPortfolioItemSection";
import ViewPortfolioItemRelatedSection from "../ViewPortfolioItemRelatedSection";
import ViewPortfolioItemImage from "../ViewPortfolioItemImage";
import { usePortfolioItemAssets } from "hooks/useAssets";

export interface ViewPortfolioNovaGroupBrandStyleGroupProps { }

const ViewPortfolioNovaGroupBrandStyleGroup = (
  props: ViewPortfolioNovaGroupBrandStyleGroupProps
) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);


  return (
    <>
      <ViewPortfolioItemAboutSection
        name={data.name}
        image={{
          isLogo: true,
          src: buildPageImageSrc("about", "svg"),
        }}
        description={data.description}
        style={{
          backgroundColor: "#222222",
          color: "#fff",
        }}
      />
      <ViewPortfolioItemSection>
        <ViewPortfolioItemImage
          src={buildPageImageSrc("screen-3")}
        />
        {/* <RevealWhenInView>
          <img
            className="u-w-full"
            src={getViewImageSrc("merch", "png")}
            alt=""
          />
        </RevealWhenInView> */}
      </ViewPortfolioItemSection>
      <ViewPortfolioItemParallaxSection
        image={{
          src: buildPageImageSrc("screen-4"),
          alt: "House and blue skys",
        }}
      />
      <ViewPortfolioItemSection>
        <ViewPortfolioItemImage
          src={buildPageImageSrc("screen-5")}
        />
        {/* <RevealWhenInView>
          <img className="u-w-full" src={getViewImageSrc("cards")} alt="" />
        </RevealWhenInView> */}
      </ViewPortfolioItemSection>

      <ViewPortfolioItemSection>
        <div
          style={{
            height: "100vh",
            backgroundImage: `url(${buildPageImageSrc("cropped-n")})`,
            backgroundSize: 100,
            position: "relative"
          }}
        />
      </ViewPortfolioItemSection>
      {data.relatedId && (
        <ViewPortfolioItemRelatedSection
          currentId={data.id}
          style={{ backgroundColor: "#215DFD", color: "#fff" }}
          id={data.relatedId}
          preview={{
            image: {
              src: buildPageImageSrc("related"),
            },
            frame: "#0131C1",
          }}
        />
      )}
    </>
  );
};

export default ViewPortfolioNovaGroupBrandStyleGroup;
