import { Redirect, RouteComponentProps, useParams } from "react-router-dom";
import { useMemo, useRef } from "react";
import { notFound as notFoundRoute } from "routes";
import ViewPortfolioItemNextSection from "./ViewPortfolioItemNextSection";

import {
  PortfolioItemData,
  PortfolioItemDataWithComponent,
} from "data/portfolio/types";
import portfolio from "data/portfolio";
import {
  getPublicFileSrcBuilder,
  IMAGES_PATH,
  PublicFileSrcBuilderConfig,
} from "helpers/path";
import {
  useSafeContext,
  createSafeConsumer,
  createSafeContext,
} from "contexts/helpers";
import ViewPortfolioItemLeadSection from "./ViewPortfolioItemLeadSection";

import Head from "components/layouts/Head";
import View from "components/layouts/View";
import Footer from "components/layouts/Footer";
import { memo } from "react";
import i18n from "i18next";
import { LangCodeType } from "i18n/types";

interface ContextValue {
  data: PortfolioItemData;
  getImageSrc: (configOrFile: string | PublicFileSrcBuilderConfig) => string;
}

const Context = createSafeContext<ContextValue>();

export const useViewPortfolioItemContext = () => useSafeContext(Context);
export const ViewPortfolioItemConsumer = createSafeConsumer(Context);

export interface ViewPortfolioItemProps extends RouteComponentProps {}

export interface ViewSpecificPortfolioItemProps {
  data: PortfolioItemData;
  relatedPortfolio?: PortfolioItemData;
}

export type ViewSpecificPortfolioItem = (
  props: ViewSpecificPortfolioItemProps
) => JSX.Element;

const ViewPortfolioItem = (props: ViewPortfolioItemProps) => {
  const params = useParams<{ portfolioItemId: string }>();
  const viewRef = useRef<HTMLDivElement>(null);

  const portfolioItem = useMemo(() => {
    const result: {
      current?: PortfolioItemDataWithComponent;
      next?: PortfolioItemDataWithComponent;
    } = {};

    //текущий язык
    const language = i18n.language as LangCodeType;

    
    const currentPortfolioIndex = portfolio[language].findIndex(
      (portfolioItemData) => portfolioItemData.id === params.portfolioItemId
    );

    let portfolioItems = portfolio[language];

    if (currentPortfolioIndex !== -1) {
      const current = portfolioItems[currentPortfolioIndex];
      let nextPortfolioItemIndex = currentPortfolioIndex + 1;

      if (nextPortfolioItemIndex >= portfolio[language].length) {
        nextPortfolioItemIndex = 0;
      }

      if (
        portfolioItems[nextPortfolioItemIndex].id.length === 0 &&
        nextPortfolioItemIndex + 1 < portfolio[language].length
      ) {
        nextPortfolioItemIndex++;
      }

      const next = portfolioItems[nextPortfolioItemIndex];

      return {
        current,
        next,
      };
    }

    return result;
  }, [params.portfolioItemId, i18n.language]);

  if (portfolioItem.current) {
    const SpecificPortfolioItemView = portfolioItem.current.component;

    const getImageSrc = getPublicFileSrcBuilder(
      `${IMAGES_PATH}/portfolios/${portfolioItem.current.id}`
    );

    return (
      <Context.Provider
        value={{
          data: portfolioItem.current,
          getImageSrc: getImageSrc,
        }}
      >
        <Head seo={portfolioItem.current.seo} />

        <View ref={viewRef} className="view view--portfolio-item">
          <ViewPortfolioItemLeadSection />

          <SpecificPortfolioItemView data={portfolioItem.current} />

          {portfolioItem.next && (
            <ViewPortfolioItemNextSection portfolioItem={portfolioItem.next} />
          )}

          <Footer />
        </View>
      </Context.Provider>
    );
  }

  return <Redirect to={notFoundRoute.path} />;
};

export default memo(ViewPortfolioItem);
