import { ImageConfig } from "components/common/Image";
import Parallax from "components/common/Parallax";
import ViewPortfolioItemSection, {
  ViewPortfolioItemSectionConfigProps,
} from "./ViewPortfolioItemSection";

interface ViewPortfolioItemParallaxSectionProps
  extends ViewPortfolioItemSectionConfigProps {
  image: ImageConfig,
  className?: string
}

const ViewPortfolioItemParallaxSection = ({
  image,
  className,
  ...sectionProps
}: ViewPortfolioItemParallaxSectionProps) => {
  return (
    <ViewPortfolioItemSection
      {...sectionProps}
      className={"view-portfolio-item-parallax-section " + className}
    >
      <div className="view-portfolio-item-parallax-section__inner">
        <Parallax
          className="view-portfolio-item-parallax-section__parallax"
          image={image}
        />
      </div>
    </ViewPortfolioItemSection>
  );
};

export default ViewPortfolioItemParallaxSection;
