import { useCallback, useState } from "react"

/**
 * Возвращает массив, первый элемент которого является функция, которая обновляет компонент,
 * а второй элемент кол-во раз это принудительное обновление было вызвано
 */
export const useForceUpdate = (): [() => void, number] => {
  const [counter, setCounter] = useState(0);

  const update = useCallback(() => {
    setCounter((oldCounter) => oldCounter + 1);
  }, []);

  return [update, counter];
}