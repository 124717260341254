import RawHtml from "components/common/RawHtml";
import PrivacyPolicy from "./PrivacyPolicy";
import PrivacyPolicyChapter from "./PrivacyPolicyChapter";

import content from "./privacy-policy-content.json";

interface ViewPrivacyPolicyMainSectionProps {}

const ViewPrivacyPolicyContentSection = (
  props: ViewPrivacyPolicyMainSectionProps
) => {
  return (
    <section className="view-privacy-policy-main-section">
      <div className="view-privacy-policy-main-section__container container">
        <PrivacyPolicy>
          {content.map((chapter, i) => {
            return (
              <PrivacyPolicyChapter key={i} name={chapter.title}>
                <RawHtml>{chapter.content}</RawHtml>
              </PrivacyPolicyChapter>
            );
          })}
        </PrivacyPolicy>
      </div>
    </section>
  );
};

export default ViewPrivacyPolicyContentSection;
