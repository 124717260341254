import React, { forwardRef, memo, ReactElement, ReactNode, Ref } from "react";
import classNames from "classnames";
import ListItem from "./ListItem";
import Print from "../Print/Print";

export interface ListProps<T> {
  /**
   * Элементы списка
   */
  items: T[];
  /**
   * Функция которая определяет как рендерить контент элемента списка
   */
  children?: (item: T, i: number) => ReactNode;
  /**
   * Промежутки между элементами списка
   */
  gap?: "xxs" | "sm" | "md" | "lg" | "xxl";
  /**
   * Если true, тогда к элементам списка добавляются маркеры
   */
  marked?: boolean;
  /**
   * Класс
   */
  className?: string;
  /**
   * Класс элементов списка
   */
  itemClassName?: string;

  /**
   * Задает фиксированный размер списка
   */
  fixed?: number;

  /**
   * Если true, тогда элементы списка будут расположены горизонтально
   */
  horizontal?: boolean;

  /**
   * Если true, тогда элемент списка не будет создан и его нужно будет задавать вручную через children
   */
  customItem?: boolean;

  style?: React.CSSProperties;
}

/**
 * Создает список
 */
const List = <T extends object | ReactNode>(
  {
    items,
    gap,
    marked,
    fixed,
    children,
    className,
    itemClassName,
    customItem,
    horizontal,
    style,
  }: ListProps<T>,
  ref: Ref<HTMLUListElement>
) => {
  if (fixed) {
    if (items.length > fixed) {
      items = items.slice(0, items.length);
    } else if (items.length < fixed) {
      const emptyItems = new Array(fixed - items.length);
      items = [...items, ...emptyItems];
    }
  }

  // @ts-ignore
    return (
    <ul
      style={style}
      ref={ref}
      className={classNames(
        "list",
        {
          [`list--gap-${gap}`]: gap,
          "list--marked": marked,
          "list--horizontal": horizontal,
        },
        className
      )}
    >
      {items.map((item, i) =>
        !customItem ? (
          <ListItem className={itemClassName} key={i}>
            {children ? children(item, i) : typeof item === "string" ? <Print data={item} /> : item}
          </ListItem>
        ) : children ? (
          children(item, i)
        ) : (
          item
        )
      )}
    </ul>
  );
};

export default memo(forwardRef(List)) as <T extends object | ReactNode>(
  props: ListProps<T> & { ref?: Ref<HTMLUListElement> }
) => ReactElement;
