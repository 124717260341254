import { useCallback } from "react";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import Ticker from "components/common/Ticker";
import Print from "components/common/Print";

import s from "./index.module.scss";

interface StageItemsProps {
  tickerText: string;
  subtitle: string;
  max_step_number: number;
  items: Array<{
    title: string;
    subtitle: string;
    text: string;
    step_number: number;
  }>;
}

const flexByIndex = [
  "offset-0 col-8 col-md-5 col-lg-3",
  "offset-3 offset-md-6 offset-lg-4 col-8 col-md-5 col-lg-3",
  "offset-0 offset-lg-8 col-8 col-md-5 col-lg-3",
  "offset-3 offset-md-6 offset-lg-4 col-8 col-md-5 col-lg-3",
  "offset-0 col-8 col-md-5 col-lg-3",
];

const StageItems = (props: StageItemsProps) => {
  const renderTickerTextItem = useCallback(() => {
    return (
      <p
        className={
          "title " + s["view-developer-sites-stages-section__sliding-text-item"]
        }
      >
        <span>
          <Print data={props.tickerText} />
        </span>
        <span
          className={
            s["view-developer-sites-stages-section__sliding-text-item-dot"]
          }
        >
          ■
        </span>
      </p>
    );
  }, [props.tickerText]);

  const ItemStepPaginator = (props: {
    step_number: number;
    max_step_number: number;
  }) => {
    const length = props.max_step_number;
    return (
      <div
        className={
          "title " +
          s["view-developer-sites-stages-section__item-paginator-items"]
        }
      >
        {Array.from({ length }, (_, index) => index).map((_, index) => {
          return (
            <div
              className={`${
                s["view-developer-sites-stages-section__item-paginator-item"]
              } ${index + 1 === props.step_number ? s.selected : ""}`}
              key={_ + index}
            ></div>
          );
        })}
      </div>
    );
  };
  return (
    <div>
      <div className={s["view-developer-sites-stages-section__ticker"]}>
        <RevealWhenInView>
          <div>
            <Ticker
              text={props.tickerText}
              duration={20}
              renderTextItem={renderTickerTextItem}
            />
          </div>
        </RevealWhenInView>
      </div>
      <div className="container">
        <header className={s['view-developer-sites-stages-section__items-header']}>
          <RevealWhenInView>
            <p className="label text--right text--uppercase side-brackets">
              {props.subtitle}
            </p>
          </RevealWhenInView>
        </header>
        <div className={s["view-developer-sites-stages-section__item-wrapper"]}>
          <div className="view-developer-sites-stages-section__container row">
            {props.items.map((item, index) => {
              return (
                <div key={item.step_number} className={"row col-11 " + s['view-developer-sites-stages-section__item-outer']}>
                  <div
                    className={` ${s["view-developer-sites-stages-section__item-inner"]} ${flexByIndex[index]}`}
                  >
                    <RevealWhenInView>
                      <p className="heading-2">{item.title}</p>
                    </RevealWhenInView>
                    <RevealWhenInView className={s['view-developer-sites-stages-section__item-subtitle']}>
                      <p>{item.subtitle}</p>
                    </RevealWhenInView>
                    <RevealWhenInView>
                      {" "}
                      <p
                        className={"side-brackets text--secondary " + s['view-developer-sites-stages-section__step-number']}
                      >{`${item.step_number} шаг`}</p>
                    </RevealWhenInView>
                    <RevealWhenInView>
                      <ItemStepPaginator
                        step_number={item.step_number}
                        max_step_number={props.max_step_number}
                      />
                    </RevealWhenInView>
                    <RevealWhenInView>
                      <p className="text--secondary">{item.text}</p>
                    </RevealWhenInView>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export { StageItems };
