import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useSafeContext,
  createSafeConsumer,
  createSafeContext,
} from "./helpers";

interface ContextValue {
  isVisible: boolean;
  interfaceHeight: number;
  lastDetectedInterfaceHeight: number;
}

const Context = createSafeContext<ContextValue>();

export const useBrowserInterfaceVisibility = () => useSafeContext(Context);
export const BrowserInterfaceVisibilityConsumer = createSafeConsumer(Context);

/**
 * Проверяет видимость адресной строки
 */
export const BrowserInterfaceVisibilityProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const [interfaceHeight, _setInterfaceHeight] = useState(0);
  const [lastDetectedInterfaceHeight, _setLastDetectedInterfaceHeight] = useState(0);
  const interfaceHeightRef = useRef(interfaceHeight);

  const screenHeightElRef = useRef<HTMLDivElement | null>(null);

  const setInterfaceHeight = useCallback((height: number) => {
    _setInterfaceHeight(interfaceHeightRef.current = height);
    _setLastDetectedInterfaceHeight((oldLastDetectedInterfaceHeight) => {
      if (height !== 0 && oldLastDetectedInterfaceHeight !== height) {
        return height;
      }

      return oldLastDetectedInterfaceHeight;
    });
  }, []);

  useEffect(() => {
    const screenHeightEl = screenHeightElRef.current!;

    const updateInterfaceHeight = () => {
      const currentInterfaceHeight = Math.abs(window.innerHeight - screenHeightEl.offsetHeight);

      if (interfaceHeightRef.current !== currentInterfaceHeight) {
        setInterfaceHeight(currentInterfaceHeight);
      }
    };

    updateInterfaceHeight();

    window.addEventListener("resize", updateInterfaceHeight);

    return () => {
      window.removeEventListener("resize", updateInterfaceHeight);
    };
  }, [setInterfaceHeight]);

  const value: ContextValue = useMemo(() => ({
    interfaceHeight,
    lastDetectedInterfaceHeight,
    isVisible: interfaceHeight !== 0
  }), [interfaceHeight, lastDetectedInterfaceHeight]);

  return (
    <Context.Provider value={value}>
      <div
        ref={screenHeightElRef}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          pointerEvents: "none",
          zIndex: -1
        }}
      />
      {children}
    </Context.Provider>
  );
};
