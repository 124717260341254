import { MorphSvgWord } from "components/common/MorphSvg";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import Section from "components/layouts/Section";
import { useEffect, useRef, useState } from "react";

import gsap from "gsap";

import s from "./index.module.scss";
import List from "components/common/List";
import ScrollDownButton from "components/layouts/ScrollDownButton";
import SlideFadeOutWhenInView from "components/views/ViewPortfolioItem/ViewPortfolioNovaGroup/SlideFadeOutWhenInView";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";

const sectionData = {
  title: "Поддержка сайтов застройщиков",
  awards: [
    { titile: "ТОП-2", text: "Разработчик в России по строительству" },
    { titile: "ТОП-9", text: "Разработчик в России по недвижимости" },
  ],
  worksThisUs: [
    "Работа с нами это",
    "Скорость редакции до 10 минут",
    "общение напрямую со специалистом",
    "прозрачная отчетность",
    "от 1800 руб/час",
  ],
  footerText: "техническая и  дизайн-поддержка",
};

interface ViewDeveloperSitesLeadSectionProps {}

const ViewDeveloperSitesLeadSection = (
  props: ViewDeveloperSitesLeadSectionProps
) => {
  const [morph, setMorph] = useState(false);

  const titleRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setMorph(true);
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    const titleElem = titleRef.current!;

    const timeline = gsap.timeline({
      defaults: { ease: "ease-out", duration: 0.6 },
    });
    const isTablet = matchMedia("(min-width: 768px)").matches;

    if (!isTablet) {
      timeline.from(titleElem, {
        y: 15,
        opacity: 0,
        delay: 0.5,
      });
    }
  }, [morph]);

  return (
    <DynamicBackgroundTrigger>
      <Section
        isLead
        className={
          s["view-developer-sites-lead-section"] + " background--dark-white"
        }
      >
        <div
          className={
            "section-container container row " +
            s["view-developer-sites-lead-section__container"]
          }
        >
          <div>
            {/* header */}
            <header className="row col-11">
              <RevealWhenInView>
                <span ref={titleRef} className={"heading " + s.title}>
                  {sectionData.title}
                </span>
              </RevealWhenInView>
            </header>
            {/* body */}
            <div
              className={
                "row col-11 " + s["view-developer-sites-lead-section__body"]
              }
            >
              {/* awards */}
              <div
                className={
                  "row col-11 offset-0 offset-sm-1 col-md-5 offset-md-0 " +
                  s["view-developer-sites-lead-section__awards"]
                }
              >
                {sectionData.awards.map((item, index) => {
                  return (
                    <RevealWhenInView className="col-11" key={index}>
                      <div
                        className={
                          "row " +
                          s["weight-500"] +
                          " " +
                          s["view-developer-sites-lead-section__awards"]
                        }
                      >
                        <div className={""}>
                          <img src="/images/sprite-icons/trophy.svg" />
                        </div>
                        <div className={"row col-7 col-sm-6"}>
                          <p className="side-brackets col-11">{item.titile}</p>
                          <p className="col-11">{item.text}</p>
                        </div>
                      </div>
                    </RevealWhenInView>
                  );
                })}
              </div>
              {/* work this us */}
              <SlideFadeOutWhenInView>
                {({ setTriggerEl, setTarget }) => (
                  <div
                    ref={setTriggerEl}
                    className={
                      "col-11 offset-0 offset-sm-1 col-md-6 offset-md-0 " +
                      s["title-list"]
                    }
                  >
                    <div className={s["title-list"]}>
                      <p className={"side-brackets label"}>
                        {sectionData.worksThisUs[0]}
                      </p>
                    </div>
                    <List
                      ref={setTarget({ selector: ".list__item" })}
                      items={sectionData.worksThisUs.slice(1)}
                      gap="lg"
                      marked
                    >
                      {(item) => <span className="col-11">{item}</span>}
                    </List>
                  </div>
                )}
              </SlideFadeOutWhenInView>
            </div>
          </div>
          <footer
            className={
              "row col-11 " + s["view-developer-sites-lead-section__footer"]
            }
          >
            <RevealWhenInView
              className={
                "col-8 heading-4 italic-text row " +
                s["view-developer-sites-lead-section__italic-text"]
              }
            >
              <p>{sectionData.footerText}</p>
            </RevealWhenInView>
            <div className=" offset-auto">
              <RevealWhenInView>
                <ScrollDownButton
                  scrollTo=".view-developer-sites-our-experience-section"
                  duration={1.5}
                />
              </RevealWhenInView>
            </div>
          </footer>
        </div>
      </Section>
    </DynamicBackgroundTrigger>
  );
};

export default ViewDeveloperSitesLeadSection;
