import React, { forwardRef, ReactNode, RefObject } from "react";
import classNames from "classnames";

interface ButtonProps {
  /**
   * Варианты кнопки
   */
  variant?: "link" | "default";
  innerRef?: RefObject<HTMLSpanElement>;
  /**
   * Рендерит элемент слева от подписи кнопки
   */
  renderLeft?: () => ReactNode;
  /**
   * Рендерит элемент справа от подписи кнопки
   */
  renderRight?: () => ReactNode;
  /**
   * Обработчик кликов
   */
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;

  children?: ReactNode;
  className?: string;
}

/**
 * Создает кнопку
 */
const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      variant,
      onClick,
      renderLeft,
      innerRef,
      renderRight,
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        onClick={onClick}
        className={classNames(
          "button",
          { [`button--${variant}`]: variant },
          className
        )}
      >
        <span ref={innerRef} className="button__inner">
          {renderLeft && <span className="button__left">{renderLeft()}</span>}
          <span className="button__label">{children}</span>
          {renderRight && (
            <span className="button__right">{renderRight()}</span>
          )}
        </span>
      </button>
    );
  }
);

export default Button;
