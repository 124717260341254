import {
  CloseMenuAction,
  CLOSE_MENU,
  OpenMenuAction,
  OPEN_MENU,
} from "./types";

export const openMenu = (): OpenMenuAction => ({
  type: OPEN_MENU,
});

export const closeMenu = (): CloseMenuAction => ({
  type: CLOSE_MENU,
});
