import React, {memo, useEffect, useRef} from "react";

import Logo from "components/layouts/Logo";
import MenuToggle from "../Menu/MenuToggle";
import LanguageSwitcher from "../../common/LanguageSwitcher";

import gsap from "gsap";
import { BodyScrollLockerOffset } from "contexts/BodyScrollLocker";
import { useTranslation } from 'react-i18next';

interface HeaderProps { }

/**
 * Шапка сайта
 */
const Header = memo((props: HeaderProps) => {
  const headerElRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const headerEl = headerElRef.current!;

    gsap.from(headerEl, {
      y: 10,
      opacity: 0,
      duration: 0.6,
      delay: 1,
      ease: "ease-out",
    });
  }, []);


  return (
    <BodyScrollLockerOffset tag="header" ref={headerElRef} className="header">
      <div className="header__container container">
        <div className="header__inner row">
          <div className="header__col-with-logo col-4 col-md-3 col-xl-2">
            <Logo className="header__logo" />
          </div>
          <div className="header__col-with-controls col">
            <a className="link label header__telegram" href="https://telegram.me/sagirovcom" target="_blank" rel="noreferrer">
              <img src="/images/sprite-icons/logos_telegram.svg"/>
              <p className="link--normal" dangerouslySetInnerHTML={{__html: t("layout:header.contact")}}/>
            </a>
            <LanguageSwitcher className="header__language-switcher" />
            <div className="header__menu-toggle-container">
              <MenuToggle className="header__menu-toggle" />
            </div>
          </div>
        </div>
      </div>
    </BodyScrollLockerOffset>
  );
});

export default Header;
