import { usePortfolioItemAssets } from 'hooks/useAssets';
import { useViewPortfolioItemContext } from '../ViewPortfolioItem';
import ViewPortfolioItemAboutSection from '../ViewPortfolioItemAboutSection';
import VimeoPlayer from '../../../common/VimeoPlayer/VimeoPlayer';
import ViewPortfolioItemSection from '../ViewPortfolioItemSection';
import RevealWhenInView from '../../../common/RevealInView/RevealWhenInView';
import { useTranslation } from 'react-i18next';
import Parallax from 'components/common/Parallax/Parallax';

export interface ViewPortfolioWildberriesProps {}

const ViewPortfolioWildberries = (props: ViewPortfolioWildberriesProps) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);
  const { t } = useTranslation();

  const language = t('portfolioItem:wildberries.language');
  return (
    <>
      <ViewPortfolioItemAboutSection
        className="view-portfolio-wildberries-section-width"
        dynamicBackgroundColor={false}
        style={{ backgroundColor: '#202020', color: '#fff' }}
        name={data.name}
        image={{
          isLogo: true,
          src: buildPageImageSrc('about', 'svg'),
        }}
        description={data.description}
      />
      {/* 3ий экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={false}
        style={{ backgroundColor: '#202020' }}
        className={`portfolio-item-section--type-wildberries-3 view-portfolio-wildberries-section-width view-portfolio-wildberries-container`}>
        <div className="portfolio-item-section">
          <div
            className={`portfolio-item-section__text portfolio-item-section__text--${language}`}>
            <RevealWhenInView>
              <p>{t('portfolioItem:wildberries.section-3.text')}</p>
            </RevealWhenInView>
          </div>
          <div className="portfolio-item-section__img-wraper">
            <RevealWhenInView>
              <img src={buildPageImageSrc('screen-3-1')} alt={'wildberries'} />
            </RevealWhenInView>
          </div>
          <div className="portfolio-item-section__main-img-wraper">
            <RevealWhenInView>
              <img src={buildPageImageSrc('screen-3-2')} alt={'wildberries'} />
            </RevealWhenInView>
          </div>
        </div>
      </ViewPortfolioItemSection>
      {/* 4ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={false}
        style={{ backgroundColor: '#202020' }}
        className="portfolio-item-section--type-wildberries-4 view-portfolio-wildberries-section-width">
        <div className="portfolio-item-section">
          <div className="row portfolio-item-section__text-container view-portfolio-wildberries-container">
            <div
              className={`portfolio-item-section__description portfolio-item-section__description--${language}`}>
              <RevealWhenInView>
                <p>{t('portfolioItem:wildberries.section-4.text.0')}</p>
              </RevealWhenInView>
            </div>
            <div
              className={`portfolio-item-section__subtitle portfolio-item-section__subtitle--${language}`}>
              <RevealWhenInView>
                <p>{t('portfolioItem:wildberries.section-4.text.1')}</p>
              </RevealWhenInView>
            </div>
          </div>

          <div className="portfolio-item-section__img-container">
            <RevealWhenInView>
              <img
                src={buildPageImageSrc(
                  t('portfolioItem:wildberries.section-4.image')
                )}
                alt={'wildberries'}
              />
            </RevealWhenInView>
          </div>
        </div>
      </ViewPortfolioItemSection>
      {/* 5-ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={false}
        style={{ backgroundColor: '#202020' }}
        className={`portfolio-item-section--type-wildberries-5 view-portfolio-wildberries-section-width ${t(
          'portfolioItem:wildberries.section-5.className'
        )}`}>
        <div className="image-container">
          <RevealWhenInView>
            <picture>
              <source
                media="(max-width: 768px)"
                srcSet={buildPageImageSrc(
                  t('portfolioItem:wildberries.section-5.image.1')
                )}
              />
              <source
                media="(min-width: 769px)"
                srcSet={buildPageImageSrc(
                  t('portfolioItem:wildberries.section-5.image.0')
                )}
              />
              <img
                src={buildPageImageSrc(
                  t('portfolioItem:wildberries.section-5.image.0')
                )}
                alt="wildberries"
              />
            </picture>
          </RevealWhenInView>
        </div>
      </ViewPortfolioItemSection>
      {/* 6ой экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={false}
        className="view-portfolio-wildberries-section-width portfolio-item-section--type-wildberries-6">
        <div className="portfolio-item-section__text-container">
          <RevealWhenInView>
            <p>{t('portfolioItem:wildberries.section-6.text.0')}</p>
          </RevealWhenInView>
        </div>
        <Parallax
          image={{
            src: buildPageImageSrc('screen-6-1'),
            sources: [
              {
                type: 'webp',
                srcSet: buildPageImageSrc('screen-6-1'),
              },
            ],
          }}
        />
      </ViewPortfolioItemSection>
      {/* 7ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={false}
        style={{ backgroundColor: '#202020' }}
        className="portfolio-item-section--type-wildberries-7 view-portfolio-wildberries-section-width">
        <div className="row portfolio-item-section">
          <div className="portfolio-item-section__item-left">
            <div
              className={`portfolio-item-section__subtitle portfolio-item-section__subtitle--${language}`}>
              <RevealWhenInView>
                <p>{t('portfolioItem:wildberries.section-7.text.0')}</p>
              </RevealWhenInView>
            </div>
            <RevealWhenInView>
              <img
                src={buildPageImageSrc(
                  t('portfolioItem:wildberries.section-7.image')
                )}
                alt={'wildberries'}
              />
            </RevealWhenInView>
          </div>
          <div className="portfolio-item-section__item-right">
            <div className="portfolio-item-section__image-container">
              <div className="portfolio-item-section__image-1">
                <RevealWhenInView>
                  <img
                    src={buildPageImageSrc('screen-7-2')}
                    alt={'wildberries'}
                  />
                </RevealWhenInView>
              </div>
              <div className="portfolio-item-section__image-2">
                <RevealWhenInView>
                  <img
                    src={buildPageImageSrc('screen-7-3')}
                    alt={'wildberries'}
                  />
                </RevealWhenInView>
              </div>
            </div>
            <div
              className={`portfolio-item-section__description portfolio-item-section__description--${language}`}>
              <RevealWhenInView>
                <p>{t('portfolioItem:wildberries.section-7.text.1')}</p>
              </RevealWhenInView>
            </div>
          </div>
        </div>
      </ViewPortfolioItemSection>
      {/* 8ой экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={false}
        className="view-portfolio-wildberries-section-width portfolio-item-section--type-wildberries-8">
        <Parallax
          image={{
            src: buildPageImageSrc('screen-8-1'),
            sources: [
              {
                type: 'webp',
                srcSet: buildPageImageSrc('screen-8-1'),
              },
            ],
          }}
        />
        <div
          className={`portfolio-item-section__content portfolio-item-section__content--${language} view-portfolio-wildberries-container`}>
          <div className={`portfolio-item-section__text`}>
            <RevealWhenInView>
              <p className={`portfolio-item-section__text`}>
                {t('portfolioItem:wildberries.section-8.text')}
              </p>
            </RevealWhenInView>
          </div>
          <div className="portfolio-item-section__card-container">
            <div className="portfolio-item-section__card">
              <RevealWhenInView>
                <img
                  src={buildPageImageSrc(
                    t('portfolioItem:wildberries.section-8.image.0')
                  )}
                  alt={'wildberries'}
                />
              </RevealWhenInView>
              <div className="portfolio-item-section__card-text">
                <RevealWhenInView>
                  <p>{t('portfolioItem:wildberries.section-8.poster.0')}</p>
                </RevealWhenInView>
              </div>
            </div>
            <div className="portfolio-item-section__card">
              <RevealWhenInView>
                <img
                  src={buildPageImageSrc(
                    t('portfolioItem:wildberries.section-8.image.1')
                  )}
                  alt={'wildberries'}
                />
              </RevealWhenInView>
              <div className="portfolio-item-section__card-text">
                <RevealWhenInView>
                  <p>{t('portfolioItem:wildberries.section-8.poster.1')}</p>
                </RevealWhenInView>
              </div>
            </div>
          </div>
        </div>
      </ViewPortfolioItemSection>
      {/* 9ый экран*/}
      <ViewPortfolioItemSection
        style={{ backgroundColor: '#202020' }}
        dynamicBackgroundColor={false}
        className="portfolio-item-section--type-wildberries-9 view-portfolio-wildberries-section-width">
        <div className="portfolio-item-section">
          <div className="portfolio-item-section__video">
            <VimeoPlayer
              size="fill"
              url={t('portfolioItem:wildberries.section-9.video.0')}
              play
              muted
              controls={false}
              loop={true}
            />
          </div>
          <div className="portfolio-item-section__video">
            <VimeoPlayer
              size="fill"
              url={t('portfolioItem:wildberries.section-9.video.1')}
              play
              muted
              controls={false}
              loop={true}
            />
          </div>
        </div>
      </ViewPortfolioItemSection>
      {/* 10ый экран*/}
      <ViewPortfolioItemSection
        style={{ backgroundColor: '#202020' }}
        dynamicBackgroundColor={false}
        className="portfolio-item-section--type-wildberries-10 view-portfolio-wildberries-section-width">
        <div className="portfolio-item-section">
          <div className="portfolio-item-section__video">
            <VimeoPlayer
              size="fill"
              url={t('portfolioItem:wildberries.section-10.video')}
              play
              muted
              controls={false}
              loop={true}
            />
          </div>
        </div>
      </ViewPortfolioItemSection>
      {/* 11ый экран*/}
      <ViewPortfolioItemSection
        style={{ backgroundColor: '#202020' }}
        dynamicBackgroundColor={false}
        className="portfolio-item-section--type-wildberries-11 view-portfolio-wildberries-section-width">
        <Parallax
          image={{
            src: buildPageImageSrc('screen-11-1'),
            sources: [
              {
                type: 'webp',
                srcSet: buildPageImageSrc('screen-11-1'),
              },
            ],
          }}
        />
        <div
          className={`portfolio-item-section portfolio-item-section--${language} view-portfolio-wildberries-container`}>
          <div className="portfolio-item-section__text">
            <RevealWhenInView>
              <p>{t('portfolioItem:wildberries.section-11.text')}</p>
            </RevealWhenInView>
          </div>
          <div className="portfolio-item-section__card-container">
            <div className="portfolio-item-section__card">
              <RevealWhenInView>
                <img
                  src={buildPageImageSrc(
                    t('portfolioItem:wildberries.section-11.image.0')
                  )}
                  alt={'wildberries'}
                />
              </RevealWhenInView>
            </div>
            <div className="portfolio-item-section__card">
              <RevealWhenInView>
                <img
                  src={buildPageImageSrc(
                    t('portfolioItem:wildberries.section-11.image.1')
                  )}
                  alt={'wildberries'}
                />
              </RevealWhenInView>
            </div>
            <div className="portfolio-item-section__card">
              <RevealWhenInView>
                <img
                  src={buildPageImageSrc(
                    t('portfolioItem:wildberries.section-11.image.2')
                  )}
                  alt={'wildberries'}
                />
              </RevealWhenInView>
            </div>
          </div>
        </div>
      </ViewPortfolioItemSection>
      <ViewPortfolioItemSection
        style={{ backgroundColor: '#202020' }}
        dynamicBackgroundColor={false}
        className="portfolio-item-section--type-wildberries-12 view-portfolio-wildberries-section-width">
        <div className="portfolio-item-section">
          <div className="portfolio-item-section__video">
            <VimeoPlayer
              size="fill"
              url={t('portfolioItem:wildberries.section-12.video')}
              play
              muted
              controls={false}
              loop={true}
            />
          </div>
        </div>
      </ViewPortfolioItemSection>
    </>
  );
};

export default ViewPortfolioWildberries;
