import List from "components/common/List";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import Print from "components/common/Print";
import SectionLink from "components/layouts/SectionLink";
import * as routes from "routes";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";
import { useRef } from "react";

interface ViewAboutLinkSectionProps { }

const ViewAboutLinkSection = (props: ViewAboutLinkSectionProps) => {
  const servicesGroups = [
    {
      label: "Sites",
      items: ["Web-applications", "Landings", "Online-shops"],
    },
    {
      label: "Mobile",
      items: ["Ios", "Android"],
    },
    {
      label: "Branding",
      items: ["Logotype", "Corporate style", "Brand book", "Packing"],
    },
  ];

  const tickerText = ["All services", "Our services"];
  const triggerRef = useRef<HTMLDivElement>(null);

  return (
    <DynamicBackgroundTrigger triggerRef={triggerRef} start="start-=300px center">
      <SectionLink
        ref={triggerRef}
        className="view-about-link-section"
        label="All services"
        to={routes.services.path}
        tickerText={tickerText}
        description={() => (
          <List gap="xxl" items={servicesGroups}>
            {({ label, items }, i) => (
              <RevealWhenInView delay={0.25 * (i + 1)}>
                <div className="row view-about-link-section__service-group">
                  <div className="col-4">
                    <p className="label side-brackets">
                      <Print data={label} />
                    </p>
                  </div>
                  <div className="col-auto">
                    <List items={items} itemClassName="label">
                      {(item) => <Print data={item} />}
                    </List>
                  </div>
                </div>
              </RevealWhenInView>
            )}
          </List>
        )}
      />
    </DynamicBackgroundTrigger >
  );
};

export default ViewAboutLinkSection;
