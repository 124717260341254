import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";
import List from "components/common/List";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import Print from "components/common/Print";
import RevealTextByLine from "components/common/RevealInView/RevealTextByLine";
import { useRef } from "react";

interface ViewAboutObjectivesSectionProps {}

const ViewAboutObjectivesSection = (props: ViewAboutObjectivesSectionProps) => {
  const triggerRef = useRef<HTMLDivElement>(null);

  return (
    <DynamicBackgroundTrigger
      triggerRef={triggerRef}
      end="bottom-=300px center"
    >
      <section
        ref={triggerRef}
        className="view-about-objectives-section section text--light section--with-scroll-appear-background background--red"
      >
        <div className="view-about-objectives-section__container section__container container">
          <div className="view-about-objectives-section__inner">
            <header className="view-about-objectives-section__header">
              <p className="label view-about-objectives-section__overline">
                <RevealWhenInView tag="span" targetClassName="side-brackets">
                  <Print data="Mission" />
                </RevealWhenInView>
              </p>
              <h2 className="view-about-objectives-section__heading heading">
                {/* <span className="offset-lg-5">
                  <RevealWhenInView inline>
                    <Print data="view:about.objectives.title.part-0" />{" "}
                  </RevealWhenInView>
                </span> */}

                <Print data="view:about.objectives.title">
                  {(data) => (
                    <RevealTextByLine
                      wordClassName={(l, w) =>
                        l === 0 && w === 0 ? "offset-lg-5" : undefined
                      }
                    >
                      {data}
                    </RevealTextByLine>
                  )}
                </Print>
              </h2>
            </header>
            <div className="view-about-objectives-section__body row">
              <List
                items={Array.from({ length: 2 }, (_, i) => {
                  const key = `view:about.objectives.list.item-${i}`;

                  return {
                    label: `${key}.label`,
                    text: `${key}.text`,
                  };
                })}
                className="view-about-objectives-section__objectives-list col offset-md-2 col-md-7 offset-lg-5 col-lg-5 col-xl-4"
              >
                {(objective, i) => (
                  <RevealWhenInView key={i} delay={0.15 * (i + 1)}>
                    <div className="row">
                      <div className="col-11 col-md-3">
                        <p className="view-about-objectives-section__objectives-item-label label side-brackets">
                          <Print data={objective.label} />
                        </p>
                      </div>
                      <div className="col-11 col-md">
                        <Print data={objective.text}>
                          {(data) => (
                            <RevealTextByLine>{data}</RevealTextByLine>
                          )}
                        </Print>
               
                      </div>
                    </div>
                  </RevealWhenInView>
                )}
              </List>
            </div>
          </div>
        </div>
      </section>
    </DynamicBackgroundTrigger>
  );
};

export default ViewAboutObjectivesSection;
