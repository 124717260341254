import List from "components/common/List";
import ViewPortfolioItemImage, {
  ViewPortfolioItemImageData,
} from "../ViewPortfolioItemImage";
import ViewPortfolioItemSection, {
  ViewPortfolioItemSectionConfigProps,
} from "../ViewPortfolioItemSection";
import SlideFadeOutWhenInView from "../ViewPortfolioNovaGroup/SlideFadeOutWhenInView";

export interface ViewPortfolioSkessGallerySectionProps
  extends ViewPortfolioItemSectionConfigProps {
  images: ViewPortfolioItemImageData[]; //[[Image, Image, Image], [Image, [Image, Image]], [Image, Image]];
}

const ViewPortfolioSkessGallerySection = ({
  images,
  ...sectionProps
}: ViewPortfolioSkessGallerySectionProps) => {
  return (
    <ViewPortfolioItemSection
      {...sectionProps}
      className="view-portfolio-skess-gallery-section  u-overflow-hidden"
    >
      <div className="container">
        <div className="view-portfolio-skess-gallery-section__group">
          <div className="row gaps--horizontal-small gaps--vertical-small">
            {images.slice(0, 3).map((image, i) => (
              <ViewPortfolioItemImage
                key={i}
                className="col-11 offset-md-1 col-md-9 offset-lg-0 col-lg-5"
                {...image}
              />
            ))}

            <div className="col-11 offset-md-1 col-md-9 offset-lg-0 col-lg-5">
              <div className="row gaps--horizontal-small gaps--vertical-small">
                <ViewPortfolioItemImage
                  className="order-1 order-md-0 col-7 offset-2 offset-md-0 col-md-5"
                  {...images[3]}
                />

                <div className="order-0 order-md-1 col-11 col-md">
                  <div className="row gaps--horizontal-small">
                    {images.slice(4, 6).map((image, i) => (
                      <ViewPortfolioItemImage
                        key={i}
                        className="col"
                        {...image}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="view-portfolio-skess-gallery-section__group">
          <div className="row">
            <div className="col-11 offset-md-1 col-md-9 col-lg-3 offset-lg-1 view-portfolio-skess-gallery-section__group-col-with-list">
              <SlideFadeOutWhenInView>
                {({ setTarget, setTriggerEl }) => (
                  <div ref={setTriggerEl}>
                    <List
                      ref={setTarget({ selector: ".list__item" })}
                      items={[1440, 768, 414].map((s) => s + " px")}
                    />
                  </div>
                )}
              </SlideFadeOutWhenInView>
            </div>
            <div className="col-11 offset-md-1 col-md-9 offset-lg-0 col-lg">
              <div className="row gaps--horizontal-small">
                {images.slice(6).map((image, i) => (
                  <ViewPortfolioItemImage key={i} className="col" {...image} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ViewPortfolioItemSection>
  );
};

export default ViewPortfolioSkessGallerySection;
