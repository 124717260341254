import ViewPortfolioEnsembleBranding from 'components/views/ViewPortfolioItem/ViewPortfolioEnsembleBranding';
import { PortfolioItemDataWithComponent } from "data/portfolio/types";
import {buildPublicImageSrc} from "../../helpers/path";


const porfolioItem: PortfolioItemDataWithComponent = {
  id: "ensemble-branding",
  component: ViewPortfolioEnsembleBranding,
  title: "ensemble",
  name: "Ensemble",
  altName: "New Islamic\nonline-banking in France",
  year: 2021,
  link: {
    label: "",
    href: "",
  },

  socials: [
    {
      label: "",
      href:
        "",
    },
  ],

  preview: {
    label: "presentation",
    media: {
      type: "image",
      fromSrc: buildPublicImageSrc({
        path: "portfolios/ensemble/preview",
        file: "1-1440w.webp",
      }),
      toSrc: buildPublicImageSrc({
        path: "portfolios/ensemble/preview",
        file: "2-1440w.webp",
      }),
    },
  },

  poolOfWorks: ["Presentation"],
  relatedId: "ensemble",
  type: "branding",
  subtype: "branding",

  description: [
    "Rapidly developing French startup. bank, which financial activities is based on sharia principles."
  ],
  shortDescription:
      "Presentation of the French online bank Ensemble",
  seo: {
    title: "Creating a presentation of the French online bank Ensemble",
    description: "Designing a beautiful presentation for the Ensemble company. An example of a created presentation for a technology startup in the banking area",
    keywords: ["заказать дизайн презентации",
      "order presentation design",
      "presentation for startup",
      "creating a presentation",
      "creating a beautiful presentation",
      "powerpoint presentation"],
    openGraph: {
      "type": "article",
      "title": "Creating a presentation of the French online bank Ensemble",
      "description": "Designing a beautiful presentation for the Ensemble company. An example of a created presentation for a technology startup in the banking area"
    }
  }

};

export default porfolioItem;
