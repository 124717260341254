import {
  cloneElement,
  ReactElement,
  RefObject,
  useCallback,
  useRef,
} from "react";
import { SwitchTransition, Transition } from "react-transition-group";

type ChangeTransitionState = "enter" | "exit";

export interface ChangeTransitionOnExitEnterParams {
  /**
   * функция триггер для завершения анимации
   */
  done: () => void;
  /**
   * Узел который нужно анимировать
   */
  node: HTMLElement | null;
}

export interface ChangeTransitionOnTransitionParams
  extends ChangeTransitionOnExitEnterParams {
  /**
   * Состояние анимации
   */
  state: ChangeTransitionState;
}

interface ChangeTransitionProps {
  /**
   * Триггер который будет задействовать анимацию
   */
  trigger: any;
  /**
   * Узел, который нужно анимировать. Обычно не нужно и
   */
  nodeRef?: RefObject<HTMLElement>;
  children: ReactElement;
  /**
   * Функция которая будет контролировать анимацию появления
   */
  onEnter?: (params: ChangeTransitionOnExitEnterParams) => void;
  /**
   * Функция которая будет контролировать анимацию исчезания
   */
  onExit?: (params: ChangeTransitionOnExitEnterParams) => void;
  /**
   * Функция объединяет в себе работу функций onEnter и onExit
   */
  onTransition?: (params: ChangeTransitionOnTransitionParams) => void;
}

/**
 * Позволяет создать анимацию смены элемента
 */
const ChangeTransition = ({
  trigger,
  children,
  nodeRef,
  onExit,
  onEnter,
  onTransition,
}: ChangeTransitionProps) => {
  const stateRef = useRef<ChangeTransitionState>("exit");
  const ref = useRef<HTMLElement>(null);
  const isAnimatingRef = useRef(false);

  const handleTransition = useCallback(
    (_done: () => void) => {
      if (isAnimatingRef.current) {
        _done();

        return;
      }

      const done = () => {
        stateRef.current = stateRef.current === "exit" ? "enter" : "exit";

        isAnimatingRef.current = false;

        _done();
      };

      const state = stateRef.current;
      const node = nodeRef ? nodeRef.current : ref.current;

      if (onTransition) {
        isAnimatingRef.current = true;

        onTransition({
          done,
          state,
          node,
        });
      } else {
        const transitionHandler = state === "exit" ? onExit : onEnter;

        if (transitionHandler) {
          isAnimatingRef.current = true;

          transitionHandler({
            done,
            node,
          });
        } else {
          done();
        }
      }
    },
    [onEnter, onExit, onTransition, nodeRef]
  );

  return (
    <SwitchTransition>
      <Transition
        addEndListener={handleTransition}
        nodeRef={nodeRef || ref}
        key={trigger}
      >
        {nodeRef ? children : cloneElement(children, { ref })}
      </Transition>
    </SwitchTransition>
  );
};

export default ChangeTransition;
