export type GetScaleFactorType = (
  targetWidth: number,
  targetHeight: number,
  height: number,
  width: number
) => number;

export const getScaleFactorToCover: GetScaleFactorType = (
  targetWidth,
  targetHeight,
  height,
  width
) => {
  return Math.max(targetWidth / height, targetHeight / width);
};

export const getScaleFactorToContain: GetScaleFactorType = (
  targetWidth,
  targetHeight,
  height,
  width
) => {
  return Math.min(targetWidth / height, targetHeight / width);
};
