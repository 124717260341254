import React, { ElementType, useCallback, useEffect, useRef, useState } from "react";

import gsap from "gsap";

import SplitText, { SplitTextProps } from "../SplitText";

interface RevealTextByLineProps extends Pick<SplitTextProps, "lineClassName" | "wordClassName"> {
  tag?: ElementType<any>;
  className?: string;
  innerClassName?: string;
  children: string;
  // text: string[]
}

const RevealTextByLine = ({
  tag: Tag = "div",
  className,
  innerClassName,
  lineClassName,
  wordClassName,
  children,
}: RevealTextByLineProps) => {
  const triggerElRef = useRef<HTMLElement>(null);
  const targetElRef = useRef<HTMLElement>(null);
  const [linesElms, setLinesElms] = useState<(HTMLElement | null)[]>([])

  useEffect(() => {
    if (linesElms.length === 0) {
      return;
    }

    const triggerEl = triggerElRef.current!;
    const targetEl = targetElRef.current!;

    const timeline = gsap.timeline({
      paused: true,
      scrollTrigger: {
        trigger: triggerEl,
        onToggle: (sr) => {
          if (sr.isActive) {
            // не проигрываем анимацию при смене размера экрана
            if (sr.getVelocity() === 0) {
              timeline.progress(1);
            } else {
              timeline.play();
            }
          } else {
            timeline.pause(0);
          }
        }
      },
    });

    timeline.fromTo(targetEl, {
      y: 40,
    }, {
      y: 0,
      ease: "ease-out",
      duration: 0.6 + 0.2 * (linesElms.length - 1),
    });

    timeline.fromTo(
      linesElms,
      {
        y: 20,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        stagger: 0.2,
        ease: "ease-out",
        duration: 0.6,
      },
      "<"
    );

    return () => {
      timeline.clear();
      timeline.kill();
    };
  }, [linesElms]);

  const handleSplitTextUpdate = useCallback(({ lines }: { lines: (HTMLElement | null)[] }) => {
    setLinesElms(lines);
  }, []);

  return (
    //@ts-ignore
    <Tag ref={triggerElRef} className={className}>
      <SplitText ref={targetElRef} onUpdate={handleSplitTextUpdate} lineClassName={lineClassName} wordClassName={wordClassName} className={innerClassName}>
        {children}
      </SplitText>
    </Tag>
  );
};

export default RevealTextByLine;
