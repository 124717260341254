import { useEffect, useRef } from "react";
import ViewPortfolioItemSection from "../ViewPortfolioItemSection";
import gsap from "gsap";
import VimeoPlayer from "components/common/VimeoPlayer";
import {useTranslation} from "react-i18next";

interface ViewPortfolioNovaGroupLandingPageSectionProps {
  video: {
    src: string
  };
}

const ViewPortfolioNovaGroupLandingPageSection = ({
  video,
}: ViewPortfolioNovaGroupLandingPageSectionProps) => {
  const infoInnerElRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  useEffect(() => {
    const infoInnerEl = infoInnerElRef.current!;
    const elmsToAnimate = infoInnerEl.querySelectorAll("p");

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: infoInnerEl,
        toggleActions: "play reset play reset",
      },
    });

    timeline.from(infoInnerEl, {
      y: 40,
      ease: "ease-out",
      duration: 0.6 + 0.2 * (elmsToAnimate.length - 1),
    });

    timeline.from(
      elmsToAnimate,
      {
        y: 20,
        opacity: 0,
        ease: "ease-out",
        duration: 0.6,
        stagger: 0.2,
      },
      "<"
    );

    return () => {
      timeline.kill();
    };
  }, []);

  return (
    <ViewPortfolioItemSection
      size={{
        top: "sm",
        bottom: "lg",
      }}
      className="view-portfolio-nova-group-gallery-section__third-group"
    >
      <div className="view-portfolio-nova-group-gallery-section__third-group-container container">
        <div className="row">
          <div className="view-portfolio-nova-group-gallery-section__third-group-col-with-info col-11">
            <div className="view-portfolio-nova-group-gallery-section__third-group-info">
              {/* <RevealWhenInView> */}
              <div
                ref={infoInnerElRef}
                className="view-portfolio-nova-group-gallery-section__third-group-info-inner row"
              >
                <p className="view-portfolio-item__text col-11">
                  {t('portfolioItem:nova-group.landing-section.caption.0')}
                </p>

                <div className="view-portfolio-nova-group-gallery-section__third-group-info-group col-auto offset-md-3">
                  <p>
                    {t('portfolioItem:nova-group.landing-section.caption.1')}
                  </p>
                </div>
              </div>
              {/* </RevealWhenInView> */}
            </div>
          </div>

          <div className="view-portfolio-nova-group-gallery-section__third-group-col-with-image u-ratio u-ratio--3/5 col-11 col-xxl-8 offset-xxl-3">
            <VimeoPlayer size="fill" url={video.src} play muted controls={false} />
          </div>
        </div>
      </div>
    </ViewPortfolioItemSection>
  );
};

export default ViewPortfolioNovaGroupLandingPageSection;
