import Copyright from 'components/layouts/Copyright/Copyright';
import List from 'components/common/List';
import { contacts, socials, socialsEn } from 'data/contacts';
import { memo, useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import Print from 'components/common/Print';
import { MorphSvgWord } from 'components/common/MorphSvg';
import View from 'components/layouts/View';
import Section from 'components/layouts/Section';
import { useTranslation } from 'react-i18next';
import portfolio from '../../../data/portfolio';
import i18n from 'i18next';

export interface ContactsProps {}

const Contacts = (props: ContactsProps) => {
  const [morphTitle, setMorphTitle] = useState(false);
  const [arSocials, setSocials] = useState(socials);
  const { t } = useTranslation();
  const titleRef = useRef<SVGSVGElement>(null);
  const titleBottomRef = useRef<SVGSVGElement>(null);
  const contactsLabelsRef = useRef<(HTMLElement | null)[]>([]);
  const contactsItemsRef = useRef<(HTMLElement | null)[]>([]);
  const socialsRef = useRef<(HTMLElement | null)[]>([]);
  const copyrightRef = useRef<HTMLParagraphElement>(null);
  useEffect(() => {
    if (i18n.language === 'en') {
      setSocials(socialsEn);
    } else {
      setSocials(socials);
    }
  }, [i18n.language]);
  useEffect(() => {
    const titleElem = titleRef.current!;
    const titleBottomElem = titleBottomRef.current!;
    const contactsLabelsElem = contactsLabelsRef.current!;
    const contactsItemsElem = contactsItemsRef.current!;
    const socialsElem = socialsRef.current!;
    const copyrightElem = copyrightRef.current!;

    const timeline = gsap.timeline({
      delay: 0.3,
      defaults: { ease: 'ease-out', duration: 0.6 },
    });

    const isMediaMinMd = matchMedia('(min-width: 768px)').matches;
    const isMediaMinXxl = matchMedia('(min-width: 1440px)').matches;

    if (!isMediaMinMd) {
      timeline.from(titleElem, {
        y: 15,
        opacity: 0,
      });
    }

    timeline.from(
      contactsLabelsElem,
      {
        y: 15,
        opacity: 0,
        stagger: 0.2,
      },
      !isMediaMinMd ? '-=0.4' : ''
    );

    timeline.from(
      contactsItemsElem,
      {
        y: 15,
        opacity: 0,
        stagger: 0.2,
      },
      '<'
    );

    timeline.from(
      socialsElem,
      {
        y: 15,
        opacity: 0,
        stagger: 0.2,
      },
      isMediaMinXxl ? '<' : '-=0.4'
    );

    if (isMediaMinMd) {
      timeline.from(
        titleBottomElem,
        {
          y: 15,
          opacity: 0,
        },
        '-=0.4'
      );
    }

    timeline.from(
      copyrightElem,
      {
        ...(isMediaMinMd ? { y: 15 } : { y: 15 }),
        opacity: 0,
        onComplete: () => {
          gsap.set(copyrightElem, {
            clearProps: 'all',
          });
        },
      },
      isMediaMinMd ? '<' : '-=0.4'
    );
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setMorphTitle(true);
    }, 1600);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    // <ViewportHeightContainer
    //   tag="section"
    //   className="view-contacts lead-section"
    //   innerClassName="view-contacts__container lead-section__container container"
    // >
    <View>
      <Section viewportHeight isLead className="view-contacts">
        <div className="view-contacts__container lead-section__container container">
          <div className="view-contacts__inner lead-section__inner ">
            <header className="view-contacts__header">
              <MorphSvgWord
                ref={titleRef}
                className="svg-title view-contacts__title"
                word={t('view:contacts.title')}
                morph={morphTitle}
              />
            </header>

            <div className="view-contacts__body lead-section__body row">
              <address className="view-contacts__address row col-11 offset-md-4 col-md offset-xl-5">
                <List
                  items={contacts}
                  className="view-contacts__contacts-list col-11 col-xl"
                  itemClassName="view-contacts__contacts-list-item"
                  gap="md">
                  {(contact, i) => (
                    <div className="row">
                      <div
                        ref={(el) => (contactsLabelsRef.current[i] = el)}
                        className="view-contacts__col-with-contacts-list-item-label col-4">
                        <p className="label side-brackets">
                          <Print data={contact.name!} />
                        </p>
                      </div>
                      <div
                        ref={(el) => (contactsItemsRef.current[i] = el)}
                        className="col">
                        {contact.href ? (
                          <a
                            href={contact.href}
                            target="_blank"
                            rel="noreferrer"
                            className="label link link--normal">
                            <Print data={contact.label} />
                          </a>
                        ) : (
                          <p className="label">
                            <Print data={contact.label} />
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </List>

                <List
                  items={arSocials}
                  className="view-contacts__socials-list col-11 col-xl-auto"
                  itemClassName="view-contacts__socials-list-item row"
                  gap="md">
                  {(social, i) => (
                    <div
                      ref={(el) => (socialsRef.current[i] = el)}
                      className="col offset-4 offset-xl-0">
                      <a
                        className="label link link--normal"
                        href={social.href}
                        target="_blank"
                        rel="noreferrer nofollow">
                        {social.label}
                      </a>
                    </div>
                  )}
                </List>
              </address>
            </div>

            <footer className="view-contacts__footer row">
              <div className="view-contacts__col-with-footer-title col">
                <MorphSvgWord
                  ref={titleBottomRef}
                  className="view-contacts__title view-contacts__title--footer svg-title"
                  word={t('view:contacts.title')}
                  morph={morphTitle}
                />
              </div>

              <div
                ref={copyrightRef}
                className="view-contacts__col-with-copyright col col-md-1 col-xl-auto offset-4 offset-md-0">
                <Copyright className="view-contacts__copyright" />
              </div>
            </footer>
          </div>
        </div>
      </Section>
    </View>
  );
};

export default memo(Contacts);
