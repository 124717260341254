import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";
import List from "components/common/List";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import Print from "components/common/Print";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import RevealTextByLine from "components/common/RevealInView/RevealTextByLine";
import {useTranslation} from "react-i18next";

interface VacancyBonusesSectionProps { }

const VacancyBonusesSection = (props: VacancyBonusesSectionProps) => {
  const bonusesInfos = Array.from({ length: 3 }, (_, i) => ({
    name: `view:vacancies.bonuses.list.${i}.name`,
    description: `view:vacancies.bonuses.list.${i}.description`,
  }));

  const bonusesElRef = useRef<HTMLUListElement>(null);
  const bodyElRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const bonusesEl = bonusesElRef.current!;
    const bodyEl = bodyElRef.current!;
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: bodyEl,
        toggleActions: "play reset play reset",
      },
    });

    const labelsElms = bonusesEl.querySelectorAll(
      ".vacancy-bonuses-section__bonus-col-with-label"
    )!;
    const descriptionsElms = bonusesEl.querySelectorAll(
      ".vacancy-bonuses-section__bonus-col-with-description"
    )!;

    timeline.from(bonusesEl, {
      y: 30,
      ease: "ease-out",
      duration: 0.6 + 0.2 * (labelsElms.length - 1),
    });

    timeline.from(
      labelsElms,
      {
        y: 15,
        opacity: 0,
        stagger: 0.2,
        ease: "ease-out",
        duration: 0.6,
      },
      "<"
    );

    timeline.from(
      descriptionsElms,
      {
        y: 15,
        opacity: 0,
        stagger: 0.2,
        ease: "ease-out",
        duration: 0.6,
      },
      "<"
    );

    return () => {
      timeline.kill();
    };
  }, []);

  const { t } = useTranslation();

  return (
    <DynamicBackgroundTrigger>
      <section className="vacancy-bonuses-section">
        <div className="vacancy-bonuses-section__container container">
          <header className="vacancy-bonuses-section__header">
            <h2 className="vacancy-bonuses-section__heading heading">
              <RevealTextByLine wordClassName={(l, w) => l === 0 && w === 0 ? "offset-3 offset-sm-4 offset-xl-5" : undefined}>{t("view:vacancies.bonuses.title")}</RevealTextByLine>
            </h2>
            <p className="vacancy-bonuses-section__subheading">
              <RevealWhenInView tag="span" inline>
                <span className="label side-brackets">hugge</span>
              </RevealWhenInView>
            </p>
          </header>
          <div ref={bodyElRef} className="vacancy-bonuses-section__body row">
            <List
              className="vacancy-bonuses-section__bonuses col-11 offset-md-3 col-md offset-xl-5 col-xl-4"
              items={bonusesInfos}
              ref={bonusesElRef}
              itemClassName="vacancy-bonuses-section__bonus"
            >
              {({ name, description }, i) => (
                <div className="row">
                  <div className="vacancy-bonuses-section__bonus-col-with-label col-11 col-md-3 col-xl-3">
                    <p className="vacancy-bonuses-section__bonus-label label side-brackets">
                      <Print data={name} />
                    </p>
                  </div>
                  <div className="vacancy-bonuses-section__bonus-col-with-description col-11 col-md">
                    <p>
                      <Print data={description} />
                    </p>
                  </div>
                </div>
                // <>
                //   <RevealWhenInView
                //     tag="p"
                //     targetTag="span"
                //     className="vacancy-bonuses-section__bonus-col-with-label col-11 col-md-3 col-xl-3"
                //   >
                //     <span className="vacancy-bonuses-section__bonus-label label side-brackets">
                //       <Print data={name} />
                //     </span>
                //   </RevealWhenInView>
                //   <RevealWhenInView
                //     tag="p"
                //     targetTag="span"
                //     className="vacancy-bonuses-section__bonus-col-with-description col-11 col-md"
                //   >
                //     <span>
                //       <Print data={description} />
                //     </span>
                //   </RevealWhenInView>
                // </>
              )}
            </List>
          </div>
        </div>
      </section>
    </DynamicBackgroundTrigger>
  );
};

export default VacancyBonusesSection;
