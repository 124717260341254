import { ReactComponent as LogoSvg } from "assets/images/logo.svg";
import {Link, useLocation} from "react-router-dom";
import {home as homeRoute} from "routes";
import classNames from "classnames";
import {useMemo} from "react";
import i18n from "i18next";

interface LogoProps {
  className?: string;
}


const Logo = ({ className }: LogoProps) => {
    const location = useLocation();
    let nextLangCode = useMemo(() => {
        return location.pathname.indexOf("/en") !== -1  ? "/en" : "";
    }, [i18n.language, location]);

  return (
    <Link to={nextLangCode + homeRoute.path.replace('/:locale( |en)?', "")} className={classNames("logo link", className)}>
      <LogoSvg className="logo__svg" />
    </Link>
  );
};

export default Logo;
