import List from "components/common/List";

import ViewPortfolioItemImage, {
  ViewPortfolioItemImageData,
} from "../ViewPortfolioItemImage";
import ViewPortfolioItemSection, {
  ViewPortfolioItemSectionConfigProps,
} from "../ViewPortfolioItemSection";
import SlideFadeOutWhenInView from "../ViewPortfolioNovaGroup/SlideFadeOutWhenInView";

interface ViewPortfolioAtlanticSpecialistCardSectionProps
  extends ViewPortfolioItemSectionConfigProps {
  caption: [string, (number | string)[]];
  images: ViewPortfolioItemImageData[]; // [[Image, Image], [Image, Image], [Image, Image, Image]];
}

const ViewPortfolioAtlanticSpecialistCardSection = ({
  caption,
  images,
  ...sectionProps
}: ViewPortfolioAtlanticSpecialistCardSectionProps) => {
  return (
    <ViewPortfolioItemSection
      {...sectionProps}
      className="view-portfolio-atlantic-specialist-card-section"
    >
      <div className="view-portfolio-atlantic-specialist-card-section__container container">
        <header className="view-portfolio-atlantic-specialist-card-section__header row">
          <SlideFadeOutWhenInView>
            {({ setTarget, setTriggerEl }) => (
              <div
                ref={setTriggerEl}
                className="col-xl-4 offset-xl-1 row u-max-w-600"
              >
                <p
                  ref={setTarget()}
                  className="col-11 view-portfolio-item__text"
                >
                  {caption[0]}
                </p>
                <List
                  ref={setTarget({
                    selector: ".list__item",
                  })}
                  gap="sm"
                  items={caption[1]}
                  className="view-portfolio-atlantic-specialist-card-section__header-list col-auto offset-md-2 offset-xl-7"
                />
              </div>
            )}
          </SlideFadeOutWhenInView>
        </header>
        <div className="row gaps--vertical-medium gaps--horizontal-medium">
          <ViewPortfolioItemImage
            className="order-3 order-md-1 col-5 offset-md-1 col-md-2 col-xl-2 offset-xl-2"
            {...images[0]}
          />
          <ViewPortfolioItemImage
            className="order-1 order-md-2 col-11 col-md-7 col-xl-7"
            {...images[1]}
          />

          <ViewPortfolioItemImage
            className="order-2 order-md-3 col-11 col-md-6 offset-md-1 offset-xl-1 col-xl-6"
            {...images[2]}
          />
          <ViewPortfolioItemImage
            className="order-4 order-md-4 col-6 col-md-3 col-xl-3"
            {...images[3]}
          />

          <ViewPortfolioItemImage
            className="view-portfolio-atlantic-specialist-card-section__col-with-card order-2 order-md-last col col-md-3 offset-md-1 offset-xl-3 col-xl-2 col-xxl-1 offset-xxl-4"
            {...images[4]}
          />
          <ViewPortfolioItemImage
            className="view-portfolio-atlantic-specialist-card-section__col-with-card order-2 order-md-last col col-md-3 col-xl-2 col-xxl-1"
            {...images[5]}
          />
          <ViewPortfolioItemImage
            className="view-portfolio-atlantic-specialist-card-section__col-with-card order-2 order-md-last col col-md-3 col-xl-2 col-xxl-1"
            {...images[6]}
          />
        </div>
      </div>
    </ViewPortfolioItemSection>
  );
};

export default ViewPortfolioAtlanticSpecialistCardSection;
