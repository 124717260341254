import { forwardRef, memo, ReactNode, Ref, useEffect, useImperativeHandle, useRef } from 'react'

import classNames from "classnames";
import gsap from "gsap";

import { useDynamicBackgroundColor } from 'components/common/DynamicBackground';

interface ViewProps {
  className?: string;
  children?: ReactNode;
  dynamicBackground?: boolean;
}

type ViewRef = HTMLDivElement;

const View = ({ className, children }: ViewProps, ref: Ref<ViewRef>) => {
  const viewRef = useRef<HTMLDivElement>(null);
  const { defaultBackgroundColor, setBackgroundColor } = useDynamicBackgroundColor();

  useImperativeHandle(ref, () => viewRef.current!);

  useEffect(() => {
    // ScrollTrigger.refresh();

    setBackgroundColor(defaultBackgroundColor);

  }, [defaultBackgroundColor, setBackgroundColor]);

  useEffect(() => {
    const viewEl = viewRef.current!;

    gsap.set(viewEl, {
      visibility: "visible"
    })
  }, []);

  return (
    <div ref={viewRef} style={{ visibility: "hidden" }} className={classNames("view", className)}>
      {children}
    </div>
  )
}

export default memo(forwardRef<ViewRef, ViewProps>(View))
