import ViewPortfolioCsd from "components/views/ViewPortfolioItem/ViewPortfolioCsd";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";

const porfolioItem: PortfolioItemDataWithComponent = {
    id: "csd",
    component: ViewPortfolioCsd,
    title: "csd",
    name: "CSD",
    altName: "One of the leading CAD \n distribution companies",
    year: 2022,
    awardsList: [
        {imgSrc : "/images/awards/gray-bg/awwwards.png"},
        {imgSrc : "/images/awards/gray-bg/special-kudos.png"},
        {imgSrc : "/images/awards/gray-bg/ux.png"},
        {imgSrc : "/images/awards/gray-bg/ui.png"},
        {imgSrc : "/images/awards/gray-bg/innovation.png"},
    ],
    vc: {
      label: 'vc.ru',
      href: 'https://vc.ru/design/501074-bez-chetkogo-tz-rezultat-nagrada-na-awwwards-ili-instrukciya-kak-razrabotat-dizayn-infoportala-po-bim',
    },
    link: {
        label: "csd.sagirov.com",
        href: "https://csd.sagirov.com/",
    },
    type: "site",
    subtype: "portal",

    preview: {
        label: "corporate site",
        backgroundColor: "#1F1F21",
        media: {
            type: "video",
            src: "https://vimeo.com/745421782?h=3c914048ee",
        },
    },

    socials: [
        {
            label: "behance",
            href:
                "https://www.behance.net/gallery/136473287/WEBSITE-CSD-BIM-3D-construction-modeling",
        },
    ],

    poolOfWorks: [
        "UX|UI design",
        "Animation",
        "Page making"
    ],


    description:
        [
            "is\u00A0a\u00A0leading distributor in\u00A0the field of\u00A0computer-aided design systems, as\u00A0well as\u00A0monitoring the construction of\u00A0facilities",
            "We\u00A0have created a\u00A0website that reflects the full range of\u00A0services and software products provided by\u00A0the company"

        ],
    shortDescription:
        "Development of an information portal on BIM technologies",
    seo: {
        title: "Development of an information portal on BIM technologies",
        description: "Creation of a website for BIM design and Autodesk software application with detailed examples, webinars and articles",
        keywords: ["website development, information portal creation, bim design, bio technologies, bio website development, bio modeling website, csd company website"],
        openGraph: {
            type: "article",
            title: "Development of an information portal on BIM technologies",
            description: "Creation of a website for BIM design and Autodesk software application with detailed examples, webinars and articles",
        }
    },
    pageContent: {
        sections: {
            mobileVersion: {
                theme: {
                    color: "#fff", backgroundColor: "#0242EC"
                },
                frameCount: 161
            }
        }
    }
};

export default porfolioItem;
