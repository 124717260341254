import Image, { ImageConfig } from "components/common/Image";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import ViewPortfolioItemSection, {
  ViewPortfolioItemSectionConfigProps,
} from "../ViewPortfolioItemSection";
import SlideFadeOutWhenInView from "../ViewPortfolioNovaGroup/SlideFadeOutWhenInView";

export interface ViewPortfolioWomanSportBrandingCardsSectionProps
  extends ViewPortfolioItemSectionConfigProps {
  caption: [string, string];
  image: ImageConfig;
}

const ViewPortfolioWomanSportBrandingCardsSection = ({
  caption,
  image,
  ...sectionProps
}: ViewPortfolioWomanSportBrandingCardsSectionProps) => {
  return (
    <ViewPortfolioItemSection
      {...sectionProps}
      className="view-portfolio-item-section view-portfolio-item-section--layout-wsb-cards"
    >
      <div className="view-portfolio-item-section__container">
        <header className="view-portfolio-item-section__header container">
          <SlideFadeOutWhenInView>
            {({ setTriggerEl, setTarget }) => (
              <div
                ref={setTriggerEl}
                className="offset-xxl-1 col-xxl-3 row u-max-w-360"
              >
                <p
                  ref={setTarget()}
                  className="col-11 view-portfolio-item-section__text"
                >
                  {caption[0]}
                </p>

                <p ref={setTarget()} className="offset-md-1 offset-xxl-2">
                  {caption[1]}
                </p>
              </div>
            )}
          </SlideFadeOutWhenInView>
        </header>
        <div className="view-portfolio-item-section__body">
          <RevealWhenInView>
            <Image {...image} />
          </RevealWhenInView>
        </div>
      </div>
    </ViewPortfolioItemSection>
  );
};

export default ViewPortfolioWomanSportBrandingCardsSection;
