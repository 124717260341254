import { Helmet } from "react-helmet-async";
import { useCurrentRoute } from "routes/helpers";
import { Seo } from "types/global";
import getOgItems from "./helpers";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo } from "react";

interface HeadProps {
  seo?: Seo;
}

/**
 * Позволяет указать мета теги для тэга head
 */
const Head = ({ seo }: HeadProps) => {
  const routeSeo = useCurrentRoute()?.seo;
  const { i18n } = useTranslation();

  const langCode = useMemo(() => {
    return i18n.language;
  }, [i18n.language]);

  if (!seo && typeof routeSeo !== "undefined") {
    // @ts-ignore
    seo = routeSeo[langCode];
  }

  const mirrorLangCode = useMemo(() => {
    return i18n.language === "ru" ? "en" : "ru";
  }, [i18n.language]);

  useEffect(() => {
    document.querySelector("html")?.setAttribute("lang", langCode);
  });

  return seo ? (
    //@ts-ignore
    <Helmet>
      <title>{seo.title}</title>
      <meta http-equiv="content-language" content={langCode}></meta>
      {seo.alternative && (
        <link
          rel="alternate"
          hrefLang={mirrorLangCode}
          href={seo.alternative}
        />
      )}
      {seo.keywords && (
        <meta name="keywords" content={seo.keywords.join(", ")} />
      )}
      {seo.description && <meta name="description" content={seo.description} />}
      {seo.openGraph &&
        getOgItems(seo.openGraph).map(([name, content], i) => {
          return <meta key={i} property={name} content={content} />;
        })}
    </Helmet>
  ) : null;
};

export default Head;
