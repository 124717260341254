import ViewHomeLeadSection from "./ViewHomeLeadSection";
import ViewHomePortfolioListSection from "./ViewHomePortfolioSection";
import PortfolioLinkSection from "components/layouts/PortfolioLinkSection";
import { memo, useRef } from "react";

import Footer from "components/layouts/Footer";
import View from "components/layouts/View";
import AwardsSection from "../../layouts/AwardsSection";


interface ViewHomeProps { }

const ViewHome = (props: ViewHomeProps) => {
  const viewElRef = useRef<HTMLDivElement>(null);
  const portfolioLinkSectionElRef = useRef<HTMLDivElement>(null);

  return (
    <View ref={viewElRef}>
      <ViewHomeLeadSection  />
      <ViewHomePortfolioListSection />
      <PortfolioLinkSection ref={portfolioLinkSectionElRef} />
      <AwardsSection />
      <Footer />
    </View>
  );
};

export default memo(ViewHome);
