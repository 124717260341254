import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import locales from "./locales";

i18n.use(LanguageDetector).init({
  fallbackLng: "ru",
  resources: locales,
  whitelist: ['en', 'ru'],
  detection:{
    order: ['path'],
    lookupFromPathIndex: 0,
  },
  interpolation: { escapeValue: false },
});


export default i18n;
