import ViewPortfolioOpzero from 'components/views/ViewPortfolioItem/ViewPortfolioOpzero';
import { PortfolioItemDataWithComponent } from 'data/portfolio/types';
import { buildPublicImageSrc } from '../../helpers/path';

const porfolioItem: PortfolioItemDataWithComponent = {
  id: 'opzero',
  component: ViewPortfolioOpzero,
  title: 'opzero',
  name: 'Operation zero',
  altName: 'Platform for purchasing zero-day exploits',
  year: 2022,
  awardsList: [{ imgSrc: '/images/awards/gray-bg/DP-graphic.svg' }],

  socials: [
    {
      label: 'behance',
      href: 'https://www.behance.net/gallery/159933693/Operation-zero',
    },
  ],
  vc: {
    label: 'vc.ru',
    href: 'https://vc.ru/design/581248-operation-zero-stilnyy-brending-dlya-hakerov',
  },

  preview: {
    label: 'corporate identity',
    media: {
      type: 'image',
      fromSrc: buildPublicImageSrc({
        path: 'portfolios/wildberries/preview',
        file: '1-1440w.webp',
      }),
      toSrc: buildPublicImageSrc({
        path: 'portfolios/wildberries/preview',
        file: '2-1440w.webp',
      }),
    },
  },

  poolOfWorks: ['Logotype', 'Brand identity'],

  type: 'branding',
  subtype: 'branding',

  description: [
    'is a platform for acquiring zero-day vulnerabilities in software, the so-called zero-day exploits. Built by security professionals, the platform provides customers with unprecedented technology for offensive and defensive operations',
  ],
  shortDescription:
    'branding for the Platform for purchasing zero-day exploits',

  seo: {
    title: 'Operation Zero corporate identity design',
    description:
      'We have created a unique corporate identity for Operation Zero, which has developed a platform for the sale of zero day exploits. As part of the work, we have developed a logo, elements of corporate identity, a presentation for use at a conference, posters, social networks and design of printing',
    keywords: [
      'corporate identity for it companies',
      'branding for companies',
      'corporate identity for startups',
      'logo development',
      'presentation design',
      'design of posts in social networks',
      'font logo for the company',
    ],
    openGraph: {
      type: 'article',
      title: 'Operation Zero corporate identity design',
      description:
        'We have created a unique corporate identity for Operation Zero, which has developed a platform for the sale of zero day exploits. As part of the work, we have developed a logo, elements of corporate identity, a presentation for use at a conference, posters, social networks and design of printing',
    },
  },
};

export default porfolioItem;
