import { usePortfolioItemAssets } from "hooks/useAssets";
import { useViewPortfolioItemContext } from "../ViewPortfolioItem";
import ViewPortfolioItemAboutSection from "../ViewPortfolioItemAboutSection";
import ViewPortfolioItemParallaxSection from "../ViewPortfolioItemParallaxSection";
import ViewPortfolioItemSection from "../ViewPortfolioItemSection";
import RevealWhenInView from "../../../common/RevealInView/RevealWhenInView";
import React from "react";
import {useTranslation} from "react-i18next";
import Print from "../../../common/Print";
import ViewPortfolioItemRelatedSection from "../ViewPortfolioItemRelatedSection";

export interface ViewPortfolioEnsembleProps { }

const ViewPortfolioEnsembleBranding = (props: ViewPortfolioEnsembleProps) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);
  const { t } = useTranslation();

  return (
    <>
      <ViewPortfolioItemAboutSection
        style={{ backgroundColor: "#57AEB1", color: "#fff" }}
        name={data.name}
        image={{
          isLogo: true,
          src: buildPageImageSrc("screen-2", "svg"),
        }}
        description={data.description}
      />

        <ViewPortfolioItemSection style={{ backgroundColor: "#F8F8F8"}} className="view-portfolio-ensemble-branding-section container portfolio-item-section--ens-type-variant border-bottom">
          <div className="portfolio-item-section__inner row">
              <div className="variant-title col-xl-4">
                  <RevealWhenInView>
                      <p className="side-brackets">
                        <Print data={"portfolioItem:ensembleBranding.section-2.variant"}/>
                      </p>
                  </RevealWhenInView>
              </div>
              <div className="variant-text col-xl-7 col-md-9 offset-xl-0 offset-md-2">
                  <div className="row">
                     <RevealWhenInView className="col-11">
                        <p>
                            <Print data={"portfolioItem:ensembleBranding.section-2.text.0"}/>
                        </p>
                     </RevealWhenInView>
                     <RevealWhenInView className="col-md-5">
                        <span> <Print data={"portfolioItem:ensembleBranding.section-2.text.1"}/></span>
                     </RevealWhenInView>
                     <RevealWhenInView className="col-md-5 offset-md-1">
                        <span><Print data={"portfolioItem:ensembleBranding.section-2.text.2"}/></span>
                     </RevealWhenInView>
                  </div>
              </div>
              <div className="count-slide col-xl-4">
                  <RevealWhenInView>
                     <span><Print data={"portfolioItem:ensembleBranding.section-2.countSlide"}/></span>
                  </RevealWhenInView>
              </div>
              <div className="prev-slide col-xl-7 col-md-9 offset-xl-0 offset-md-2">
                  <RevealWhenInView>
                    <img src={buildPageImageSrc('screen-1')} alt={'ensemble'}/>
                  </RevealWhenInView>
              </div>
          </div>
        </ViewPortfolioItemSection>

        <ViewPortfolioItemSection style={{ backgroundColor: "#F8F8F8"}} className="view-portfolio-ensemble-branding-section portfolio-item-section--ens-3 border-top border-bottom">
            <div className="portfolio-item-section__inner">
                <div className="wrap-img">
                    <RevealWhenInView>
                        <img src={buildPageImageSrc('screen-2')} alt={'ensemble'}/>
                    </RevealWhenInView>
                </div>
            </div>
        </ViewPortfolioItemSection>

        <ViewPortfolioItemSection style={{ backgroundColor: "#F8F8F8"}} className="view-portfolio-ensemble-branding-section portfolio-item-section--img-line double border-top border-bottom">
            <div className="portfolio-item-section__inner row">
                <div className="wrap-img">
                    <RevealWhenInView>
                        <img src={buildPageImageSrc('screen-3-left')} alt={'ensemble'}/>
                    </RevealWhenInView>
                </div>
                <div className="wrap-img">
                    <RevealWhenInView>
                        <img src={buildPageImageSrc('screen-3-right')} alt={'ensemble'}/>
                    </RevealWhenInView>
                </div>
            </div>
        </ViewPortfolioItemSection>

        <ViewPortfolioItemSection style={{ backgroundColor: "#F8F8F8",
                                           backgroundImage: "url(/assets/portfolios/ensemble-branding/page/screen-4-back.webp)"}
                                  }
                                  className="view-portfolio-ensemble-branding-section container portfolio-item-section--ens-4 border-top border-bottom">
            <div className="portfolio-item-section__inner row">
                <div className="col-xl-3 row">
                    <div className="caption-slide col-auto">
                        <RevealWhenInView>
                            <span>
                                <Print data={"portfolioItem:ensembleBranding.section-4.text.0"}/>
                            </span>
                        </RevealWhenInView>
                    </div>
                    <div className="text-slide col-11">
                        <RevealWhenInView>
                            <span>
                                <Print data={"portfolioItem:ensembleBranding.section-4.text.1"}/>
                            </span>
                        </RevealWhenInView>
                    </div>
                </div>

                <div className="col-xl-7 col-md-9 offset-md-1  wrap-img">
                    <RevealWhenInView>
                        <img src={buildPageImageSrc('screen-4-content')} alt={'ensemble'}/>
                    </RevealWhenInView>
                </div>
            </div>
        </ViewPortfolioItemSection>

        <ViewPortfolioItemSection style={{ backgroundColor: "#F8F8F8"}} className="view-portfolio-ensemble-branding-section container portfolio-item-section--ens-5 border-top border-bottom">
            <div className="portfolio-item-section__inner row">
                <div className="caption-slide col-md-auto col-6">
                    <RevealWhenInView>
                        <span>
                            <Print data={"portfolioItem:ensembleBranding.section-5.text.0"}/>
                        </span>
                    </RevealWhenInView>
                </div>
                <div className="caption-slide col-auto offset-auto">
                    <RevealWhenInView>
                        <span>
                            <Print data={"portfolioItem:ensembleBranding.section-5.text.1"}/>
                        </span>
                    </RevealWhenInView>
                </div>
                <div className="wrap-img col-md-9 offset-md-1">
                    <RevealWhenInView>
                        <img src={buildPageImageSrc('screen-5')} alt={'ensemble'}/>
                    </RevealWhenInView>
                </div>
            </div>
        </ViewPortfolioItemSection>

        <ViewPortfolioItemSection style={{ backgroundColor: "#F8F8F8"}} className="view-portfolio-ensemble-branding-section portfolio-item-section--img-line double border-top border-bottom">
            <div className="portfolio-item-section__inner row">
                <div className="wrap-img">
                    <RevealWhenInView>
                        <img src={buildPageImageSrc('screen-6-left')} alt={'ensemble'}/>
                    </RevealWhenInView>
                </div>
                <div className="wrap-img">
                    <RevealWhenInView>
                        <img src={buildPageImageSrc('screen-6-right')} alt={'ensemble'}/>
                    </RevealWhenInView>
                </div>
            </div>
        </ViewPortfolioItemSection>

        <ViewPortfolioItemSection style={{ backgroundColor: "#F8F8F8", backgroundImage: "url(/assets/portfolios/ensemble-branding/page/screen-4-back.webp)"}}
                                  className="view-portfolio-ensemble-branding-section container portfolio-item-section--ens-6 border-top border-bottom">
            <div className="portfolio-item-section__inner row">
                <div className="caption-slide col-5">
                    <RevealWhenInView>
                        <span>
                            <Print data={"portfolioItem:ensembleBranding.section-6.text.0"}/>
                        </span>
                    </RevealWhenInView>
                </div>
                <div className="caption-slide col-auto offset-auto">
                    <RevealWhenInView>
                        <span>
                            <Print data={"portfolioItem:ensembleBranding.section-6.text.1"}/>
                        </span>
                    </RevealWhenInView>
                </div>
                <div className="wrap-img col-md-9 offset-md-1">
                    <RevealWhenInView>
                        <img src={buildPageImageSrc('screen-7-content')} alt={'ensemble'}/>
                    </RevealWhenInView>
                </div>
            </div>
        </ViewPortfolioItemSection>

        <ViewPortfolioItemSection style={{ backgroundColor: "#F8F8F8"}}
                                  className="view-portfolio-ensemble-branding-section container portfolio-item-section--ens-7 border-top border-bottom">
            <div className="portfolio-item-section__inner row">
                <div className="caption-slide col-5">
                    <RevealWhenInView>
                        <span>
                            <Print data={"portfolioItem:ensembleBranding.section-7.text.0"}/>
                        </span>
                    </RevealWhenInView>
                </div>
                <div className="caption-slide col-auto offset-auto">
                    <RevealWhenInView>
                        <span>
                            <Print data={"portfolioItem:ensembleBranding.section-7.text.1"}/>
                        </span>
                    </RevealWhenInView>
                </div>
                <div className="wrap-img col-md-9 offset-md-1">
                    <RevealWhenInView>
                        <img src={buildPageImageSrc('screen-8')} alt={'ensemble'}/>
                    </RevealWhenInView>
                </div>
            </div>
        </ViewPortfolioItemSection>

        <ViewPortfolioItemSection style={{ backgroundColor: "#F8F8F8", backgroundImage: "url(/assets/portfolios/ensemble-branding/page/screen-9-back.webp)"}}
                                  className="view-portfolio-ensemble-branding-section container portfolio-item-section--ens-type-variant border-bottom">
            <div className="portfolio-item-section__inner row">
                <div className="variant-title col-xl-4">
                    <RevealWhenInView>
                        <p className="side-brackets">
                            <Print data={"portfolioItem:ensembleBranding.section-8.variant"}/>
                        </p>
                    </RevealWhenInView>
                </div>
                <div className="variant-text col-xl-7 col-md-9 offset-xl-0 offset-md-2">
                    <div className="row">
                        <RevealWhenInView className="col-11">
                            <p>
                                <Print data={"portfolioItem:ensembleBranding.section-8.text.0"}/>
                            </p>
                        </RevealWhenInView>
                        <RevealWhenInView className="col-md-5">
                            <span> <Print data={"portfolioItem:ensembleBranding.section-8.text.1"}/></span>
                        </RevealWhenInView>
                        <RevealWhenInView className="col-md-5 offset-md-1">
                            <span><Print data={"portfolioItem:ensembleBranding.section-8.text.2"}/></span>
                        </RevealWhenInView>
                    </div>
                </div>
                <div className="count-slide col-xl-4">
                    <RevealWhenInView>
                        <span><Print data={"portfolioItem:ensembleBranding.section-8.countSlide"}/></span>
                    </RevealWhenInView>
                </div>
                <div className="prev-slide col-xl-7 col-md-9 offset-xl-0 offset-md-2">
                    <RevealWhenInView>
                        <img src={buildPageImageSrc('screen-9-content')} alt={'ensemble'}/>
                    </RevealWhenInView>
                </div>
            </div>
        </ViewPortfolioItemSection>

        <ViewPortfolioItemSection style={{ backgroundColor: "#F8F8F8", backgroundImage: "url(/assets/portfolios/ensemble-branding/page/screen-10-back.webp)"}}
                                  className="view-portfolio-ensemble-branding-section container portfolio-item-section--ens-9 border-top border-bottom">
            <div className="portfolio-item-section__inner row">
                <div className="caption-slide col-md-auto">
                    <RevealWhenInView>
                        <span>
                            <Print data={"portfolioItem:ensembleBranding.section-9.text.0"}/>
                        </span>
                    </RevealWhenInView>
                </div>
                <div className="caption-slide col-md-auto offset-auto">
                    <RevealWhenInView>
                        <span>
                            <Print data={"portfolioItem:ensembleBranding.section-9.text.1"}/>
                        </span>
                    </RevealWhenInView>
                </div>
                <div className="wrap-img col-md-9 offset-md-1">
                    <RevealWhenInView>
                        <img src={buildPageImageSrc('screen-10-content')} alt={'ensemble'}/>
                    </RevealWhenInView>
                </div>
            </div>
        </ViewPortfolioItemSection>

        <ViewPortfolioItemSection style={{ backgroundColor: "#F8F8F8"}}
                                  className="view-portfolio-ensemble-branding-section container portfolio-item-section--ens-10 border-top border-bottom">
            <div className="portfolio-item-section__inner row">
                <div className="caption-slide col-md-auto">
                    <RevealWhenInView>
                        <span>
                            <Print data={"portfolioItem:ensembleBranding.section-10.text.0"}/>
                        </span>
                    </RevealWhenInView>
                </div>
                <div className="caption-slide col-md-auto offset-auto">
                    <RevealWhenInView>
                        <span>
                            <Print data={"portfolioItem:ensembleBranding.section-10.text.1"}/>
                        </span>
                    </RevealWhenInView>
                    <RevealWhenInView>
                        <span>
                            <Print data={"portfolioItem:ensembleBranding.section-10.text.2"}/>
                        </span>
                    </RevealWhenInView>
                </div>
                <div className="wrap-img col-md-9 offset-md-1">
                    <RevealWhenInView>
                        <img src={buildPageImageSrc('screen-11')} alt={'ensemble'}/>
                    </RevealWhenInView>
                </div>
            </div>
        </ViewPortfolioItemSection>

        <ViewPortfolioItemSection style={{ backgroundColor: "#F8F8F8"}} className="view-portfolio-ensemble-branding-section portfolio-item-section--img-line double border-top border-bottom">
            <div className="portfolio-item-section__inner row">
                <div className="wrap-img">
                    <RevealWhenInView>
                        <img src={buildPageImageSrc('screen-11-left')} alt={'ensemble'}/>
                    </RevealWhenInView>
                </div>
                <div className="wrap-img">
                    <RevealWhenInView>
                        <img src={buildPageImageSrc('screen-11-right')} alt={'ensemble'}/>
                    </RevealWhenInView>
                </div>
            </div>
        </ViewPortfolioItemSection>

        <ViewPortfolioItemSection style={{ backgroundColor: "#F8F8F8"}}
                                  className="view-portfolio-ensemble-branding-section container portfolio-item-section--ens-12 border-top border-bottom">
            <div className="portfolio-item-section__inner row">
                <div className="caption-slide col-md-auto">
                    <RevealWhenInView>
                        <span>
                            <Print data={"portfolioItem:ensembleBranding.section-12.text.0"}/>
                        </span>
                    </RevealWhenInView>
                </div>
                <div className="caption-slide col-md-auto offset-auto">
                    <RevealWhenInView>
                        <span>
                            <Print data={"portfolioItem:ensembleBranding.section-12.text.1"}/>
                        </span>
                    </RevealWhenInView>
                </div>
                <div className="wrap-img col-md-9 offset-md-1">
                    <RevealWhenInView>
                        <img src={buildPageImageSrc('screen-12')} alt={'ensemble'}/>
                    </RevealWhenInView>
                </div>
            </div>
        </ViewPortfolioItemSection>

        <ViewPortfolioItemSection style={{ backgroundColor: "#F8F8F8"}} className="view-portfolio-ensemble-branding-section portfolio-item-section--img-line triple border-top border-bottom">
            <div className="portfolio-item-section__inner row">
                <div className="wrap-img">
                    <RevealWhenInView>
                        <img src={buildPageImageSrc('screen-13-left')} alt={'ensemble'}/>
                    </RevealWhenInView>
                </div>
                <div className="wrap-img">
                    <RevealWhenInView>
                        <img src={buildPageImageSrc('screen-13-center')} alt={'ensemble'}/>
                    </RevealWhenInView>
                </div>
                <div className="wrap-img">
                    <RevealWhenInView>
                        <img src={buildPageImageSrc('screen-13-right')} alt={'ensemble'}/>
                    </RevealWhenInView>
                </div>
            </div>
        </ViewPortfolioItemSection>

        <ViewPortfolioItemSection style={{ backgroundColor: "#F8F8F8"}}
                                  className="view-portfolio-ensemble-branding-section container portfolio-item-section--ens-14 border-top border-bottom">
            <div className="portfolio-item-section__inner row">
                <div className="caption-slide col-md-auto">
                    <RevealWhenInView>
                        <span>
                            <Print data={"portfolioItem:ensembleBranding.section-14.text.0"}/>
                        </span>
                    </RevealWhenInView>
                </div>
                <div className="caption-slide col-md-auto offset-auto">
                    <RevealWhenInView>
                        <span>
                            <Print data={"portfolioItem:ensembleBranding.section-14.text.1"}/>
                        </span>
                    </RevealWhenInView>
                </div>
                <div className="wrap-img col-md-9 offset-md-1">
                    <RevealWhenInView>
                        <img src={buildPageImageSrc('screen-14')} alt={'ensemble'}/>
                    </RevealWhenInView>
                </div>
            </div>
        </ViewPortfolioItemSection>

        <ViewPortfolioItemParallaxSection
            dynamicBackgroundColor={false}
            image={{
                src: buildPageImageSrc("screen-15"),
                sources: [
                    {
                        type: "webp",
                        srcSet: buildPageImageSrc("screen-15")
                    }
                ]
            }}
        />

        {data.relatedId && (
            <ViewPortfolioItemRelatedSection
                currentId={data.id}
                style={{ backgroundColor: "#57AFB2", color: "#fff" }}
                id={data.relatedId}
                preview={{
                    image: {
                        src: buildPageImageSrc("related"),
                    },
                    frame: "#3B787A",
                }}
            />
        )}

    </>
  );
};

export default ViewPortfolioEnsembleBranding;
