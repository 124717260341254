import React, { forwardRef, memo, Ref } from "react";
import classNames from "classnames";
import MorphSvgPath from "./MorphSvgPath";
import words, { WordsType } from "./words";

export interface MorphSvgWordProps {
  morph?: boolean;
  /**
   * @default "0.4s"
   */
  duration?: string;

  word: WordsType;

  className?: string;
}

type MorphSvgWordRef = SVGSVGElement;


const MorphSvgWord = ({
  word,
  duration = "0.6s",
  morph,
  className,
}: MorphSvgWordProps, ref: Ref<MorphSvgWordRef>) => {
  const specificWord = words[word];

  if (!specificWord) {
    return null;
  }

  return (
    <svg
      ref={ref}
      width={specificWord.width}
      height={specificWord.height}
      viewBox={`0 0 ${specificWord.width} ${specificWord.height}`}
      fill="none"
      className={classNames("morph-svg-word", className)}
    >
      {specificWord.letters.map(({ path }, i) => {
        const from = typeof path === "string" ? path : path.from;
        const to = typeof path === "string" ? undefined : path.to;

        return (
          <MorphSvgPath
            key={i}
            duration={duration}
            from={from}
            to={to}
            morph={morph}
          />
        );
      })}
    </svg>
  );
};

export default memo(forwardRef<MorphSvgWordRef, MorphSvgWordProps>(MorphSvgWord));
