import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";
import { MorphSvgWord } from "components/common/MorphSvg";
import Print from "components/common/Print";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import ScrollDownButton from "components/layouts/ScrollDownButton";
import Section from "components/layouts/Section";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import "./_portfolio-lead-section.scss";

interface ViewPortfolioLeadSectionProps {}

const ViewPortfolioLeadSection = (props: ViewPortfolioLeadSectionProps) => {
  const [morph, setMorph] = useState(false);
  const stats = [
    {
      counter: 89,
      label: "view:portfolio.section.lead.statLabel.projects",
    },
    {
      counter: 9,
      label: "view:portfolio.section.lead.statLabel.experience",
    },
  ];

  const { t } = useTranslation();
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setMorph(true);
    }, 800);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <DynamicBackgroundTrigger>
      <Section viewportHeight isLead className="portfolio-lead-section">
        <div className="portfolio-lead-section__container lead-section__container container">
          <div className="portfolio-lead-section__inner">
            <header className="portfolio-lead-section__header row">
              <div className="portfolio-lead-section__col-with-title col-11 col-md">
                <RevealWhenInView>
                  <MorphSvgWord
                    className="svg-title"
                    word={t("view:portfolio.section.lead.title")}
                    morph={morph}
                  />
                </RevealWhenInView>
              </div>
              <div className="portfolio-lead-section__col-with-label col-11 col-md-auto">
                <RevealWhenInView>
                  <p className="label side-brackets">
                    <Print data="view:portfolio.section.lead.subtitle" />
                  </p>
                </RevealWhenInView>
              </div>
            </header>
            <div className="portfolio-lead-section__body row">
              <div className="portfolio-lead-section__col-with-text col-11 col-xl-auto">
                <RevealWhenInView className="u-max-w-480">
                  <p className="portfolio-lead-section__text text text--indent-md-half text--indent-xl-half">
                    <Print data="view:portfolio.section.lead.description" />
                  </p>
                </RevealWhenInView>
              </div>

              <div className="portfolio-lead-section__col-with-stats-and-arrow row col-11 col-xl-auto">
                <div className="portfolio-lead-section__col-with-stats col col-md-auto">
                  {stats.map(({ counter, label }, i) => (
                    <p key={i} className="portfolio-lead-section__stat">
                      <span className="portfolio-lead-section__stat-counter display title--line-height-none">
                        <RevealWhenInView tag="span">
                          {counter}
                        </RevealWhenInView>
                      </span>
                      <span className="portfolio-lead-section__stat-label footnote text--pre-line">
                        <RevealWhenInView tag="span">
                          <Print data={label} />
                        </RevealWhenInView>
                      </span>
                    </p>
                  ))}
                </div>

                <div className="portfolio-lead-section__col-with-arrow col-auto">
                  <RevealWhenInView>
                    <ScrollDownButton scrollTo=".portfolio-showcase-section" />
                  </RevealWhenInView>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </DynamicBackgroundTrigger>
  );
};

export default ViewPortfolioLeadSection;
