export const OPEN_MENU = "OPEN_MENU";
export const CLOSE_MENU = "CLOSE_MENU";

export interface OpenMenuAction {
  type: typeof OPEN_MENU;
}

export interface CloseMenuAction {
  type: typeof CLOSE_MENU;
}

export type AppActionTypes = OpenMenuAction | CloseMenuAction;

export interface AppState {
  menuOpen: boolean;
}
