import ViewPortfolioItemAboutSection from "../ViewPortfolioItemAboutSection";
import ViewPortfolioItemParallaxSection from "../ViewPortfolioItemParallaxSection";
import {
    useViewPortfolioItemContext,
    ViewSpecificPortfolioItemProps,
} from "../ViewPortfolioItem";
import RevealWhenInView from "../../../common/RevealInView/RevealWhenInView";
import ViewPortfolioItemSection from "../ViewPortfolioItemSection";
import { usePortfolioItemAssets } from "hooks/useAssets";
import { useTranslation } from "react-i18next";
import { removeEmitHelper } from "typescript";
import VimeoPlayer from "../../../common/VimeoPlayer/VimeoPlayer";
import React from "react";

const ViewPortfolioCfps = (props: ViewSpecificPortfolioItemProps) => {
    const { data } = useViewPortfolioItemContext();
    const { buildPageImageSrc } = usePortfolioItemAssets(data.id);
    const { t } = useTranslation();
    return (
        <>
            <ViewPortfolioItemAboutSection
                style={{ backgroundColor: "#8FB1AE", color: "#fff" }}
                caption={[t("portfolioItem:cfps.about.0"), t("portfolioItem:cfps.about.1"), t("portfolioItem:cfps.about.2"), ]}
                name={data.name} description={data.description}
                video={{src:("https://vimeo.com/914703645?share=copy")}}
                className="cfps-site"/>
            {/* экран 2 */}
            <ViewPortfolioItemSection style={{ backgroundColor: "#8FB1AE" }} className=" view-portfolio-cfps-section  portfolio-item-section--type-cfps-2">
                <RevealWhenInView>
                    <img src={buildPageImageSrc(t("portfolioItem:cfps.section-2.image"))} alt={'cfps'} />
                </RevealWhenInView>
            </ViewPortfolioItemSection>

            {/* экран 3 */}
            <ViewPortfolioItemSection style={{ backgroundColor: "#ABC7C4", color: "#fff" }} className=" view-portfolio-cfps-section  portfolio-item-section--type-cfps-3 row">
                <div className="left-wrap container">
                    <RevealWhenInView>
                        <p className="description">{t("portfolioItem:cfps.section-3.left.caption")}</p>
                    </RevealWhenInView>
                    <div className="img-wrap">
                        <RevealWhenInView>
                            <img src={buildPageImageSrc(t("portfolioItem:cfps.section-3.left.image"))} alt={'cfps'} />
                        </RevealWhenInView>
                    </div>
                </div>
                <div className="right-wrap">
                    <div className="top-wrap container">
                        <RevealWhenInView>
                            <p className="description">{t("portfolioItem:cfps.section-3.right.caption")}</p>
                        </RevealWhenInView>
                        <div className="img-wrap">
                            <RevealWhenInView>
                                <img src={buildPageImageSrc(t("portfolioItem:cfps.section-3.right.image"))} alt={'cfps'} />
                            </RevealWhenInView>
                        </div>
                    </div>
                    <div className="bottom-wrap container">
                        <div className="img-wrap">
                            <RevealWhenInView>
                                <img src={buildPageImageSrc(t("portfolioItem:cfps.section-3.right.icon"))} alt={'cfps'} />
                            </RevealWhenInView>
                        </div>
                    </div>
                </div>
            </ViewPortfolioItemSection>

            {/* экран 4 */}
            <ViewPortfolioItemSection style={{ backgroundColor: "#ADC4AE", color: "#fff" }} className=" view-portfolio-cfps-section  portfolio-item-section--type-cfps-4 row">
                <div className="img-wrap left-wrap">
                    <RevealWhenInView>
                        <img src={buildPageImageSrc(t("portfolioItem:cfps.section-4.left.image"))} alt={'cfps'} />
                    </RevealWhenInView>
                </div>
                <div className="right-wrap container">
                    <div className="description">
                        <RevealWhenInView>
                            <p>{t("portfolioItem:cfps.section-4.right.caption.0")}</p>
                        </RevealWhenInView>
                        <RevealWhenInView>
                            <p>{t("portfolioItem:cfps.section-4.right.caption.1")}</p>
                        </RevealWhenInView>
                    </div>
                    <div className="img-wrap">
                        <RevealWhenInView>
                            <img src={buildPageImageSrc(t("portfolioItem:cfps.section-4.right.image"))} alt={'cfps'} />
                        </RevealWhenInView>
                    </div>
                </div>
            </ViewPortfolioItemSection>

            {/* экран 5 */}
            <ViewPortfolioItemSection style={{ backgroundColor: "#1F504B", color: "#fff" }} className=" view-portfolio-cfps-section  portfolio-item-section--type-cfps-5">
                <div className="description container">
                    <RevealWhenInView><p>{t("portfolioItem:cfps.section-5.caption")}</p></RevealWhenInView>
                    <RevealWhenInView><span>{t("portfolioItem:cfps.section-5.description")}</span></RevealWhenInView>
                </div>
                <RevealWhenInView>
                    <div className={"u-ratio"}>
                        <VimeoPlayer  size="fill"  url={'https://vimeo.com/914703710?share=copy'} play muted controls={false} loop={true} />
                    </div>
                </RevealWhenInView>
            </ViewPortfolioItemSection>

            {/* экран 6 */}
            <ViewPortfolioItemSection style={{ backgroundColor: "#8FB1AE", color: "#fff" }} className=" view-portfolio-cfps-section  portfolio-item-section--type-cfps-6 container">
                <div className="description">
                    <RevealWhenInView><p>{t("portfolioItem:cfps.section-6.caption.0")}</p></RevealWhenInView>
                    <RevealWhenInView><p>{t("portfolioItem:cfps.section-6.caption.1")}</p></RevealWhenInView>
                </div>
                <div className="col-md-7 offset-md-1 about-company">
                    <div className="menu-company">
                        <RevealWhenInView><p>{t("portfolioItem:cfps.section-6.menu.0")}</p></RevealWhenInView>
                        <RevealWhenInView><p>{t("portfolioItem:cfps.section-6.menu.1")}</p></RevealWhenInView>
                        <RevealWhenInView><p>{t("portfolioItem:cfps.section-6.menu.2")}</p></RevealWhenInView>
                    </div>
                    <div className="img-wrap">
                        <RevealWhenInView>
                            <img src={buildPageImageSrc(t("portfolioItem:cfps.section-6.image.0"))} alt={'cfps'} />
                        </RevealWhenInView>
                    </div>
                    <div className="img-wrap">
                        <RevealWhenInView>
                            <img src={buildPageImageSrc(t("portfolioItem:cfps.section-6.image.1"))} alt={'cfps'} />
                        </RevealWhenInView>
                    </div>
                </div>

                <div className="col-md-7 offset-md-3 blog">
                    <div className="img-wrap">
                        <RevealWhenInView>
                            <img src={buildPageImageSrc(t("portfolioItem:cfps.section-6.image.2"))} alt={'cfps'} />
                        </RevealWhenInView>
                    </div>
                </div>

            </ViewPortfolioItemSection>

            {/* экран 7 */}
            <ViewPortfolioItemSection style={{ backgroundColor: "#1F504B", color: "#fff" }} className=" view-portfolio-cfps-section  portfolio-item-section--type-cfps-7">
                <p className="caption container">
                    <RevealWhenInView>{t("portfolioItem:cfps.section-7.caption")}</RevealWhenInView>
                </p>
                <div className="row">
                    <div className="left-wrap col-md-4 offset-md-1">
                        <div className="img-wrap">
                            <RevealWhenInView>
                                <img src={buildPageImageSrc(t("portfolioItem:cfps.section-7.image.0"))} alt={'cfps'} />
                            </RevealWhenInView>
                        </div>
                    </div>
                    <div className="right-wrap col-md-4 offset-md-2 offset-auto">
                        <p className="description">{t("portfolioItem:cfps.section-7.description")}</p>
                        <div className="img-wrap">
                            <RevealWhenInView>
                                <img src={buildPageImageSrc(t("portfolioItem:cfps.section-7.image.1"))} alt={'cfps'} />
                            </RevealWhenInView>
                        </div>
                    </div>
                </div>
            </ViewPortfolioItemSection>

            {/* экран 8 */}
            <ViewPortfolioItemSection dynamicBackgroundColor={false} style={{ backgroundColor: "#ADC4AE", color: "#fff" }} className=" view-portfolio-cfps-section  portfolio-item-section--type-cfps-8 ">
                <div className="top-wrap container">
                    <p className="caption">
                        <RevealWhenInView>{t("portfolioItem:cfps.section-8.caption")}</RevealWhenInView>
                    </p>
                </div>

                <div className="bottom-wrap container">
                    <p className="description">
                        <RevealWhenInView>{t("portfolioItem:cfps.section-8.description")}</RevealWhenInView>
                    </p>
                </div>

                <div className="img-wrap col-md-5">
                    <RevealWhenInView>
                        <img src={buildPageImageSrc(t("portfolioItem:cfps.section-8.image"))} alt={'cfps'} />
                    </RevealWhenInView>
                </div>
            </ViewPortfolioItemSection>

            <ViewPortfolioItemParallaxSection dynamicBackgroundColor={false} image={{
                src: buildPageImageSrc(t("portfolioItem:cfps.section-9.image")),
                sources: [
                    {
                        type: "webp",
                        srcSet: buildPageImageSrc(t("portfolioItem:cfps.section-9.image")),
                    },
                ],
            }} />
            </>
    );
};

export default ViewPortfolioCfps;