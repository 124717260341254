import ViewPortfolioCryptoro from "components/views/ViewPortfolioItem/ViewPortfolioCryptoro/";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";
import {buildPublicImageSrc} from "../../helpers/path";

const porfolioItem: PortfolioItemDataWithComponent = {
  id: "cryptoro",
  component: ViewPortfolioCryptoro,
  title: "cryptoro",
  name: "cryptoro",
  altName: "Online store\nof cold wallets\nfor cryptocurrencies",
  year: 2023,
  awardsList: [],

  link: {
    label: "cryptoro.ru",
    href: "https://cryptoro.ru/",
  },

  socials: [
    {
      label: "behance",
      href: "https://www.behance.net/gallery/205014905/Cryptoro",
    },
  ],

  preview: {
    label: 'corporate site',
    media: {
      type: 'image',
      fromSrc: buildPublicImageSrc({
        file: '1.webp',
      }),
      toSrc: buildPublicImageSrc({
        file: '2.webp',
      }),
    },
  },

  poolOfWorks: ['UX|UI design', 'Page making'],

  type: 'site',
  subtype: 'site',

  description: [
    "a company that makes digital asset protection simple by ensuring the security of cryptocurrency savings",
    "We should have demonstrated the company's technological prowess and reliability"
  ],

  shortDescription: "a company that makes digital asset protection simple, ensuring the security of cryptocurrency savings",
  seo: {
    title:
        "Development of an Online Store for Mining and Cryptocurrency Storage Equipment Cryptoro",
    description:
        "Learn how we created the Cryptoro online store, offering equipment for cryptocurrency storage, mining, and trading. A detailed case study, stages of design, website development for crypto, and results.",
    keywords: [
      "online store development, Cryptoro, mining equipment, cryptocurrency online store, cryptocurrency storage, crypto website case study, mining portfolio, online store creation, crypto wallet store",
    ],
    openGraph: {
      type: "article",
      title:
          "Development of an Online Store for Mining and Cryptocurrency Storage Equipment Cryptoro",
      description:
          "Learn how we created the Cryptoro online store, offering equipment for cryptocurrency storage, mining, and trading. A detailed case study, stages of design, website development for crypto, and results.",
    },
  },
};

export default porfolioItem;
