import ViewPortfolioMumu from 'components/views/ViewPortfolioItem/ViewPortfolioMumu';
import { PortfolioItemDataWithComponent } from "data/portfolio/types";
import {buildPublicImageSrc} from "../../helpers/path";


const porfolioItem: PortfolioItemDataWithComponent = {
  id: "mumu",
  component: ViewPortfolioMumu,
  title: "mumuEn",
  name: "mu mu",
  altName: "an art museum of garbage",
  year: 2022,
  awardsList: [
    {imgSrc : "/images/awards/DP-graphic.svg"},
  ],

  link: {
    label: "art-mumu.ru",
    href: "https://art-mumu.ru/",
  },

  socials: [
    {
      label: "behance",
      href:
        "https://www.behance.net/gallery/153944813/BRANDING-Garbage-museum",
    },
  ],

  preview: {
    label: "corporate identity",
    media: {
      type: "image",
      fromSrc: buildPublicImageSrc({
        path: "portfolios/mumu/preview",
        file: "1-1440w.webp",
      }),
      toSrc: buildPublicImageSrc({
        path: "portfolios/mumu/preview",
        file: "2-1440w.webp",
      }),
    },
  },

  poolOfWorks: ["Logotype", "Brand style"],



  type: "branding",
  subtype: "branding",

  description: [
    "an art museum of garbage, whose mission is to draw attention to the idea of reasonable consumption.",
    "This is the largest exhibition of art objects made from garbage."
  ],
  shortDescription:
    "Branding development for the Garbage Art Museum (MuMu)",
  seo: {
    title: "Branding development for the Garbage Art Museum (MuMu)",
    description: "We have created a concept of branding and corporate identity elements (posters, merch, business cards, tickets, navigation) for the MUMU Museum, which uses forms and siluets of garbage waste as graphic elements",
    keywords: ["branding of the museum",
      "development of the museum's corporate identity",
      "branding for a modern museum",
      "graphic design for an art museum"],
    openGraph: {
      "type": "article",
      "title": "Branding development for the Garbage Art Museum (MuMu)",
      "description": "We have created a concept of branding and corporate identity elements (posters, merch, business cards, tickets, navigation) for the MUMU Museum, which uses forms and siluets of garbage waste as graphic elements"
    }
  }

};

export default porfolioItem;
