
import List from "components/common/List";
import ViewPortfolioItemSection, {
  ViewPortfolioItemSectionConfigProps,
} from "../ViewPortfolioItemSection";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import Image, { ImageConfig } from "components/common/Image";

export interface ViewPortfolioWomanSportProductsSectionProps
  extends ViewPortfolioItemSectionConfigProps {
  products: [
    {
      image: ImageConfig;
      caption: string | string[]
    },
    {
      image: {
        first: ImageConfig,
        second: ImageConfig
      },

      caption: string | string[]
    },
    {
      image: ImageConfig;
      caption: string | string[]
    },
    {
      image: ImageConfig;
      caption: string | string[]
    },
    {
      image: ImageConfig;
    },
    {
      image: ImageConfig;
      caption: string | string[]
    },
    {
      image: ImageConfig;
      caption: string[]
    },
  ];
}

const ViewPortfolioWomanSportProductsSection = ({
  products,
  ...sectionProps
}: ViewPortfolioWomanSportProductsSectionProps) => {
  return (
    <ViewPortfolioItemSection
      {...sectionProps}
      className="view-portfolio-item-section view-portfolio-item-section--layout-wsb-products"
    >
      <div className="view-portfolio-item-section__container">
        <ul className="view-portfolio-item-section__products-list list">
          <li className="view-portfolio-item-section__products-item container">
            <figure className="row">
              <div className="col-11 col-xl-4 offset-xl-7">
                <RevealWhenInView>
                  <figcaption className="view-portfolio-item-section__products-item-label">
                    {products[0].caption}
                  </figcaption>
                </RevealWhenInView>
              </div>
              <div
                className="col-11 col-xl-6"
              >
                <RevealWhenInView>
                  <Image {...products[0].image} />
                </RevealWhenInView>
              </div>
            </figure>
          </li>

          <li className="view-portfolio-item-section__products-item container">
            <figure className="row">
              <div className="col-11 col-xl-10 offset-xl1 ">
                <RevealWhenInView>
                  <figcaption className="view-portfolio-item-section__products-item-label">
                    {products[1].caption}
                  </figcaption>
                </RevealWhenInView>
              </div>

              <div className="col-11 col-xl-8 offset-xl-3">
                <div className="row">
                  <div className="col-8">
                    <RevealWhenInView>
                      <Image {...products[1].image.first} />
                    </RevealWhenInView>
                  </div>
                  <div className="col-3">
                    <RevealWhenInView>
                      <Image {...products[1].image.second} />
                    </RevealWhenInView>
                  </div>
                </div>
              </div>
            </figure>
          </li>

          <li className="view-portfolio-item-section__products-item container">
            <figure className="row">

              <div className="col-11 col-xl-3 offset-xl-8">
                <RevealWhenInView>
                  <figcaption className="view-portfolio-item-section__products-item-label">
                    {products[2].caption}
                  </figcaption>
                </RevealWhenInView>
              </div>

              <div
                className="col-11 col-xl-8"
              >
                <RevealWhenInView>
                  <Image {...products[2].image} />
                </RevealWhenInView>

              </div>
            </figure>
          </li>

          <li className="view-portfolio-item-section__products-item view-portfolio-woman-sport-products-section__products-item--overlay-next container">
            <figure className="row">
              <div className="col-11 offset-xl-3 col-xl-2">
                <RevealWhenInView>
                  <figcaption className="view-portfolio-item-section__products-item-label">
                    {products[3].caption}
                  </figcaption>
                </RevealWhenInView>
              </div>

              <div
                className="col-11 col-xl-6"
              >
                <RevealWhenInView>
                  <Image {...products[3].image} />
                </RevealWhenInView>
              </div>
            </figure>
          </li>

          <li
            style={{ backgroundColor: "#E10066" }}
            className="view-portfolio-item-section__products-item view-portfolio-woman-sport-products-section__products-item--empty"
          />

          <li className="view-portfolio-item-section__products-item view-portfolio-woman-sport-products-section__products-item--overlay-prev">
            <div className="row">
              <div
                className="col-11"
              >
                <RevealWhenInView>
                  <Image {...products[4].image} />
                </RevealWhenInView>
              </div>
            </div>
          </li>

          <li className="view-portfolio-item-section__products-item">
            <figure className="row">
              <div className="col-11 col-xl-2 order-xl-2 container">
                <RevealWhenInView>
                  <figcaption className="view-portfolio-item-section__products-item-label">
                    {products[5].caption}
                  </figcaption>
                </RevealWhenInView>
              </div>
              <div
                className="col-11 col-xl-9 order-xl-1"
              >
                <RevealWhenInView>
                  <Image {...products[5].image} />
                </RevealWhenInView>
              </div>
            </figure>
          </li>

          <li className="view-portfolio-item-section__products-item">
            <figure className="row">
              <div className="col-11 col-xl-2 offset-xl-1 container">
                <RevealWhenInView>
                  <figcaption className="view-portfolio-item-section__products-item-label">
                    <List items={products[6].caption!} gap="xxs" />
                  </figcaption>
                </RevealWhenInView>
              </div>

              <div
                className="col-11 col-xl-8"
              >
                <RevealWhenInView>
                  <Image {...products[6].image} />
                </RevealWhenInView>
              </div>


            </figure>
          </li>
        </ul>
      </div>
    </ViewPortfolioItemSection>
  );
};

export default ViewPortfolioWomanSportProductsSection;
