import ViewPortfolioV6 from "components/views/ViewPortfolioItem/ViewPortfolioV6/";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";

const porfolioItem: PortfolioItemDataWithComponent = {
  id: "v6-group",
  component: ViewPortfolioV6,
  title: "v6-group",
  name: "v6-group",
  altName: "Architectural bureau\n in Sochi",
  year: '2023',
  awardsList: [],

  socials: [
    {
      label: "behance",
      href: "https://www.behance.net/gallery/193024329/SK10-Construction-company-Real-estate-developer",
    },
  ],

  preview: {
    label: "корпоративный сайт",
    backgroundColor: "#1F1F21",
    media: {
      type: "video",
      src: "https://vimeo.com/1001523628/e38922299a",
    },
  },

  poolOfWorks: ["Research", 'UX|UI design', "Page making"],

  type: 'site',
  subtype: 'site',

  description: [
    "мы создали для архитектурной компании в Сочи сайт, который отражает их уникальный подход к созданию современного и комфортного пространства. ",
    "Нашей целью было сделать ресурс, который бы привлекал внимание, подчеркивал профессионализм компании и помогал ей эффективно представлять свои проекты клиентам"
  ],
  shortDescription: "сайт для Ростовской строительной компании",

  seo: {
    title:
        "Разработка сайта для застройщика №1 СК10 | Кейс студии SAGIROV.com",
    description:
        "Узнайте, как студия SAGIROV.com разработала современный и функциональный сайт для девелопера СК10. Полное решение для презентации жилых комплексов, повышения конверсии и улучшения пользовательского опыта, реализация функционала оформления онлайн ипотеки и покупки квартиры через сайт",
    keywords: [
      "разработка сайта для девелопера",
      "кейс СК10",
      "создание сайтов для застройщиков",
      "веб-дизайн жилые комплексы",
      "сайт строительной компании",
    ],
    openGraph: {
      type: "article",
      title:
          "Разработка сайта для застройщика №1 СК10 | Кейс студии SAGIROV.com",
      description:
          "Узнайте, как студия SAGIROV.com разработала современный и функциональный сайт для девелопера СК10. Полное решение для презентации жилых комплексов, повышения конверсии и улучшения пользовательского опыта, реализация функционала оформления онлайн ипотеки и покупки квартиры через сайт",
    },
  },
};

export default porfolioItem;
