import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Switch,
  Route,
  useLocation,
  Redirect,
  matchPath,
} from "react-router-dom";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { debounce } from "debounce";

import { routes, notFound, portfolioItem as portfolioItemRoute } from "routes";
import { DynamicBackground } from "components/common/DynamicBackground";
import TransitionOverlay from "components/common/TransitionOverlay";
import { ASSETS_PATH } from "helpers/path";
import { useBodyScrollLocker } from "contexts/BodyScrollLocker";
import { useDispatch } from "react-redux";
import { closeMenu } from "redux_/store/app/actions";
import portfolio from "data/portfolio";

interface MainProps {}

const Main = (props: MainProps) => {
  const mainRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const { scrollbarWidth } = useBodyScrollLocker();

  const [activeLocation, setActiveLocation] = useState(location);

  const imagesSrc = useMemo(() => {
    const matched = matchPath<{ portfolioItemId: string }>(
      location.pathname,
      portfolioItemRoute
    );
    const portfolioItemId = matched?.params.portfolioItemId;
    // метод sort мутирует оригинальный массив, поэтому создаем его копию
    const sorted = [...portfolio.ru].sort((a, b) => {
      return a.id === portfolioItemId ? 1 : b.id === portfolioItemId ? -1 : 0;
    });
    if (sorted.length > 10) {
      let excess = sorted.length - 10;
      sorted.splice(0, excess);
    }

    let result = sorted.map((portfolioItem) => {
      return `${ASSETS_PATH}/portfolios/${portfolioItem.id}/transition.webp`;
    });

    return result;
  }, [location]);

  const nextLocation = useCallback(() => {
    setActiveLocation(location);
  }, [location]);

  const locationInfo = useMemo(() => {
    return {
      isTransitionOverlayLocation: !!matchPath(
        location.pathname,
        portfolioItemRoute
      ),
      isSameLocation: location === activeLocation,
    };
  }, [location, activeLocation]);

  const shouldPlayTransitionOverlay = useCallback(
    () =>
      locationInfo.isTransitionOverlayLocation && !locationInfo.isSameLocation,
    [locationInfo]
  );

  /*useEffect(() => {
    //  переходим к след. странице если это не таже страница и если это страница без оверлей анимации перехода
    if (!locationInfo.isSameLocation && !locationInfo.isTransitionOverlayLocation) {
      nextLocation();
    }
  }, [locationInfo, nextLocation]);*/

  useEffect(() => {
    const viewImgElms = mainRef.current!.querySelectorAll("img");

    const handleLoad: EventListener = debounce(() => {
      ScrollTrigger.refresh();
    }, 200);

    viewImgElms.forEach((viewImageEl) => {
      viewImageEl.addEventListener("load", handleLoad);
    });

    window.scrollTo(0, 0);

    dispatch(closeMenu());

    ScrollTrigger.update();

    return () => {
      viewImgElms.forEach((viewImageEl) => {
        viewImageEl.removeEventListener("load", handleLoad);
      });
    };
  }, [activeLocation, dispatch]);

  return (
    <TransitionOverlay
      trigger={location.pathname}
      images={imagesSrc}
      onBeforeEnd={nextLocation}
      activator={shouldPlayTransitionOverlay}
    >
      <main
        ref={mainRef}
        className="main"
        style={{ marginRight: scrollbarWidth }}
      >
        <DynamicBackground className="main__dynamic-background">
          <Switch location={activeLocation}>
            {routes.map((route) => (
              <Route key={route.path} {...route} />
            ))}
            <Redirect to={notFound.path} />
          </Switch>
        </DynamicBackground>
        <a
          className={"badge"}
          href="https://www.cssdesignawards.com/sites/sagirov-studio/41410/"
          target="_blank"
          rel="noreferrer"
        >
          <div id="cssda-badge">
            <svg
              version="1.1"
              id="cssda-badge-logo"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 90 90"
              xmlSpace="preserve"
            >
              <path
                d="M32.6,43.6c-0.3,0-0.5,0.1-0.7,0.4c-0.2,0.2-0.3,0.6-0.3,1c0,0.9,0.3,1.3,1,1.3c0.2,0,0.4,0,0.6-0.1
                  c0.2-0.1,0.4-0.1,0.6-0.2v1.1c-0.4,0.2-0.8,0.3-1.3,0.3c-0.7,0-1.2-0.2-1.6-0.6c-0.4-0.4-0.6-1-0.6-1.8c0-0.5,0.1-0.9,0.3-1.3
                  c0.2-0.4,0.4-0.6,0.8-0.8c0.3-0.2,0.7-0.3,1.2-0.3c0.5,0,1,0.1,1.4,0.3l-0.4,1c-0.2-0.1-0.3-0.1-0.5-0.2
                  C33,43.6,32.8,43.6,32.6,43.6z"
              />
              <path
                d="M40,45.8c0,0.3-0.1,0.5-0.2,0.8c-0.1,0.2-0.4,0.4-0.6,0.5c-0.3,0.1-0.6,0.2-1,0.2c-0.3,0-0.6,0-0.8-0.1s-0.4-0.1-0.7-0.2
                  v-1.1c0.2,0.1,0.5,0.2,0.8,0.3s0.5,0.1,0.7,0.1c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.1-0.1,0.1-0.3c0-0.1,0-0.1-0.1-0.2
                  c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.3-0.1-0.6-0.3c-0.3-0.1-0.5-0.3-0.7-0.4c-0.2-0.1-0.3-0.3-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.6
                  c0-0.4,0.2-0.8,0.5-1c0.3-0.2,0.7-0.4,1.3-0.4c0.5,0,1,0.1,1.5,0.3l-0.4,1c-0.4-0.2-0.8-0.3-1.1-0.3c-0.2,0-0.3,0-0.4,0.1
                  C38,43.7,38,43.8,38,43.8c0,0.1,0,0.2,0.1,0.2c0.1,0.1,0.4,0.2,0.8,0.4c0.4,0.2,0.7,0.4,0.8,0.6C39.9,45.3,40,45.5,40,45.8z"
              />
              <path
                d="M45.9,45.8c0,0.3-0.1,0.5-0.2,0.8c-0.1,0.2-0.4,0.4-0.6,0.5c-0.3,0.1-0.6,0.2-1,0.2c-0.3,0-0.6,0-0.8-0.1
                  s-0.4-0.1-0.7-0.2v-1.1c0.2,0.1,0.5,0.2,0.8,0.3s0.5,0.1,0.7,0.1c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.1-0.1,0.1-0.3
                  c0-0.1,0-0.1-0.1-0.2c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.3-0.1-0.6-0.3c-0.3-0.1-0.5-0.3-0.7-0.4c-0.2-0.1-0.3-0.3-0.3-0.4
                  c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.4,0.2-0.8,0.5-1c0.3-0.2,0.7-0.4,1.3-0.4c0.5,0,1,0.1,1.5,0.3l-0.4,1c-0.4-0.2-0.8-0.3-1.1-0.3
                  c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.2,0.1,0.2c0.1,0.1,0.4,0.2,0.8,0.4c0.4,0.2,0.7,0.4,0.8,0.6
                  C45.9,45.3,45.9,45.5,45.9,45.8z"
              />
              <path
                d="M52.8,44.8c0,0.8-0.2,1.4-0.6,1.8c-0.4,0.4-1,0.6-1.8,0.6h-1.5v-4.7h1.6c0.8,0,1.3,0.2,1.7,0.6S52.8,44.1,52.8,44.8z
                   M51.5,44.9c0-0.4-0.1-0.7-0.3-1c-0.2-0.2-0.4-0.3-0.8-0.3h-0.4v2.6h0.3c0.4,0,0.7-0.1,0.8-0.3C51.4,45.7,51.5,45.3,51.5,44.9z"
              />
              <path
                d="M58.7,47.3l-0.2-0.9h-1.5l-0.2,0.9h-1.4l1.5-4.7h1.7l1.5,4.7H58.7z M58.2,45.4L58,44.6c0-0.2-0.1-0.4-0.2-0.7
                  c-0.1-0.3-0.1-0.5-0.1-0.6c0,0.1-0.1,0.3-0.1,0.5c-0.1,0.2-0.2,0.7-0.4,1.5H58.2z"
              />
              <polygon points="65.8,39.9 65.8,39.2 51.2,39.2 46.4,34.4 41.7,39.2 39,39.2 49,29.2 38.5,29.2 28.4,39.2 24.6,39.2 24.6,39.9 	" />
              <polygon
                points="24.6,49.9 24.6,50.6 39.1,50.6 43.8,55.3 48.6,50.6 51.2,50.6 41.2,60.6 51.7,60.6 57,55.4 61.7,50.6 65.8,50.6
                65.8,49.9"
              />
            </svg>
            <div className="cssda-badge-content">
              <svg
                version="1.1"
                id="cssda-badge-title"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 90 90"
                xmlSpace="preserve"
              >
                <path
                  d="M15.4,42.5l0,1.3l-2.8,0.7c-0.1,0-0.3,0.1-0.6,0.1c-0.3,0.1-0.5,0.1-0.6,0.1c0.1,0,0.3,0.1,0.6,0.1c0.3,0.1,0.5,0.1,0.6,0.1
                    l2.7,0.8l0,1.3L10,48.2l0-1.1l2.9-0.6c0.5-0.1,1-0.2,1.4-0.2c-0.1,0-0.3-0.1-0.6-0.1C13.4,46,13.2,46,13,45.9l-2.9-0.8l0-1l2.9-0.7
                    c0.1,0,0.3-0.1,0.6-0.1c0.3,0,0.5-0.1,0.7-0.1c-0.2,0-0.4-0.1-0.7-0.1c-0.3-0.1-0.5-0.1-0.7-0.2l-2.8-0.7l0-1.1L15.4,42.5z"
                />
                <path d="M16.6,36.4l-0.7,2.9l-5.1-1.2l0.7-2.9l0.9,0.2l-0.5,1.9l1.1,0.3l0.4-1.7l0.9,0.2l-0.4,1.7l1.3,0.3l0.5-1.9L16.6,36.4z" />
                <path
                  d="M12.6,31.9l0.7-1.5c0.3-0.7,0.6-1.1,1-1.3c0.3-0.2,0.7-0.2,1.1,0c0.3,0.1,0.5,0.3,0.6,0.5c0.1,0.2,0.1,0.4,0.1,0.7l0,0
                    c0.2-0.3,0.4-0.4,0.7-0.5c0.2,0,0.5,0,0.8,0.1c0.4,0.2,0.7,0.5,0.8,0.9c0.1,0.4,0,0.9-0.2,1.4l-0.8,1.8L12.6,31.9z M15,31.8l0.3-0.6
                    c0.1-0.3,0.2-0.5,0.2-0.7c0-0.2-0.1-0.3-0.3-0.4c-0.2-0.1-0.3-0.1-0.5,0c-0.1,0.1-0.3,0.3-0.4,0.6l-0.2,0.5L15,31.8z M15.8,32.2
                    l1.2,0.6l0.3-0.7c0.1-0.3,0.2-0.5,0.1-0.7c0-0.2-0.2-0.3-0.4-0.4c-0.4-0.2-0.7,0-1,0.6L15.8,32.2z"
                />
                <path
                  d="M20.9,25.4c0.4,0.3,0.6,0.6,0.6,1.1c0,0.4-0.2,0.9-0.5,1.4c-0.3,0.5-0.7,0.8-1.1,1L19,28.2c0.3-0.2,0.6-0.4,0.8-0.5
                    c0.2-0.2,0.4-0.3,0.5-0.5c0.1-0.2,0.2-0.4,0.2-0.5s-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.1-0.3-0.1s-0.2,0-0.3,0.1s-0.4,0.2-0.7,0.3
                    c-0.3,0.2-0.6,0.3-0.8,0.3c-0.2,0-0.4,0.1-0.6,0c-0.2,0-0.4-0.1-0.6-0.3c-0.4-0.3-0.6-0.6-0.6-1s0.1-0.8,0.4-1.3
                    c0.2-0.2,0.3-0.4,0.5-0.6c0.2-0.2,0.4-0.3,0.7-0.5l0.5,0.8c-0.3,0.2-0.5,0.3-0.6,0.4c-0.1,0.1-0.3,0.3-0.4,0.4
                    c-0.1,0.2-0.2,0.3-0.2,0.5c0,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.1,0.2,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.4-0.2,0.7-0.4
                    c0.5-0.3,0.9-0.4,1.2-0.4C20.3,25.1,20.6,25.2,20.9,25.4z"
                />
                <path d="M23.6,24.6l-3.7-3.7l0.8-0.8l3.7,3.7L23.6,24.6z" />
                <path d="M27.9,20.9L27,21.5L24.5,18l-1.1,0.8l-0.5-0.7l3.2-2.3l0.5,0.7l-1.1,0.8L27.9,20.9z" />
                <path d="M33.6,17.7L30.9,19l-2.3-4.7l2.7-1.3l0.4,0.8L30,14.6l0.5,1l1.6-0.8l0.4,0.8l-1.6,0.8l0.6,1.2l1.7-0.8L33.6,17.7z" />
                <path
                  d="M44,12.8c0.1,0.9-0.1,1.5-0.4,2.1c-0.4,0.5-1,0.8-1.8,0.9c-0.8,0.1-1.4-0.1-1.9-0.5s-0.8-1.1-0.8-1.9
                    c-0.1-0.9,0.1-1.6,0.4-2.1c0.4-0.5,1-0.8,1.8-0.9c0.8-0.1,1.4,0.1,1.9,0.5C43.7,11.3,44,12,44,12.8z M40.2,13.2
                    c0.1,0.6,0.2,1,0.5,1.3c0.3,0.3,0.6,0.4,1,0.3c0.9-0.1,1.3-0.7,1.1-1.9c-0.1-1.2-0.6-1.7-1.5-1.6c-0.4,0-0.8,0.2-1,0.5
                    C40.3,12.2,40.2,12.6,40.2,13.2z"
                />
                <path d="M47.7,15.7l-1.1-0.1l0.6-5.2l3,0.3l-0.1,0.9l-1.9-0.2L48,12.7l1.8,0.2l-0.1,0.9l-1.8-0.2L47.7,15.7z" />
                <path d="M57.5,18.4l-1-0.5l1.8-3.9l-1.3-0.6l0.4-0.8l3.6,1.6L60.5,15l-1.3-0.6L57.5,18.4z" />
                <path d="M64,22.5l-0.9-0.7l1.3-1.8l-1.7-1.2l-1.3,1.8L60.5,20l3.1-4.2l0.9,0.7l-1.2,1.7l1.7,1.2l1.2-1.7l0.9,0.7L64,22.5z" />
                <path d="M67.8,26.5l-2-2.2l3.9-3.5l2,2.2l-0.7,0.6l-1.3-1.4L68.9,23l1.2,1.3l-0.7,0.6l-1.2-1.3l-1,0.9l1.3,1.4L67.8,26.5z" />
                <path
                  d="M75.4,34.8c-0.8,0.3-1.5,0.4-2.1,0.1c-0.6-0.3-1.1-0.8-1.5-1.7l-0.6-1.4l4.8-2l0.6,1.5c0.3,0.8,0.4,1.5,0.1,2.1
                    C76.6,34,76.1,34.5,75.4,34.8z M74.9,33.7c1-0.4,1.4-1.1,1-2l-0.2-0.5l-3.1,1.3l0.2,0.4C73.1,33.9,73.9,34.2,74.9,33.7z"
                />
                <path
                  d="M74.1,41.2l1.2-0.6l-0.3-1.9l-1.3-0.2l-0.2-1.2l5.5,0.9l0.2,1.3l-4.9,2.7L74.1,41.2z M76.1,40.2c1.1-0.5,1.6-0.8,1.8-0.9
                    c0.1-0.1,0.2-0.1,0.3-0.2c-0.3,0-1.1-0.1-2.4-0.3L76.1,40.2z"
                />
                <path d="M77.4,46l2.1,1.2l0,1.2l-3.1-1.8l-2-0.1l0-1.1l2,0.1l3.3-1.6l0,1.2L77.4,46z" />
              </svg>
              <svg
                version="1.1"
                id="cssda-badge-award"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 90 90"
                xmlSpace="preserve"
              >
                <path
                  d="M23.4,72l0.6-1.3l-1.5-1.4l-1.2,0.7l-1-0.9l5.3-2.9l1.1,1l-2.3,5.6L23.4,72z M24.4,69.8c0.5-1.2,0.8-1.8,0.9-2
                    c0.1-0.2,0.1-0.3,0.2-0.4c-0.3,0.2-1,0.6-2.2,1.3L24.4,69.8z"
                />
                <path
                  d="M32,77.2l-1.2-0.6l0.6-3c0-0.1,0.1-0.3,0.2-0.7c0.1-0.3,0.2-0.5,0.2-0.7c-0.1,0.1-0.2,0.3-0.4,0.6c-0.2,0.2-0.3,0.4-0.4,0.5
                    l-2,2.3l-1.2-0.6l1.2-5.7l1.1,0.5L29.3,73c-0.1,0.6-0.3,1.1-0.4,1.5c0.1-0.1,0.2-0.3,0.4-0.6s0.3-0.4,0.5-0.6l2.2-2.5l1,0.5
                    l-0.7,3.2c0,0.1-0.1,0.4-0.2,0.6c-0.1,0.3-0.2,0.5-0.2,0.7c0.1-0.2,0.3-0.4,0.4-0.6c0.2-0.2,0.3-0.4,0.5-0.6l2-2.4l1.1,0.5L32,77.2z
                    "
                />
                <path
                  d="M39.9,79.3l-0.1-1.4l-2-0.4L37,78.8l-1.3-0.2l3-5.2l1.4,0.3l0.9,6L39.9,79.3z M39.6,76.9c-0.1-1.3-0.2-2-0.2-2.2
                    c0-0.2,0-0.3,0-0.4c-0.1,0.3-0.5,1.1-1.2,2.3L39.6,76.9z"
                />
                <path
                  d="M45.4,77.5l0.1,2.2l-1.2,0l-0.2-5.7l1.6-0.1c0.8,0,1.3,0.1,1.7,0.4c0.4,0.3,0.6,0.7,0.6,1.3c0,0.3-0.1,0.6-0.2,0.9
                    c-0.2,0.3-0.4,0.5-0.8,0.6c0.9,1.2,1.5,2,1.8,2.4l-1.3,0.1L46,77.5L45.4,77.5z M45.4,76.5l0.4,0c0.4,0,0.7-0.1,0.8-0.2
                    c0.2-0.1,0.3-0.3,0.2-0.6c0-0.3-0.1-0.5-0.3-0.6S46,75,45.7,75l-0.4,0L45.4,76.5z"
                />
                <path
                  d="M55.7,75c0.3,0.9,0.2,1.7-0.2,2.3c-0.4,0.6-1,1.1-2,1.3L51.9,79l-1.5-5.5l1.7-0.5c0.9-0.3,1.6-0.2,2.3,0.1
                    C55,73.5,55.4,74.1,55.7,75z M54.5,75.3c-0.3-1.2-1-1.6-2-1.3l-0.6,0.2l1,3.6l0.5-0.1C54.4,77.3,54.8,76.5,54.5,75.3z"
                />
                <path d="M62.6,74.7l-2.9,1.6l-2.7-5l2.9-1.6l0.5,0.9l-1.8,1l0.6,1.1l1.7-0.9l0.5,0.9l-1.7,0.9l0.7,1.3l1.8-1L62.6,74.7z" />
                <path
                  d="M67.1,67.5c0.6,0.7,0.9,1.4,0.8,2.1c-0.1,0.7-0.5,1.4-1.2,2.1l-1.2,1.1l-3.7-4.3l1.3-1.2c0.7-0.6,1.4-0.9,2.1-0.9
                    C65.9,66.5,66.6,66.8,67.1,67.5z M66.2,68.3c-0.8-0.9-1.6-1-2.4-0.3l-0.5,0.4l2.4,2.8l0.4-0.3C67,70.1,67,69.3,66.2,68.3z"
                />
              </svg>
            </div>
          </div>
        </a>
      </main>
    </TransitionOverlay>
  );
};

export default memo(Main);
