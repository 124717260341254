import ViewPortfolioClt from "components/views/ViewPortfolioItem/ViewPortfolioClt/";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";

const porfolioItem: PortfolioItemDataWithComponent = {
  id: "clt",
  component: ViewPortfolioClt,
  title: "clt",
  name: "clt",
  altName: "Архитектурная компания\nиз канады",
  year: '2023',
  awardsList: [],

  link: {
    label: "clthouse.io",
    href: "https://clthouse.webflow.io/",
  },

  socials: [
    {
      label: "",
      href: "",
    },
  ],

  preview: {
    label: "корпоративный сайт",
    backgroundColor: "#00387A",
    media: {
      type: "video",
      src: "https://vimeo.com/1008375149/e6165f6b18",
    },
  },

  poolOfWorks: ['Research', 'UX|UI design', 'Page making webflow'],

  type: 'site',
  subtype: 'site',

  description: [
    "Основная цель проекта заключалась в создании визуально привлекательного сайта, отражающего экологически чистый и энергоэффективный подход к строительству"
  ],
  shortDescription: "Архитектурная компания из канады",

  seo: {
    title:
        "Сайт-портфолио для студии художественного литья",
    description:
        "Сайт-портфолио для студии художественного литья",
    keywords: [
      "Сайт-портфолио для студии художественного литья",
      "Сайт-портфолио для студии художественного литья",
      "Сайт-портфолио для студии художественного литья",
      "Сайт-портфолио для студии художественного литья",
      "Сайт-портфолио для студии художественного литья",
    ],
    openGraph: {
      type: "article",
      title:
          "Сайт-портфолио для студии художественного литья",
      description:
          "Сайт-портфолио для студии художественного литья",
    },
  },
};

export default porfolioItem;
