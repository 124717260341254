
import { Fragment, useEffect, useRef, useState } from "react";

import classNames from "classnames";

export interface SplitTextInnerProps {
  className?: string;
  children: string;
  lineClassName?: ((i: number) => string | undefined) | string;
  wordClassName?: ((lineIndex: number, wordIndex: number) => string | undefined | false | null) | string;
  onUpdate?: (data: { lines: (HTMLSpanElement | null)[], words: (HTMLSpanElement | null)[] }) => void;
}

/**
 * разбивает текст на строки и слова
 */
export const SplitTextInner = ({
  children,
  onUpdate,
  lineClassName,
  wordClassName
}: SplitTextInnerProps) => {
  const wordsRef = useRef<{ el: HTMLSpanElement | null, text: string }[]>([]);
  const linesElmsRef = useRef<(HTMLSpanElement | null)[]>([]);
  const [linesAndWords, setLinesAndWords] = useState<string[][]>([children.split(" ")]);
  const readyToUpdateRef = useRef(false);

  wordsRef.current = [];

  useEffect(() => {
    setLinesAndWords([children.split(" ")]);
    const words = wordsRef.current;
    const newLinesAndWords: string[][] = [];

    let lastWordY: number | null = null;
    let line: string[] = [];
    words.forEach(({ el, text }) => {
      const wordY = el!.getBoundingClientRect().top;

      if (wordY !== lastWordY) {
        if (lastWordY !== null) {
          newLinesAndWords.push(line);
        }

        line = [text];
      } else {
        line.push(text);
      }

      lastWordY = wordY;
    });

    if (line.length !== 0) {
      newLinesAndWords.push(line);
    }

    if (linesAndWords.length !== newLinesAndWords.length) {
      setLinesAndWords(newLinesAndWords);
    } else {
      for (let i = 0, len = linesAndWords.length; i < len; ++i) {
        if (linesAndWords[i].length !== newLinesAndWords[i].length) {
          setLinesAndWords(newLinesAndWords);

          break;
        }
      }
    }

    readyToUpdateRef.current = true;
  }, [children]);

  useEffect(() => {
    setLinesAndWords([children.split(" ")]);
    if (readyToUpdateRef.current && onUpdate) {
      const lines = linesElmsRef.current!;
      const words = wordsRef.current!.map(({ el }) => el);
      onUpdate({
        lines,
        words
      })
    }
  }, [children]);

  // 1242
  let wordIndex = -1;

  const setLineEl = (i: number) => (el: HTMLSpanElement | null) => linesElmsRef.current[i] = el;
  const setWordEl = (i: number, word: string) => (el: HTMLSpanElement | null) => wordsRef.current[i] = { el, text: word }

  return (
    <>
      {linesAndWords.map((line, i) => {
        return <span key={i} ref={setLineEl(i)} className={classNames("split-text__line", typeof lineClassName === "function" ? lineClassName(i) : lineClassName)}>
          {line.map((word, j) => {
            return (
              <Fragment key={j}>
                  {/* @ts-ignore */}
                <span ref={setWordEl(++wordIndex, word)} className={classNames("split-text__word", typeof wordClassName === "function" ? wordClassName(i, j) : lineClassName)}>
                  {word}
                </span>
              </Fragment>
            );
          })}
        </span>
      })}
    </>
  );
};

export default SplitTextInner;