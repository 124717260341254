import { useEffect, useRef } from "react";
import gsap from "gsap";

interface TargetBreakpointOrder {
  [key: number]: number;
}

interface SetTargetParams {
  breakpointOrder?: TargetBreakpointOrder;
  selector?: string;
}

type SetTarget = (params?: SetTargetParams) => (el: HTMLElement | null) => void;

type SlideFadeOutWhenInViewChildrenPropType = ({
  setTarget,
  setTriggerEl,
}: {
  setTarget: SetTarget;
  setTriggerEl: (el: HTMLElement | null) => void;
}) => JSX.Element;

interface SlideFadeOutWhenInViewProps {
  /**
   * @default 0.2
   */
  stagger?: number;
  /**
   * @default 0.6
   */
  duration?: number;
  children: SlideFadeOutWhenInViewChildrenPropType;
}

interface Target {
  el: HTMLElement[] | HTMLElement | null;
  breakpointOrder?: TargetBreakpointOrder;
}

const SlideFadeOutWhenInView = ({
  children,
  stagger = 0.2,
  duration = 0.6,
}: SlideFadeOutWhenInViewProps) => {
  const triggerElRef = useRef<HTMLElement | null>(null);
  const targetsRef = useRef<Target[]>([]);

  let targetIndex = -1;

  const setTarget: SetTarget = (params = {}) => {
    return (element) => {
      const el =
        element && params.selector
          ? Array.from(element.querySelectorAll<HTMLElement>(params.selector))
          : element;

      targetsRef.current[++targetIndex] = {
        el,
        breakpointOrder: params.breakpointOrder,
      };
    };
  };

  const setTriggerEl = (el: HTMLElement | null) => {
    triggerElRef.current = el;
  };

  useEffect(() => {
    const triggerEl = triggerElRef.current!;
    const targets = targetsRef.current!;

    const getActiveBreakpoint = () => {
      let result: number | undefined;

      for (let i = 0, len = targets.length; i < len; ++i) {
        const target = targets[i];

        if (target.breakpointOrder) {
          const breakpoint = Object.keys(target.breakpointOrder)
            .reverse()
            .find(
              (breakpoint) => matchMedia(`(min-width: ${breakpoint}px)`).matches
            );

          if (breakpoint && +breakpoint !== result) {
            result = +breakpoint;

            break;
          }
        }
      }

      return result;
    };

    const getTargetBreakpointOrder = (target: Target, breakpoint: number) => {
      if (target.breakpointOrder && target.breakpointOrder[breakpoint]) {
        return target.breakpointOrder[breakpoint];
      }

      return targets.findIndex((t) => t === target);
    };

    const getSortedTargetsElmsByBreakpoint = (breakpoint?: number) => {
      const sortedTargets =
        typeof breakpoint === "number"
          ? [...targets].sort(
              (a, b) =>
                getTargetBreakpointOrder(a, breakpoint) -
                getTargetBreakpointOrder(b, breakpoint)
            )
          : targets;

      return sortedTargets.reduce(
        (acc, target) => acc.concat(target.el),
        [] as (HTMLElement | null)[]
      );
    };

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: triggerEl,
        toggleActions: "play reset play reset",
        // onRefresh: () => {
        //   timeline.clear();
        //   initTimeline();
        // },
      },
    });

    const activeBreakpoint = getActiveBreakpoint();
    const targetsElms = getSortedTargetsElmsByBreakpoint(activeBreakpoint);

    timeline.from(triggerEl, {
      y: 30,
      ease: "ease-out",
      duration: duration + stagger * (targetsElms.length - 1),
    });

    timeline.from(
      targetsElms,
      {
        y: 15,
        opacity: 0,
        stagger: stagger,
        ease: "ease-out",
        duration: duration,
      },
      "<"
    );

    return () => {
      timeline.kill();
    };
  }, [duration, stagger]);

  return children({
    setTarget,
    setTriggerEl,
  });
};

export default SlideFadeOutWhenInView;
