import ViewPortfolioWomanSportSite from "components/views/ViewPortfolioItem/ViewPortfolioWomanSportSite";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";

const portfolioItem: PortfolioItemDataWithComponent = {
  id: "woman-sport-site",
  component: ViewPortfolioWomanSportSite,
  title: "woman-sport",
  name: "Woman sport",
  altName: "Женский\nфитнес-клуб",
  year: 2019,
  link: {
    href: "https://www.wsportclub.ru",
    label: "wsportclub.ru",
  },

  socials: [
    {
      label: "behance",
      href: "https://www.behance.net/gallery/76903475/Woman-Sport",
    },
  ],

  preview: {
    label: "портал для тренировок",
    media: {
      type: "video",
      src: "https://vimeo.com/542713244",
    },
  },

  poolOfWorks: [
    "UX|UI design",
    "Branding",
    "Page making",
    "Personal accounts",
    // "Integration with 1c-bitrix",
    "Integration",
    "1c-fitness",
  ],

  type: "site",
  subtype: "site",

  description:
    "женский фитнес-клуб с уникальными круговыми тренировками. Для него мы создали яркий многостраничный сайт с личным кабинетом",
  shortDescription:
    "сайт для женского фитнес-клуба",


  relatedId: "woman-sport-branding",
  seo: {
    title: "Разработка сайта фитнес клуба Woman Sport с личным кабинетом",
    description: "Дизайн и создание сайта для фитнес клуба Woman Sport включащий интеграцию с 1С фитнес, систему личных кабинетов клиентов и тренеров, а также мобильную и планшетную версию сайта",
    keywords: ["создание сайта для фитнес клуба", "разработка сайта спорт клуба", "дизайн сайта фитнес клуба", "личный кабинет на сайте фитнес клуба", "интеграция сайта с 1с фитнес"],
    openGraph: {
      type: "article",
      title: "Разработка сайта фитнес клуба Woman Sport с личным кабинетом",
      description: "Дизайн и создание сайта для фитнес клуба Woman Sport включащий интеграцию с 1С фитнес, систему личных кабинетов клиентов и тренеров, а также мобильную и планшетную версию сайта",
    }
  },

  pageContent: {
    sections: {
      mobileVersion: {
        theme: {
          backgroundColor: "#E10066",
          color: "#fff",
        },
        frameCount: 160
      }
    }
  }
};

export default portfolioItem;
