import { memo, useCallback, useMemo } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { PortfolioItemMediaTriggerProp } from "./PortfolioItemPreview";
import { buildLinkToPortfolioItem } from "routes/helpers";
import isTouchDevice from "is-touch-device";
import { PortfolioItemData } from "data/portfolio/types";
import { usePortfolioItemAssets } from "hooks/useAssets";
import { IMAGES_PATH } from "helpers/path";
import { ImageConfig } from "components/common/Image/Image";
import PortfolioItemPreviewVideo from "./PortfolioItemPreviewVideo";
import PortfolioItemPreviewImage from "./PortfolioItemPreviewImage";
import i18n from "i18next";
import RevealWhenInView from "../../common/RevealInView/RevealWhenInView";

interface PortfolioItemProps
  extends Pick<
      PortfolioItemData,
      "id" | "preview" | "name" | "subtype" | "noPadding"
    >,
    PortfolioItemMediaTriggerProp {
  className?: string;
}

const DEFAULT_DISPLACEMENT_SRC = `${IMAGES_PATH}/disposition-effect/displacement/15.jpg`;

const PortfolioItem = ({
  id,
  preview,
  trigger,
  name,
  subtype,
  className,
  noPadding,
}: PortfolioItemProps) => {
  const { buildSrc } = usePortfolioItemAssets(id);

  if (!trigger) {
    trigger = {
      type: isTouchDevice() ? "scroll" : "hover",
    };
  }

  const getPreviewImage = useCallback(
    (name: string): ImageConfig => ({
      src: buildSrc(
        {
          name,
          ext: "jpg",
        },
        "preview"
      ),
      sources: [
        {
          type: "webp",
          srcSet: buildSrc(
            {
              name,
              ext: "webp",
            },
            "preview"
          ),
        },
      ],
    }),
    [buildSrc]
  );

  const image: Record<"first" | "second" | "displacement", ImageConfig> =
    useMemo(() => {
      return {
        first: getPreviewImage("1"),
        second: getPreviewImage("2"),
        displacement: {
          src: DEFAULT_DISPLACEMENT_SRC,
        },
      };
    }, [getPreviewImage]);

  const nextLangCode = useMemo(() => {
    return i18n.language === "en" ? "/en" : "";
  }, [i18n.language]);

  return (
    <Link
      to={nextLangCode + buildLinkToPortfolioItem(id)}
      className={classNames(
        "portfolio-item link" +
          (!id ? " no-detail" : "") +
          (noPadding ? " no-padding" : ""),
        className
      )}
    >
      <figure className="portfolio-item__inner">
        <RevealWhenInView
          delay={0}
          scrollTriggerConfig={{
            start: "top-=20% center",
            end: "bottom+=70% center",
          }}
        >
          <div
            className={classNames(
              "portfolio-item__preview",
              `portfolio-item__preview--${preview.media.type}`
            )}
          >
            <div className="portfolio-item__preview-inner">
              {preview.media.type === "video" ? (
                <PortfolioItemPreviewVideo
                  src={preview.media.src}
                  trigger={trigger}
                />
              ) : (
                <PortfolioItemPreviewImage image={image} trigger={trigger} />
              )}
            </div>
          </div>
        </RevealWhenInView>
        <RevealWhenInView delay={0.25}>
          <figcaption data-scroll className="portfolio-item__caption">
            <span className="portfolio-item__name label side-brackets">
              {name}
            </span>
            <span className="portfolio-item__type caption">
              {preview.label}
            </span>
          </figcaption>
        </RevealWhenInView>
      </figure>
    </Link>
  );
};

export default memo(PortfolioItem);
