import List from "components/common/List";
import ViewPortfolioItemImage, {
  ViewPortfolioItemImageData,
} from "../ViewPortfolioItemImage";
import ViewPortfolioItemSection, {
  ViewPortfolioItemSectionConfigProps,
} from "../ViewPortfolioItemSection";
import SlideFadeOutWhenInView from "../ViewPortfolioNovaGroup/SlideFadeOutWhenInView";

interface ViewPortfolioAtlanticInnerPagesSectionProps
  extends ViewPortfolioItemSectionConfigProps {
  caption: [string, string[]];
  images: ViewPortfolioItemImageData[]; // [ViewPortfolioItemImageData, Image, [Image, Image, Image]];
}

const ViewPortfolioAtlanticInnerPagesSection = ({
  caption,
  images,
  ...sectionProps
}: ViewPortfolioAtlanticInnerPagesSectionProps) => {
  return (
    <ViewPortfolioItemSection
      {...sectionProps}
      className="view-portfolio-atlantic-inner-pages-section"
    >
      <div className="container">
        <header className="view-portfolio-atlantic-inner-pages-section__header row">
          <SlideFadeOutWhenInView>
            {({ setTarget, setTriggerEl }) => (
              <div
                ref={setTriggerEl}
                className="col-xl-5 row u-max-w-600 u-m-auto"
              >
                <p
                  ref={setTarget()}
                  className="col-11 view-portfolio-item__text"
                >
                  {caption[0]}
                </p>
                <List
                  ref={setTarget()}
                  items={caption[1]}
                  className="view-portfolio-atlantic-inner-pages-section__header-list col offset-md-2"
                />
              </div>
            )}
          </SlideFadeOutWhenInView>
        </header>
        <div className="row">
          <div className="col-11 col-xl-8 offset-xl-2 row gaps--vertical-medium">
            <ViewPortfolioItemImage className="col-11" {...images[0]} />
            <ViewPortfolioItemImage className="col-11" {...images[1]} />

            <div className="col-11">
              <div className="row gaps--horizontal-medium gaps--vertical-medium">
                <ViewPortfolioItemImage
                  className="col-4 col-md-2 order-2 order-md-1"
                  {...images[2]}
                />
                <ViewPortfolioItemImage
                  className="col-7 col-md-3 order-3 order-md-2"
                  {...images[3]}
                />
                <ViewPortfolioItemImage
                  className="col-11 col-md-6 order-1 order-md-3"
                  {...images[4]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ViewPortfolioItemSection>
  );
};

export default ViewPortfolioAtlanticInnerPagesSection;
